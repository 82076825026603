import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import { Trans } from '@lingui/react'
import login from 'api/login'
import handleAuthorizationUrl from 'util/authorization'
import useAppState from 'components/hooks/useAppState'
import useTracking from 'components/hooks/useTracking'
import useAppSecrets from 'components/hooks/useAppSecrets'
import useAsyncEffect from 'components/hooks/useAsyncEffect'
import AuthenticatingPage from './AuthenticatingPage'

const { REACT_APP_ACTIVE_DIRECTORY_REDIRECT_URI } = process.env

const AuthenticatingPageWithState = () => {
    const appState = useAppState()
    const location = useLocation()
    const navigate = useNavigate()
    const { trackError } = useTracking()
    const apolloClient = useApolloClient()
    const { activeDirectoryClientId } = useAppSecrets()
    const { search } = location

    useAsyncEffect(async () => {
        const { code, state } = handleAuthorizationUrl(
            search,
            activeDirectoryClientId,
        )
        try {
            const {
                me,
                accessToken,
                accessTokenExpiration,
                refreshToken,
                refreshTokenExpiration,
                refreshTokenSlidingWindowExpiration,
            } = await login(apolloClient, {
                code,
                callBackUrl: REACT_APP_ACTIVE_DIRECTORY_REDIRECT_URI,
                invitationId: state,
            })
            await appState.login(
                accessToken,
                accessTokenExpiration,
                refreshToken,
                refreshTokenExpiration,
                refreshTokenSlidingWindowExpiration,
                me,
            )
            navigate('/')
        } catch (e) {
            trackError(e)
            appState.logout()
            navigate('/login', {
                state: {
                    error: `${
                        state
                            ? 'Your invitation has expired.'
                            : "It's not possible for you to login with your Microsoft account."
                    }`,
                    errorMsg: `Contact your Lexor administrator to invite you or 'Activate login with Microsoft' in your Lexor profile page.`,
                },
            })
        }
    }, [search])

    return <AuthenticatingPage />
}

export default AuthenticatingPageWithState
