import gql from 'graphql-tag'

const COMPLETE_ONBOARDING = gql`
    mutation completeOnboarding {
        completeOnboarding
    }
`

export default async function completeOnboarding(apolloClient) {
    const mutation = COMPLETE_ONBOARDING
    await apolloClient.mutate({
        mutation,
    })
    return true
}
