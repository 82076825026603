import React from 'react'
import { Trans } from '@lingui/macro'
import styled from 'styled-components'
import OnboardingModal from 'components/pages/onboarding/OnboardingModal'
import PrimaryButton from 'components/util/PrimaryButton'
import Checkbox from 'components/util/Checkbox'
import LinkButton from 'components/util/LinkButton'
import { TERMS_AND_CONDITIONS_URL, PRIVACY_POLICY_URL } from 'util/constants'
import Tooltip from 'components/util/Tooltip'

const AdminOnboardingModalGetStarted = ({
    disclaimerChecked,
    handleCheckDisclaimer,
    isSubmitting,
    onGetStarted,
    ...props
}) => {
    const termsAndConditionsUrl = (
        <StyledLinkButton href={TERMS_AND_CONDITIONS_URL} target="_blank">
            <Trans>terms & conditions</Trans>
        </StyledLinkButton>
    )
    const privacyStatementUrl = (
        <StyledLinkButton href={PRIVACY_POLICY_URL} target="_blank">
            <Trans>privacy policy</Trans>
        </StyledLinkButton>
    )
    return (
        <OnboardingModal
            title={<Trans>Ready to go!</Trans>}
            subTitle={
                <Trans>You have succesfully setup your account on Lexor.</Trans>
            }
            {...props}
        >
            <Container>
                <Checkbox
                    checked={disclaimerChecked}
                    onChange={(e) => handleCheckDisclaimer(e.target.checked)}
                >
                    <Trans>
                        I agree with the {termsAndConditionsUrl} and{' '}
                        {privacyStatementUrl}
                    </Trans>
                </Checkbox>
            </Container>
            <ButtonContainer>
                <Tooltip
                    show={!disclaimerChecked}
                    label={
                        <Trans>
                            Please accept our terms & conditions and privacy
                            policy
                        </Trans>
                    }
                >
                    <div>
                        <GetStartedButton
                            onClick={onGetStarted}
                            disabled={!disclaimerChecked || isSubmitting}
                            isLoading={isSubmitting}
                        >
                            <Trans>Start using Lexor</Trans>
                        </GetStartedButton>
                    </div>
                </Tooltip>
            </ButtonContainer>
        </OnboardingModal>
    )
}

const StyledLinkButton = styled(LinkButton)`
    :hover {
        text-decoration: underline;
    }
`

const Container = styled.div(
    ({ theme }) => `
    margin-top: ${theme.spacing}rem;
    margin-bottom: ${theme.spacing}rem;
`,
)

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const GetStartedButton = styled(PrimaryButton)(
    ({ theme }) => `
    min-width: 20rem;
    margin: ${theme.spacingSmall}rem;
    align: center;
`,
)

export default AdminOnboardingModalGetStarted
