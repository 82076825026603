import React from 'react'
import styled from 'styled-components'
import { TOPBAR_HEIGHT } from './TopBar'

export const SIDEBAR_WIDTH = 10

const SideBar = ({ children, level = 0, ...props }) => (
    <MainContainer level={level}>
        <Container {...props}>
            <ContentContainer>{children}</ContentContainer>
        </Container>
    </MainContainer>
)

const Container = styled.aside`
    width: ${SIDEBAR_WIDTH}rem;
`

const ContentContainer = styled.div(
    ({ theme }) => `
    position: fixed;
    border-right: 1px solid ${theme.colorGreyLightest};
    height: 100%;
    width: ${SIDEBAR_WIDTH}rem;
    text-align: center;
`,
)

const MainContainer = styled.div(
    ({ theme, level }) => `
    position: fixed;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: ${level * SIDEBAR_WIDTH}rem;
    padding-top: ${TOPBAR_HEIGHT}rem;
    background: ${theme.colorWhite};
    z-index: 1;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.04);
    clip-path: inset(0px -15px 0px 0px);
`,
)

SideBar.WIDTH = SIDEBAR_WIDTH

export default SideBar
