import React from 'react'
import { Trans } from '@lingui/macro'
import styled from 'styled-components'
import FormItem from 'components/util/FormItem'
import TextArea from 'components/util/TextArea'

const TenantSelectionImpersonationForm = ({ formState }) => {
    const { getNativeInputProps, getErrorMessages } = formState
    return (
        <FormContainer>
            <FormItem
                label={<Trans>Description</Trans>}
                error={getErrorMessages('description')}
                required
            >
                <TextArea minRows={3} {...getNativeInputProps('description')} />
            </FormItem>
        </FormContainer>
    )
}

const FormContainer = styled.div`
    display: flex;
    flex-direction column;
`

export default TenantSelectionImpersonationForm
