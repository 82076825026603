import React from 'react'
import styled, { withTheme } from 'styled-components'
import { motion } from 'framer-motion'
import { getAccentColor } from 'components/util/IconContainer'

const SIZE = 32

const Spinner = ({ size = SIZE, ...props }) => (
    <SpinnerContainer
        animate={{ rotate: 360 }}
        transition={{ ease: 'linear', duration: 1, repeat: Infinity }}
    >
        <StyledSvg
            data-testid="Spinner"
            x="0px"
            y="0px"
            width={size}
            height={size}
            viewBox={`0 0 ${SIZE} ${SIZE}`}
            fill="none"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            {...props}
        >
            <title>spinner</title>
            <circle cx="16" cy="16" opacity="0.2" r="15" />
            <path d="M16,1A15,15,0,0,1,31,16" />
        </StyledSvg>
    </SpinnerContainer>
)

const StyledSvg = styled.svg`
    stroke: ${({ color, theme }) =>
        typeof color !== 'undefined'
            ? getAccentColor(color, theme)
            : theme.colorPrimary};
`

const SpinnerContainer = styled(motion.div)`
    display: inline-block;
`

export default withTheme(Spinner)
