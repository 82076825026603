import React, { forwardRef } from 'react'
import styled from 'styled-components'
import Icon from 'components/util/Icon'
import Popover from 'components/util/Popover'
import Spinner from 'components/util/Spinner'

const Dropdown = ({ listItems, children, ...props }) => (
    <Popover content={listItems} {...props}>
        {children}
    </Popover>
)

const DropdownButton = forwardRef((props, ref) => (
    <button type="button" ref={ref} {...props} />
))

const DropdownListItem = ({
    disabled,
    isLoading,
    iconName,
    onClick,
    children,
    ...props
}) => (
    <ListItem>
        <DropdownListButton
            disabled={disabled || isLoading}
            onClick={onClick}
            {...props}
        >
            {isLoading ? (
                <Spinner size={12} />
            ) : (
                <StyledIcon isSmaller name={iconName} />
            )}
            <ChildrenContainer>{children}</ChildrenContainer>
        </DropdownListButton>
    </ListItem>
)

const DropdownHeaderListItem = ({ children }) => (
    <li className="text-center text-gray-900 font-semibold py-2 px-1">
        {children}
    </li>
)

const DropdownList = styled.ul(
    ({ theme }) => `
    display: flex;
    flex-direction: column;
    min-width: 15rem;
    margin-top: ${theme.spacingSmaller}rem;
    margin-bottom: ${theme.spacingSmaller}rem;
`,
)

const ListItem = styled.li(
    ({ theme }) => `
    border-left-color: transparent;
    border-left-width: ${theme.borderWidthLarge}rem;
    :hover {
        background: ${theme.colorGreyWhite};
        border-left-color: ${theme.colorPrimary};
    }
`,
)

const DropdownListButton = styled.button.attrs({ type: 'button' })(
    ({ theme, disabled }) => `
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    line-height: normal;
    color: ${theme.colorGreyDark};
    cursor: ${disabled ? 'default' : 'pointer'};
    padding: ${theme.spacingSmaller}rem ${theme.spacingSmall}rem;
    ${disabled && 'opacity: 0.5;'}
`,
)

const StyledIcon = styled(Icon)(
    ({ theme }) => `
    color: ${theme.colorPrimary};
`,
)

const ChildrenContainer = styled.div(
    ({ theme }) => `
    margin-left: ${theme.spacingSmall}rem;
`,
)

Dropdown.List = DropdownList
Dropdown.Button = DropdownButton
Dropdown.ListItem = DropdownListItem
Dropdown.HeaderListItem = DropdownHeaderListItem

export default Dropdown
