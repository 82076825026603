import React from 'react'
import styled from 'styled-components'
import useTableState from 'components/hooks/useTableState'
import Icon from 'components/util/Icon'

export const ASC = 'asc'
export const DESC = 'desc'

const TableSorting = ({ name }) => {
    const { onSort, activeSortName, activeSortDirection } = useTableState()
    const hasActiveSorting = name === activeSortName
    const AscSorting = hasActiveSorting && activeSortDirection === ASC
    const DescSorting = hasActiveSorting && activeSortDirection === DESC
    return (
        <Container>
            {AscSorting && (
                <SortArrow
                    name="chevronUp"
                    onClick={(e) => {
                        e.stopPropagation()
                        onSort(name, ASC)
                    }}
                    size={10}
                    isSmaller
                />
            )}
            {DescSorting && (
                <SortArrow
                    name="chevronDown"
                    onClick={(e) => {
                        e.stopPropagation()
                        onSort(name, DESC)
                    }}
                    size={10}
                    isSmaller
                />
            )}
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const SortArrow = styled(Icon)(
    ({ theme }) => `
    fill: ${theme.colorGreyBlack};
`,
)

export default TableSorting
