import React from 'react'
import styled from 'styled-components'
import { getPermissionsReasonMessage } from 'util/entities'
import Tooltip from './Tooltip'

const PermissionWrapper = ({ permission = {}, children, position = 'top' }) => {
    const { isHidden, reasonSlug, value: isEnabled } = permission
    const childrenArray = React.Children.toArray(children)
    if ((!isEnabled && isHidden) || childrenArray.length === 0) return null
    return (
        <Tooltip
            disabled={!isEnabled && !isHidden}
            show={!isEnabled && !isHidden}
            label={getPermissionsReasonMessage(reasonSlug)}
            position={position}
        >
            <Container>
                {childrenArray.map((child, index) =>
                    React.cloneElement(child, {
                        // eslint-disable-next-line react/no-array-index-key
                        key: index,
                        disabled:
                            child.props.disabled || (!isEnabled && !isHidden),
                    }),
                )}
            </Container>
        </Tooltip>
    )
}

const Container = styled.div(
    ({ disabled }) => `
    opacity: ${disabled ? 0.4 : 1};
`,
)

export default PermissionWrapper
