import localforage from 'localforage'
import mockDriver from 'localforage-memoryStorageDriver'

// eslint-disable-next-line no-underscore-dangle
const MOCK_DRIVER = mockDriver._driver

if (process.env.NODE_ENV === 'test') {
    localforage.defineDriver(mockDriver)
    localforage.setDriver(MOCK_DRIVER)
}

const ACCESS_TOKEN = 'accessToken'
const ACCESS_TOKEN_EXP = 'accessTokenExpiration'
const REFRESH_TOKEN_KEY = 'refreshToken'
const REFRESH_TOKEN_EXP = 'refreshTokenExpiration'
const REFRESH_TOKEN_WINDOW = 'refreshTokenSlidingWindowExpiration'
const LANGUAGE_KEY = 'language'
const TENANT_GUID = 'tenantGUID'
const IMPERSONATION_KEY = 'impersonationKey'
const AUTHORIZATION_SCHEME = 'authorizationScheme'
const IGNORE_TOKEN_WARNING = 'ignoreTokenWarning'
const MS365_OPEN = 'ms365open'
const TIMER_SECONDS = 'timerSeconds'
const TIMER_IS_ACTIVE = 'timerIsActive'

// In memory cache
let accessToken = null
let accessTokenExpiration = null
let refreshToken = null
let refreshTokenExpiration = null
let refreshTokenSlidingWindowExpiration = null
let tenantGUID = null
let impersonationKey = null
let authorizationScheme = null
let ignoreTokenWarning = null
let ms365open = null
let timerSeconds = null
let timerIsActive = null

export const getAccessToken = async () => {
    if (accessToken === null) {
        accessToken = await localforage.getItem(ACCESS_TOKEN)
    }
    return accessToken
}

export const getAccessTokenExpiration = async () => {
    if (accessTokenExpiration === null) {
        accessTokenExpiration = await localforage.getItem(ACCESS_TOKEN_EXP)
    }
    return accessTokenExpiration
}

export const setAccessToken = async (newAccessToken, newAccessTokenExp) => {
    await localforage.setItem(ACCESS_TOKEN, newAccessToken)
    await localforage.setItem(ACCESS_TOKEN_EXP, newAccessTokenExp)
    accessToken = newAccessToken
    accessTokenExpiration = newAccessTokenExp
}

export const removeAccessToken = async () => {
    await localforage.removeItem(ACCESS_TOKEN)
    await localforage.removeItem(ACCESS_TOKEN_EXP)
    accessToken = null
    accessTokenExpiration = null
}

export const getRefreshToken = async () => {
    if (refreshToken === null) {
        refreshToken = await localforage.getItem(REFRESH_TOKEN_KEY)
    }
    return refreshToken
}

export const getRefreshTokenExpiration = async () => {
    if (refreshTokenExpiration === null) {
        refreshTokenExpiration = await localforage.getItem(REFRESH_TOKEN_EXP)
    }
    return refreshTokenExpiration
}

export const setRefreshToken = async (newRefreshToken, newRefreshTokenExp) => {
    await localforage.setItem(REFRESH_TOKEN_KEY, newRefreshToken)
    await localforage.setItem(REFRESH_TOKEN_EXP, newRefreshTokenExp)
    refreshToken = newRefreshToken
    refreshTokenExpiration = newRefreshTokenExp
}

export const setRefreshTokenWindow = async (date) => {
    await localforage.setItem(REFRESH_TOKEN_WINDOW, date)
    refreshTokenSlidingWindowExpiration = date
}

export const getRefreshTokenWindow = async () => {
    refreshTokenSlidingWindowExpiration =
        await localforage.getItem(REFRESH_TOKEN_WINDOW)
    return refreshTokenSlidingWindowExpiration
}

export const removeRefreshTokenWindow = async () => {
    await localforage.removeItem(REFRESH_TOKEN_WINDOW)
    refreshTokenSlidingWindowExpiration = null
}

export const removeRefreshToken = async () => {
    await localforage.removeItem(REFRESH_TOKEN_KEY)
    await localforage.removeItem(REFRESH_TOKEN_EXP)
    refreshToken = null
    refreshTokenExpiration = null
}

export const getTenantGUID = async () => {
    if (tenantGUID === null) {
        tenantGUID = await localforage.getItem(TENANT_GUID)
    }
    return tenantGUID
}

export const setTentantGUID = async (newTenantGUID) => {
    await localforage.setItem(TENANT_GUID, newTenantGUID)
    tenantGUID = newTenantGUID
}

export const removeTenantGUID = async () => {
    await localforage.removeItem(TENANT_GUID)
    tenantGUID = null
}

export const getImpersonationKey = async () => {
    if (impersonationKey === null) {
        impersonationKey = await localforage.getItem(IMPERSONATION_KEY)
    }
    return impersonationKey
}

export const setImpersonationKey = async (newImpersonationKey) => {
    await localforage.setItem(IMPERSONATION_KEY, newImpersonationKey)
    impersonationKey = newImpersonationKey
}

export const removeImpersonationKey = async () => {
    await localforage.removeItem(IMPERSONATION_KEY)
    impersonationKey = null
}

export const setLanguage = async (newLanguage) => {
    await localforage.setItem(LANGUAGE_KEY, newLanguage)
}

// AUTHORIZATION SCHEME
export const setAuthorizationScheme = async (newAuthorizationScheme) => {
    await localforage.setItem(AUTHORIZATION_SCHEME, newAuthorizationScheme)
    authorizationScheme = newAuthorizationScheme
}
export const getAuthorizationScheme = async () => {
    if (authorizationScheme === null) {
        authorizationScheme = await localforage.getItem(AUTHORIZATION_SCHEME)
    }
    return authorizationScheme
}
export const removeAuthorizationScheme = async () => {
    await localforage.removeItem(AUTHORIZATION_SCHEME)
    authorizationScheme = null
}

// USER IGNORE TOKEN EXPIRATION MODAL
export const setUserHasIgnoretokenWarning = async (newIgnoreTokenWarning) => {
    await localforage.setItem(IGNORE_TOKEN_WARNING, newIgnoreTokenWarning)
    ignoreTokenWarning = newIgnoreTokenWarning
}

export const getUserHasIgnoretokenWarning = async () => {
    if (ignoreTokenWarning === null) {
        ignoreTokenWarning = await localforage.getItem(IGNORE_TOKEN_WARNING)
    }
    return ignoreTokenWarning
}

export const removeUserHasIgnoretokenWarning = async () => {
    await localforage.removeItem(IGNORE_TOKEN_WARNING)
    ignoreTokenWarning = null
}

export const setMS365openModal = async (newMS365open) => {
    await localforage.setItem(MS365_OPEN, newMS365open)
    ms365open = newMS365open
}

export const getMS365openModal = async () => {
    if (ms365open === null) {
        ms365open = await localforage.getItem(MS365_OPEN)
    }
    return ms365open
}

export const removeMS365openModal = async () => {
    await localforage.removeItem(MS365_OPEN)
    ms365open = null
}

// store the seconds and timer status for reload page

export const setTimerSeconds = async (newTimerSeconds) => {
    await localforage.setItem(TIMER_SECONDS, newTimerSeconds)
    timerSeconds = newTimerSeconds
}

export const getTimerSeconds = async () => {
    if (timerSeconds === null) {
        timerSeconds = await localforage.getItem(TIMER_SECONDS)
    }
    return timerSeconds
}

export const removeTimerSeconds = async () => {
    await localforage.removeItem(TIMER_SECONDS)
    timerSeconds = null
}

export const setTimerIsActive = async (newTimerIsActive) => {
    await localforage.setItem(TIMER_IS_ACTIVE, newTimerIsActive)
    timerIsActive = newTimerIsActive
}

export const getTimerIsActive = async () => {
    if (timerIsActive === null) {
        timerIsActive = await localforage.getItem(TIMER_IS_ACTIVE)
    }
    return timerIsActive
}

export const removeTimerIsActive = async () => {
    await localforage.removeItem(TIMER_IS_ACTIVE)
    timerIsActive = null
}
