import React from 'react'
import Logo from 'components/util/Logo'
import AppContainer from 'components/layout/AppContainer'
import PrimaryButton from 'components/util/PrimaryButton'
import TertiaryButton from 'components/util/TertiaryButton'
import Icon from 'components/util/Icon'
import styled from 'styled-components'

const LoginPage = ({ isLoading, onLogin, errorOnLogin, onCloseError }) => (
    <AppContainer
        data-testid="LoginPage"
        className="items-center justify-center flex-col w-1/2 m-auto"
    >
        <Logo size={40} className="mb-20" />
        <div className="grid w-97 gap-y-4">
            <PrimaryButton
                iconName="microsoft"
                onClick={() => onLogin('AD')}
                disabled={isLoading}
                isLoading={isLoading}
            >
                Login with Microsoft
            </PrimaryButton>
            <TertiaryButton
                onClick={() => onLogin('B2C')}
                disabled={isLoading}
                isLoading={isLoading}
            >
                Login with B2C (deprecated)
            </TertiaryButton>
        </div>
        {errorOnLogin && (
            <div className="flex flex-col items-center justify-center bg-red-200 border-solid border mt-10 w-1/4 p-3 rounded  relative ">
                <CloseIcon
                    isSmaller
                    name="close"
                    onClick={() => onCloseError()}
                />
                <p className="font-semibold text-center">
                    {errorOnLogin.error}
                </p>
                <p className="mt-2 text-center">{errorOnLogin.errorMsg}</p>
            </div>
        )}
    </AppContainer>
)

const CloseIcon = styled(Icon)`
    position: absolute;
    top: ${({ theme }) => theme.spacingSmaller}rem;
    right: ${({ theme }) => theme.spacingSmaller}rem;
    color: ${({ theme }) => theme.colorGreyDark};
`
export default LoginPage
