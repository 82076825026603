/* eslint-disable react/no-unstable-nested-components */
import React, { forwardRef } from 'react'
import clsx from 'clsx'
import Popover from './Popover2'
import DropdownMenuItem from './DropdownMenuItem'

const DropdownMenu = forwardRef(
    ({ content, className, style, ...props }, ref) => (
        <Popover
            content={(options) => (
                <div
                    style={style}
                    className={clsx(
                        'my-2 rounded-lg shadow-lg border border-gray-100',
                        className,
                    )}
                >
                    <ul
                        ref={ref}
                        className="rounded-lg bg-white shadow-lg max-h-[400px] overflow-auto list-none"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                    >
                        {(() => {
                            if (typeof content === 'function') {
                                return content(options)
                            }
                            return content
                        })()}
                    </ul>
                </div>
            )}
            className="flex-1"
            {...props}
        />
    ),
)

DropdownMenu.Item = DropdownMenuItem

export default DropdownMenu
