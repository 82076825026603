import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState from 'components/hooks/useFormState'
import createFormValidation from 'util/createFormValidation'

const INITIAL_VALUES = {
    project: null,
    description: '',
}

const toActivityFormValues = ({ date, end, start, project }) => {
    let values = INITIAL_VALUES
    if (project !== null) {
        values = { ...values, project }
    }
    return {
        project: values.project,
        description: values.description,
    }
}

export const toActivityInput = ({ project, description }) => ({
    projectId: project !== null ? project.id : null,
    description,
})

export const validation = createFormValidation([
    {
        path: 'description',
        validate: (description) => description.length > 0,
        message: <Trans>Description is required</Trans>,
    },
])

const useCreateActivityForm = (values, options) => {
    const formState = useFormState(toActivityFormValues(values), {
        validation,
        valuesToInput: toActivityInput,
        ...options,
    })

    const handleChangeProject = (value) => {
        formState.setValues({
            project: value,
        })
    }

    return {
        ...formState,
        handleChangeProject,
    }
}

export default useCreateActivityForm
