import { createGlobalStyle, css } from 'styled-components'

// @TODO: move this to styles/index.css

const AppGlobalStyles = createGlobalStyle`
    ${({ theme }) => css`
        *,
        *:after,
        *:before {
            box-sizing: inherit;
            border-width: 0;
            border-style: solid;
            border-color: ${theme.defaultBorderColor};
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
        *:focus {
            // outline: 5px auto ${theme.colorPrimaryDark};
        }

        html {
            box-sizing: border-box;
            font-size: 62.5%;
        }

        body {
            font-family: ${theme.defaultFontFamily};
            font-size: ${theme.defaultFontSize}rem;
            font-weight: ${theme.defaultFontWeight};
            line-height: ${theme.defaultLineHeight};
            letter-spacing: ${theme.defaultLetterSpacing}rem;
            color: ${theme.defaultFontColor};
            background-color: ${theme.defaultBackgroundColor};
        }

        a {
            text-decoration: none;
            color: ${theme.defaultFontColor};
            cursor: pointer;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin: 0;
        }

        img {
            max-width: 100%;
        }

        svg {
            vertical-align: middle;
            display: inline;
        }

        td,
        th {
            padding: 0;
            margin: 0;
        }

        button {
            color: inherit;
            background-color: transparent;
            padding: 0;
            font-size: ${theme.defaultFontSize}rem;
            font-weight: ${theme.defaultFontWeight};
            cursor: pointer;
        }

        [data-reach-dialog-overlay] {
            background: hsla(0, 0%, 0%, 0.33);
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            overflow: auto;
            z-index: ${theme.modalBackgroundZIndex};
        }

        [data-reach-dialog-content] {
            margin: 5vh auto;
            background: white;
            outline: none;
            z-index: ${theme.modalZIndex};
        }

        [data-reach-tooltip] {
            z-index: 1;
            pointer-events: none;
            position: absolute;
            padding: ${theme.spacingSmall}rem;
            box-shadow: ${theme.boxShadowLarge};
            white-space: nowrap;
            background-color: ${theme.colorGreyBlack};
            background-color: ${theme.colorWhite};
        }

        :root {
            --reach-combobox: 1;
        }

        [data-reach-combobox] {
            position: relative;
        }

        [data-reach-combobox-popover] {
            z-index: ${theme.selectMenuZIndex};
            box-shadow: ${theme.boxShadow};
            background: ${theme.colorWhite};
            border-radius: ${theme.defaultBorderRadius}rem;
        }

        [data-reach-combobox-list] {
            margin: 0;
            padding: 0;
            list-style: none;
            user-select: none;
        }

        [data-reach-combobox-option] {
            padding: ${theme.spacingSmaller}rem;
            font-size: ${theme.fontSize}rem;
            font-weight: ${theme.fontWeightLight};
            border-radius: ${theme.defaultBorderRadius}rem;
        }

        [data-reach-combobox-option]:hover,
        [data-reach-combobox-option][aria-selected='true'],
        [data-reach-combobox-option][aria-selected='true']:hover {
            color: ${theme.colorWhite};
            background: ${theme.colorPrimary};
        }

        [data-suggested-value] {
            font-weight: ${theme.fontWeightBold};
        }
    `}
`

export default AppGlobalStyles
