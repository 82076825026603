import gql from 'graphql-tag'

const CREATE_COWORKERS_MUTATION = gql`
    mutation createCoworkers($input: [CreateCoworkerInput]!) {
        createCoworkers(input: $input) {
            partners {
                id
            }
        }
    }
`

export default async function createCoworkers(apolloClient, input, options) {
    const mutation = CREATE_COWORKERS_MUTATION
    const variables = { input }
    const response = await apolloClient.mutate({
        mutation,
        variables,
        ...options,
    })
    return response.data
}
