import React from 'react'
import styled, { css } from 'styled-components'
import Icon from 'components/util/Icon'
import Title from 'components/util/Title'

const ModalTopBar = ({ title, onClose, ...props }) => (
    <Container {...props}>
        <Title data-testid="ModalTopBarTitle">{title}</Title>
        {typeof onClose === 'function' && (
            <Icon
                data-testid="ModalTopBarCloseButton"
                name="cross"
                onClick={onClose}
            />
        )}
    </Container>
)

const Container = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${({ hasTabs, theme }) =>
        !hasTabs &&
        css`
            border-bottom-width: ${theme.borderWidth}rem;
            border-bottom-color: ${theme.colorGreyLighter};
        `}
    padding: ${({ theme }) => `${theme.spacingSmaller}rem ${theme.spacing}rem`};
`

export default ModalTopBar
