import React from 'react'
import { Trans } from '@lingui/macro'
import styled from 'styled-components'
import Modal, { ModalMainContainer } from 'components/layout/Modal'
import InversedButton from 'components/util/InversedButton'
import ModalLoading from 'components/layout/ModalLoading'
import ModalTopBar from 'components/layout/ModalTopBar'
import PrimaryButton from 'components/util/PrimaryButton'
import { sortBy } from 'util/index'

const TenantSelectionModal = ({
    isLoading,
    isSubmitting,
    availableTenants,
    availableImpersonationRights = [],
    onSelectTenant,
    onSelectImpersonation,
    onTerminateImpersonation,
    onClose,
}) => {
    if (isLoading) {
        return <ModalLoading />
    }
    const sortedAvailableImpersonationRights = [
        ...availableImpersonationRights,
    ].sort(sortBy('puppetLoginEmail'))
    return (
        <Modal ariaLabel="Select organization">
            <ModalTopBar
                title={<Trans>Select your organization</Trans>}
                onClose={onClose}
            />
            <StyledModalMainContainer>
                {availableTenants.map(({ name, guid }) => (
                    <TenantButton
                        onClick={() => onSelectTenant(guid)}
                        key={guid}
                    >
                        {name}
                    </TenantButton>
                ))}
                {sortedAvailableImpersonationRights.map(
                    ({ key, puppetLoginEmail, tenant }) => (
                        <Flex key={tenant.guid + puppetLoginEmail}>
                            <ImpersonationButton
                                isAddonBefore
                                onClick={() =>
                                    onSelectImpersonation(key, tenant.guid)
                                }
                            >
                                {puppetLoginEmail} : {tenant.name}
                            </ImpersonationButton>
                            <TerminateButton
                                isAddonAfter
                                onClick={() => onTerminateImpersonation(key)}
                                disabled={isSubmitting === key}
                                iconName="cross"
                            />
                        </Flex>
                    ),
                )}
            </StyledModalMainContainer>
        </Modal>
    )
}

const Flex = styled.div`
    display: flex;
`

const StyledModalMainContainer = styled(ModalMainContainer)(
    ({ theme }) => `
    align-items: center;
    text-align: center;
    padding-left: ${theme.spacingLargest}rem;
    padding-right: ${theme.spacingLargest}rem; 
`,
)

const TenantButton = styled(InversedButton)(
    ({ theme }) => `
    width: 100%;
    padding: ${theme.spacing}rem;
    margin-top: ${theme.spacingSmall}rem;
    margin-bottom: ${theme.spacingSmall}rem;
`,
)

const ImpersonationButton = styled(PrimaryButton)(
    ({ theme }) => `
    width: 100%;
    padding: ${theme.spacingSmallest}rem;
    margin-top: ${theme.spacingSmallest}rem;
    margin-bottom: ${theme.spacingSmallest}rem;
    margin-right: ${theme.borderWidth}rem;
    color: ${theme.colorGrey};
    background-color: ${theme.colorGreyLightest};
    border-color: ${theme.colorGreyLightest};
    :hover:not(:disabled) {
        background-color: ${theme.colorGreyLighter};
        border-color: ${theme.colorGreyLighter};
    }
`,
)

const TerminateButton = styled(PrimaryButton)(
    ({ theme }) => `
    padding: ${theme.spacingSmallest}rem;
    margin-top: ${theme.spacingSmallest}rem;
    margin-bottom: ${theme.spacingSmallest}rem;
    color: ${theme.colorGrey};
    background-color: ${theme.colorGreyLightest};
    border-color: ${theme.colorGreyLightest};
    :hover:not(:disabled) {
        background-color: ${theme.colorGreyLighter};
        border-color: ${theme.colorGreyLighter};
    }
    :disabled {
        cursor: not-allowed;
        background-color: ${theme.colorGreyLighter};
        border-color: ${theme.colorGreyLighter};
    }
`,
)

export default TenantSelectionModal
