import React, { forwardRef } from 'react'
import Async from 'react-select/async'
import { components as SelectComponents } from 'react-select'
import styled, { useTheme } from 'styled-components'
import Icon from 'components/util/Icon'
import { Trans } from '@lingui/macro'

const { DropdownIndicator } = SelectComponents

export const getSelectTheme = (selectTheme, theme) => ({
    ...selectTheme,
    colors: {
        ...selectTheme.colors,
        primary: theme.colorPrimary,
        primary25: theme.colorPrimaryLightest,
    },
})

export const getSelectStyles = (
    theme,
    {
        isAddonBefore,
        isAddonAfter,
        hasAddonBefore,
        hasAddonAfter,
        hasError,
        isDisabled,
    },
) => ({
    container: (provided) => ({
        ...provided,
        boxShadow: 'none',
    }),
    control: (provided) => ({
        ...provided,
        outline: 'unset',
        boxShadow: 'none',
        minWidth: '12rem',
        borderRight: isAddonBefore && 0,
        borderLeft: isAddonAfter && 0,
        borderTopRightRadius:
            hasAddonAfter || isAddonBefore
                ? 0
                : `${theme.defaultBorderRadius}rem`,
        borderTopLeftRadius:
            hasAddonBefore || isAddonAfter
                ? 0
                : `${theme.defaultBorderRadius}rem`,
        borderBottomRightRadius:
            hasAddonAfter || isAddonBefore
                ? 0
                : `${theme.defaultBorderRadius}rem`,
        borderBottomLeftRadius:
            hasAddonBefore || isAddonAfter
                ? 0
                : `${theme.defaultBorderRadius}rem`,
        borderColor: hasError ? theme.colorError : theme.defaultBorderColor,
        minHeight: '3.8rem',
        backgroundColor: isDisabled ? theme.colorGreyWhite : theme.colorWhite,
        '&:hover': {
            borderColor: theme.colorGrey,
        },
    }),
    option: (provided) => ({
        ...provided,
        padding: '8px 12px', // For some reason async creatable returns NaNpx here
        cursor: 'pointer',
        fontWeight: theme.fontWeightLight,
        '&:hover': {
            color: theme.colorBlack,
            background: theme.colorPrimaryLightest,
        },
    }),
    placeholder: (provided) => ({
        ...provided,
        color: hasError ? theme.colorRed : theme.colorGreyLight,
        fontStyle: 'italic',
    }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: theme.selectMenuZIndex,
    }),
    menu: (provided) => ({
        ...provided,
        margin: 0,
        border: 'none',
        borderRadius: `${theme.defaultBorderRadius}rem`,
        boxShadow: theme.boxShadow,
        zIndex: theme.selectMenuZIndex,
    }),
    dropdownIndicator: () => ({
        paddingRight: `${theme.spacingSmall}rem`,
    }),
    valueContainer: (provided) => ({
        ...provided,
        paddingLeft: `${theme.spacingSmaller}rem`,
    }),
    singleValue: (provided) => ({
        ...provided,
        margin: 0,
    }),
    multiValue: (provided) => ({
        ...provided,
        color: theme.colorWhite,
        backgroundColor: theme.colorPrimary,
        margin: 0,
        marginRight: `${theme.spacingSmaller}rem`,
        borderRadius: `${theme.borderRadius}rem`,
        maxWidth: '20rem',
        '&:not(:first-of-type)': {
            marginTop: `${theme.spacingMinimum}rem`,
        },
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        padding: '.2rem',
        paddingLeft: `${theme.spacingSmaller}rem`,
        paddingRight: `${theme.spacingSmallest}rem`,
        color: theme.colorWhite,
        fontSize: `${theme.fontSize}rem`,
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        cursor: 'pointer',
    }),
})

const Select = forwardRef(
    (
        {
            testId,
            hasError,
            isFixed,
            isSearchable = true,
            isAddonBefore = false,
            isAddonAfter = false,
            hasAddonBefore = false,
            hasAddonAfter = false,
            isDisabled,
            getOptionValue = ({ id }) => id,
            getOptionLabel = ({ translation }) => translation,
            noResultsText = <Trans>No results</Trans>,
            loadingText = <Trans>Loading</Trans>,
            loadOptions,
            ...props
        },
        ref,
    ) => {
        const theme = useTheme()
        let selectProps = props

        if (isFixed) {
            selectProps = {
                ...selectProps,
                menuPosition: 'fixed',
                menuPlacement: 'bottom',
                menuPortalTarget: document.body,
            }
        }
        return (
            <Async
                ref={ref}
                instanceId={testId}
                inputId={`${testId || selectProps.name}Input`}
                theme={(selectTheme) => getSelectTheme(selectTheme, theme)}
                styles={getSelectStyles(theme, {
                    isDisabled,
                    hasError,
                    isAddonBefore,
                    isAddonAfter,
                    hasAddonAfter,
                    hasAddonBefore,
                })}
                components={{
                    // eslint-disable-next-line no-use-before-define
                    DropdownIndicator: SelectDropdownIndicator,
                    IndicatorSeparator: () => null,
                }}
                isDisabled={isDisabled}
                isSearchable={
                    typeof loadOptions !== 'undefined' && isSearchable
                }
                getOptionValue={getOptionValue}
                getOptionLabel={getOptionLabel}
                noOptionsMessage={() => noResultsText}
                loadingMessage={() => loadingText}
                loadOptions={loadOptions}
                backspaceRemovesValue={false}
                {...selectProps}
            />
        )
    },
)

export const SelectDropdownIndicator = (props) => {
    const { selectProps } = props
    return (
        <DropdownIndicator {...props}>
            <SelectDropDownIndicatorIcon
                isSmaller
                name={selectProps.menuIsOpen ? 'chevronUp' : 'chevronDown'}
            />
        </DropdownIndicator>
    )
}

export const SelectDropDownIndicatorIcon = styled(Icon)`
    fill: ${({ theme }) => theme.colorGreyLight};
`

export default Select
