import { gql, useMutation, useApolloClient } from '@apollo/client'
// import { useCacheAndNetworkQuery } from 'components/hooks/useApolloQueries'


const mutation = gql`
    mutation useO365UserToLogInMutation($o365UserId: Int) {
        useO365UserToLogIn(o365UserId: $o365UserId)
    }
`
export default function useUseO365UserToLogInMutation(options) {
    return useMutation(mutation, options)
}


