import React, { useEffect } from 'react'
import { setupI18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { en, nl, fr, es } from 'make-plural/plurals'
import { messages as messagesEn } from 'locales/en/messages'
import { messages as messagesNl } from 'locales/nl/messages'
import { messages as messagesFr } from 'locales/fr/messages'
import { messages as messagesEs } from 'locales/es/messages'

const EN = 'en'
const NL = 'nl'
const FR = 'fr'
const ES = 'es'

export const i18n = setupI18n()

i18n.loadLocaleData('en', { plurals: en })
i18n.loadLocaleData('nl', { plurals: nl })
i18n.loadLocaleData('fr', { plurals: fr })
i18n.loadLocaleData('es', { plurals: es })

// load empty messages when the messages are not available after import
i18n.load({
    en: messagesEn || {},
    nl: messagesNl || {},
    fr: messagesFr || {},
    es: messagesEs || {},
})

const LanguageProvider = ({ languageCode = EN, children }) => {
    useEffect(() => i18n.activate(languageCode), [languageCode])
    return (
        <I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
            {children}
        </I18nProvider>
    )
}

LanguageProvider.EN = EN
LanguageProvider.NL = NL
LanguageProvider.FR = FR
LanguageProvider.ES = ES

export default LanguageProvider
