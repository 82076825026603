import React from 'react'
import { Trans } from '@lingui/macro'
import styled from 'styled-components'
import Modal, { ModalMainContainer } from 'components/layout/Modal'
import PrimaryButton from 'components/util/PrimaryButton'
import Logo from 'components/util/Logo'
import InversedButton from 'components/util/InversedButton'

const OnboardingModal = ({
    title,
    subTitle,
    onNext,
    onSkip,
    onPrevious,
    currentStep,
    totalSteps,
    isSubmitting,
    showNextButton,
    showPreviousButton,
    showSkipButton,
    children,
}) => {
    const showSteps = typeof currentStep !== 'undefined'
    const isLastStep = currentStep === totalSteps
    return (
        <StyledModal ariaLabel="Onboarding" islarge="true">
            <TopBar>
                <StyledLogo size={36} />
                <TitleContainer>
                    <Title>{title}</Title>
                    {showSteps && (
                        <Step>
                            <CurrentStep>{currentStep}</CurrentStep>/
                            {totalSteps}
                        </Step>
                    )}
                </TitleContainer>
                <SubTitle>{subTitle}</SubTitle>
            </TopBar>
            <StyledModalMainContainer>{children}</StyledModalMainContainer>
            <BottomBar>
                {showPreviousButton && (
                    <PreviousButton
                        onClick={onPrevious}
                        isLoading={isSubmitting}
                        disabled={isSubmitting}
                    >
                        <Trans>Previous</Trans>
                    </PreviousButton>
                )}
                {showSkipButton && (
                    <SkipButton
                        onClick={onSkip}
                        isLoading={isSubmitting}
                        disabled={isSubmitting}
                    >
                        <Trans>Skip</Trans>
                    </SkipButton>
                )}
                {showNextButton && (
                    <NextButton
                        onClick={onNext}
                        isLoading={isSubmitting}
                        disabled={isSubmitting}
                    >
                        {isLastStep ? (
                            <Trans>Finish!</Trans>
                        ) : (
                            <Trans>Next</Trans>
                        )}
                    </NextButton>
                )}
            </BottomBar>
        </StyledModal>
    )
}

const StyledModal = styled(Modal)(
    ({ theme }) => `
    box-shadow: ${theme.boxShadowLarger};
`,
)

const StyledLogo = styled(Logo)(
    ({ theme }) => `
    margin-bottom: ${theme.spacing}rem;
`,
)

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const Title = styled.h2(
    ({ theme }) => `
    font-size: 3rem;
    font-weight: ${theme.fontWeightBold};
`,
)

const Step = styled.span(
    ({ theme }) => `
    color: ${theme.colorGreyDark};
    font-size: ${theme.fontSizeLargest}rem;
`,
)

const CurrentStep = styled.span(
    ({ theme }) => `
    color: ${theme.colorPrimary};
`,
)

const SubTitle = styled.h3(
    ({ theme }) => `
    color: ${theme.colorGrey};
    font-size: ${theme.fontSizeLarger}rem;
    font-weight: ${theme.fontWeightRegular};
`,
)

const NextButton = styled(PrimaryButton)(
    ({ theme }) => `
    margin-left: ${theme.spacing}rem;
`,
)

const PreviousButton = styled(InversedButton)(
    ({ theme }) => `
    margin-left: ${theme.spacing}rem;
`,
)

const SkipButton = styled(InversedButton)(
    ({ theme }) => `
    margin-left: ${theme.spacing}rem;
`,
)

const StyledModalMainContainer = styled(ModalMainContainer)(
    ({ theme }) => `
    position: relative;
    padding-left: ${theme.spacingLargest}rem;
    padding-right: ${theme.spacingLargest}rem;
`,
)

const TopBar = styled.header(
    ({ theme }) => `
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: ${theme.spacingLargest}rem;
    padding-bottom: 0;
`,
)

const BottomBar = styled.footer(
    ({ theme }) => `
    display: flex;
    align-items: right;
    justify-content: flex-end;
    padding: ${theme.spacingLargest}rem;
    padding-top: 0;
`,
)

export default OnboardingModal
