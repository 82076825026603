import React from 'react'
import { Trans } from '@lingui/react'
import * as routes from 'routes/index'
import usePermissions from 'components/hooks/usePermissions'
import SideBar from 'components/layout/SideBar'
import SideBarMenu from 'components/layout/SideBarMenu'
import SideBarMenuItem from 'components/layout/SideBarMenuItem'
import PermissionWrapper from 'components/util/PermissionWrapper'

const MainSideBar = () => {
    const {
        canReadTimesheetEntries,
        canReadActivities,
        canReadActivityStatistics,
        canReadProjects,
        canReadPartners,
        canReadCosts,
    } = usePermissions()
    return (
        <SideBar>
            <SideBarMenu>
                <PermissionWrapper permission={canReadTimesheetEntries}>
                    <SideBarMenuItem
                        iconName="pencil"
                        path="/timesheet-entries"
                    >
                        <Trans id="Timesheets input" />
                    </SideBarMenuItem>
                </PermissionWrapper>
                <PermissionWrapper permission={canReadTimesheetEntries}>
                    <SideBarMenuItem
                        iconName="clock"
                        path="/timesheet-entries-list"
                    >
                        <Trans id="Timesheets overview" />
                    </SideBarMenuItem>
                </PermissionWrapper>
                <PermissionWrapper permission={canReadActivityStatistics}>
                    <SideBarMenuItem
                        iconName="dashboard"
                        path={routes.dashboard()}
                    >
                        <Trans id="Dashboard" />
                    </SideBarMenuItem>
                </PermissionWrapper>
                <PermissionWrapper permission={canReadActivities}>
                    <SideBarMenuItem
                        iconName="calendar"
                        path={routes.activities()}
                    >
                        <Trans id="Activities" />
                    </SideBarMenuItem>
                </PermissionWrapper>
                <PermissionWrapper permission={canReadProjects}>
                    <SideBarMenuItem
                        iconName="projects"
                        path={routes.projects()}
                    >
                        <Trans id="Projects" />
                    </SideBarMenuItem>
                </PermissionWrapper>
                <PermissionWrapper permission={canReadPartners}>
                    <SideBarMenuItem
                        iconName="contacts"
                        path={routes.contacts()}
                    >
                        <Trans id="Contacts" />
                    </SideBarMenuItem>
                </PermissionWrapper>
                <PermissionWrapper permission={canReadCosts}>
                    <SideBarMenuItem iconName="coins" path={routes.costs()}>
                        <Trans id="Costs" />
                    </SideBarMenuItem>
                </PermissionWrapper>
                <SideBarMenuItem iconName="settings" path={routes.settings()}>
                    <Trans id="Settings" />
                </SideBarMenuItem>
            </SideBarMenu>
        </SideBar>
    )
}

MainSideBar.WIDTH = SideBar.WIDTH

export default MainSideBar
