import gql from 'graphql-tag'
import { PARTNER_DETAILS_FRAGMENT } from 'components/pages/contact-details/useFetchContactDetailsPageData'

const updatePartnerMutation = gql`
    mutation updatePartner($partnerId: Int!, $input: UpdatePartnerInput!) {
        updatePartner(id: $partnerId, input: $input) {
            partner {
                ...PartnerDetails
            }
        }
    }
    ${PARTNER_DETAILS_FRAGMENT}
`

export default async function updatePartner(
    apolloClient,
    partnerId,
    input,
    options,
) {
    const mutation = updatePartnerMutation
    const variables = { partnerId, input }
    const response = await apolloClient.mutate({
        mutation,
        variables,
        ...options,
    })
    return response.data.updatePartner
}
