import React, { forwardRef } from 'react'
import styled from 'styled-components'
import IconContainer from 'components/util/IconContainer'
import { colorPrimary } from 'styles/theme'

const ActivateProject = () => (
    <>
        <path d="M15,2H8.4L6.7,0.3C6.5,0.1,6.3,0,6,0H1C0.4,0,0,0.4,0,1v14c0,0.6,0.4,1,1,1h14c0.6,0,1-0.4,1-1V3 C16,2.4,15.6,2,15,2z M14,14H2V2h3.6l1.7,1.7C7.5,3.9,7.7,4,8,4h6V14z" />
        <polygon points="7,6 7,8 5,8 5,10 7,10 7,12 9,12 9,10 11,10 11,8 9,8 9,6 " />
    </>
)

const ActivatePerson = () => (
    <>
        <path d="M7,14c0-1.531,0.578-2.924,1.522-3.984C8.348,10.011,8.18,10,8,10c-3.192,0-5.539,0.795-6.837,1.382 C0.45,11.705,0,12.417,0,13.2V16h7.349C7.127,15.374,7,14.702,7,14z" />
        <circle cx="8" cy="4" r="4" />
        <path d="M11,16c-0.256,0-0.512-0.098-0.707-0.293l-2-2l1.414-1.414L11,13.586l3.293-3.293 l1.414,1.414l-4,4C11.512,15.902,11.256,16,11,16z" />
    </>
)

const Add = () => (
    <>
        <path d="M8,5.5H6.5V4a.5.5,0,0,0-1,0V5.5H4a.5.5,0,0,0,0,1H5.5V8a.5.5,0,0,0,1,0V6.5H8a.5.5,0,0,0,0-1Z" />
        <path d="M6,0a6,6,0,1,0,6,6A6.006,6.006,0,0,0,6,0ZM6,10a4,4,0,1,1,4-4A4,4,0,0,1,6,10Z" />
    </>
)

const AI = () => (
    <>
        <path d="M7.38058 10.4565H5.52037L5.03774 8.43505H2.45125L1.96863 10.4565H0.155273L2.73239 0.456543H4.80346L7.38058 10.4565ZM4.60197 6.60161L3.7445 3.01531L2.88702 6.60161H4.60197Z" />
        <path d="M12.1553 10.4565H8.06937V8.68354H9.21267V2.22954H8.06937V0.456543H12.1553V2.22954H11.012V8.68354H12.1553V10.4565Z" />
    </>
)

const ArrowLeft = () => (
    <path d="M6.414,2,5,.586.293,5.293a1,1,0,0,0,0,1.414L5,11.414,6.414,10l-3-3H12V5H3.414Z" />
)

const ArrowRight = () => (
    <path d="M1,7H8.586L6.293,9.293a1,1,0,1,0,1.414,1.414l4-4a1,1,0,0,0,0-1.416l-4-4A1,1,0,0,0,6.293,2.707L8.586,5H1A1,1,0,0,0,1,7Z" />
)

const Attachment = () => (
    <path d="M10.217,6.636,7.254,9.6A3.077,3.077,0,0,1,2.9,5.249L6.124,2.027a1.847,1.847,0,0,1,2.547,0,1.8,1.8,0,0,1,0,2.547L5.708,7.536a.526.526,0,0,1-.743,0,.528.528,0,0,1,0-.743L7.154,4.6a.75.75,0,1,0-1.06-1.06L3.9,5.733A2.026,2.026,0,0,0,6.769,8.6L9.731,5.635A3.3,3.3,0,0,0,5.063.967L1.843,4.188A4.576,4.576,0,0,0,8.314,10.66L11.277,7.7a.75.75,0,0,0-1.06-1.06Z" />
)

const AzureActiveDirectory = () => (
    <path
        fill="currentColor"
        stroke="none"
        d="m5.444 1 3.538 3.057L12 9.457H9.278L5.444 1Zm-.471.715 1.51 4.241-2.895 3.625 5.617.962H0l4.973-8.828Z"
    />
)

const Ban = () => (
    <path d="M10.242,1.758l-.011-.008A6,6,0,0,0,1.75,10.231l.008.011.011.008A6,6,0,0,0,10.25,1.769ZM6,2a3.947,3.947,0,0,1,2.019.567L2.567,8.019A3.947,3.947,0,0,1,2,6,4,4,0,0,1,6,2Zm0,8a3.947,3.947,0,0,1-2.019-.567L9.433,3.981A3.947,3.947,0,0,1,10,6,4,4,0,0,1,6,10Z" />
)

const Bin = () => (
    <>
        <polygon points="8 0 4 0 4 2 1 2 1 4 11 4 11 2 8 2 8 0" />
        <path d="M8,10H4V6H2v5a1,1,0,0,0,1,1H9a1,1,0,0,0,1-1V6H8Z" />
    </>
)

const Block = () => (
    <path
        stroke="currentColor"
        fill="none"
        d="M6 11A5 5 0 1 0 6 1a5 5 0 0 0 0 10ZM9.5 2.5l-7 7"
    />
)

const Calculator = () => (
    <>
        <path d="M2.85742 5H1.70215V1.32227L0.567383 1.65381V0.778809L2.75146 0.0234375H2.85742V5Z" />
        <path d="M7.77686 8H4.3042V7.24805L5.90381 5.56641C6.29801 5.11751 6.49512 4.7609 6.49512 4.49658C6.49512 4.28239 6.4484 4.11947 6.35498 4.00781C6.26156 3.89616 6.12598 3.84033 5.94824 3.84033C5.77279 3.84033 5.63037 3.91553 5.521 4.06592C5.41162 4.21403 5.35693 4.39974 5.35693 4.62305H4.20166C4.20166 4.31771 4.27799 4.0363 4.43066 3.77881C4.58333 3.51904 4.79525 3.31624 5.06641 3.17041C5.33757 3.02458 5.64062 2.95166 5.97559 2.95166C6.51335 2.95166 6.92692 3.07585 7.21631 3.32422C7.50798 3.57259 7.65381 3.9292 7.65381 4.39404C7.65381 4.59001 7.61735 4.78141 7.54443 4.96826C7.47152 5.15283 7.35758 5.34766 7.20264 5.55273C7.04997 5.75553 6.80273 6.02783 6.46094 6.36963L5.81836 7.11133H7.77686V8Z" />
        <path d="M9.35693 8.02979H9.90039C10.3311 8.02979 10.5464 7.81901 10.5464 7.39746C10.5464 7.2334 10.4951 7.1001 10.3926 6.99756C10.29 6.89274 10.1453 6.84033 9.9585 6.84033C9.80583 6.84033 9.67253 6.88477 9.55859 6.97363C9.44694 7.0625 9.39111 7.17301 9.39111 7.30518H8.23926C8.23926 7.04313 8.31217 6.80957 8.45801 6.60449C8.60384 6.39941 8.8055 6.23991 9.06299 6.12598C9.32275 6.00977 9.60758 5.95166 9.91748 5.95166C10.4712 5.95166 10.9064 6.07812 11.2231 6.33105C11.5399 6.58398 11.6982 6.93148 11.6982 7.37354C11.6982 7.58773 11.6322 7.79053 11.5 7.98193C11.3701 8.17106 11.1799 8.32487 10.9292 8.44336C11.1935 8.53906 11.4009 8.6849 11.5513 8.88086C11.7017 9.07454 11.7769 9.31494 11.7769 9.60205C11.7769 10.0464 11.606 10.4019 11.2642 10.6685C10.9224 10.9351 10.4735 11.0684 9.91748 11.0684C9.59163 11.0684 9.28857 11.0068 9.0083 10.8838C8.73031 10.7585 8.51953 10.5864 8.37598 10.3677C8.23242 10.1466 8.16064 9.896 8.16064 9.61572H9.31934C9.31934 9.76839 9.38086 9.90055 9.50391 10.0122C9.62695 10.1239 9.77848 10.1797 9.9585 10.1797C10.1613 10.1797 10.3231 10.1239 10.4438 10.0122C10.5646 9.89827 10.625 9.75358 10.625 9.57812C10.625 9.32747 10.5623 9.14974 10.437 9.04492C10.3117 8.9401 10.1385 8.8877 9.91748 8.8877H9.35693V8.02979Z" />
    </>
)

const Calendar = () => (
    <path d="M11,2H10V0H8V2H4V0H2V2H1A1,1,0,0,0,0,3v8a1,1,0,0,0,1,1H11a1,1,0,0,0,1-1V3A1,1,0,0,0,11,2Zm-1,8H2V5h8Z" />
)

const Coins = () => (
    <>
        <path d="M8.5 8C7.0245 8 5.7985 7.6805 5 7.144C5 7.517 5 7.763 5 8C5 8.8285 6.567 9.5 8.5 9.5C10.433 9.5 12 8.8285 12 8C12 7.763 12 7.517 12 7.144C11.2015 7.6805 9.9755 8 8.5 8Z" />
        <path d="M8.5 10.5C7.0245 10.5 5.7985 10.1805 5 9.644C5 10.017 5 10.263 5 10.5C5 11.3285 6.567 12 8.5 12C10.433 12 12 11.3285 12 10.5C12 10.263 12 10.017 12 9.644C11.2015 10.1805 9.9755 10.5 8.5 10.5Z" />
        <path d="M8.5 7C10.433 7 12 6.32843 12 5.5C12 4.67157 10.433 4 8.5 4C6.567 4 5 4.67157 5 5.5C5 6.32843 6.567 7 8.5 7Z" />
        <path d="M3.5 3C5.433 3 7 2.32843 7 1.5C7 0.671573 5.433 0 3.5 0C1.567 0 0 0.671573 0 1.5C0 2.32843 1.567 3 3.5 3Z" />
        <path d="M4 8.9865C3.8365 8.9945 3.6705 9 3.5 9C2.0245 9 0.7985 8.6805 0 8.144C0 8.517 0 8.763 0 9C0 9.8285 1.567 10.5 3.5 10.5C3.67 10.5 3.8365 10.493 4 10.483V8.9865Z" />
        <path d="M4 6.4865C3.8365 6.4945 3.6705 6.5 3.5 6.5C2.0245 6.5 0.7985 6.1805 0 5.644C0 6.017 0 6.263 0 6.5C0 7.3285 1.567 8 3.5 8C3.67 8 3.8365 7.993 4 7.983V6.4865Z" />
        <path d="M4.96 3.883C4.509 3.958 4.021 4 3.5 4C2.0245 4 0.7985 3.6805 0 3.144C0 3.517 0 3.763 0 4C0 4.8285 1.567 5.5 3.5 5.5C3.6705 5.5 3.837 5.493 4.0015 5.483C4.0075 4.8515 4.355 4.303 4.96 3.883Z" />
    </>
)

const Comment = () => (
    <path d="M6,0C2.691,0,0,2.362,0,5.267s2.691,5.266,6,5.266a6.8,6.8,0,0,0,1.036-.079l2.725,1.485A.505.505,0,0,0,10,12a.5.5,0,0,0,.5-.5V8.711A4.893,4.893,0,0,0,12,5.267C12,2.362,9.309,0,6,0Z" />
)

const Company = () => (
    <>
        <path d="M11.4 1.51495L3.9 0.0149547C3.79102 -0.00729082 3.67846 -0.0050182 3.57046 0.021608C3.46247 0.0482341 3.36175 0.0985472 3.2756 0.168903C3.18945 0.239258 3.12003 0.327894 3.07236 0.42839C3.02469 0.528887 2.99998 0.638727 3 0.749955V4.49995H0.75C0.551088 4.49995 0.360322 4.57897 0.21967 4.71962C0.0790176 4.86028 0 5.05104 0 5.24995L0 11.25C0 11.4489 0.0790176 11.6396 0.21967 11.7803C0.360322 11.9209 0.551088 12 0.75 12H11.25C11.4489 12 11.6397 11.9209 11.7803 11.7803C11.921 11.6396 12 11.4489 12 11.25V2.24995C12 2.07706 11.9403 1.90946 11.831 1.77552C11.7217 1.64158 11.5694 1.54953 11.4 1.51495ZM4.5 10.5H1.5V8.99995H4.5V10.5ZM4.5 7.49995H1.5V5.99995H4.5V7.49995ZM10.5 10.5H6V5.24995C6 5.05104 5.92098 4.86028 5.78033 4.71962C5.63968 4.57897 5.44891 4.49995 5.25 4.49995H4.5V1.66495L10.5 2.86495V10.5Z" />
        <path d="M9 4.5H7.5V9H9V4.5Z" />
    </>
)

const Copy = () => (
    <>
        <path d="M9,1A1,1,0,0,0,8,0H1A1,1,0,0,0,0,1V9a1,1,0,0,0,1,1H4.5V4.5a1,1,0,0,1,1-1H9Z" />
        <rect height="7" width="6" rx="0.5" x="6" y="5" />
    </>
)

const Check = () => (
    <path d="M10.293,1.293,4,7.586,1.707,5.293A1,1,0,0,0,.293,6.707l3,3a1,1,0,0,0,1.414,0l7-7a1,1,0,0,0-1.414-1.414Z" />
)

const ChevronDown = ({ accentColor }) => (
    <path
        stroke={accentColor}
        d="M10.375,3.219,6,6.719l-4.375-3.5A1,1,0,1,0,.375,4.781l5,4a1,1,0,0,0,1.25,0l5-4a1,1,0,0,0-1.25-1.562Z"
    />
)

const ChevronRight = () => (
    <path d="M4.781.375a1,1,0,0,0-1.562,1.25L6.719,6l-3.5,4.375a1,1,0,0,0,1.562,1.25l4-5a1,1,0,0,0,0-1.25Z" />
)

const ChevronLeft = () => (
    <path d="M8.625.219A1,1,0,0,0,7.219.375l-4,5a1,1,0,0,0,0,1.25l4,5a1,1,0,0,0,1.562-1.25L5.281,6l3.5-4.375A1,1,0,0,0,8.625.219Z" />
)

const ChevronUp = () => (
    <path d="M11.625,7.219l-5-4a1,1,0,0,0-1.25,0l-5,4a1,1,0,1,0,1.25,1.562L6,5.281l4.375,3.5a1,1,0,1,0,1.25-1.562Z" />
)

const Cross = () => (
    <path d="M10.707,1.293a1,1,0,0,0-1.414,0L6,4.586,2.707,1.293A1,1,0,0,0,1.293,2.707L4.586,6,1.293,9.293a1,1,0,1,0,1.414,1.414L6,7.414l3.293,3.293a1,1,0,0,0,1.414-1.414L7.414,6l3.293-3.293A1,1,0,0,0,10.707,1.293Z" />
)

const Date = () => (
    <path d="M11 2H10V1C10 0.447715 9.55229 0 9 0C8.44771 0 8 0.447715 8 1V2H4V1C4 0.447715 3.55228 0 3 0C2.44772 0 2 0.447715 2 1V2H1C0.734784 2 0.48043 2.10536 0.292893 2.29289C0.105357 2.48043 0 2.73478 0 3L0 11C0 11.2652 0.105357 11.5196 0.292893 11.7071C0.48043 11.8946 0.734784 12 1 12H11C11.2652 12 11.5196 11.8946 11.7071 11.7071C11.8946 11.5196 12 11.2652 12 11V3C12 2.73478 11.8946 2.48043 11.7071 2.29289C11.5196 2.10536 11.2652 2 11 2ZM10 10H2V5H10V10Z" />
)

const DeactivateProject = () => (
    <>
        <path d="M15,2H8.4L6.7,0.3C6.5,0.1,6.3,0,6,0H1C0.4,0,0,0.4,0,1v14c0,0.6,0.4,1,1,1h14c0.6,0,1-0.4,1-1V3 C16,2.4,15.6,2,15,2z M14,14H2V2h3.6l1.7,1.7C7.5,3.9,7.7,4,8,4h6V14z" />
        <rect height="2" width="6" x="5" y="8" />
    </>
)

const DeactivatePerson = () => (
    <>
        <circle cx="8" cy="4" r="4" />
        <rect height="2" width="6" x="10" y="12" />
        <path d="M8,13c0-1.117,0.379-2.137,0.998-2.97C8.673,10.013,8.344,10,8,10c-3.192,0-5.539,0.795-6.837,1.382 C0.45,11.705,0,12.417,0,13.2V16h9.023C8.39,15.162,8,14.131,8,13z" />
    </>
)

const DragHandle = () => (
    <>
        <path d="M11,9H1a1,1,0,0,0,0,2H11a1,1,0,0,0,0-2Z" />
        <path d="M11,1H1A1,1,0,0,0,1,3H11a1,1,0,0,0,0-2Z" />
        <path d="M11,5H1A1,1,0,0,0,1,7H11a1,1,0,0,0,0-2Z" />
    </>
)

const Dots = () => (
    <>
        <circle cx="6" cy="6" r="1.5" />
        <circle cx="10.5" cy="6" r="1.5" />
        <circle cx="1.5" cy="6" r="1.5" />
    </>
)

const Download = () => (
    <>
        <path d="M10.97,6.757A1,1,0,0,0,10,6H8.75L7.25,8H9.219l.5,2H2.281l.5-2H4.75L3.25,6H2a1,1,0,0,0-.97.757l-1,4A1,1,0,0,0,1,12H11a1,1,0,0,0,.97-1.243Z" />
        <polygon points="6 8 9 4 7 4 7 0 5 0 5 4 3 4 6 8" />
    </>
)

const Error = () => (
    <>
        <path d="M6 0C2.7 0 0 2.7 0 6C0 9.3 2.7 12 6 12C9.3 12 12 9.3 12 6C12 2.7 9.3 0 6 0ZM6 10.5C3.525 10.5 1.5 8.475 1.5 6C1.5 3.525 3.525 1.5 6 1.5C8.475 1.5 10.5 3.525 10.5 6C10.5 8.475 8.475 10.5 6 10.5Z" />
        <path d="M6.75 6V3.75C6.75 3.33579 6.41421 3 6 3C5.58579 3 5.25 3.33579 5.25 3.75V6C5.25 6.41421 5.58579 6.75 6 6.75C6.41421 6.75 6.75 6.41421 6.75 6Z" />
        <path d="M6 9C6.41421 9 6.75 8.66421 6.75 8.25C6.75 7.83579 6.41421 7.5 6 7.5C5.58579 7.5 5.25 7.83579 5.25 8.25C5.25 8.66421 5.58579 9 6 9Z" />
    </>
)

const Euro = () => (
    <path d="M10.3643 9.6C9.59286 10.0286 8.82143 10.2857 7.96428 10.2857C6.25 10.2857 4.70714 9.25714 4.02143 7.71429H7.10714C7.62143 7.71429 7.96428 7.37143 7.96428 6.85714C7.96428 6.34286 7.62143 6 7.10714 6H3.67857C3.67857 5.74286 3.67857 5.4 3.76428 5.14286H7.10714C7.62143 5.14286 7.96428 4.8 7.96428 4.28571C7.96428 3.77143 7.62143 3.42857 7.10714 3.42857H4.53571C5.30714 2.4 6.50714 1.71429 7.96428 1.71429C8.82143 1.71429 9.59286 1.97143 10.3643 2.4C10.7929 2.65714 11.3071 2.57143 11.5643 2.14286C11.8214 1.71429 11.7357 1.2 11.3071 0.942857C10.2786 0.342857 9.16428 0 7.96428 0C5.56428 0 3.50714 1.37143 2.56429 3.42857H1.10714C0.592857 3.42857 0.25 3.77143 0.25 4.28571C0.25 4.8 0.592857 5.14286 1.10714 5.14286H2.05C1.96429 5.4 1.96429 5.74286 1.96429 6H1.10714C0.592857 6 0.25 6.34286 0.25 6.85714C0.25 7.37143 0.592857 7.71429 1.10714 7.71429H2.22143C2.99286 10.2 5.22143 12 7.96428 12C9.16428 12 10.2786 11.6571 11.3071 10.9714C11.7357 10.7143 11.8214 10.2 11.5643 9.77143C11.2214 9.42857 10.7071 9.34286 10.3643 9.6Z" />
)

const Folder = () => (
    <path d="M4.809,0H0V9a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V2H5.809Z" />
)

const Home = () => (
    <path d="M0,4v7a1,1,0,0,0,1,1H4V8H8v4h3a1,1,0,0,0,1-1V4L6,0Z" />
)

const LaunchApp = () => (
    <>
        <polygon points="8.293 2.293 4.586 6 6 7.414 9.707 3.707 12 6 12 0 6 0 8.293 2.293" />
        <path d="M10,10H2V2H4V0H2A2,2,0,0,0,0,2v8a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V8H10Z" />
    </>
)

const Lock = () => (
    <path d="M10,5H9V3A3,3,0,0,0,3,3V5H2A1,1,0,0,0,1,6v5a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V6A1,1,0,0,0,10,5ZM7,5H5V3A1,1,0,0,1,7,3Z" />
)

const Logout = () => (
    <>
        <polygon points="7 5 4 5 4 7 7 7 7 10 12 6 7 2 7 5" />
        <path d="M2,2H6V0H2A2,2,0,0,0,0,2v8a2,2,0,0,0,2,2H6V10H2Z" />
    </>
)

const Mail = () => (
    <>
        <path d="M10,6.136V9H2V6.136L0,4.8V10a1,1,0,0,0,1,1H11a1,1,0,0,0,1-1V4.8Z" />
        <path d="M12,3,6,7,0,3V2A1,1,0,0,1,1,1H11a1,1,0,0,1,1,1Z" />
    </>
)

const Document = () => (
    <svg>
        <g>
            <path d="M10.707,3.293l-3-3A1,1,0,0,0,7,0H2A1,1,0,0,0,1,1V11a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V4A1,1,0,0,0,10.707,3.293ZM3,10V2H6V5H9v5Z" />
        </g>
    </svg>
)

const Microsoft = () => (
    <>
        <path
            d="M5 0.5H0.5V5H5V0.5Z"
            strokeWidth="1.2"
            strokeMiterlimit="10"
            strokeLinecap="square"
        />
        <path
            d="M7 5L11.5 5L11.5 0.5L7 0.5L7 5Z"
            strokeWidth="1.2"
            strokeMiterlimit="10"
            strokeLinecap="square"
        />
        <path
            d="M5 7H0.5V11.5H5V7Z"
            strokeWidth="1.2"
            strokeMiterlimit="10"
            strokeLinecap="square"
        />
        <path
            d="M7 11.5L11.5 11.5L11.5 7L7 7L7 11.5Z"
            strokeWidth="1.2"
            strokeMiterlimit="10"
            strokeLinecap="square"
        />
    </>
)

const MoveRight = () => (
    <>
        <path d="M1,0A1,1,0,0,0,0,1V11a1,1,0,0,0,2,0V1A1,1,0,0,0,1,0Z" />
        <polygon points="7 5 4 5 4 7 7 7 7 10 12 6 7 2 7 5" />
    </>
)

const Phone = () => (
    <path d="M11.46375,9.22875 L9.53025,7.28325 C9.23775,6.9885 8.7615,6.9885 8.46825,7.28175 L6.75,9 L3,5.25 L4.7205,3.5295 C5.013,3.237 5.01375,2.76225 4.72125,2.469 L2.78625,0.531 C2.493,0.23775 2.01825,0.23775 1.725,0.531 L0.003,2.25225 L0,2.25 C0,7.635 4.365,12 9.75,12 L11.46225,10.28775 C11.75475,9.99525 11.7555,9.522 11.46375,9.22875 Z" />
)

const Single = () => (
    <>
        <circle cx="6" cy="3" r="3" />
        <path d="M6,7C2.625,7,1,9.106,1,10.333V11a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1v-.667C11,9.106,9.375,7,6,7Z" />
    </>
)

const Single2 = () => (
    <g strokeWidth="1" fill="#111111" stroke="#111111">
        <circle
            cx="6"
            cy="3"
            r="2.5"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6,7.5c-3.038,0-4.5,1.895-4.5,3v1h9v-1C10.5,9.4,9.038,7.5,6,7.5Z"
            fill="none"
            stroke="#111111"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
)

const Text = () => (
    <path d="M4.5 0.75H0V3H0.75L1.5 2.25H3.75V10.5L2.25 11.25V12H4.5H6.75V11.25L5.25 10.5V2.25H7.5L8.25 3H9V0.75H4.5Z" />
)

const TextArea = () => (
    <>
        <path d="M4.5 0.75H0V3H0.75L1.5 2.25H3.75V10.5L2.25 11.25V12H4.5H6.75V11.25L5.25 10.5V2.25H7.5L8.25 3H9V0.75H4.5Z" />
        <path d="M6 4.5V6.75H6.75L7.5 6H8.25V10.5L7.5 11.25V12H10.5V11.25L9.75 10.5V6H10.5L11.25 6.75H12V4.5H6Z" />
    </>
)

const Sync = () => (
    <>
        <path d="M6,0A6,6,0,0,0,.5,3.6l1.832.8A4,4,0,0,1,6,2,3.946,3.946,0,0,1,8.794,3.172l-1.919,1.9L12,5.639V0L10.229,1.752A5.937,5.937,0,0,0,6,0Z" />
        <path d="M6,10A3.946,3.946,0,0,1,3.206,8.828l1.919-1.9L0,6.361V12l1.771-1.752A5.937,5.937,0,0,0,6,12a6,6,0,0,0,5.5-3.6L9.668,7.6A4,4,0,0,1,6,10Z" />
    </>
)

const Other = () => (
    <>
        <path d="M5.926,0a7.438,7.438,0,0,0-3.7.977l.852,1.711a6.006,6.006,0,0,1,2.672-.766A1.789,1.789,0,0,1,6.8,2.188a.886.886,0,0,1,.375.773,1.383,1.383,0,0,1-.262.82,4.891,4.891,0,0,1-1.082.953A4.2,4.2,0,0,0,4.66,5.914a2.561,2.561,0,0,0-.32,1.305V7.8H6.41V7.328a1.319,1.319,0,0,1,.2-.758,4.63,4.63,0,0,1,.95-.843A5.574,5.574,0,0,0,9.043,4.32a2.746,2.746,0,0,0,.391-1.484A2.528,2.528,0,0,0,8.484.773,3.931,3.931,0,0,0,5.926,0Z" />
        <circle cx="5.5" cy="10.5" r="1.5" />
    </>
)

const Pen = () => (
    <path d="M10.121.293a1,1,0,0,0-1.414,0L1,8,0,12l4-1,7.707-7.707a1,1,0,0,0,0-1.414Z" />
)

const Plus = () => (
    <path d="M11,5H7V1A1,1,0,0,0,5,1V5H1A1,1,0,0,0,1,7H5v4a1,1,0,0,0,2,0V7h4a1,1,0,0,0,0-2Z" />
)

const Question = () => (
    <>
        <path d="M5.926,0a7.438,7.438,0,0,0-3.7.977l.852,1.711a6.006,6.006,0,0,1,2.672-.766A1.789,1.789,0,0,1,6.8,2.188a.886.886,0,0,1,.375.773,1.383,1.383,0,0,1-.262.82,4.891,4.891,0,0,1-1.082.953A4.2,4.2,0,0,0,4.66,5.914a2.561,2.561,0,0,0-.32,1.305V7.8H6.41V7.328a1.319,1.319,0,0,1,.2-.758,4.63,4.63,0,0,1,.95-.843A5.574,5.574,0,0,0,9.043,4.32a2.746,2.746,0,0,0,.391-1.484A2.528,2.528,0,0,0,8.484.773,3.931,3.931,0,0,0,5.926,0Z" />
        <circle cx="5.5" cy="10.5" r="1.5" />
    </>
)

const Remove = () => (
    <path d="M10.707,1.293a1,1,0,0,0-1.414,0L6,4.586,2.707,1.293A1,1,0,0,0,1.293,2.707L4.586,6,1.293,9.293a1,1,0,1,0,1.414,1.414L6,7.414l3.293,3.293a1,1,0,0,0,1.414-1.414L7.414,6l3.293-3.293A1,1,0,0,0,10.707,1.293Z" />
)

const Stopwatch = () => (
    <>
        <path d="M11.25,4a.744.744,0,0,1-.53-.22l-2.5-2.5A.75.75,0,0,1,9.28.22l2.5,2.5A.75.75,0,0,1,11.25,4Z" />
        <path d="M5,2a5,5,0,1,0,5,5A5.006,5.006,0,0,0,5,2ZM7.5,7.5H5A.5.5,0,0,1,4.5,7V4.5a.5.5,0,0,1,1,0v2h2a.5.5,0,0,1,0,1Z" />
    </>
)

const TimerStop = () => (
    <>
        <path d="M11.25,4a.744.744,0,0,1-.53-.22l-2.5-2.5A.75.75,0,0,1,9.28.22l2.5,2.5A.75.75,0,0,1,11.25,4Z" />
        <path d="M5,2a5,5,0,1,0,5,5A5.006,5.006,0,0,0,5,2ZM7.5,7.5H5A.5.5,0,0,1,4.5,7V4.5a.5.5,0,0,1,1,0v2h2a.5.5,0,0,1,0,1Z" />
    </>
)

const TimerRun = () => (
    <>
        <path d="M11.25,4a.744.744,0,0,1-.53-.22l-2.5-2.5A.75.75,0,0,1,9.28.22l2.5,2.5A.75.75,0,0,1,11.25,4Z" />
        <path d="M5,2a5,5,0,1,0,5,5A5.006,5.006,0,0,0,5,2ZM7.5,7.5H5A.5.5,0,0,1,4.5,7V4.5a.5.5,0,0,1,1,0v2h2a.5.5,0,0,1,0,1Z" />
    </>
)

const TimerPause = () => (
    <g>
        <path
            d="M17,43H9c-1.10457,0-2-0.89543-2-2V7c0-1.10457,0.89543-2,2-2h8c1.10457,0,2,0.89543,2,2v34 C19,42.10457,18.10457,43,17,43z"
            fill="#444444"
        />
        <path
            d="M39,43h-8c-1.10457,0-2-0.89543-2-2V7c0-1.10457,0.89543-2,2-2h8c1.10457,0,2,0.89543,2,2v34 C41,42.10457,40.10457,43,39,43z"
            fill="#444444"
        />
    </g>
)

const Time = () => (
    <>
        <g clipPath="url(#clip0)">
            <path d="M6 12C4.81331 12 3.65328 11.6481 2.66658 10.9888C1.67989 10.3295 0.910851 9.39246 0.456726 8.2961C0.00259972 7.19975 -0.11622 5.99335 0.115291 4.82946C0.346802 3.66558 0.918247 2.59648 1.75736 1.75736C2.59648 0.918247 3.66558 0.346802 4.82946 0.115291C5.99335 -0.11622 7.19975 0.00259972 8.2961 0.456726C9.39246 0.910851 10.3295 1.67989 10.9888 2.66658C11.6481 3.65328 12 4.81331 12 6C11.9984 7.59081 11.3658 9.11602 10.2409 10.2409C9.11602 11.3658 7.59081 11.9984 6 12ZM6 2C5.20888 2 4.43552 2.2346 3.77772 2.67412C3.11993 3.11365 2.60724 3.73836 2.30448 4.46927C2.00173 5.20017 1.92252 6.00444 2.07686 6.78036C2.2312 7.55629 2.61217 8.26902 3.17158 8.82843C3.73099 9.38784 4.44372 9.7688 5.21964 9.92314C5.99557 10.0775 6.79983 9.99827 7.53074 9.69552C8.26164 9.39277 8.88636 8.88008 9.32588 8.22228C9.76541 7.56449 10 6.79113 10 6C10 4.93914 9.57858 3.92172 8.82843 3.17158C8.07828 2.42143 7.06087 2 6 2Z" />
            <path d="M8.5 6.5H6C5.86739 6.5 5.74021 6.44732 5.64645 6.35355C5.55268 6.25979 5.5 6.13261 5.5 6V3.5C5.5 3.36739 5.55268 3.24021 5.64645 3.14645C5.74021 3.05268 5.86739 3 6 3C6.13261 3 6.25979 3.05268 6.35355 3.14645C6.44732 3.24021 6.5 3.36739 6.5 3.5V5.5H8.5C8.63261 5.5 8.75979 5.55268 8.85355 5.64645C8.94732 5.74021 9 5.86739 9 6C9 6.13261 8.94732 6.25979 8.85355 6.35355C8.75979 6.44732 8.63261 6.5 8.5 6.5Z" />
        </g>
        <defs>
            <clipPath id="clip0">
                <path d="M0 0H12V12H0V0Z" />
            </clipPath>
        </defs>
    </>
)

const Upload = () => (
    <>
        <polygon points="5 8 7 8 7 4 9 4 6 0 3 4 5 4 5 8" />
        <path d="M10.97,6.757A1,1,0,0,0,10,6H8V8H9.219l.5,2H2.281l.5-2H4V6H2a1,1,0,0,0-.97.757l-1,4A1,1,0,0,0,1,12H11a1,1,0,0,0,.97-1.243Z" />
    </>
)

const Warning = () => (
    <>
        <polygon points="6.961 7.633 7.359 0 4.656 0 5.055 7.633 6.961 7.633" />
        <circle cx="6" cy="10.5" r="1.5" />
    </>
)

const FilledQuestionMark = () => (
    <path d="M6 0C2.7 0 0 2.7 0 6s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6zm0 9.75c-.45 0-.75-.3-.75-.75s.3-.75.75-.75.75.3.75.75-.3.75-.75.75zM7.125 6.3c-.375.225-.375.3-.375.45v.75h-1.5v-.75c0-.975.6-1.425 1.05-1.725.375-.225.45-.3.45-.525 0-.45-.3-.75-.75-.75-.3 0-.525.15-.675.375L4.95 4.8l-1.275-.75.375-.675A2.238 2.238 0 0 1 6 2.25c1.275 0 2.25.975 2.25 2.25 0 1.05-.675 1.5-1.125 1.8z" />
)

const Gear = () => (
    <path d="M9.8,3.613,10.95,2.464,9.536,1.05,8.387,2.2A4.443,4.443,0,0,0,7,1.62V0H5V1.62A4.443,4.443,0,0,0,3.613,2.2L2.464,1.05,1.05,2.464,2.2,3.613A4.443,4.443,0,0,0,1.62,5H0V7H1.62A4.443,4.443,0,0,0,2.2,8.387L1.05,9.536,2.464,10.95,3.613,9.8A4.443,4.443,0,0,0,5,10.38V12H7V10.38A4.443,4.443,0,0,0,8.387,9.8L9.536,10.95,10.95,9.536,9.8,8.387A4.443,4.443,0,0,0,10.38,7H12V5H10.38A4.443,4.443,0,0,0,9.8,3.613ZM6,8A2,2,0,1,1,8,6,2,2,0,0,1,6,8Z" />
)

const Filter = () => (
    <path d="M11.941.264A.5.5,0,0,0,11.5,0H.5A.5.5,0,0,0,.084.777L4,6.651V11.5a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5V6.651L11.916.777A.5.5,0,0,0,11.941.264Z" />
)

const Select = () => (
    <g>
        <path d="M11,9H1a1,1,0,0,0,0,2H11a1,1,0,0,0,0-2Z" />
        <path d="M11,1H1A1,1,0,0,0,1,3H11a1,1,0,0,0,0-2Z" />
        <path d="M11,5H1A1,1,0,0,0,1,7H11a1,1,0,0,0,0-2Z" />
    </g>
)

const Tag = () => (
    <g strokeWidth="1">
        <path
            d="M.5.5h5l5.707,5.707a1,1,0,0,1,0,1.414L7.621,11.207a1,1,0,0,1-1.414,0L.5,5.5Z"
            strokeLinecap="round"
        />
    </g>
)

const Clock = ({ colorPrimary }) => (
    <g stroke={colorPrimary}>
        <path d="M6,12a6,6,0,1,1,6-6A6.006,6.006,0,0,1,6,12ZM6,2a4,4,0,1,0,4,4A4,4,0,0,0,6,2Z" />
        <path d="M8.5,6.5H6A.5.5,0,0,1,5.5,6V3.5a.5.5,0,0,1,1,0v2h2a.5.5,0,0,1,0,1Z" />
    </g>
)

const Star = () => (
    <g strokeWidth="1" stroke={colorPrimary}>
        <polygon
            fill="none"
            points="6 0.5 7.365 4.778 11.5 4.778 8.209 7.298 9.574 11.5 6 8.903 2.426 11.5 3.791 7.298 0.5 4.778 4.635 4.778 6 0.5"
            strokeLinecap="round"
        />
    </g>
)

const StarFilled = () => (
    <g>
        <polygon points="7.489 4.667 6 0 4.511 4.667 0 4.667 3.59 7.416 2.101 12 6 9.167 9.899 12 8.41 7.416 12 4.667 7.489 4.667" />
    </g>
)

const Undo = () => (
    <path d="M8,0H7A1,1,0,0,0,7,2H8A2,2,0,0,1,8,6H5V3.5a.5.5,0,0,0-.829-.376l-4,3.5a.5.5,0,0,0,0,.752l4,3.5A.5.5,0,0,0,4.5,11a.5.5,0,0,0,.5-.5V8H8A4,4,0,0,0,8,0Z" />
)

const Teamleader = () => (
    <>
        <path d="M4.86668 5.92222C3.35557 6.91111 2.12223 7.74444 2.12223 7.77778C2.11112 7.81111 3.3889 7.82222 4.95557 7.81111L7.7889 7.77778L7.81113 5.94444C7.82224 4.77778 7.7889 4.11111 7.72224 4.12222C7.66668 4.12222 6.37779 4.93333 4.86668 5.92222Z" />
        <path d="M8 7.92222C8 10.0444 8.02222 11.7778 8.05556 11.7778C8.1 11.7778 11.3556 9.64444 11.5333 9.49999C11.5667 9.46666 8.27778 4.36666 8.1 4.16666C8.04444 4.1111 8 5.74444 8 7.92222Z" />
        <path d="M6.14445 9.24443C7.56668 11.4333 7.72223 11.6667 7.77779 11.6667C7.81112 11.6667 7.83334 10.8444 7.83334 9.83332V7.99999H6.5889H5.33334L6.14445 9.24443Z" />
        <path d="M10.8889 8.05555C10.8889 8.16666 11.6778 9.33333 11.7556 9.33333C11.8 9.32222 12.2889 9.02222 12.8334 8.66666L13.8334 8H12.3667C11.5556 8 10.8889 8.02222 10.8889 8.05555Z" />
    </>
)

const FloppyDisk = () => (
    <g>
        <path d="M11.854,2.854,9.146.146A.5.5,0,0,0,8.793,0H8V3.5a.5.5,0,0,1-.5.5h-4A.5.5,0,0,1,3,3.5V0H2A2,2,0,0,0,0,2v8a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V3.207A.5.5,0,0,0,11.854,2.854ZM10,9.5a.5.5,0,0,1-.5.5h-7A.5.5,0,0,1,2,9.5v-2A.5.5,0,0,1,2.5,7h7a.5.5,0,0,1,.5.5Z" />
        <path d="M6.5,3A.5.5,0,0,1,6,2.5V.5a.5.5,0,0,1,1,0v2A.5.5,0,0,1,6.5,3Z" />
    </g>
)

const Stop = () => <rect width="11" height="11" x=".5" y=".5" rx="1" />
const Pause = () => (
    <svg>
        <g>
            <path d="M17 43H9a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v34a2 2 0 0 1-2 2zM39 43h-8a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v34a2 2 0 0 1-2 2z" />
        </g>
    </svg>
)
const Start = () => (
    <svg>
        <path d="m11.741 5.562-10-5.5a.5.5 0 0 0-.5.008A.5.5 0 0 0 1 .5v11a.5.5 0 0 0 .246.43.491.491 0 0 0 .254.07.5.5 0 0 0 .241-.062l10-5.5a.5.5 0 0 0 0-.876Z" />
    </svg>
)

const Echeck = ({ accentColor }) => (
    <g>
        <path d="M8,0C3.589,0,0,3.589,0,8s3.589,8,8,8,8-3.589,8-8S12.411,0,8,0Zm0,14c-3.309,0-6-2.691-6-6S4.691,2,8,2s6,2.691,6,6-2.691,6-6,6Z" />
        <path d="M7,11c-.256,0-.512-.098-.707-.293l-2-2c-.391-.391-.391-1.023,0-1.414s1.023-.391,1.414,0l1.293,1.293,3.293-3.293c.391-.391,1.023-.391,1.414,0s.391,1.023,0,1.414l-4,4c-.195,.195-.451,.293-.707,.293Z" />
    </g>
)

const IconSmall = forwardRef(({ name, size, ...props }, ref) => (
    <FillIconContainer
        ref={ref}
        name={name}
        size={size}
        viewBox="0 0 12 12"
        {...props}
    >
        {(accentColor) => {
            const iconProps = { accentColor }
            switch (name) {
                case 'eCheck':
                    return <Echeck />
                case 'stopTimer':
                    return <Stop />
                case 'pauseTimer':
                    return <Pause />
                case 'starTimer':
                    return <Start />
                case 'activate-project':
                    return <ActivateProject />
                case 'activate-person':
                    return <ActivatePerson />
                case 'add':
                    return <Add />
                case 'arrowLeft':
                    return <ArrowLeft />
                case 'arrowRight':
                    return <ArrowRight />
                case 'azure-active-directory':
                    return <AzureActiveDirectory />
                case 'attachment':
                case 'outlook':
                    return <Attachment />
                case 'ban':
                    return <Ban />
                case 'block':
                    return <Block />
                case 'event':
                case 'calendar':
                    return <Calendar />
                case 'boolean':
                case 'check':
                case 'confirmed':
                    return <Check />
                case 'chevronDown':
                    return <ChevronDown {...iconProps} />
                case 'chevronRight':
                    return <ChevronRight />
                case 'chevronLeft':
                    return <ChevronLeft />
                case 'chevronUp':
                    return <ChevronUp />
                case 'coins':
                    return <Coins />
                case 'company':
                    return <Company />
                case 'coworker':
                    return <Single2 />
                case 'correspondence':
                    return <Mail />
                case 'word':
                    return <Document />
                case 'date':
                case 'datetime':
                    return <Date />
                case 'deactivate-person':
                    return <DeactivatePerson />
                case 'deactivate-project':
                    return <DeactivateProject />
                case 'drag-handle':
                    return <DragHandle />
                case 'error':
                    return <Error />
                case 'euro':
                case 'billed':
                    return <Euro />
                case 'group':
                case 'copy':
                    return <Copy />
                case 'dots':
                    return <Dots />
                case 'download':
                    return <Download />
                case 'edit':
                    return <Pen />
                case 'home':
                    return <Home />
                case 'lock':
                    return <Lock />
                case 'logout':
                    return <Logout />
                case 'moveRight':
                    return <MoveRight />
                case 'microsoft':
                    return <Microsoft />
                case 'openInTab':
                    return <LaunchApp />
                case 'other':
                    return <Other />
                case 'phone':
                    return <Phone />
                case 'comment':
                case 'contacts':
                    return <Comment />
                case 'person':
                    return <Single />
                case 'sync':
                    return <Sync />
                case 'project':
                    return <Folder />
                case 'number':
                    return <Calculator />
                case 'cross':
                case 'close':
                    return <Cross />
                case 'plus':
                    return <Plus />
                case 'question':
                    return <Question />
                case 'remove':
                    return <Remove />
                case 'settings':
                    return <Gear />
                case 'stopwatch':
                    return <Stopwatch />
                case 'timerpause':
                    return <TimerPause />
                case 'text':
                    return <Text />
                case 'textarea':
                    return <TextArea />
                case 'time':
                    return <Time />
                case 'delete':
                    return <Bin />
                case 'upload':
                    return <Upload />
                case 'warning':
                    return <Warning />
                case 'filledQuestionMark':
                    return <FilledQuestionMark />
                case 'select':
                    return <Select />
                case 'tag':
                    return <Tag />
                case 'clock':
                    return <Clock />
                case 'ai':
                    return <AI />
                case 'favoriteChecked':
                    return <StarFilled />
                case 'favorite':
                    return <Star />
                case 'filter':
                    return <Filter />
                case 'undo':
                    return <Undo />
                case 'teamleader':
                    return <Teamleader />
                case 'save':
                    return <FloppyDisk />
                default:
                    return <Other />
                // throw Error(`Icon with name '${name}' was not found in IconSmall.`)
            }
        }}
    </FillIconContainer>
))

const FillIconContainer = styled(IconContainer)`
    stroke: none;
    fill: currentColor;
`

export default IconSmall
