import React, { useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import * as routes from 'routes/index'
import { linkOffice365Account } from 'util/office365'
import useUserOnboardingForm from 'components/pages/onboarding/user-onboarding/useUserOnboardingForm'
import useFetchUserOnboardingData from 'components/pages/onboarding/user-onboarding/useFetchUserOnboardingData'
import PageNotFound from 'components/layout/PageNotFound'
import PageWithState from 'components/layout/PageWithState'
import ModalLinkO365 from 'components/pages/onboarding/admin-onboarding/AdminOnboardingModalLinkO365'
import ModalWelcome from 'components/pages/onboarding/user-onboarding/UserOnboardingModalWelcome'
import ModalGetStarted from 'components/pages/onboarding/admin-onboarding/AdminOnboardingModalGetStarted'
import UserOnboardingUpdateProfile from 'components/pages/onboarding/user-onboarding/UserOnboardingUpdateProfile'
import SkeletonPage from 'components/layout/SkeletonPage'
import updatePartner from 'api/updatePartner'
import useNotifications from 'components/hooks/useNotifications'
import activatePartner from 'api/activatePartner'
import updateAccountStateCompletion from 'api/updateAccountStateCompletion'
import completeOnboarding from 'api/completeOnboarding'
import useAppState from 'components/hooks/useAppState'
import useAppSecrets from 'components/hooks/useAppSecrets'
import useTracking from 'components/hooks/useTracking'
import useAppCultures from 'components/hooks/useAppCultures'

const TOTAL_STEPS = 5

const UserOnboardingModalWithState = ({ ...props }) => {
    const { step } = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const { state : authorizReponse } = location
    const { refresh, currentUser } = useAppState()
    const { trackError } = useTracking()
    const { o365DrivesClientId, o365MailsAndCalendarsClientId } = useAppSecrets()
    const apolloClient = useApolloClient()
    const formState = useUserOnboardingForm(currentUser)
    const { dispatchError } = useNotifications()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const currentStep = Number(step)
    const nextStep = () => navigate(routes.userOnboarding(currentStep + 1))
    const [disclaimerChecked, checkDisclaimer] = useState(false)

    const { isFetching, genders } = useFetchUserOnboardingData()

    const { interfaceCultures } = useAppCultures()

    if (isFetching) {
        return <SkeletonPage />
    }
    switch (currentStep) {
        case 1:
            return (
                <PageWithState {...props}>
                    <ModalWelcome
                        showNextButton
                        totalSteps={TOTAL_STEPS}
                        onNext={nextStep}
                        {...props}
                    />
                </PageWithState>
            )
        case 2:
            return (
                <PageWithState {...props}>
                    <UserOnboardingUpdateProfile
                        showNextButton
                        totalSteps={TOTAL_STEPS}
                        formState={formState}
                        isLoading={isFetching}
                        genders={genders}
                        cultures={interfaceCultures}
                        onSkip={async () => {
                            await updateAccountStateCompletion(apolloClient, {
                                hasUpdatedProfile: true,
                            })
                            nextStep()
                        }}
                        onNext={async () => {
                            const isValid = formState.validate()
                            if (isValid) {
                                setIsSubmitting(true)
                                try {
                                    const input = formState.valuesToInput()
                                    await Promise.all([
                                        updatePartner(
                                            apolloClient,
                                            currentUser.id,
                                            input,
                                        ),
                                        activatePartner(
                                            apolloClient,
                                            currentUser.id,
                                        ),
                                        updateAccountStateCompletion(
                                            apolloClient,
                                            { hasUpdatedProfile: true },
                                        ),
                                    ])
                                    setIsSubmitting(false)
                                    nextStep()
                                } catch (e) {
                                    trackError(e)
                                    dispatchError({
                                        title: (
                                            <Trans>Something went wrong!</Trans>
                                        ),
                                        content: e.message,
                                    })
                                } finally {
                                    setIsSubmitting(false)
                                }
                            }
                        }}
                        isSubmitting={isSubmitting}
                        {...props}
                    />
                </PageWithState>
            )
        case 3: // link with mail & calendar
            return (
                <PageWithState {...props}>
                    <ModalLinkO365
                        authorizReponse={authorizReponse}
                        linkTo="mails_calendars"
                        showNextButton
                        totalSteps={TOTAL_STEPS}
                        isSubmitting={isSubmitting}
                        onNext={() => nextStep()}
                        onLinkOffice365Account={async () => {
                            setIsSubmitting(true)
                            try {
                                linkOffice365Account(
                                    o365MailsAndCalendarsClientId,
                                '',
                                '/user-onboarding',
                                'mails_calendars',
                            )
                            await updateAccountStateCompletion(apolloClient, {
                                hasLinkedO365: true,
                                hasUpdatedProfile: true,
                            })
                            } catch (e) {
                                trackError(e)
                                dispatchError({
                                    title: <Trans>Something went wrong!</Trans>,
                                    content: e.message,
                                })
                            } finally {
                                setIsSubmitting(false)
                            }
                        }}
                        {...props}
                    />
                </PageWithState>
            )
        case 4: // link with drives
            return (
                <PageWithState {...props}>
                    <ModalLinkO365
                        authorizReponse={authorizReponse}
                        linkTo="drives"
                        showSkipButton
                        totalSteps={TOTAL_STEPS}
                        isSubmitting={isSubmitting}
                        onSkip={() => nextStep()}
                        onLinkOffice365Account={async () => {
                            setIsSubmitting(true)
                            try {
                                linkOffice365Account(
                                    o365DrivesClientId,
                                    '',
                                    '/user-onboarding',
                                    'drives',
                                )
                                await updateAccountStateCompletion(apolloClient, {
                                    hasLinkedO365: true,
                                    hasUpdatedProfile: true,
                                })
                            } catch (e) {
                                trackError(e)
                                dispatchError({
                                    title: <Trans>Something went wrong!</Trans>,
                                    content: e.message,
                                })
                            } finally {
                                setIsSubmitting(false)
                            }
                        }}

                        {...props}
                    />
                </PageWithState>
            )
        case 5:
            return (
                <PageWithState {...props}>
                    <ModalGetStarted
                        disclaimerChecked={disclaimerChecked}
                        handleCheckDisclaimer={checkDisclaimer}
                        onGetStarted={async () => {
                            await completeOnboarding(apolloClient)
                            navigate(routes.projects())
                            refresh()
                        }}
                        {...props}
                    />
                </PageWithState>
            )
        default:
            return <PageNotFound />
    }
}

export default UserOnboardingModalWithState
