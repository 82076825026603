import { getAppInsights } from 'services/applicationInsights'

const { REACT_APP_APPINSIGHTS_KEY } = process.env

const useAppInsights = () => {
    const environment = process.env.NODE_ENV
    if (environment !== 'test' && environment !== 'local') {
        return getAppInsights(REACT_APP_APPINSIGHTS_KEY)
    }
    return null
}
export default useAppInsights
