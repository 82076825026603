import React from 'react'
import { Trans } from '@lingui/macro'
import Table from 'components/util/Table'
import NewTabLinkButton from 'components/util/NewTabLinkButton'
import styled from 'styled-components'
import ConfirmPopover from 'components/util/ConfirmPopover'
import IconButton from 'components/util/IconButton'

const toProjectLinkRow = ({
    project,
    isCurrentProject,
    level,
    projectLinkId,
}) => ({
    project,
    isCurrentProject,
    level,
    projectLinkId,
})

const ProjectLinksTable = ({
    projectLinks,
    onClickProject,
    onClickProjectNewTab,
    removeLinkEnabled = true,
    onDeleteProjectLink,
    isDeleting,
}) => (
    <Table
        data={projectLinks.map(toProjectLinkRow)}
        header={<ProjectsHeaderRow removeLinkEnabled={removeLinkEnabled} />}
        columnWidths={[20, 'auto']}
        renderRow={(projectInfo, index) => (
            <ProjectLinksTableRow
                key={index}
                onClickProject={onClickProject}
                onClickProjectNewTab={onClickProjectNewTab}
                projectInfo={projectInfo}
                removeLinkEnabled={removeLinkEnabled}
                onDeleteProjectLink={onDeleteProjectLink}
                isDeleting={isDeleting}
            />
        )}
    />
)

const ProjectsHeaderRow = ({ removeLinkEnabled, ...props }) => (
    <Table.Row>
        <Table.HeaderCell {...props}>
            <Trans>Number</Trans>
        </Table.HeaderCell>
        <Table.HeaderCell {...props}>
            <Trans>Title</Trans>
        </Table.HeaderCell>
        {removeLinkEnabled && <Table.HeaderCell {...props} />}
    </Table.Row>
)

const ProjectLinksTableRow = ({
    projectInfo,
    onClickProject,
    onClickProjectNewTab,
    onDeleteProjectLink,
    removeLinkEnabled,
    isDeleting,
}) => {
    const { project, isCurrentProject, level, projectLinkId } = projectInfo
    return (
        <StyledTableRow isCurrentProject={isCurrentProject}>
            <StyledTableCell level={level}>
                <NewTabLinkButton
                    onClick={() => onClickProject(project)}
                    onClickNewTab={() => onClickProjectNewTab(project)}
                >
                    {project.number}
                </NewTabLinkButton>
            </StyledTableCell>
            <StyledTableCell level={level}>{project.title}</StyledTableCell>
            {removeLinkEnabled && (
                <StyledTableCell textAlign="right">
                    {!isCurrentProject && (
                        <ConfirmPopover
                            hasArrow
                            isLoading={isDeleting}
                            onConfirm={() => onDeleteProjectLink(projectLinkId)}
                            confirmText={
                                <Trans>
                                    Are you sure you want to remove this link?
                                </Trans>
                            }
                        >
                            <IconButton isSmaller iconName="cross" />
                        </ConfirmPopover>
                    )}
                </StyledTableCell>
            )}
        </StyledTableRow>
    )
}

const StyledTableRow = styled(Table.Row)(
    ({ theme, isCurrentProject }) => `
    ${
        isCurrentProject &&
        `
        font-weight: ${theme.fontWeightBold};
    `
    }
`,
)
const StyledTableCell = styled(Table.Cell)(
    ({ theme, level }) => `
    padding-left: ${level * theme.spacing}rem;
`,
)

export default ProjectLinksTable
