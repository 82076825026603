import React from 'react'
import { Trans } from '@lingui/macro'
import styled from 'styled-components'
import ModalTopBar from 'components/layout/ModalTopBar'
import Modal, { ModalMainContainer } from 'components/layout/Modal'
import ModalConfirmButtonBar from 'components/layout/ModalConfirmButtonBar'
import ActivityForm from 'components/pages/activities-create/ActivityForm'

const CreateActivityModal = ({
    formState,
    onClose,
    onSubmit,
    isSubmitting,
}) => (
    <Modal
        data-testid="CreateActivityModal"
        ariaLabel="Create activity"
        islarge="true"
    >
        <ModalTopBar title={<Trans>Create activity</Trans>} onClose={onClose} />
        <StyledModalMainContainer>
            <ActivityForm formState={formState} />
        </StyledModalMainContainer>
        <ModalConfirmButtonBar
            isSubmitting={isSubmitting}
            onCancel={onClose}
            onSubmit={onSubmit}
        />
    </Modal>
)

const StyledModalMainContainer = styled(ModalMainContainer)`
    min-height: 35rem;
`

export default CreateActivityModal
