import gql from 'graphql-tag'

const startActivityMutation = gql`
    mutation startTimer($timerId: Int, $input: CreateTimerInput!) {
        startTimer(timerId: $timerId, input: $input) {
            timer {
                id
                description
                project {
                    id
                    title
                    number
                }
                status
                elapsedDurationInSeconds
                earliestStartDateTime
                latestStartDateTime
            }
        }
    }
`
export default async function startActivity(apolloClient, variables, options) {
    const mutation = startActivityMutation
    const response = await apolloClient.mutate({
        mutation,
        variables,
        ...options,
    })
    return response.data.startTimer
}
