import React from 'react'
import { Trans } from '@lingui/react'
import Icon from 'components/util/Icon'
import Title from 'components/util/Title'
import TopBar, { TOPBAR_HEIGHT } from 'components/layout/TopBar'
import MainSideBar from 'components/layout/MainSideBar'
import AppContainer from 'components/layout/AppContainer'

const PageError = ({ error, ...props }) => (
    <AppContainer {...props}>
        <MainSideBar />
        <div className="w-full flex-1">
            <main
                className="flex items-center justify-center flex-col overflow-auto p-10 bg-gray-50"
                style={{ height: `calc(100% - ${TOPBAR_HEIGHT}rem)` }}
            >
                <Icon
                    isLarger
                    name="alarm"
                    accentColor="primary"
                    className="mb-2"
                />
                <Title>
                    <Trans id="Something went wrong!" />
                </Title>
                {error ? <p className="text-center">{error}</p> : null}
            </main>
        </div>
    </AppContainer>
)

export default PageError
