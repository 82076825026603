import useAppState from 'components/hooks/useAppState'

export default function useAppSecrets() {
    const { applicationSecrets = {} } = useAppState()

    const {
        activeDirectoryClientId,
        aADActiveDirectoryClientId,
        azureMapsKey,
        stripePublishableKey,
        o365ClientId,
        o365DrivesClientId,
        o365MailsAndCalendarsClientId,
        o365AdminClientId,
        o365AdminCallRecordsClientId,
    } = applicationSecrets

    return {
        activeDirectoryClientId,
        aADActiveDirectoryClientId,
        azureMapsKey,
        stripePublishableKey,
        o365ClientId,
        o365DrivesClientId,
        o365MailsAndCalendarsClientId,
        o365AdminClientId,
        o365AdminCallRecordsClientId,
    }
}
