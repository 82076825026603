import React from 'react'
import { useNavigate } from 'react-router-dom'
import Box from 'components/util/Box'
import Icon from 'components/util/Icon'
import { colorSuccess, colorWarning, colorError } from 'styles/theme'

const BannerComponent = ({ bannerContent }) => {
    const navigate = useNavigate()

    const handleAction = (type) => {
        
        switch (type) {
            case 'review_access':
            case 'renew_access':
            case 'missing_link': 
            case 'azureAdLogin':
                return navigate('/profile', { state: { source: 'banner' } })
            default:
                return null
        }
    }

    const statusColors = {
        warning: colorWarning,
        error: colorError,
        infos: colorSuccess,
    }

    if (bannerContent.length === 0) return null
    return bannerContent.length && bannerContent.map((banner)=><div
            style={{backgroundColor:`${banner.type === 'azureAdLogin' ? '#F3CACA' : '#f8f3d6'}`}}
            className='mr-3 ml-3 mb-0 flex justify-center items-center p-2'  
        >
            <Icon
                name="bannerWarning"
                accentColor={banner.type === 'azureAdLogin' ? '#f76262': '#dcbe2a'}
                className="mr-2"
                aria-hidden="true"
            />
            <p>{banner.content}</p>
            <button
                className="ml-1 text-[#E56A59] font-semibold hover:underline"
                type="button"
                onClick={()=>handleAction(banner.type)}
            >
                {banner.actionContent?.message}
            </button>
        </div>
    )
}

export default BannerComponent
