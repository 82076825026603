import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Trans } from '@lingui/react'
import styled from 'styled-components'
import * as routes from 'routes/index'
import Icon from 'components/util/Icon'
import Title from 'components/util/Title'
import PrimaryButton from 'components/util/PrimaryButton'
import AppContainer from 'components/layout/AppContainer'

const PageNotFound = () => {
    const navigate = useNavigate()
    return (
        <AppContainer>
            <div className="flex flex-col justify-center items-center m-auto">
                <Icon
                    isLargest
                    name="warning"
                    accentColor="primary"
                    className="mb-6"
                />
                <Title>
                    <Trans id="Oops! Page not found" />
                </Title>
                <p>
                    <Trans id="Sorry, we couldn't find the page you were looking for." />
                    <br />
                    <Trans id="We suggest that you return to the main page" />
                </p>
                <StyledButton onClick={() => navigate(routes.projects())}>
                    <Trans id="Go to the main page" />
                </StyledButton>
            </div>
        </AppContainer>
    )
}

const StyledButton = styled(PrimaryButton)(
    ({ theme }) => `
    margin: ${theme.spacing}rem;
`,
)

export default PageNotFound
