import gql from 'graphql-tag'
import { useCacheAndNetworkQuery } from 'components/hooks/useApolloQueries'

export const TENANT_SELECTION_SCREEN_QUERY = gql`
    query tenantSelectionQuery {
        me {
            id
            availableTenants {
                name
                guid
            }
            availableImpersonationRights {
                id
                key
                puppetLoginEmail
                expirationDateTime
                tenant {
                    guid
                    name
                }
            }
        }
    }
`

const useFetchTenantSelectionModalData = (options) =>
    useCacheAndNetworkQuery(TENANT_SELECTION_SCREEN_QUERY, options)

export default useFetchTenantSelectionModalData
