import React from 'react'
import { Trans } from '@lingui/react'
import OnboardingModal from 'components/pages/onboarding/OnboardingModal'
import SecondaryButton from 'components/util/SecondaryButton'
import PrimaryButton from 'components/util/PrimaryButton'

const AzureAdLoginModal = ({
    onClose,
    activateMsLogin,
    o365AccountNumber,
}) => {   
    const title = <><br /><Trans id="Login with your Microsoft account." /><br /></>

    const subTitle = (
        <>
            <br />
            <br />
            <Trans
                id='Activate the "login with Microsoft" option for enhanced security and ease of logging in without an additional password '
            />
            <br />
            <br />
            <br />
        </>
    )  

    return (
        <OnboardingModal title={title} subTitle={subTitle}>
            <div className="flex justify-end mt-4 gap-x-4">
                <SecondaryButton
                     onClick={() => onClose()}
                >
                    <Trans id="Skip" />
                </SecondaryButton>
                <PrimaryButton
                        onClick={activateMsLogin}
                    >
                        {o365AccountNumber === 1 ? <Trans id="Activate Microsoft login" /> : <Trans id="Go to profile" />} 
                    </PrimaryButton>
            </div>
        </OnboardingModal>
    )
}

export default AzureAdLoginModal
