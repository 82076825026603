import useAppState from 'components/hooks/useAppState'
import { PERMISSION_ROLE_SLUG_ADMIN } from 'util/constants'

const defaultPermission = {
    isHidden: true,
    reasonSlug: null,
    value: false,
}

export default function usePermissions() {
    const { applicationPermissions, currentUser } = useAppState()
    const permissions = {
        canCreateTimer: { ...defaultPermission },
        canCreateActivities: { ...defaultPermission },
        canCreateCosts: { ...defaultPermission },
        canCreateProjects: { ...defaultPermission },
        canCreatePartners: { ...defaultPermission },
        canDeleteTimer: { ...defaultPermission },
        canDeleteActivities: { ...defaultPermission },
        canDeleteActivityBlacklist: { ...defaultPermission },
        canDeleteBilling: { ...defaultPermission },
        canDeleteCosts: { ...defaultPermission },
        canDeleteProjects: { ...defaultPermission },
        canDeletePartners: { ...defaultPermission },
        canFilterActivitiesByRelevancy: { ...defaultPermission },
        canReadActivitiesMarkup: { ...defaultPermission },
        canReadTimer: { ...defaultPermission },
        canReadActivities: { ...defaultPermission },
        canReadActivityStatistics: { ...defaultPermission },
        canReadActivityBlacklist: { ...defaultPermission },
        canReadCosts: { ...defaultPermission },
        canReadDailyTimesheetEntryPredictions: {
            isHidden: false,
            reasonSlug: 'subscriptionplan',
            value: false,
        },
        canReadProjects: { ...defaultPermission },
        canReadPartners: { ...defaultPermission },
        canReadSettingsTenantSettings: { ...defaultPermission },
        canReadSettingsSubscription: { ...defaultPermission },
        canReadSettingsCoworkers: { ...defaultPermission },
        canReadSettingsTemplates: { ...defaultPermission },
        canReadSettingsProjectFields: { ...defaultPermission },
        canReadSettingsProjectPartnerLinkFields: { ...defaultPermission },
        canReadSettingsProjectNumbering: { ...defaultPermission },
        canReadSettingsProjectTags: { ...defaultPermission },
        canReadSettingsPartnerFields: { ...defaultPermission },
        canReadSettingsPartnerLinkFields: { ...defaultPermission },
        canReadSettingsProfessions: { ...defaultPermission },
        canReadSettingsPartnerLinkRoles: { ...defaultPermission },
        canReadSettingsPartnerInfoTypes: { ...defaultPermission },
        canReadSettingsPartnerTags: { ...defaultPermission },
        canReadSettingsActivityCategories: { ...defaultPermission },
        canReadSettingsCostCategories: { ...defaultPermission },
        canReadSettingsO365: { ...defaultPermission },
        canReadSettingsExternalApplication: {
            isHidden: true,
            reasonSlug: 'subscriptionplan',
            value: false,
        },
        canReadSettingsApiSubscription: {
            isHidden: false,
            reasonSlug: 'manualoverride',
            value: false,
        },
        canReadUninvolvedProjects: {
            isHidden: false,
            reasonSlug: 'permission',
            value: false,
        },
        canUpdateTimer: { ...defaultPermission },
        canUpdateActivityBlacklist: { ...defaultPermission },
        canUpdateSettingsTenantSettings: { ...defaultPermission },
        canUpdateSettingsSubscription: { ...defaultPermission },
        canUpdateSettingsCoworkers: { ...defaultPermission },
        canUpdateSettingsProjectNumbering: { ...defaultPermission },
        canUpdateSettingsProjectTags: { ...defaultPermission },
        canUpdateSettingsProfessions: { ...defaultPermission },
        canUpdateSettingsPartnerLinkRoles: { ...defaultPermission },
        canUpdateSettingsPartnerInfoTypes: { ...defaultPermission },
        canUpdateSettingsProjectPartnerLinkRoles: { ...defaultPermission },
        canUpdateSettingsPartnerTags: { ...defaultPermission },
        canUpdateSettingsActivityCategories: { ...defaultPermission },
        canUpdateSettingsCostCategories: { ...defaultPermission },
        canUpdateSettingsO365: { ...defaultPermission },
        canExportActivities: { ...defaultPermission },
        canExportCosts: { ...defaultPermission },
        canFilterActivities: { ...defaultPermission },
        canFilterCosts: { ...defaultPermission },
        canFilterProjects: { ...defaultPermission },
        canFilterPartners: { ...defaultPermission },
        canFilterTimesheetEntriesByPartner: { ...defaultPermission },
        canFilterActivitiesByPartner: { ...defaultPermission },
        // CUSTOM
        isAdmin:
            currentUser && currentUser.permissionRole
                ? currentUser.permissionRole.slug === PERMISSION_ROLE_SLUG_ADMIN
                : false,
        ...applicationPermissions,
    }
    return permissions
}
