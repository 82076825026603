/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react'
import { isSafari } from 'util/index'

const registerOpenDropdownHandlers = ({
    options,
    activeIndex,
    setActiveIndex,
    select,
    setIsDropdownOpen,
}) => {
    const optionsLength = options.length
    const keyDownCallback = (e) => {
        switch (e.key) {
            case 'Up':
            case 'ArrowUp':
                e.preventDefault()
                setActiveIndex(
                    activeIndex <= 0 ? optionsLength - 1 : activeIndex - 1,
                )
                return
            case 'Down':
            case 'ArrowDown':
                e.preventDefault()
                setActiveIndex(
                    activeIndex + 1 === optionsLength ? 0 : activeIndex + 1,
                )
                return
            case 'Enter':
                e.preventDefault()
                select(options[activeIndex])
                return
            case 'Esc':
            case 'Escape':
                e.preventDefault()
                select(false)
                setIsDropdownOpen(false)
                return
            case 'PageUp':
            case 'Home':
                e.preventDefault()
                setActiveIndex(0)
                return
            case 'PageDown':
            case 'End':
                e.preventDefault()
                setActiveIndex(options.length - 1)
                break
            default:
                break
        }
    }
    document.addEventListener('keydown', keyDownCallback)
    return () => {
        document.removeEventListener('keydown', keyDownCallback)
    }
}

const useAccessibleDropdown = ({
    value,
    valueKey = 'id',
    options,
    onChange,
    isOpen = false,
    autoFocus = false,
    closeAfterSelect = false,
}) => {
    const listRef = useRef()
    const [isFocus, setIsFocus] = useState(autoFocus)
    const [activeIndex, setActiveIndex] = useState(0)
    const [isDropdownOpen, setIsDropdownOpenInternal] = useState(isOpen)

    const setIsDropdownOpen = (v) => {
        if (v) {
            if (listRef.current && isSafari()) {
                requestAnimationFrame(() => {
                    listRef.current.focus()
                })
            }
        } else if (listRef.current && isSafari()) {
            requestAnimationFrame(() => {
                listRef.current.previousSibling.focus()
            })
        }
        setIsDropdownOpenInternal(v)
    }

    const select = (v) => {
        if (v && onChange) {
            onChange(v)
        }
        if (closeAfterSelect) {
            setIsDropdownOpen(false)
        }
    }

    useEffect(() => {
        if (isDropdownOpen && value) {
            const selected = options.findIndex(
                (o) => o[valueKey] === value[valueKey],
            )
            setActiveIndex(selected < 0 ? 0 : selected)
        }
    }, [isDropdownOpen, valueKey, options.length, value])

    useEffect(() => {
        if (isDropdownOpen) {
            return registerOpenDropdownHandlers({
                activeIndex,
                setActiveIndex,
                setIsDropdownOpen,
                options,
                select,
            })
        }
        return () => {}
    }, [isDropdownOpen, activeIndex, isFocus, options.length])

    return {
        isDropdownOpen,
        setIsDropdownOpen,
        activeIndex,
        setActiveIndex,
        select,
        setIsFocus,
        listRef,
    }
}

export default useAccessibleDropdown
