import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import useAsyncEffect from 'components/hooks/useAsyncEffect'
import AppLoading from 'components/AppLoading'
import useTracking from 'components/hooks/useTracking'
import { parseQueryString } from 'util/index'
import useSetTeamleaderFocusUserPermissions from 'api/setTeamleaderFocusUserPermissions'

const TeamleaderUserRedirectPage = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { search, origin, pathname } = location
    const { code } = parseQueryString(search)
    const { trackError } = useTracking()
    const [setTeamleaderFocusUserPermissions] =
        useSetTeamleaderFocusUserPermissions()

    useAsyncEffect(async () => {
        try {
            await setTeamleaderFocusUserPermissions({
                variables: {
                    code,
                    redirectUrl: origin + pathname,
                },
            })
        } catch (e) {
            trackError(e)
        } finally {
            navigate('/profile')
        }
    }, [])
    return <AppLoading />
}

export default TeamleaderUserRedirectPage
