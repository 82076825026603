import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import useAppState from 'components/hooks/useAppState'
import useNotifications from 'components/hooks/useNotifications'
import useTracking from 'components/hooks/useTracking'
import { TENANT_SELECTION_SCREEN_QUERY } from 'components/pages/tenant-selection/useFetchTenantSelectionModalData'
import useModalState from 'components/hooks/useModalState'
import useTerminateImpersonationRightsMutation from '../support/useTerminateImpersonationRightsMutation'
import TenantSelectionModal from './TenantSelectionModal'
import useFetchTenantSelectionModalData from './useFetchTenantSelectionModalData'
import TenantSelectionImpersonationModalWithState from './TenantSelectionImpersonationModalWithState'

const TenantSelectionModalWithState = ({ onClose, skipDataFetch = false }) => {
    const {
        availableTenants: availableTenantsFromAppState,
        availableImpersonationRights: availableImpersonationRightsFromAppState,
    } = useAppState()
    const { data = {}, loading } = useFetchTenantSelectionModalData({
        skip: skipDataFetch,
    })
    const [terminateImpersonationRights] =
        useTerminateImpersonationRightsMutation()
    const [isSubmitting, setIsSubmitting] = useState(null)
    const { trackError } = useTracking()
    const { dispatchError, dispatchSuccess } = useNotifications()
    const { setTenant, setImpersonationKey } = useAppState()
    const impersonationModal = useModalState()
    const {
        me: { availableTenants = [], availableImpersonationRights = [] } = {},
    } = data
    const tenants = skipDataFetch
        ? availableTenantsFromAppState
        : availableTenants
    const impersonationRights = skipDataFetch
        ? availableImpersonationRightsFromAppState
        : availableImpersonationRights

    return (
        <>
            <TenantSelectionModal
                isLoading={loading}
                isSubmitting={isSubmitting}
                availableTenants={tenants}
                availableImpersonationRights={impersonationRights}
                onSelectTenant={setTenant}
                onSelectImpersonation={(impersonationKey, tenantGUID) => {
                    impersonationModal.open({
                        impersonationKey,
                        tenantGUID,
                    })
                }}
                onTerminateImpersonation={async (key) => {
                    setIsSubmitting(key)
                    try {
                        await terminateImpersonationRights({
                            variables: { keys: [key] },
                            refetchQueries: () => [
                                {
                                    query: TENANT_SELECTION_SCREEN_QUERY,
                                },
                            ],
                        })
                        dispatchSuccess({
                            content: <Trans>Success!</Trans>,
                        })
                    } catch (e) {
                        trackError(e)
                        dispatchError({
                            content: <Trans>Something went wrong!</Trans>,
                        })
                    } finally {
                        setIsSubmitting(null)
                    }
                }}
                onClose={onClose}
            />
            {impersonationModal.isOpen && (
                <TenantSelectionImpersonationModalWithState
                    onClose={() => impersonationModal.close()}
                    handleSetImpersonationKey={setImpersonationKey}
                    {...impersonationModal.initialState}
                />
            )}
        </>
    )
}

export default TenantSelectionModalWithState
