import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState from 'components/hooks/useFormState'
import createFormValidation from 'util/createFormValidation'

const INITIAL_VALUES = {
    firstName: '',
    name: '',
    gender: null,
    preferredCulture: null,
}

const toOnboardingFormValues = ({ firstName, name, preferredCulture }) => ({
    ...INITIAL_VALUES,
    firstName,
    name,
    preferredCulture,
})

const toOnboardingInput = ({
    firstName,
    name,
    // gender, --the gender attribute was deleted because we will not consider gender-- (in relation with the Task 6020)
    preferredCulture,
}) => ({
    firstName,
    name,
    genderId: 3, // force the gender to have the neutral value as default value
    preferredCultureId: preferredCulture.id,
})

const validation = createFormValidation([
    {
        path: 'firstName',
        validate: (firstName) => firstName !== '',
        message: <Trans>First name is required</Trans>,
    },
    {
        path: 'name',
        validate: (name) => name !== '',
        message: <Trans>Name is required</Trans>,
    },
    {
        path: 'preferredCulture',
        validate: (preferredCulture) => preferredCulture !== null,
        message: <Trans>Language is required</Trans>,
    },
])

const useUserOnboardingForm = (currentUser) => {
    const formState = useFormState(toOnboardingFormValues(currentUser), {
        validation,
        valuesToInput: toOnboardingInput,
    })

    return {
        ...formState,
    }
}

export default useUserOnboardingForm
