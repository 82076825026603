import gql from 'graphql-tag'
import { useCacheAndNetworkQuery } from 'components/hooks/useApolloQueries'

export const userOnboardingQuery = gql`
    query userOnboardingQuery {
        genders {
            id
            slug
            translation
        }
    }
`

const useFetchRegisterModalData = () => {
    const {
        data = {},
        loading,
        error,
    } = useCacheAndNetworkQuery(userOnboardingQuery)
    const { genders } = data

    return {
        error,
        isFetching: loading,
        genders,
    }
}

export default useFetchRegisterModalData
