import usePartnerAppPreferences from './usePartnerAppPreferences'

const useSearchOptionsPreferences = () => {
    const { searchOptionsPreferences = {}, handleUpdateAppPreferencesByPath } =
        usePartnerAppPreferences()

    const path = 'searchOptionsPreferences'

    const preferences = {
        involvedProjectsOnly: false,
        ...searchOptionsPreferences,
    }

    const handleUpdatePreferences = (body) =>
        handleUpdateAppPreferencesByPath(body, path)

    return {
        preferences,
        handleUpdatePreferences,
    }
}

export default useSearchOptionsPreferences
