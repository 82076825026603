import React from 'react'
import { Trans } from '@lingui/macro'
import styled from 'styled-components'
import Icon from 'components/util/Icon'
import Title from 'components/util/Title'
import AppContainer from 'components/layout/AppContainer'
import PrimaryButton from 'components/util/PrimaryButton'

const PageError = ({ error, ...props }) => (
    <AppContainer {...props}>
        <ContentContainer>
            <StyledIcon isLarger name="alarm" accentColor="primary" />
            <Title>
                <Trans>Something went wrong!</Trans>
            </Title>
            <ErrorMessage>
                <Trans>
                    It looks like we ran into a problem. Please try to reload
                    this page or return to the homepage.
                </Trans>
            </ErrorMessage>
            <PrimaryButton
                onClick={() => {
                    window.location.href = '/'
                }}
            >
                <Trans>Homepage</Trans>
            </PrimaryButton>
        </ContentContainer>
    </AppContainer>
)

const ContentContainer = styled.main(
    ({ theme }) => `
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: auto;
    padding: ${theme.spacingLarger}rem;
    background: ${theme.colorGreyWhite};
`,
)

const StyledIcon = styled(Icon)`
    margin-bottom: ${({ theme }) => theme.spacingSmaller}rem;
`

const ErrorMessage = styled.p`
    text-align: center;
`

export default PageError
