import React from 'react'
import IconContainer from 'components/util/IconContainer'

const Comment = () => (
    <path d="M27.5,21.9 C29.7,19.8,31,17,31,14c0-6.8-6.7-12-15-12S1,7.2,1,14c0,6.8,6.7,12.3,15,12.3c1.5,0,2.9-0.2,4.2-0.5L28,29L27.5,21.9z" />
)

const Alarm = ({ accentColor }) => (
    <>
        <path stroke={accentColor} d="M13,28a3,3,0,0,0,6,0" />
        <path d="M29,25s-3-5-3-9V11A10,10,0,0,0,6,11v5c0,4-3,9-3,9Z" />
    </>
)

const Add = ({ accentColor }) => (
    <>
        <line stroke={accentColor} x1="16" x2="16" y1="9" y2="23" />
        <line stroke={accentColor} x1="23" x2="9" y1="16" y2="16" />
        <circle cx="16" cy="16" r="15" />
    </>
)

const Ban = ({ accentColor }) => (
    <>
        <line
            fill="none"
            stroke="#111111"
            x1="26.6"
            x2="5.4"
            y1="5.4"
            y2="26.6"
        />
        <circle cx="16" cy="16" fill="none" r="15" stroke={accentColor} />
    </>
)

const Check = () => (
    <path d="M16,0A16,16,0,1,0,32,16,16.047,16.047,0,0,0,16,0ZM13,22.414,7.586,17,9,15.586l4,4,10-10L24.414,11Z" />
)

const Stopwatch = ({ accentColor }) => (
    <>
        <polyline stroke={accentColor} points="16 13 16 19 22 19" />
        <circle cx="16" cy="19" fill="none" r="12" />
        <line stroke={accentColor} x1="12" x2="20" y1="1" y2="1" />
        <line stroke={accentColor} x1="16" x2="16" y1="1" y2="4" />
        <line stroke={accentColor} x1="26" x2="28" y1="7" y2="9" />
    </>
)

const ConstructionSign = ({ accentColor }) => (
    <>
        <line x1="9" x2="9" y1="21" y2="30" />
        <line x1="5" x2="5" y1="30" y2="21" />
        <line x1="27" x2="27" y1="21" y2="30" />
        <line x1="23" x2="23" y1="30" y2="21" />
        <line x1="2" x2="12" y1="30" y2="30" />
        <line x1="20" x2="30" y1="30" y2="30" />
        <polyline points="9 5 9 1 5 1 5 5" />
        <polyline points="27 5 27 1 23 1 23 5" />
        <line stroke={accentColor} x1="5" x2="17" y1="5" y2="17" />
        <line stroke={accentColor} x1="1" x2="11" y1="7" y2="17" />
        <line stroke={accentColor} x1="21" x2="31" y1="5" y2="15" />
        <line stroke={accentColor} x1="15" x2="27" y1="5" y2="17" />
        <rect height="12" width="30" x="1" y="5" />
    </>
)

const Cross = () => (
    <>
        <line x1="27" x2="5" y1="5" y2="27" />
        <line x1="27" x2="5" y1="27" y2="5" />
    </>
)

const Delete = ({ accentColor }) => (
    <>
        <path
            d="M27,10,25.259,28.284A3,3,0,0,1,22.272,31H9.728a3,3,0,0,1-2.987-2.716L5,10"
            fill="none"
            stroke={accentColor}
        />
        <line fill="none" x1="30" x2="2" y1="6" y2="6" />
        <path d="M11,6V3a2,2,0,0,1,2-2h6a2,2,0,0,1,2,2V6" fill="none" />
    </>
)

const Export = ({ accentColor }) => (
    <>
        <line fill="none" x1="16" x2="16" y1="1" y2="14" stroke={accentColor} />
        <polyline
            fill="none"
            points="24 11 26 11 31 20 31 30 1 30 1 20 6 11 8 11"
        />
        <polyline fill="none" points="21 9 16 14 11 9" stroke={accentColor} />
        <polyline fill="none" points="31 20 21 20 21 23 11 23 11 20 1 20" />
    </>
)

const Single = ({ accentColor }) => (
    <>
        <path
            d="M30,26.587 c0-1.684-1.045-3.188-2.629-3.76C25.044,21.986,21.206,21,16,21s-9.044,0.986-11.371,1.827C3.045,23.399,2,24.903,2,26.587V31h28 V26.587z"
            fill="none"
        />
        <path
            stroke={accentColor}
            d="M16,17L16,17 c-3.866,0-7-3.134-7-7V8c0-3.866,3.134-7,7-7h0c3.866,0,7,3.134,7,7v2C23,13.866,19.866,17,16,17z"
            fill="none"
        />
    </>
)

const Update = ({ accentColor }) => (
    <>
        <line fill="none" x1="20" x2="27" y1="5" y2="12" />
        <path
            d="M11,28,2,30l2-9L22.414,2.586a2,2,0,0,1,2.828,0l4.172,4.172a2,2,0,0,1,0,2.828Z"
            fill="none"
            stroke={accentColor}
        />
    </>
)

const Warning = ({ accentColor }) => (
    <path
        d="M16,0C7.178,0,0,7.178,0,16s7.178,16,16,16s16-7.178,16-16S24.822,0,16,0z M17.929,7L17,19h-2L14.071,7 H17.929z M16,25c-1.105,0-2-0.895-2-2c0-1.105,0.895-2,2-2c1.105,0,2,0.895,2,2C18,24.105,17.105,25,16,25z"
        fill={accentColor}
        stroke="none"
    />
)

const Visa = () => (
    <>
        <rect
            height="19"
            width="46"
            fill="#E6E6E6"
            x="1"
            y="14"
            stroke="none"
        />
        <path
            d="M4,41h40c1.657,0,3-1.343,3-3v-5H1v5C1,39.657,2.343,41,4,41z"
            fill="#E79800"
            stroke="none"
        />
        <path
            d="M44,7H4c-1.657,0-3,1.343-3,3v5h46v-5C47,8.343,45.657,7,44,7z"
            fill="#1A1876"
            stroke="none"
        />
        <polygon
            fill="#1A1876"
            points="19.238,28.8 21.847,28.8 23.48,19.224 20.87,19.224 "
            stroke="none"
        />
        <path
            d="M28.743,23.069c-0.912-0.443-1.471-0.739-1.465-1.187c0-0.398,0.473-0.824,1.495-0.824 c0.836-0.013,1.51,0.157,2.188,0.477l0.354-2.076c-0.517-0.194-1.327-0.402-2.339-0.402c-2.579,0-4.396,1.299-4.411,3.16 c-0.015,1.376,1.297,2.144,2.287,2.602c1.016,0.469,1.358,0.769,1.353,1.188c-0.006,0.642-0.811,0.935-1.562,0.935 c-1.158,0-1.742-0.179-2.793-0.655l-0.366,2.144c0.61,0.267,1.737,0.499,2.908,0.511c2.744,0,4.525-1.284,4.545-3.272 C30.944,24.581,30.249,23.752,28.743,23.069z"
            fill="#1A1876"
            stroke="none"
        />
        <path
            d="M38.007,19.233H35.99c-0.625,0-1.092,0.171-1.367,0.794l-3.876,8.776h2.741c0,0,0.448-1.18,0.55-1.439 c0.3,0,2.962,0.004,3.343,0.004c0.078,0.335,0.318,1.435,0.318,1.435h2.422L38.007,19.233z M34.789,25.406 c0.108-0.276,1.173-3.011,1.386-3.591c0.353,1.651,0.009,0.049,0.781,3.591H34.789z"
            fill="#1A1876"
            stroke="none"
        />
        <path
            d="M17.049,19.231l-2.556,6.53l-0.272-1.327l-0.915-4.401c-0.158-0.606-0.616-0.787-1.183-0.808H7.913 L7.88,19.424c1.024,0.248,1.939,0.606,2.742,1.05l2.321,8.317l2.762-0.003l4.109-9.558H17.049z"
            fill="#1A1876"
            stroke="none"
        />
    </>
)

const Mastercard = () => (
    <>
        <rect
            height="34"
            width="46"
            fill="#243747"
            rx="3"
            ry="3"
            x="1"
            y="7"
            stroke="none"
        />
        <circle cx="19" cy="24" fill="#e61c24" r="8" stroke="none" />
        <circle cx="28.934" cy="24" fill="#f99f1b" r="8" stroke="none" />
        <path
            d="M23.967,17.736h0a7.985,7.985,0,0,0,0,12.528h0a7.985,7.985,0,0,0,0-12.528Z"
            fill="#f26622"
            stroke="none"
        />
    </>
)

const Amex = () => (
    <>
        <path
            d="M44,7H4c-1.657,0-3,1.343-3,3v28c0,1.657,1.343,3,3,3h40c1.657,0,3-1.343,3-3V10C47,8.343,45.657,7,44,7z"
            fill="#007AC6"
            stroke="none"
        />
        <path
            d="M10.533,24.429h2.33l-1.165-2.857L10.533,24.429z M43,19h-5.969l-1.456,1.571L34.264,19H21.598l-1.165,2.571 L19.268,19h-5.096v1.143L13.59,19H9.222L5,29h5.096l0.582-1.571h1.456L12.716,29h5.678v-1.143L18.831,29h2.912l0.437-1.286V29 h11.648l1.456-1.571L36.594,29h5.969l-3.785-5L43,19z M25.383,27.571h-1.602V22l-2.475,5.571h-1.456L17.375,22v5.571h-3.349 L13.444,26H9.95l-0.582,1.571H7.475l3.057-7.143h2.475l2.766,6.714v-6.714h2.766l2.184,4.857l2.038-4.857h2.766v7.143H25.383z M39.797,27.571h-2.184l-1.893-2.429l-2.184,2.429h-6.552v-7.143h6.697l2.038,2.286l2.184-2.286h2.038L36.739,24L39.797,27.571z M28.586,21.857v1.286h3.64v1.429h-3.64V26h4.077l1.893-2.143l-1.747-2H28.586z"
            fill="#FFFFFF"
            stroke="none"
        />
    </>
)

const IconLarge = ({ size, name, ...props }) => (
    <IconContainer name={name} size={size} viewBox="0 0 32 32" {...props}>
        {(accentColor) => {
            const iconProps = { accentColor }
            switch (name) {
                case 'add':
                    return <Add {...iconProps} />
                case 'ban':
                    return <Ban {...iconProps} />
                case 'contacts':
                    return <Comment {...iconProps} />
                case 'check':
                    return <Check {...iconProps} />
                case 'construction':
                    return <ConstructionSign {...iconProps} />
                case 'delete':
                    return <Delete {...iconProps} />
                case 'export':
                    return <Export {...iconProps} />
                case 'stopwatch':
                    return <Stopwatch {...iconProps} />
                case 'alarm':
                    return <Alarm {...iconProps} />
                case 'cross':
                    return <Cross {...iconProps} />
                case 'person':
                    return <Single {...iconProps} />
                case 'update':
                    return <Update {...iconProps} />
                case 'warning':
                    return <Warning {...iconProps} />
                case 'visa':
                    return <Visa {...iconProps} />
                case 'mastercard':
                    return <Mastercard {...iconProps} />
                case 'amex':
                    return <Amex {...iconProps} />
                default:
                    throw Error(
                        `Icon with name '${name}' was not found in IconLarge.`,
                    )
            }
        }}
    </IconContainer>
)

export default IconLarge
