import React from 'react'
import styled from 'styled-components'
import { Trans } from '@lingui/macro'
import { useApolloClient } from '@apollo/client'
import { delay } from 'util/index'
import isTenantAvailable from 'api/isTenantAvailable'
import useAppState from 'components/hooks/useAppState'
import Modal from 'components/pages/onboarding/OnboardingModal'
import ErrorPage from 'components/pages/error/ErrorPage'
import useTracking from 'components/hooks/useTracking'

const REQUEST_TIMEOUT = 10000

const RegisterModalLoading = () => {
    const apolloClient = useApolloClient()
    const { refresh, currentTenant } = useAppState()
    const { trackError } = useTracking()

    const tenantInput = currentTenant === null ? '' : currentTenant.id

    const checkIsTenantAvailable = async () => {
        try {
            const isReady = await isTenantAvailable(apolloClient, tenantInput)
            if (isReady) {
                refresh()
            } else {
                await delay(REQUEST_TIMEOUT)
                return checkIsTenantAvailable()
            }
        } catch (e) {
            trackError(e)
            return <ErrorPage error={e.message} />
        }
        return null
    }
    checkIsTenantAvailable()

    return (
        <Modal
            title={<Trans>Finishing</Trans>}
            subTitle={
                <Trans>Hang in there, we are finishing the installation!</Trans>
            }
        >
            <RegisterModalContainer>
                <Message>
                    <Trans>Finishing installation...</Trans>
                </Message>
                <Message>
                    <Trans>
                        This will take approximately 5 minutes. You&apos;ll be
                        notified by mail when we are ready, or you can just wait
                        here.
                    </Trans>
                </Message>
            </RegisterModalContainer>
        </Modal>
    )
}

const RegisterModalContainer = styled.div`
    min-height: 15vh;
    overflow: auto;
`

const Message = styled.div(
    ({ theme }) => `
    padding-top: ${theme.spacingSmall}rem;
    padding-bottom: ${theme.spacingSmall}rem;
    width: 100%;
`,
)

export default RegisterModalLoading
