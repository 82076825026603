/* eslint-disable no-underscore-dangle */
import React from 'react'
import gql from 'graphql-tag'
import { Trans } from '@lingui/react'
import { PAGE_INFO_FRAGMENT, connectionToCollection } from 'util/graphql'
import Icon from 'components/util/Icon'

export const SEARCH_QUERY = gql`
    query searchQuery($search: String, $where: NewProjectWhereInput) {
        partners(
            first: 10
            search: {
                firstNameContains: $search
                nameContains: $search
                tagContains: $search
                fullNameContains: $search
                reverseFullNameContains: $search
            }
        ) {
            edges {
                node {
                    id
                    fullName
                    type {
                        id
                        slug
                        translation
                    }
                    primaryAddress {
                        id
                        address
                    }
                }
                cursor
            }
            pageInfo {
                ...PageInfo
            }
        }
        projectsPaginated(
            currentPage: 1
            pageSize: 10
            search: {
                numberContains: $search
                titleContains: $search
                tagContains: $search
            }
            where: $where
        ) {
            nodes {
                id
                number
                title
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
`

const partnerToSearchResult = (partner) => {
    let partnerAddress = ''
    if (partner.primaryAddress !== null) {
        partnerAddress = `(${partner.primaryAddress.address})`
    }
    return {
        id: partner.id,
        label: (
            <Label iconName={partner.type.slug}>
                {partner.fullName} <i>{partnerAddress}</i>
            </Label>
        ),
        type: partner.__typename,
        node: partner,
    }
}

const projectToSearchResult = (project) => ({
    id: project.id,
    label: (
        <Label iconName="project">
            {project.number} <i>({project.title})</i>
        </Label>
    ),
    type: project.__typename,
    node: project,
})

export default async function search(apolloClient, searchValue) {
    const query = SEARCH_QUERY
    const variables = {
        search: searchValue,
        where: {
            statusSlugs: ['active'],
        },
    }
    const { data = {}, error } = await apolloClient.query({
        query,
        variables,
        fetchPolicy: 'network-only',
    })
    if (error) {
        throw new Error(error)
    }
    const { partners, projectsPaginated } = data
    return [
        {
            label: <Trans id="Contacts" />,
            options: connectionToCollection(partners).map(
                partnerToSearchResult,
            ),
        },
        {
            label: <Trans id="Projects" />,
            options: projectsPaginated.nodes.map(projectToSearchResult),
        },
    ]
}

const Label = ({ iconName, children }) => (
    <span>
        <Icon name={iconName} isSmaller className="mr-4" />
        {children}
    </span>
)
