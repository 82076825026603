import React from 'react'
import styled from 'styled-components'
import useAppState from 'components/hooks/useAppState'
import SubTitle from 'components/util/SubTitle'

const ImpersonationOverlay = () => {
    const { isImpersonating, currentUser, currentTenant } = useAppState()

    if (isImpersonating) {
        return (
            <Overlay>
                <StyledText>
                    {currentUser && currentUser.loginEmail}
                    <br />
                    {currentTenant && currentTenant.name}
                    <br />
                    {currentTenant && currentTenant.guid}
                </StyledText>
            </Overlay>
        )
    }

    return null
}

const Overlay = styled.div`
    display: flex;
    opacity: 0.2;
    width: 100%;
    height: 100%;
    z-index: 999;
    position: fixed;
    pointer-events: none;
    align-items: center;
    justify-content: center;
`

const StyledText = styled(SubTitle)`
    text-align: center;
`

export default ImpersonationOverlay
