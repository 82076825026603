import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import NotificationsProvider from 'components/providers/NotificationsProvider'
import useAppState from 'components/hooks/useAppState'
import { DayPickerGlobalStyles } from 'components/util/DatePicker'
import TopBar, { TOPBAR_HEIGHT } from 'components/layout/TopBar'
import { ActivitiesUpdaterProvider } from 'components/providers/ActivitiesUpdaterProvider'
import Toaster from 'components/util/Toaster'
import ApolloProvider from 'components/providers/ApolloProvider'
import ThemeProvider from 'components/providers/ThemeProvider'
import LanguageProvider from 'components/providers/LanguageProvider'
import AppRouterWithState from 'components/AppRouterWithState'
import usePermissions from 'components/hooks/usePermissions'
import AppLoading from 'components/AppLoading'
import AppGlobalStyles from 'components/AppGlobalStyles'
import AppGlobalFontStyles from 'components/AppGlobalFontStyles'
import AppErrorBoundary from 'components/AppErrorBoundary'
import ImpersonationOverlay from './pages/tenant-selection/ImpersonationOverlay'
import PopoverStateProvider from './providers/PopoverStateProvider'

const App = ({ apolloClient, notificationDispatcher }) => {
    const {
        isLoading,
        isAuthenticated,
        isAuthorized,
        accountState,
        accountStateDetails,
        accountStateCompletion,
        preferredCultureCode,
        currentUser,
        currentTenant,
    } = useAppState()
    const { canReadTimer } = usePermissions()
    return (
        <BrowserRouter>
            <ApolloProvider client={apolloClient}>
                <NotificationsProvider dispatcher={notificationDispatcher}>
                    <ThemeProvider>
                        <LanguageProvider languageCode={preferredCultureCode}>
                            <PopoverStateProvider>
                                <AppGlobalFontStyles />
                                <DayPickerGlobalStyles />
                                <AppGlobalStyles />
                                <Toaster />
                                <ImpersonationOverlay />
                                <AppErrorBoundary>
                                    {isLoading ? (
                                        <AppLoading />
                                    ) : (
                                        <ActivitiesUpdaterProvider>
                                            {isAuthenticated && (
                                                <TopBar
                                                    currentUser={currentUser}
                                                    currentTenant={
                                                        currentTenant
                                                    }
                                                    canReadTimer={canReadTimer}
                                                />
                                            )}
                                            <AppRouterWithState
                                                isLoading={isLoading}
                                                isAuthenticated={
                                                    isAuthenticated
                                                }
                                                isAuthorized={isAuthorized}
                                                accountState={accountState}
                                                accountStateDetails={
                                                    accountStateDetails
                                                }
                                                accountStateCompletion={
                                                    accountStateCompletion
                                                }
                                            />
                                        </ActivitiesUpdaterProvider>
                                    )}
                                </AppErrorBoundary>
                            </PopoverStateProvider>
                        </LanguageProvider>
                    </ThemeProvider>
                </NotificationsProvider>
            </ApolloProvider>
        </BrowserRouter>
    )
}

export default App
