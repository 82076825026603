/* eslint-disable no-param-reassign */
import React from 'react'
import { Trans } from '@lingui/react'
import {
    APP_NAME,
    PARTNER_TYPE_PERSON as PERSON,
    PARTNER_TYPE_COMPANY as COMPANY,
    PARTNER_TYPE_COWORKER as COWORKER,
    ACTIVITY_STATUS_BILLED,
    ACTIVITY_STATUS_APPROVED,
    ACTIVITY_STATUS_PROPOSED,
    CUSTOM_FIELD_TYPE_SLUG_BOOLEAN,
    CUSTOM_FIELD_TYPE_SLUG_DATE,
    CUSTOM_FIELD_TYPE_SLUG_DATETIME,
    ACTIVITY_STATUS_CONFIRMED,
    CUSTOM_FIELD_TYPE_SLUG_SELECT,
    PAYMENT_TYPE_CREDITCARD,
    PAYMENT_TYPE_SEPA,
    O365_TOKEN_EXPIRED_SLUG,
    O365_TOKEN_ALERT_SLUG,
    TIMESHEETENTRY_STATUS_CREATED,
    TIMESHEETENTRY_STATUS_CONFIRMED,
    TIMESHEETENTRY_STATUS_BILLED,
    PRODUCT_STATUS_SLUG_ARCHIVED,
    PRODUCT_STATUS_SLUG_ACTIVE,
} from 'util/constants'
import { where, toBoolean } from 'util/index'
import {
    DEFAULT_FORMAT_TIME_STRING,
    DEFAULT_FORMAT_DATE_STRING,
    isValidDate,
    parseDate,
    formatDate,
} from 'util/dates'
import LinkButton from 'components/util/LinkButton'
import { i18n } from '../components/providers/LanguageProvider'

export const isCompany = (slug) => slug === COMPANY
export const isPerson = (slug) => slug === PERSON || slug === COWORKER

const ACTIVE = 'active'
const ARCHIVED = 'archived'

export const getStatusColor = (status, theme) => {
    if (status === ACTIVE) {
        return theme.colorActive
    }
    if (status === ARCHIVED) {
        return theme.colorArchived
    }
    return theme.colorActive
}

export const getSum = (entities, key) =>
    entities.reduce((sum, current) => sum + current[key], 0)

export const toHours = (duration) => duration / 60

const notificationSlugTest = (
    <Trans id="This is a test message, only visible on dev environment" />
)
const notificationSlugNoO365AdminConsent = (
    <Trans
        id="{APP_NAME} needs extra permissions to connect to O365, please contact your office admin"
        values={{ APP_NAME }}
    />
)
const notificationSlugIntegrationRuntimePending = (
    <Trans id="There is no data because you still need to install the Integration Runtime" />
)
const notificationSlugO365TokenExpired = (
    <>
        <Trans id="The connection between Lexor and O365 has expired. Please open your" />{' '}
        <LinkButton href="./profile">
            <Trans id="profile page" />
        </LinkButton>{' '}
        <Trans id="to renew the connection." />
    </>
)
const notificationSlugO365TokenAlert = (
    <>
        <Trans id="The connection between Lexor and O365 will expire soon. Please open your" />{' '}
        <LinkButton href="./profile">
            <Trans id="profile page" />
        </LinkButton>{' '}
        <Trans id="to renew the connection." />
    </>
)

const notificationSlugUnknown = <Trans id="Invalid message" />

export const getNotificationMessage = (slug) => {
    switch (slug) {
        case 'test':
            return notificationSlugTest
        case 'noO365AdminConsent':
            return notificationSlugNoO365AdminConsent
        case 'integrationRuntimePending':
            return notificationSlugIntegrationRuntimePending
        case O365_TOKEN_EXPIRED_SLUG:
            return notificationSlugO365TokenExpired
        case O365_TOKEN_ALERT_SLUG:
            return notificationSlugO365TokenAlert
        default:
            return `${notificationSlugUnknown} (slug: ${slug})`
    }
}

export const noProjectId = -1
export const noProjectTitle = i18n._(/* i18n */ 'No Project')
export const noProjectNumber = i18n._(/* i18n */ 'No Project')

export const confidentialProjectId = -2
export const confidentialProjectTitle = i18n._(/* i18n */ 'Confidential')
export const confidentialProjectNumber = i18n._(/* i18n */ 'Confidential')

export const fakePartnerId = -1

export const activityStatusOptions = [
    {
        slug: ACTIVITY_STATUS_PROPOSED,
        translation: <Trans id="Proposed" />,
    },
    {
        slug: ACTIVITY_STATUS_APPROVED,
        translation: <Trans id="Approved" />,
    },
    {
        slug: ACTIVITY_STATUS_CONFIRMED,
        translation: <Trans id="Confirmed" />,
    },
    {
        slug: ACTIVITY_STATUS_BILLED,
        translation: <Trans id="Billed" />,
    },
]

export const timesheetEntryStatusOptions = [
    {
        slug: TIMESHEETENTRY_STATUS_CREATED,
        translation: <Trans id="Created" />,
    },
    {
        slug: TIMESHEETENTRY_STATUS_CONFIRMED,
        translation: <Trans id="Confirmed" />,
    },
    {
        slug: TIMESHEETENTRY_STATUS_BILLED,
        translation: <Trans id="Billed" />,
    },
]

export const RelevanceOptions = [
    {
        isRelevant: true,
        slug: 'relevant',
        translation: i18n._(/* i18n */ 'Focused'),
    },
    {
        isRelevant: false,
        slug: 'irrelevant',
        translation: i18n._(/* i18n */ 'Other'),
    },
]

export const dateFormatOptions = [
    {
        value: 'dd-MM-yyyy',
        mask: '99-99-9999',
        translation: i18n._(/* i18n */ 'EU format (23-02-2020)'),
    },
    {
        value: 'yyyy-MM-dd',
        mask: '9999-99-99',
        translation: i18n._(/* i18n */ 'US format (2020-02-23)'),
    },
]

export const projectStatusOptions = [
    {
        value: PRODUCT_STATUS_SLUG_ARCHIVED,
        slug: PRODUCT_STATUS_SLUG_ARCHIVED,
        translation: i18n._(/* i18n */ 'Archived'),
    },
    {
        value: PRODUCT_STATUS_SLUG_ACTIVE,
        slug: PRODUCT_STATUS_SLUG_ACTIVE,
        translation: i18n._(/* i18n */ 'Active'),
    },
]

export const subscriptionIntervalOptions = [
    {
        value: 'year',
        translation: i18n._(/* i18n */ 'yearly'),
    },
    {
        value: 'month',
        translation: i18n._(/* i18n */ 'monthly'),
    },
]

export const PaymentOptions = [
    {
        slug: PAYMENT_TYPE_CREDITCARD,
        translation: i18n._(/* i18n */ 'Credit card'),
    },
    {
        slug: PAYMENT_TYPE_SEPA,
        translation: i18n._(/* i18n */ 'SEPA (EU only)'),
    },
]

export const tablePaginationOptions = [
    {
        value: 25,
        translation: i18n._(/* i18n */ '25 / page'),
    },
    {
        value: 50,
        translation: i18n._(/* i18n */ '50 / page'),
    },
    {
        value: 100,
        translation: i18n._(/* i18n */ '100 / page'),
    },
    {
        value: 250,
        translation: i18n._(/* i18n */ '250 / page'),
    },
    {
        value: 500,
        translation: i18n._(/* i18n */ '500 / page'),
    },
]

export const whereCustomFieldId = where('customFieldId')

export const getCustomFieldValue = (
    customFieldValue,
    typeSlug,
    dateFormat = DEFAULT_FORMAT_DATE_STRING,
) => {
    if (typeof customFieldValue !== 'undefined') {
        let value = null
        if (customFieldValue.value !== null) {
            value = customFieldValue.value
        }
        switch (typeSlug) {
            case CUSTOM_FIELD_TYPE_SLUG_BOOLEAN: {
                value = toBoolean(customFieldValue.value) ? (
                    <Trans id="Yes" />
                ) : (
                    <Trans id="No" />
                )
                return value
            }
            case CUSTOM_FIELD_TYPE_SLUG_DATE: {
                const dateValue = parseDate(value)
                if (isValidDate(dateValue)) {
                    return formatDate(dateValue, dateFormat)
                }
                return value
            }
            case CUSTOM_FIELD_TYPE_SLUG_DATETIME: {
                const dateValue = parseDate(value)
                const dateTimeFormat = `${dateFormat} ${DEFAULT_FORMAT_TIME_STRING}`
                if (isValidDate(dateValue)) {
                    return formatDate(dateValue, dateTimeFormat)
                }
                return value
            }
            case CUSTOM_FIELD_TYPE_SLUG_SELECT: {
                const { selectedDatasetItem } = customFieldValue
                if (selectedDatasetItem === null) {
                    return value
                }
                return selectedDatasetItem.translation
            }
            default: {
                return value
            }
        }
    }
    return null
}

export const getPermissionsReasonMessage = (slug) => {
    switch (slug) {
        case 'subscriptionplan':
            return (
                <Trans id="Please upgrade to an advanced plan to enable this feature." />
            )
        case 'role':
            return <Trans id="No permission (wrong user role)" />
        case 'partner':
            return <Trans id="No permission (partner privacy settings)" />
        case 'item':
            return <Trans id="No permission (item)" />
        case 'externalapplication':
            return <Trans id="Managed by external application" />
        case 'activityislinkedtotimesheetentry':
            return <Trans id="Activity is linked to a timesheet" />
        case 'timesheetentryisconfirmed':
            return <Trans id="Timesheet is confirmed" />
        case 'timesheetentryisbilled':
            return <Trans id="Timesheet is billed" />
        case 'timesheetentryisinlockedperiod':
            return (
                <Trans id="Timesheet can not be changed because that period is locked" />
            )
        case 'timesheetentrylinkedtoexternalapplication':
            return <Trans id="Timesheet is linked to external application" />
        case 'activitycategoryislocked':
        case 'costcategoryislocked':
            return <Trans id="Category is locked" />
        case 'projectpartnerlinkislinkedtoprojectfromextapp':
            return (
                <Trans id="The partner is linked to an external application" />
            )
        case 'templatelinkedtoextapp':
            return (
                <Trans id="This templates is linked to an external application" />
            )
        case 'IsUser':
            return (
                <Trans
                    id="This user is a {APP_NAME} user"
                    values={{ APP_NAME }}
                />
            )
        case 'managedbysupportagent':
            return (
                <Trans
                    id="Contact the {APP_NAME} team to change your subscription."
                    values={{ APP_NAME }}
                />
            )
        case 'onlyAvailableForMultipleParticipants':
            return <Trans id="Only available for multiple participants" />
        case 'pluginActivityTypeProcessing':
            return (
                <Trans id="Adjust the settings in the plugin to stop processing this activity" />
            )
        case 'otherActivityTypeProcessing':
            return (
                <Trans id="This activity type can't be stopped from processing" />
            )
        default:
            return slug
    }
}
