import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from 'components/hooks/useNotifications'
import useTracking from 'components/hooks/useTracking'
import TenantSelectionImpersonationModal from './TenantSelectionImpersonationModal'
import useTenantSelectionImpersonationForm from './useTenantSelectionImpersonationForm'
import useStartImpersonationSessionMutation from './useStartImpersonationSessionMutation'

const TenantSelectionImpersonationModalWithState = ({
    onClose,
    handleSetImpersonationKey,
    impersonationKey,
    tenantGUID,
    ...props
}) => {
    const [startImpersonationSession, { loading: isSubmitting }] =
        useStartImpersonationSessionMutation()
    const { trackError } = useTracking()
    const formState = useTenantSelectionImpersonationForm({
        key: impersonationKey,
    })
    const { dispatchError } = useNotifications()
    return (
        <TenantSelectionImpersonationModal
            {...props}
            formState={formState}
            onClose={onClose}
            onSubmit={async () => {
                const isValid = formState.validate()
                if (isValid) {
                    try {
                        await handleSetImpersonationKey
                        const input = formState.valuesToInput()
                        await startImpersonationSession({
                            variables: input,
                        })
                        await handleSetImpersonationKey(
                            impersonationKey,
                            tenantGUID,
                        )
                    } catch (e) {
                        trackError(e)
                        dispatchError({
                            content: <Trans>Something went wrong!</Trans>,
                        })
                    } finally {
                        onClose()
                    }
                }
            }}
            isSubmitting={isSubmitting}
        />
    )
}

export default TenantSelectionImpersonationModalWithState
