import React from 'react'
import styled from 'styled-components'
import { TOPBAR_HEIGHT } from 'components/layout/TopBar'
import useAppState from 'components/hooks/useAppState'
import AppContainer from 'components/layout/AppContainer'
import BannerComponent from 'components/layout/BannerComponent'
import MainSideBar from 'components/layout/MainSideBar'
import { SIDEBAR_WIDTH } from './SideBar'

const Page = ({ children, ...props }) => {
    const { bannerToDisplay } = useAppState()

    return (
        <AppContainer {...props}>
            <MainSideBar />
            <PageMainContainer>
                {bannerToDisplay && bannerToDisplay.length > 0 && (
                    <BannerComponent bannerContent={bannerToDisplay} />
                )}
                {children}
            </PageMainContainer>
        </AppContainer>
    )
}
export const PageMainContainer = styled.main(
    ({ theme }) => `
    width: 100%;
    margin-top: ${TOPBAR_HEIGHT}rem;
    margin-left: ${SIDEBAR_WIDTH}rem;
    background-color: ${theme.colorGreyLightest};
`,
)

export default Page
