import React from 'react'
import { Dialog as ReachDialog } from '@reach/dialog'
import styled, { withTheme } from 'styled-components'
import Spinner from 'components/util/Spinner'
import { motion, AnimatePresence } from 'framer-motion'

const getModalSize = ({ issmall, islarge, islarger, theme }) => {
    if (issmall) {
        return theme.modalSizeSmall
    }
    if (islarge) {
        return theme.modalSizeLarge
    }
    if (islarger) {
        return theme.modalSizeLarger
    }
    return theme.modalSize
}

const Modal = withTheme(({ theme, ariaLabel, children, ...props }) => (
    <AnimatePresence exitBeforeEnter>
        <MotionReachDialog
            isOpen
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
                top: '50%',
                left: '50%',
                position: 'absolute',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: theme.colorWhite,
                borderRadius: `${theme.borderRadius}rem`,
                maxWidth: `${getModalSize({ theme, ...props })}rem`,
                width: '100%',
                margin: 0,
            }}
            aria-label={ariaLabel}
            {...props}
        >
            {children}
        </MotionReachDialog>
    </AnimatePresence>
))

const MotionReachDialog = motion(ReachDialog)

export const ModalMainContainer = ({ isLoading, children, ...props }) => (
    <StyledMainContainer {...props}>
        {isLoading ? (
            <SpinnerContainer>
                <Spinner />
            </SpinnerContainer>
        ) : (
            children
        )}
    </StyledMainContainer>
)

const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`

const StyledMainContainer = styled.main(
    ({ theme }) => `
    overflow: auto;
    position: relative;
    max-height: 65vh;
    padding: ${theme.spacing}rem ${theme.spacing}rem;
`,
)

export default Modal
