import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Async from 'react-select/async'
import styled, { withTheme } from 'styled-components'
import { components as SelectComponents } from 'react-select'
import { Trans } from '@lingui/react'
import * as routes from 'routes/index'
import { getSelectTheme } from 'components/util/Select'
import useDebouncedCallback from 'components/hooks/useDebouncedCallback'
import useAppState from 'components/hooks/useAppState'
import { ACCOUNT_STATE_READY } from 'util/constants'

const { Option } = SelectComponents

const createCustomStyles = (theme) => ({
    container: (provided) => ({
        ...provided,
        boxShadow: 'none',
    }),
    control: (provided) => ({
        ...provided,
        outline: 'unset',
        boxShadow: 'none',
        minWidth: '12rem',
        borderTopRightRadius: `${theme.defaultBorderRadius}rem`,
        borderColor: theme.defaultBorderColor,
        minHeight: '3.8rem',
        backgroundColor: theme.colorWhite,
        '&:hover': {
            borderColor: theme.colorGrey,
        },
    }),
    option: (provided) => ({
        ...provided,
        cursor: 'pointer',
        fontWeight: theme.fontWeightLight,
        color: theme.colorBlack,
        background: theme.colorWhite,
        '&:hover': {
            
            background: theme.colorPrimaryLightest,
        },
    }),
    placeholder: (provided) => ({
        ...provided,
        color: theme.colorGreyLight,
        fontStyle: 'italic',
    }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: theme.selectMenuZIndex,
    }),
    menu: (provided) => ({
        ...provided,
        margin: 0,
        border: 'none',
        borderRadius: `${theme.defaultBorderRadius}rem`,
        boxShadow: theme.boxShadow,
        zIndex: theme.selectMenuZIndex,
    }),
    dropdownIndicator: () => ({
        paddingRight: `${theme.spacingSmall}rem`,
    }),
    valueContainer: (provided) => ({
        ...provided,
        paddingLeft: `${theme.spacingSmaller}rem`,
    }),
    singleValue: (provided) => ({
        ...provided,
        margin: 0,
    }),
    multiValue: (provided) => ({
        ...provided,
        color: theme.colorWhite,
        backgroundColor: theme.colorPrimary,
        margin: 0,
        marginRight: `${theme.spacingSmaller}rem`,
        borderRadius: `${theme.borderRadius}rem`,
        maxWidth: '20rem',
        '&:not(:first-child)': {
            marginTop: `${theme.spacingMinimum}rem`,
        },
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        padding: '.2rem',
        paddingLeft: `${theme.spacingSmaller}rem`,
        paddingRight: `${theme.spacingSmallest}rem`,
        color: theme.colorWhite,
        fontSize: `${theme.fontSize}rem`,
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        cursor: 'pointer',
    }),
})

const getPath = (data) => {
    if (data === null) {
        return ''
    }
    if (data.type === 'Project') {
        return routes.projectDetails(data.id, 'overview')
    }
    if (data.type === 'Partner') {
        return routes.contactDetails(data.id)
    }
    return ''
}

const TopBarSearch = ({
    theme,
    onSearch,
    noResultsText = <Trans id="No search results" />,
    loadingText = <Trans id="Loading" />,
}) => {
    const { accountState, refresh } = useAppState()
    const navigate = useNavigate()
    const location = useLocation()
    const [isSearching, setIsSearching] = useState(false)
    const handleSearch = (search, callback) => {
        setIsSearching(true)
        onSearch(search).then((options) => {
            callback(options)
            setIsSearching(false)
        })
    }
    const debouncedHandleSearch = useDebouncedCallback(handleSearch, 500)
    if (accountState !== ACCOUNT_STATE_READY) {
        return null
    }
    return (
        <StyledSelect
            onChange={(data) => {
                const path = getPath(data)
                if (path !== location.pathname) {
                    navigate(path)
                } else {
                    refresh()
                }
            }}
            loadOptions={debouncedHandleSearch}
            isLoading={isSearching}
            theme={(provided) => getSelectTheme(provided, theme)}
            styles={createCustomStyles(theme)}
            placeholder={<Trans id="Search..." />}
            components={{
                DropdownIndicator: () => null,
                Option,
                IndicatorSeparator: () => null,
            }}
            noOptionsMessage={() => noResultsText}
            loadingMessage={() => loadingText}
        />
    )
}

const StyledSelect = styled(Async)(
    ({ theme }) => `
    width: 50rem;
    font-size: ${theme.fontSizeSmall}rem;
`,
)

export default withTheme(TopBarSearch)
