import React from 'react'
import { Trans } from '@lingui/macro'
import OnboardingModal from 'components/pages/onboarding/OnboardingModal'

const UserOnboardingModalWelcome = ({ ...props }) => (
    <OnboardingModal
        title={<Trans>Welcome</Trans>}
        subTitle={
            <Trans>
                Before we get started, we need a few details to setup your
                account
            </Trans>
        }
        {...props}
    />
)

export default UserOnboardingModalWelcome
