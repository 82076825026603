import gql from 'graphql-tag'
import { useApolloClient } from '@apollo/client'
import {
    createAddQueryUpdater,
    createRemoveQueryUpdater,
    createReplaceQueryUpdater,
    createAlterQueryUpdater,
} from 'util/graphql'
import { activityStatusOptions } from 'util/entities'
import { useCacheAndNetworkQuery } from 'components/hooks/useApolloQueries'
import { PERMISSION_FRAGMENT } from 'api/getInitialData'
import { ACTIVITY_CATEGORY_FRAGMENT } from 'api/fragments'

export const ACTIVITY_FRAGMENT = gql`
    fragment ActivityWithCosts on Activity {
        id
        start
        end
        title
        durationInMinutes
        description
        status
        bodyPreview
        categoryId
        isProposed
        isLinkedToBillEntry
        isBillable
        isRelevant
        partnerId
        partner {
            id
            fullName
        }
        projectId
        project {
            id
            title
            number
            externalApplicationIds
            allowedActivityCategoryIds
            invoicingLanguage {
                id
                slug
                translation
            }
            children {
                id
                child {
                    id
                    title
                    number
                    externalApplicationIds
                    invoicingLanguage {
                        id
                        slug
                        translation
                    }
                }
            }
            parents {
                id
                parent {
                    id
                    title
                    number
                    externalApplicationIds
                    invoicingLanguage {
                        id
                        slug
                        translation
                    }
                }
            }
        }
        activityCategory {
            ...ActivityCategory
        }
        activityType {
            id
            slug
            translation
        }
        permissions {
            canUpdate {
                ...Permission
            }
            canDelete {
                ...Permission
            }
        }
        projectGuesses {
            id
            score
            project {
                id
                title
                number
                externalApplicationIds
                allowedActivityCategoryIds
                invoicingLanguage {
                    id
                    slug
                    translation
                }
                children {
                    id
                    child {
                        id
                        number
                        title
                        externalApplicationIds
                        invoicingLanguage {
                            id
                            slug
                            translation
                        }
                    }
                }
                parents {
                    id
                    parent {
                        id
                        number
                        title
                        externalApplicationIds
                        invoicingLanguage {
                            id
                            slug
                            translation
                        }
                    }
                }
            }
        }
        participants {
            name
            emailAddress
            phoneNumber
        }
    }
    ${PERMISSION_FRAGMENT}
    ${ACTIVITY_CATEGORY_FRAGMENT}
`

export const ACTIVITIES_PAGE_PAGINATED_QUERY = gql`
    query activitiesPagePaginatedQuery(
        $where: NewActivityWhereInput
        $currentPage: Int!
        $pageSize: Int!
        $orderBy: NewActivityOrderInput
    ) {
        activitiesPaginated(
            where: $where
            currentPage: $currentPage
            pageSize: $pageSize
            orderBy: $orderBy
        ) {
            nodes {
                ...ActivityWithCosts
                relatedActivityIds(where: $where)
            }
            meta {
                currentPage
                pageSize
                lastPage
                totalCount
                exportKey
                totalDurationInMinutes
            }
        }
        activityCategories {
            id
            slug
            translation
            externalApplicationIds
        }
    }
    ${ACTIVITY_FRAGMENT}
`

const useFetchActivitiesPagePaginatedData = (
    pageSize,
    currentPage,
    whereInput,
) => {
    const apolloClient = useApolloClient()
    const variables = {
        pageSize,
        currentPage,
        where: whereInput,
        orderBy: {
            start: 'asc',
        },
    }
    const {
        data = {},
        error,
        loading,
    } = useCacheAndNetworkQuery(ACTIVITIES_PAGE_PAGINATED_QUERY, {
        variables,
    })

    let activities = []
    if (!loading && typeof data.activitiesPaginated !== 'undefined') {
        activities = data.activitiesPaginated.nodes.map((activity) => ({
            ...activity,
            editable:
                activity.status === 'proposed' ||
                activity.status === 'approved',
        }))
    }

    const activitiesQueryUpdater = {
        replace: createReplaceQueryUpdater(
            apolloClient,
            ACTIVITIES_PAGE_PAGINATED_QUERY,
            variables,
        ),
        add: createAddQueryUpdater(
            apolloClient,
            ACTIVITIES_PAGE_PAGINATED_QUERY,
            variables,
        ),
        remove: createRemoveQueryUpdater(
            apolloClient,
            ACTIVITIES_PAGE_PAGINATED_QUERY,
            variables,
        ),
        alter: createAlterQueryUpdater(
            apolloClient,
            ACTIVITIES_PAGE_PAGINATED_QUERY,
            variables,
        ),
    }

    const activitiesPageRefetchQueries = [
        {
            query: ACTIVITIES_PAGE_PAGINATED_QUERY,
            variables,
        },
    ]

    return {
        error,
        isFetching: loading,
        activities,
        exportKey: !loading && data.activitiesPaginated.meta.exportKey,
        pageMeta: !loading && data.activitiesPaginated.meta,
        activityCategories: !loading && data.activityCategories,
        activityStatuses: activityStatusOptions,
        activitiesQueryUpdater,
        activitiesPageRefetchQueries,
    }
}

export default useFetchActivitiesPagePaginatedData
