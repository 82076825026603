import React, { useState } from 'react'
import gql from 'graphql-tag'
import { Trans, useLingui } from '@lingui/react'
import { useQuery } from '@apollo/client'
import { whereId, sortBy } from '../../util/index'
import useSearch from '../hooks/useSearch'
import usePopoverState from '../hooks/usePopoverState'
import useAccessibleDropdown from '../hooks/useAccessibleDropdown'
import MultiSelect from './MultiSelect'
import { PERMISSION_FRAGMENT } from '../../api/getInitialData'

export const QUERY = gql`
    query activityCategoriesQuery($where: ActivityCategoryWhereInput) {
        activityCategories(where: $where) {
            id
            slug
            translation
            isActive
            externalApplicationIds
            isBillableByDefault
            translation
            translations {
                id
                value
                targetCultureId
                targetCulture {
                    id
                    code
                }
            }
            # Permissions were added to fix the 'missing field' to the apollo cache
            permissions {
                canUpdate {
                    ...Permission
                }
                canDelete {
                    ...Permission
                }
            }
        }
    }
    ${PERMISSION_FRAGMENT}
`

const ActivityCategorySearchMultiSelect = ({
    open,
    autoFocus = false,
    id = 'activityCategory',
    name = 'activityCategory',
    projectId = null,
    value = [],
    onChange,
    defaultOptions = [],
    ...props
}) => {
    const { i18n } = useLingui()
    const [search, setSearch] = useSearch('')
    const [inputValue, setInputValue] = useState('')
    const { openPopover, closePopover } = usePopoverState(id, open)

    const variables = {
        where: {
            forProjectId: projectId,
        },
    }

    const { data = {}, loading } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
    })

    let activityCategories = data.activityCategories || []
    if (defaultOptions.length > 0) {
        const ids = defaultOptions.map((o) => o.id)
        activityCategories = activityCategories.filter(
            (ac) => !ids.includes(ac.id),
        )
    }
    if (value.length > 0) {
        activityCategories = activityCategories.filter(
            (ac) => !value.find(whereId(ac.id)),
        )
    }
    if (search) {
        activityCategories = activityCategories.filter(({ translation }) =>
            translation.toLowerCase().includes(search.toLowerCase()),
        )
    }
    const sortedActivityCategories = [...activityCategories].sort(
        sortBy('translation'),
    )

    const handleClear = () => {
        setSearch('')
        setInputValue('')
        onChange([])
    }

    const handleChangeInput = (e) => {
        setSearch(e.target.value)
        setInputValue(e.target.value)
    }

    const handleChange = (project) => {
        const index = value.findIndex(whereId(project.id))
        let updatedValues = value
        if (index === -1) {
            updatedValues = [...value, project]
        } else {
            updatedValues.splice(index, 1)
        }
        setInputValue('')
        onChange(updatedValues)
    }

    const { setIsDropdownOpen, activeIndex, select, setIsFocus, listRef } =
        useAccessibleDropdown({
            options: sortedActivityCategories,
            isOpen: open,
            onChange: handleChange,
            autoFocus,
        })

    return (
        <MultiSelect
            ref={listRef}
            id={id}
            content={() => {
                if (loading) {
                    return (
                        <MultiSelect.Placeholder>
                            <Trans id="Loading..." />
                        </MultiSelect.Placeholder>
                    )
                }
                if (sortedActivityCategories.length === 0) {
                    return (
                        <MultiSelect.Placeholder>
                            <Trans id="No results" />
                        </MultiSelect.Placeholder>
                    )
                }
                return sortedActivityCategories.map(
                    (activityCategory, index) => (
                        <MultiSelect.Option
                            key={activityCategory.id}
                            label={activityCategory.translation}
                            isActive={index === activeIndex}
                            onClick={() => {
                                select(activityCategory)
                                setIsDropdownOpen(false)
                            }}
                        />
                    ),
                )
            }}
            {...props}
        >
            <>
                {value.map((category) => (
                    <MultiSelect.Value
                        key={category.id}
                        label={category.translation}
                        onRemove={() => handleChange(category)}
                    />
                ))}
                <MultiSelect.SearchInput
                    autoComplete="off"
                    id={id}
                    name={name}
                    value={value}
                    inputValue={inputValue}
                    onClear={handleClear}
                    onChange={handleChangeInput}
                    placeholder={i18n._(/* i18n */ 'Select category')}
                    autoFocus={autoFocus}
                    onFocus={(e) => {
                        e.target.select()
                        setIsFocus(true)
                        setIsDropdownOpen(true)
                        openPopover()
                    }}
                    onBlur={() => {
                        setIsFocus(false)
                        setIsDropdownOpen(false)
                        closePopover()
                    }}
                />
            </>
        </MultiSelect>
    )
}

export default ActivityCategorySearchMultiSelect
