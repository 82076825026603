import { InMemoryCache, ApolloClient } from '@apollo/client'
import { createHttpLink } from '@apollo/client/link/http'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import apolloLogger from 'apollo-link-logger'
import * as storage from 'util/storage'
import { loadAndValidateTokens } from 'util/auth'
import EventTargetPolyfill from 'util/eventTargetPolyfill'
import { getAppInsights } from 'services/applicationInsights'

const { REACT_APP_GRAPHQL_URI, REACT_APP_APPINSIGHTS_KEY } = process.env

export const tokenEventTarget = new EventTargetPolyfill()

const setAuthContext = async (request, { headers }) => {
    const currentHeaders = { ...headers, ClientInfo: 'WebApp' }
    const { accessToken, isUserLogged } = await loadAndValidateTokens()
    const authorizationScheme = await storage.getAuthorizationScheme()
    if (authorizationScheme) {
        currentHeaders['Authorization-Scheme'] = authorizationScheme
    }
    if (accessToken === null && !isUserLogged) {
        return { headers: currentHeaders }
    }
    if (accessToken === null && isUserLogged) {
        tokenEventTarget.dispatchEvent(new CustomEvent('tokenExpired'))
        return null
    }
    let authHeaders = {}
    let impersonationHeaders = {}
    const impersonationKey = await storage.getImpersonationKey()
    if (impersonationKey !== null) {
        impersonationHeaders = { 'Impersonation-Key': impersonationKey }
    }
    const tenantGUID = await storage.getTenantGUID()
    if (tenantGUID !== null) {
        authHeaders = { Tenant: tenantGUID }
    }

    return {
        headers: {
            ...authHeaders,
            ...currentHeaders,
            ...impersonationHeaders,
            Authorization: `Bearer ${accessToken}`,
        },
    }
}

const errorLink = onError(
    ({ response, operation, networkError, graphQLErrors }) => {
        const environment = process.env.NODE_ENV
        const isDev = environment === 'development'

        const appInsights = getAppInsights(REACT_APP_APPINSIGHTS_KEY)

        if (operation.operationName === 'IgnoreErrorsQuery') {
            response.errors = null
        }

        if (graphQLErrors) {
            if (isDev) {
                // eslint-disable-next-line no-console
                graphQLErrors.map(({ message, locations, path }) =>
                    console.log(
                        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                    ),
                )
            }
            const error = new Error('GraphQL.Error')
            error.stack = JSON.stringify({
                graphQLErrors,
            })
            appInsights.trackException({
                exception: error,
            })
        }

        if (networkError) {
            if (isDev) {
                // eslint-disable-next-line no-console
                console.log(`[Network error]: ${JSON.stringify(networkError)}`)
            }
            if (networkError.statusCode === 401) {
                storage.removeAccessToken()
                storage.removeRefreshToken()
                storage.removeTenantGUID()
                storage.removeImpersonationKey()
                storage.removeAuthorizationScheme()
            }
            appInsights.trackException({
                exception: networkError,
            })
        }
    },
)

const httpLink = createHttpLink({
    uri: REACT_APP_GRAPHQL_URI,
    credentials: 'include',
})

let setAuthorizationHeadersLink

if (process.env.REACT_APP_ENV !== 'production') {
    setAuthorizationHeadersLink =
        setContext(setAuthContext).concat(apolloLogger)
} else {
    setAuthorizationHeadersLink = setContext(setAuthContext)
}

const linkFlow = setAuthorizationHeadersLink.concat(errorLink.concat(httpLink))

export default function createApolloClient() {
    return new ApolloClient({
        link: linkFlow,
        cache: new InMemoryCache(),
    })
}
