import React from 'react'
import styled from 'styled-components'
import { DEFAULT_LABEL_WITH } from 'components/layout/FormDetails'

const FormItem = ({
    label = null,
    optionalLabel,
    error = null,
    isHorizontal,
    labelWidth,
    required,
    children,
    ...props
}) => (
    <Container isHorizontal={isHorizontal} {...props}>
        {label && (
            <div className= {`flex ${optionalLabel ? 'justify-between' : 'flex-start'}`}>
                <Label
                    required={required}
                    isHorizontal={isHorizontal}
                    labelWidth={labelWidth}
                >
                    {label}
                    {label !== null ? ':' : ''}
                </Label>
                {optionalLabel && <Label>{optionalLabel}</Label>}
            </div>
        )}
        <InputContainer>
            {children}
            <ErrorContainer>
                {error !== null &&
                    error.map((e, index) => {
                        if (e !== null) {
                            return (
                                <Error
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={index}
                                    data-testid="FormItemError"
                                >
                                    {e}
                                </Error>
                            )
                        }
                        return e
                    })}
            </ErrorContainer>
        </InputContainer>
    </Container>
)

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: ${({ isHorizontal }) => (isHorizontal ? 'row' : 'column')};
    justify-content: ${({ isHorizontal }) =>
        isHorizontal ? 'flex-start' : 'center'};
    align-items: ${({ isHorizontal }) => isHorizontal && 'center'};
    margin-bottom: ${({ theme }) => theme.spacingSmallest}rem;
    font-size: ${({ theme }) => theme.defaultFontSize}rem;
`

const Label = styled.label`
    position: relative;
    display: flex;
    align-self: flex-start;
    align-items: center;
    color: ${({ theme }) => theme.colorGrey};
    justify-content: ${({ isHorizontal }) => isHorizontal && 'flex-end'};
    margin-right: ${({ isHorizontal, theme }) =>
        isHorizontal && `${theme.spacing}rem`};
    ${({ isHorizontal, theme }) =>
        isHorizontal
            ? `padding-top: ${theme.spacingSmallest}rem;`
            : `padding-bottom: ${theme.spacingSmallest}rem;`}
    ::after {
        color: ${({ theme }) => theme.colorRed};
        content: '${({ required }) => required && '*'}';
        padding-left: ${({ theme }) => theme.spacingSmallest}rem;
    }
    min-height: 2.6rem;
    ${({ isHorizontal, labelWidth }) =>
        isHorizontal &&
        (typeof labelWidth !== 'undefined'
            ? `width: ${labelWidth}rem;`
            : `width: ${DEFAULT_LABEL_WITH}rem;`)}
`

const InputContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`

const ErrorContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: ${({ theme }) => theme.fontSizeSmall}rem;
`

const Error = styled.span`
    color: ${({ theme }) => theme.colorError};
    padding-top: ${({ theme }) => theme.spacingSmallest}rem;
`

export default FormItem
