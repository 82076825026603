export const colorWhite = '#FFFFFF'
export const colorBlack = '#181817'

export const colorSalmonLightest = '#FDF1F0'
export const colorSalmonLighter = '#F5C4BE'
export const colorSalmonLight = '#ED978B'
export const colorSalmon = '#E56A59'
export const colorSalmonDark = '#B85548'

export const colorBlueLight = '#359BE0'
export const colorBlueDark = '#124B5F'

export const colorRed = '#FF7B68'

export const colorGreen = '#4bd285'

export const colorYellow = '#ffe066'

export const colorSuccess = '#def2d6'
export const colorWarning = '#f8f3d6'
export const colorError = '#ebc8c4'

export const colorPrimaryLightest = colorSalmonLightest
export const colorPrimaryLighter = colorSalmonLighter
export const colorPrimaryLight = colorSalmonLight
export const colorPrimary = colorSalmon
export const colorPrimaryDark = colorSalmonDark
export const colorPrimaryAccent = colorBlueDark

export const colorGreyWhite = '#FBFBFB'
export const colorGreyLightest = 'rgba(18, 75, 95, 0.02)'
export const colorGreyLighter = '#e2e8f0'
export const colorGreyLight = '#cbd5e0'
export const colorGrey = '#a0aec0'
export const colorGreyDark = '#718096'
export const colorGreyDarker = '#4a5568'
export const colorGreyDarkest = '#2d3748'
export const colorGreyBlack = '#1a202c'

export const colorActive = colorGreen
export const colorArchived = colorSalmonDark

export const chartColors = [
    '#e56a59',
    '#e5ba59',
    '#3ea05a',
    '#3e5aa0',
    '#a29b51',
    '#6374e5',
    '#e259e5',
    '#207787',
    '#120403',
    '#87b8dd',
]

export const spacingMinimum = 0.1
export const spacingSmallest = 0.5
export const spacingSmaller = 1
export const spacingSmall = 1.5
export const spacing = 2
export const spacingLarge = 3
export const spacingLarger = 4
export const spacingLargest = 5

export const borderRadiusSmall = 0.3
export const borderRadius = 0.5
export const borderRadiusLarge = 1
export const borderRadiusLarger = 1.5

export const borderWidth = 0.1
export const borderWidthLarge = 0.2
export const borderWidthLarger = 0.4

export const fontSizeSmallest = 0.8
export const fontSizeSmaller = 1
export const fontSizeSmall = 1.2
export const fontSize = 1.3
export const fontSizeLarge = 1.5
export const fontSizeLarger = 2
export const fontSizeLargest = 2.4

export const lineHeightLarge = 1.9

export const fontWeightThin = 100
export const fontWeightExtraLight = 200
export const fontWeightLight = 300
export const fontWeightRegular = 400
export const fontWeightMedium = 500
export const fontWeightSemiBold = 600
export const fontWeightBold = 700
export const fontWeightExtraBold = 800
export const fontWeightBlack = 900

export const boxShadow = '0 2px 4px 0 rgba(0,0,0,0.10)'
export const boxShadowLarge =
    '0 4px 8px 0 rgba(0,0,0,0.12),0 2px 4px 0 rgba(0,0,0,0.08)'
export const boxShadowLarger =
    '0 15px 30px 0 rgba(0,0,0,0.11),0 5px 15px 0 rgba(0,0,0,0.08)'

export const minWidth = 102.4

export const defaultSpacing = spacing

export const defaultBackgroundColor = colorWhite

export const defaultFontFamily =
    '"Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
export const numberFontFamily = '"Verdana", Monaco, monospace'
export const defaultFontWeight = fontWeightLight
export const defaultFontSize = fontSize
export const defaultFontColor = colorGreyDarker
export const defaultFontColorDark = colorGreyBlack
export const defaultFontColorLight = colorGrey
export const defaultFontColorDisabled = colorGreyLight
export const defaultLineHeight = 1.5
export const defaultLetterSpacing = 0

export const defaultBorderColor = colorGreyLighter
export const defaultBorderWidth = borderWidth
export const defaultBorderRadius = borderRadius

export const defaultIconColor = colorBlack
export const defaultIconInnerColor = colorBlack

export const defaultButtonBorderRadius = borderRadiusSmall

export const iconSizeSmallest = 8
export const iconSizeSmaller = 12
export const iconSizeSmall = 16
export const iconSize = 22
export const iconSizeLarge = 26
export const iconSizeLarger = 32
export const iconSizeLargest = 60

export const modalSizeSmall = 40
export const modalSize = 55
export const modalSizeLarge = 70
export const modalSizeLarger = 90

export const defaultNavigationZIndex = 1
export const defaultPopoverZIndex = 2
export const modalBackgroundZIndex = 3
export const modalZIndex = 4
export const selectMenuZIndex = 5
export const toasterZIndex = 6
export const toolTipZIndex = 7

// Media queries
export const bpSmall = 576 // Mobile landscape for most smaller screens
export const bpMedium = 768 // Tablet landscape or big phone landscape
export const bpLarge = 992 // Most tablets and small desktop screens
export const bpExtraLarge = 1200 // Desktops

export const forSmallOnly = `max-width: ${bpSmall - 1}px`
export const forSmallAndUp = `min-width: ${bpSmall}px`

export const forMediumAndUp = `min-width: ${bpMedium}px`
export const forBelowMedium = `max-width: ${bpMedium - 1}px`

export const forLargeAndUp = `min-width: ${bpLarge}px`
export const forBelowLarge = `max-width: ${bpLarge - 1}px`

export const forExtraLargeAndUp = `min-width: ${bpExtraLarge}px`
export const forBelowExtraLarge = `max-width: ${bpExtraLarge - 1}px`

export const cursorPointer =
    "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Ctitle%3Epen%3C/title%3E%3Cg fill='%23000000'%3E%3Cpath d='M11.707,1.879,10.121.293a1,1,0,0,0-1.414,0L7.354,1.646l3,3,1.353-1.353A1,1,0,0,0,11.707,1.879Z'%3E%3C/path%3E%3Cpolygon points='6.646 2.354 1 8 0 12 4 11 9.646 5.354 6.646 2.354' fill='%23000000'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E\"), text"

export const theme = {
    colorWhite,
    colorBlack,
    colorSalmonLightest,
    colorSalmonLighter,
    colorSalmonLight,
    colorSalmon,
    colorSalmonDark,
    colorBlueLight,
    colorBlueDark,
    colorRed,
    colorGreen,
    colorYellow,
    colorSuccess,
    colorWarning,
    colorError,
    colorPrimaryLightest,
    colorPrimaryLighter,
    colorPrimaryLight,
    colorPrimary,
    colorPrimaryDark,
    colorPrimaryAccent,
    colorGreyWhite,
    colorGreyLightest,
    colorGreyLighter,
    colorGreyLight,
    colorGrey,
    colorGreyDark,
    colorGreyDarker,
    colorGreyDarkest,
    colorGreyBlack,
    colorActive,
    colorArchived,
    chartColors,
    spacingMinimum,
    spacingSmallest,
    spacingSmaller,
    spacingSmall,
    spacing,
    spacingLarge,
    spacingLarger,
    spacingLargest,
    borderRadiusSmall,
    borderRadius,
    borderRadiusLarge,
    borderRadiusLarger,
    borderWidth,
    borderWidthLarge,
    borderWidthLarger,
    fontSizeSmallest,
    fontSizeSmaller,
    fontSizeSmall,
    fontSize,
    fontSizeLarge,
    fontSizeLarger,
    fontSizeLargest,
    lineHeightLarge,
    fontWeightThin,
    fontWeightExtraLight,
    fontWeightLight,
    fontWeightRegular,
    fontWeightMedium,
    fontWeightSemiBold,
    fontWeightBold,
    fontWeightExtraBold,
    fontWeightBlack,
    boxShadow,
    boxShadowLarge,
    boxShadowLarger,
    minWidth,
    defaultSpacing,
    defaultBackgroundColor,
    defaultFontFamily,
    numberFontFamily,
    defaultFontWeight,
    defaultFontSize,
    defaultFontColor,
    defaultFontColorDark,
    defaultFontColorLight,
    defaultFontColorDisabled,
    defaultLineHeight,
    defaultLetterSpacing,
    defaultBorderColor,
    defaultBorderWidth,
    defaultBorderRadius,
    defaultIconColor,
    defaultIconInnerColor,
    defaultButtonBorderRadius,
    iconSizeSmallest,
    iconSizeSmaller,
    iconSizeSmall,
    iconSize,
    iconSizeLarge,
    iconSizeLarger,
    iconSizeLargest,
    modalSizeSmall,
    modalSize,
    modalSizeLarge,
    modalSizeLarger,
    defaultNavigationZIndex,
    defaultPopoverZIndex,
    modalBackgroundZIndex,
    modalZIndex,
    selectMenuZIndex,
    toasterZIndex,
    toolTipZIndex,
    bpSmall,
    bpMedium,
    bpLarge,
    bpExtraLarge,
    forSmallOnly,
    forSmallAndUp,
    forMediumAndUp,
    forBelowMedium,
    forLargeAndUp,
    forBelowLarge,
    forExtraLargeAndUp,
    forBelowExtraLarge,
}
