import gql from 'graphql-tag'
import { useApolloClient } from '@apollo/client'
import { createQueryUpdater } from 'util/graphql'
import { useCacheAndNetworkQuery } from 'components/hooks/useApolloQueries'
import { PERMISSION_FRAGMENT } from 'api/getInitialData'
import { CUSTOM_FIELD_FRAGMENT } from '../settings-project-fields/useFetchSettingsProjectFieldsPageData'

export const PARTNER_INFO_ADDRESS_FRAGMENT = gql`
    fragment PartnerInfoAddress on PartnerInfoAddress {
        id
        address
        addressData
        country {
            id
            code
            slug
            translation
        }
        type {
            id
            slug
            translation
        }
        isPrimary
    }
`

export const PARTNER_INFO_EMAIL_FRAGMENT = gql`
    fragment PartnerInfoEmail on PartnerInfoEmail {
        id
        email
        type {
            id
            slug
            translation
        }
        isPrimary
    }
`

export const PARTNER_INFO_PHONE_FRAGMENT = gql`
    fragment PartnerInfoPhone on PartnerInfoPhone {
        id
        number
        type {
            id
            slug
            translation
        }
        isPrimary
    }
`

export const PARTNER_LINK_FRAGMENT = gql`
    fragment PartnerLink on PartnerLink {
        id
        role {
            id
            slug
            translation
        }
        partnerTwo {
            id
            fullName
        }
    }
`

export const PARTNER_DETAILS_FRAGMENT = gql`
    fragment PartnerDetails on Partner {
        id
        fullName
        firstName
        name
        gender {
            id
            slug
            translation
        }
        country {
            id
            slug
            translation
        }
        addresses {
            ...PartnerInfoAddress
        }
        emails {
            ...PartnerInfoEmail
        }
        phones {
            ...PartnerInfoPhone
        }
        partnerLinks {
            ...PartnerLink
        }
        primaryEmail {
            id
            email
        }
        primaryPhone {
            id
            number
        }
        projectPartnerLinks {
            id
            partnerId
            role {
                id
                slug
                translation
            }
            project {
                id
                number
                title
                projectOwnerId
                mainClient {
                    name
                }
            }
        }
        type {
            id
            slug
            translation
        }
        tags {
            id
            label
        }
        professions {
            id
            label
        }
        birthPlace
        birthDate
        socialSecurityNumber
        preferredCulture {
            id
            slug
            translation
        }
        fiscalInformations {
            id
            vatNumber
            enterpriseNumber
            country {
                id
                slug
                translation
            }
        }
        customFieldValues {
            id
            value
            customFieldId
            selectedDatasetItem {
                id
                translation
            }
        }
        permissions {
            canUpdate {
                ...Permission
            }
            canDelete {
                ...Permission
            }
        }
    }
    ${PARTNER_INFO_ADDRESS_FRAGMENT}
    ${PARTNER_INFO_EMAIL_FRAGMENT}
    ${PARTNER_INFO_PHONE_FRAGMENT}
    ${PARTNER_LINK_FRAGMENT}
    ${PERMISSION_FRAGMENT}
`

export const PARTNER_DETAILS_QUERY = gql`
    query partner($partnerId: Int!) {
        partner(id: $partnerId) {
            ...PartnerDetails
        }
        genders {
            id
            slug
            translation
        }
        cultures {
            id
            slug
            translation
        }
        partnerInfoTypes {
            id
            slug
            translation
        }
        countries {
            id
            slug
            code
            translation
        }
        partnerLinkRoles {
            id
            slug
            translation
        }
        partnerProfessions {
            id
            label
        }
        tenantSettings {
            id
            partnerCustomFields {
                ...CustomField
            }
        }
    }
    ${PARTNER_DETAILS_FRAGMENT}
    ${CUSTOM_FIELD_FRAGMENT}
`

const useFetchContactDetailsPageData = ({ partnerId }) => {
    const variables = { partnerId }
    const apolloClient = useApolloClient()
    const {
        data = {},
        error,
        loading,
    } = useCacheAndNetworkQuery(PARTNER_DETAILS_QUERY, {
        variables,
    })
    const {
        partner,
        genders,
        cultures,
        countries,
        partnerInfoTypes,
        partnerLinkRoles,
        partnerProfessions,
        tenantSettings = {},
    } = data

    const { partnerCustomFields } = tenantSettings

    const updatePartnerQuery = createQueryUpdater(
        apolloClient,
        PARTNER_DETAILS_QUERY,
        variables,
    )

    const refetchQueries = [
        {
            query: PARTNER_DETAILS_QUERY,
            variables,
        },
    ]

    // parse the addressData field as soon as it comes from the database.
    // start of the block
    let newPartner = null
    if (partner) {
        const { addresses } = partner
        const newAddresses = []
        addresses.map((address) =>
            newAddresses.push({
                ...address,
                addressData: JSON.parse(address.addressData),
            }),
        )
        newPartner = {
            ...partner,
            addresses: newAddresses,
        }
    }
    // end of the block

    return {
        isFetching: loading,
        error,
        partner: newPartner,
        genders,
        cultures,
        countries,
        partnerInfoTypes,
        partnerLinkRoles,
        partnerProfessions,
        partnerCustomFields,
        updatePartnerQuery,
        refetchQueries,
    }
}

export default useFetchContactDetailsPageData
