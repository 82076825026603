import { ACTIVITY_FRAGMENT } from 'components/pages/activities/useFetchActivitiesPagePaginatedData'
import gql from 'graphql-tag'

const CREATE_ACTIVITY_MUTATION = gql`
    mutation createActivity($input: CreateActivityInput!) {
        createActivity(input: $input) {
            activity {
                ...ActivityWithCosts
            }
        }
    }
    ${ACTIVITY_FRAGMENT}
`

export default async function createActivity(apolloClient, input, options) {
    const mutation = CREATE_ACTIVITY_MUTATION
    const variables = { input }
    const response = await apolloClient.mutate({
        mutation,
        variables,
        ...options,
    })
    return response.data.createActivity
}
