import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState from 'components/hooks/useFormState'
import createFormValidation from 'util/createFormValidation'
import {
    isBefore,
    parseTime,
    formatTime,
    getUtcDateTime,
    isValidTimeString,
    isValidDate,
    minutesToTimeFormatString,
    timeStringToMinutes,
    addMinutes,
    differenceInMinutes,
    minutesUntilMidnight,
} from 'util/dates'

const INITIAL_VALUES = {
    project: null,
    category: null,
    type: null,
    description: '',
    date: new Date(),
    start: formatTime(Date.now()),
    end: formatTime(Date.now()),
    duration: '00:00',
    isBillable: true,
    timerId: null,
}

const toActivityFormValues = ({
    date,
    end,
    start,
    project,
    description,
    type,
    timerId,
}) => {
    let values = INITIAL_VALUES
    if (project !== null) {
        values = { ...values, project }
    }
    if (date !== null) {
        values = { ...values, date }
    }
    if (start !== '') {
        values = { ...values, start }
    }
    if (end !== '') {
        values = { ...values, end }
    }
    if (description !== '') {
        values = { ...values, description }
    }
    if (type !== null) {
        values = { ...values, type }
    }
    if (timerId !== null) {
        values = { ...values, timerId }
    }
    return {
        project: values.project,
        category: values.category,
        type: values.type,
        description: values.description,
        date: values.date,
        start: values.start,
        end: values.end,
        duration: minutesToTimeFormatString(
            differenceInMinutes(parseTime(values.end), parseTime(values.start)),
        ),
        isBillable: values.isBillable,
        timerId: values.timerId,
    }
}

export const toActivityInput = ({
    project,
    category,
    type,
    description,
    date,
    start,
    end,
    isBillable,
    timerId,
}) => ({
    projectId: project !== null ? project.id : null,
    categoryId: category.id,
    typeId: type.id,
    description,
    isBillable,
    start: getUtcDateTime(date, start),
    end: getUtcDateTime(date, end),
    timerId,
})

export const validation = createFormValidation([
    {
        path: 'category',
        validate: (category, { type }) => category !== null && type !== null,
        message: <Trans>Category and type are required</Trans>,
    },
    {
        path: 'type',
        validate: (type, { category }) => category !== null && type !== null,
        message: <Trans>Category and type are required</Trans>,
    },
    {
        path: 'description',
        validate: (description) => description.length > 0,
        message: <Trans>Description is required</Trans>,
    },
    {
        path: 'date',
        validate: (start) => isValidDate(start),
        message: <Trans>Invalid date</Trans>,
    },
    {
        path: 'start',
        validate: (start) => isValidTimeString(start),
        message: <Trans>Invalid starttime</Trans>,
    },
    {
        path: 'start',
        validate: (start, { end }) =>
            isValidTimeString(end) &&
            isBefore(parseTime(start), parseTime(end)),
        message: <Trans>Starttime must be before endtime.</Trans>,
    },
    {
        path: 'end',
        validate: (end) => isValidTimeString(end),
        message: <Trans>Invalid endtime</Trans>,
    },
    {
        path: 'end',
        validate: (end, { start }) =>
            isValidTimeString(end) &&
            isBefore(parseTime(start), parseTime(end)),
        message: <Trans>Endtime must be after starttime.</Trans>,
    },
    {
        path: 'duration',
        validate: (duration, { start }) =>
            timeStringToMinutes(duration) <
            minutesUntilMidnight(parseTime(start)),
        message: <Trans>An activity can not exceed midnight</Trans>,
    },
])

const useCreateActivityForm = (values, options) => {
    const formState = useFormState(toActivityFormValues(values), {
        validation,
        valuesToInput: toActivityInput,
        ...options,
    })

    const { start, end, category } = formState.values

    let startDate = isValidTimeString(start) ? parseTime(start) : null

    let endDate = isValidTimeString(end) ? parseTime(end) : null

    const handleChangeProject = (value) => {
        formState.setValues({
            project: value,
            category:
                category !== null &&
                value !== null &&
                value.allowedActivityCategoryIds &&
                value.allowedActivityCategoryIds.includes(category.id)
                    ? category
                    : null,
        })
    }

    const handleChangeCategory = (value) => {
        formState.setValues({
            category: value,
            isBillable: value ? value.isBillableByDefault : true,
        })
    }

    const handleChangeDescription = (e) => {
        formState.setValues({
            description: e.target.value,
        })
    }

    const handleChangeEndTime = (e) => {
        const endInput = e.target.value
        let duration = ''
        endDate = isValidTimeString(endInput) ? parseTime(endInput) : null
        if (
            isValidDate(startDate) &&
            isValidDate(endDate) &&
            isBefore(parseTime(start), parseTime(endInput))
        ) {
            duration = minutesToTimeFormatString(
                differenceInMinutes(endDate, startDate),
            )
        }
        formState.setValues({
            end: endInput,
            duration,
        })
    }

    const handleChangeStartTime = (e) => {
        const startInput = e.target.value
        let duration = ''
        startDate = isValidTimeString(startInput) ? parseTime(startInput) : null
        if (
            isValidDate(startDate) &&
            isValidDate(endDate) &&
            isBefore(parseTime(startInput), parseTime(end))
        ) {
            duration = minutesToTimeFormatString(
                differenceInMinutes(endDate, startDate),
            )
        }
        formState.setValues({
            start: startInput,
            duration,
        })
    }

    const handleChangeDuration = (e) => {
        const durationInput = e.target.value
        endDate = addMinutes(startDate, timeStringToMinutes(durationInput))
        formState.setValues({
            end: formatTime(endDate),
            duration: durationInput,
        })
    }

    const handleAddCost = (newCost) => {
        formState.handleChange('costs', (costs) => [...costs, newCost])
    }

    const handleRemoveCost = (index) => {
        formState.handleChange('costs', (costs) =>
            costs.filter((v, i) => i !== index),
        )
    }

    const handleUpdateCost = (updatedCost, index) => {
        formState.handleChange('costs', (costs) =>
            costs.map((cost, i) => (i === index ? updatedCost : cost)),
        )
    }

    return {
        ...formState,
        handleChangeProject,
        handleChangeCategory,
        handleAddCost,
        handleRemoveCost,
        handleUpdateCost,
        handleChangeEndTime,
        handleChangeStartTime,
        handleChangeDuration,
        handleChangeDescription,
    }
}

export default useCreateActivityForm
