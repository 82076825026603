import React, { useState } from 'react'
import { Trans, useLingui } from '@lingui/react'
import { useQuery } from '@apollo/client'
import useSearch from '../hooks/useSearch'
import usePopoverState from '../hooks/usePopoverState'
import useAccessibleDropdown from '../hooks/useAccessibleDropdown'
import { sortBy } from '../../util/index'
import Select from './Select2'
import { QUERY } from './ActivityCategorySearchMultiSelect'

const ActivityCategorySearchSelect = ({
    id = 'activityCategory',
    open,
    name = 'activityCategory',
    autoFocus = false,
    projectId = null,
    disabled = false,
    defaultOptions = [],
    value = null,
    onChange,
    ...props
}) => {
    const { i18n } = useLingui()
    const [search, setSearch] = useSearch('')
    const [inputValue, setInputValue] = useState('')
    const [selectedValue, setSelectedValue] = useState(value)
    const { isOpen, openPopover, closePopover } = usePopoverState(id, open)

    const variables = {
        where: {
            forProjectId: projectId,
        },
    }

    const { data = {}, loading } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
    })

    let activityCategories = data.activityCategories || []
    if (defaultOptions.length > 0) {
        const ids = defaultOptions.map((o) => o.id)
        activityCategories = activityCategories.filter(
            (ac) => !ids.includes(ac.id),
        )
    }
    if (search) {
        activityCategories = activityCategories.filter(({ translation }) =>
            translation.toLowerCase().includes(search.toLowerCase()),
        )
    }
    const sortedActivityCategories = [...activityCategories].sort(
        sortBy('translation'),
    )

    const handleClear = () => {
        setSearch('')
        setInputValue('')
        onChange(null)
        setSelectedValue(null)
    }

    const handleChangeInput = (e) => {
        openPopover()
        setSearch(e.target.value)
        setInputValue(e.target.value)
        onChange('')
        setSelectedValue(null)
    }

    const handleChange = (project) => {
        setSearch('')
        setInputValue('')
        onChange(project)
        setSelectedValue(project)
        closePopover()
    }

    const { setIsDropdownOpen, activeIndex, select, setIsFocus, listRef } =
        useAccessibleDropdown({
            value,
            options: sortedActivityCategories,
            isOpen,
            onChange: handleChange,
            autoFocus,
            closeAfterSelect: true,
        })

    let finalInputValue = inputValue
    if (selectedValue) {
        finalInputValue = selectedValue.translation
        if (value && selectedValue.id !== value.id) {
            finalInputValue = value.translation
        }
    }

    return (
        <Select
            ref={listRef}
            id={id}
            disabled={disabled}
            content={() => {
                if (loading) {
                    return (
                        <Select.Placeholder>
                            <Trans id="Loading..." />
                        </Select.Placeholder>
                    )
                }
                if (activityCategories.length === 0) {
                    return (
                        <Select.Placeholder>
                            <Trans id="No results" />
                        </Select.Placeholder>
                    )
                }
                return sortedActivityCategories.map(
                    (activityCategory, index) => (
                        <Select.Option
                            key={activityCategory.id}
                            label={activityCategory.translation}
                            isActive={index === activeIndex}
                            onClick={() => {
                                select(activityCategory)
                            }}
                        />
                    ),
                )
            }}
            {...props}
        >
            <Select.SearchInput
                autoComplete="off"
                id={id}
                name={name}
                disabled={disabled}
                value={finalInputValue}
                onClear={handleClear}
                onChange={handleChangeInput}
                placeholder={i18n._(/* i18n */ 'Select category')}
                autoFocus={autoFocus}
                onFocus={(e) => {
                    e.target.select()
                    openPopover()
                    setIsFocus(true)
                    setIsDropdownOpen(true)
                }}
                onBlur={() => {
                    setIsFocus(false)
                    setIsDropdownOpen(false)
                    closePopover()
                }}
            />
        </Select>
    )
}

export default ActivityCategorySearchSelect
