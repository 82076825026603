import React from 'react'
import styled from 'styled-components'

const TableColumnWidths = ({ widths = [] }) => (
    <colgroup>
        {widths.map((columnWidth, i) => (
            <Column
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                columnWidth={columnWidth}
            />
        ))}
    </colgroup>
)

const Column = styled.col`
    width: ${({ columnWidth }) => `${columnWidth}rem`};
    min-width: ${({ columnWidth }) => `${columnWidth}rem`};
    max-width: ${({ columnWidth }) => `${columnWidth}rem`};
`

export default TableColumnWidths
