import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import useAsyncEffect from 'components/hooks/useAsyncEffect'
import AppLoading from 'components/AppLoading'
import useTracking from 'components/hooks/useTracking'
import { parseQueryString } from 'util/index'
import useSetTeamleaderFocusAdminPermissions from 'api/setTeamleaderFocusAdminPermissions'

const TeamleaderAdminRedirectPage = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { search, pathname } = location
    const { code } = parseQueryString(search)
    const { trackError } = useTracking()
    const [setTeamleaderFocusAdminPermissions] =
        useSetTeamleaderFocusAdminPermissions()

    useAsyncEffect(async () => {
        try {
            await setTeamleaderFocusAdminPermissions({
                variables: {
                    code,
                    redirectUrl: window.location.origin + pathname,
                },
            })
        } catch (e) {
            trackError(e)
        } finally {
            navigate('/settings/external-applications')
        }
    }, [])
    return <AppLoading />
}

export default TeamleaderAdminRedirectPage
