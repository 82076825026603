import React, { useState } from 'react'
import { Trans } from '@lingui/react'
import { useApolloClient } from '@apollo/client'
import startActivity from 'api/startActivity'
import useNotifications from 'components/hooks/useNotifications'
import StartActivityModal from 'components/pages/activities-create/activity-start/StartActivityModal'
import useStartActivityForm from 'components/pages/activities-create/activity-start/useStartActivityForm'
import usePermissions from 'components/hooks/usePermissions'
import ModalUnauthorized from 'components/layout/ModalUnauthorized'
import useTracking from 'components/hooks/useTracking'
import { getUtcDateTime, formatTime } from 'util/dates'

const date = new Date()

const StartActivityModalWithState = ({
    project = null,
    onClose,
    setTimer,
    setSeconds,
    setIsActive,
    ...props
}) => {
    const { canCreateActivities } = usePermissions()
    const { trackError } = useTracking()
    const { dispatchError, dispatchSuccess } = useNotifications()
    const apolloClient = useApolloClient()
    const formState = useStartActivityForm({
        project,
    })
    const [isSubmitting, setIsSubmitting] = useState(false)
    if (canCreateActivities.value !== true) {
        return <ModalUnauthorized />
    }

    return (
        <StartActivityModal
            formState={formState}
            onSubmit={async () => {
                const isValid = formState.validate()
                if (isValid) {
                    setIsSubmitting(true)
                    try {
                        const NOW = formatTime(Date.now())
                        const input = {
                            startDateTime: getUtcDateTime(date, NOW),
                            ...formState.valuesToInput(),
                        }
                        await startActivity(apolloClient, {
                            input: { ...input },
                        }).then((data) => {
                            const { timer } = data
                            setTimer(timer)
                            setIsActive(true)
                            setSeconds(0)
                        })
                        await dispatchSuccess({
                            content: (
                                <Trans id="You successfully start this activity!" />
                            ),
                        })
                        await onClose()
                    } catch (e) {
                        trackError(e)
                        dispatchError({
                            title: <Trans id="Something went wrong!" />,
                            content: e.message,
                        })
                        setIsSubmitting(false)
                    }
                }
            }}
            onClose={onClose}
            isSubmitting={isSubmitting}
            {...props}
        />
    )
}

export default StartActivityModalWithState
