import { useRef, useCallback, useEffect } from 'react'

export default function useDebouncedCallback(callback, delay, deps) {
    const functionTimeoutHandler = useRef(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedFunction = useCallback(callback, deps)

    useEffect(
        () => () => {
            clearTimeout(functionTimeoutHandler.current)
        },
        [],
    )

    return (...args) => {
        clearTimeout(functionTimeoutHandler.current)
        functionTimeoutHandler.current = setTimeout(() => {
            debouncedFunction(...args)
        }, delay)
    }
}
