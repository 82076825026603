import React, { useState } from 'react'
import { Trans } from '@lingui/react'
import useNotifications from 'components/hooks/useNotifications'
import useTracking from 'components/hooks/useTracking'
import DeleteTimerModal from './DeleteTimerModal'

const DeleteTimerModalWithState = ({
    customField,
    onClose,
    onSubmit,
    ...props
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { trackError } = useTracking()
    const { dispatchError } = useNotifications()
    return (
        <DeleteTimerModal
            {...props}
            isSubmitting={isSubmitting}
            onClose={onClose}
            onSubmit={async () => {
                setIsSubmitting(true)
                try {
                    await onSubmit(customField)
                } catch (e) {
                    trackError(e)
                    dispatchError({
                        title: <Trans id="Something went wrong!" />,
                        content: e.message,
                    })
                } finally {
                    setIsSubmitting(false)
                    onClose()
                }
            }}
        />
    )
}

export default DeleteTimerModalWithState
