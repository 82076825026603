import React, { useMemo } from 'react'
import { Trans } from '@lingui/react'
import styled from 'styled-components'
import CircledIcon from '../util/CircledIcon'
import Tooltip from '../util/Tooltip'
import Icon from '../util/Icon'
import TimerStopDropDownButton from './TimerStopDropDownButton'

const TimerCounter = ({ time, isActive }) => {
    const formatTime = useMemo(() => {
        const hours = Math.floor(time / 3600)
        const minutes = Math.floor((time % 3600) / 60)
        const seconds = time % 60

        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
            .toString()
            .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
        return formattedTime
    }, [time])

    // Update title with timer value
    if (isActive) {
        document.title = `Lexor - ${formatTime}`
    } else {
        document.title = `Lexor`
    }
    return (
        <div
            className={`${
                isActive ? 'text-primary-400' : 'text-gray-400'
            } text-lg w-14`}
        >
            {formatTime}
        </div>
    )
}

const Timer = ({
    isDayChanged,
    seconds,
    isActive,
    timer,
    pauseTimer,
    createActivity,
    startActivity,
    cancelTimer,
    isActionLoading,
}) => {
    const displayRunning = () =>
        isActionLoading ? (
            <Icon name="pauseTimer" className="text-gray-400" />
        ) : (
            <Icon
                name="pauseTimer"
                accentColor="primary"
                className="cursor-pointer text-primary-400"
                onClick={() => pauseTimer()}
            />
        )

    const displayPause = () =>
        timer?.status === 'expired-duration-exceeded' ||
        isActionLoading ||
        seconds >= 28800 ||
        isDayChanged ? (
            <Icon name="starTimer" className="text-gray-400" />
        ) : (
            <Icon
                name="starTimer"
                className="cursor-pointer text-primary-400"
                onClick={() => startActivity()}
            />
        )

    const handleTimerAction = () => (
        <div className="flex justify-between items-center pt-3">
            {seconds < 28800 && !isDayChanged && timer?.status === 'running' ? (
                <Tooltip position="bottom" label="pause timer">
                    {displayRunning()}
                </Tooltip>
            ) : (
                <Tooltip position="bottom" label="start timer">
                    {displayPause()}
                </Tooltip>
            )}
            <Tooltip position="bottom" label="stop timer">
                <div>
                    <TimerStopDropDownButton
                        disabled={false}
                        onCreateActivity={() => createActivity()}
                        onCancelTimer={() => cancelTimer()}
                    />
                </div>
            </Tooltip>
        </div>
    )

    if (!timer) return null
    return (
        <div className="flex justify-center items-center border-2 border-[#E56A59] rounded-3xl ml-3 pr-2">
            <div className="p-1 mr-1">
                <Icon
                    name="stopwatch"
                    accentColor="primary"
                    className=" cursor-pointer"
                />
            </div>
            {handleTimerAction()}
            <TimerCounter time={seconds} isActive={isActive} />
            {(isDayChanged || seconds >= 28800) && (
                <div className="ml-2">
                    <Tooltip
                        position="bottom"
                        label={
                            isDayChanged ? (
                                <Trans id="Timer is automatically stopped at midnight" />
                            ) : (
                                <Trans id="Timer is automatically stopped after 8 hours" />
                            )
                        }
                    >
                        <InfoIcon isSmallest name="info" />
                    </Tooltip>
                </div>
            )}
            <div className="w-[2px] h-4 bg-[#E56A59] ml-2 mr-4" />
            <Tooltip position="bottom" label={timer?.description || ''}>
                <div className="w-40 truncate">
                    <span>{timer?.description || ''}</span>
                </div>
            </Tooltip>
        </div>
    )
}

const InfoIcon = styled(CircledIcon)(
    () => `
    background-color: #a0aec0;
`,
)

export default Timer
