import React, { forwardRef } from 'react'
import { withTheme } from 'styled-components'
import IconLarge from 'components/util/IconLarge'
import IconMedium from 'components/util/IconMedium'
import IconSmall from 'components/util/IconSmall'

export const getIconSize = ({
    isSmallest,
    isSmaller,
    isSmall,
    isLarge,
    isLarger,
    isLargest,
    theme,
}) => {
    if (isSmallest) {
        return theme.iconSizeSmallest
    }
    if (isSmaller) {
        return theme.iconSizeSmaller
    }
    if (isSmall) {
        return theme.iconSizeSmall
    }
    if (isLarge) {
        return theme.iconSizeLarge
    }
    if (isLarger) {
        return theme.iconSizeLarger
    }
    if (isLargest) {
        return theme.iconSizeLargest
    }
    return theme.iconSize
}

const Icon = forwardRef(
    (
        {
            theme,
            isSmall,
            isSmallest,
            isSmaller,
            isLarge,
            isLarger,
            isLargest,
            ...props
        },
        ref,
    ) => {
        const finalSize = getIconSize({
            isSmallest,
            isSmaller,
            isSmall,
            isLarge,
            isLarger,
            isLargest,
            theme,
        })
        const renderSmall = isSmaller || isSmallest
        const renderLarge = isLarge || isLarger || isLargest
        if (renderSmall) {
            return <IconSmall size={finalSize} ref={ref} {...props} />
        }
        if (renderLarge) {
            return <IconLarge size={finalSize} ref={ref} {...props} />
        }
        return <IconMedium size={finalSize} ref={ref} {...props} />
    },
)

export default withTheme(Icon)
