import { useNavigate } from 'react-router-dom'
import useAppState from 'components/hooks/useAppState'
import useModalState from 'components/hooks/useModalState'
import TenantSelectionModalWithState from 'components/pages/tenant-selection/TenantSelectionModalWithState'
import React from 'react'
import TopBarUserDropdown from './TopBarUserDropdown'

const TopBarUserDropdownWithState = () => {
    const navigate = useNavigate()
    const tenantSelectionModal = useModalState()
    const { logout } = useAppState()

    return (
        <>
            <TopBarUserDropdown
                onClickProfile={() => navigate('/profile')}
                onSwitchTenant={() => tenantSelectionModal.open()}
                onSupport={() => navigate('/support')}
                onLogout={logout}
            />
            {tenantSelectionModal.isOpen && (
                <TenantSelectionModalWithState
                    onClose={() => tenantSelectionModal.close()}
                />
            )}
        </>
    )
}

export default TopBarUserDropdownWithState
