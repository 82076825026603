import gql from 'graphql-tag'

const updateAccountStateCompletionMutation = gql`
    mutation updateAccountStateCompletion(
        $input: AccountStateCompletionInput!
    ) {
        updateAccountStateCompletion(input: $input) {
            hasInvitedUsers
            hasLinkedO365
            hasUpdatedProfile
        }
    }
`

export default async function updateAccountStateCompletion(
    apolloClient,
    input,
    options,
) {
    const mutation = updateAccountStateCompletionMutation
    const variables = { input }
    const response = await apolloClient.mutate({
        mutation,
        variables,
        ...options,
    })
    return response.data.updateAccountStateCompletion
}
