import React, { useEffect, useState } from 'react'
import gql from 'graphql-tag'
import { useLingui } from '@lingui/react'
import SearchSelect from 'components/util/SearchSelect'
import { useCacheAndNetworkQuery } from 'components/hooks/useApolloQueries'
import styled from 'styled-components'
import { capitalizeFirstLetter, sortBy } from 'util/index'
import { ACTIVITIY_TYPE_OTHER } from 'util/constants'
import ActivityTypeIcon from './ActivityTypeIcon'

export const ACTIVITY_TYPES_QUERY = gql`
    query activityTypes {
        activityTypes {
            id
            slug
            translation
        }
    }
`

const ActivityTypeInputSearch = ({ defaultValue = false, ...props }) => {
    const { loading, data = {} } = useCacheAndNetworkQuery(ACTIVITY_TYPES_QUERY)
    const [types, setTypes] = useState([])
    const { i18n } = useLingui()
    useEffect(() => {
        if (!loading) {
            const sortedTypes = [...data.activityTypes].sort(
                sortBy('translation'),
            )
            setTypes(sortedTypes)
            if (defaultValue && !props.value) {
                const value = sortedTypes.find(
                    ({ slug }) => slug === ACTIVITIY_TYPE_OTHER,
                )
                props.onChange(value)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading])

    return (
        <SearchSelect
            defaultOptions={types}
            placeholder={i18n._('Select type')}
            isLoading={loading}
            formatOptionLabel={({ slug, translation }) => (
                <>
                    <StyledIcon name={slug} />
                    {capitalizeFirstLetter(translation)}
                </>
            )}
            isFixed
            {...props}
        />
    )
}

const StyledIcon = styled(ActivityTypeIcon)(
    ({ theme }) => `
    margin-right: ${theme.spacingSmaller}rem;
`,
)

export default ActivityTypeInputSearch
