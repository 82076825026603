import React, { useState } from 'react'
import { Trans } from '@lingui/react'
import Icon from 'components/util/Icon'
import Modal from './Modal'
import ModalTopBar from './ModalTopBar'

const ModalUnauthorized = ({
    message = <Trans id="You're not allowed to open this window" />,
    children,
}) => {
    const [isOpen, setIsOpen] = useState(true)
    return (
        <Modal
            data-testid="UnauthorizedModal"
            ariaLabel="unauthorized"
            isOpen={isOpen}
        >
            <ModalTopBar
                title={<Trans id="Unauthorized" />}
                onClose={() => setIsOpen(false)}
            />
            <div className="flex flex-col justify-center items-center p-5">
                <Icon
                    isLarger
                    name="ban"
                    accentColor="primary"
                    className="mb-2"
                />
                <p>{message}</p>
                {children}
            </div>
        </Modal>
    )
}

export default ModalUnauthorized
