import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import linkO365Account from 'api/linkO365Account'
import * as routes from 'routes/index'
import { parseQueryString } from 'util/index'
import PageError from 'components/layout/PageError'
import AppLoading from 'components/AppLoading'
import useAsyncEffect from 'components/hooks/useAsyncEffect'
import { ACCOUNT_STATE_INVITED, ACCOUNT_STATE_ONBOARDING } from 'util/constants'
import useTracking from 'components/hooks/useTracking'
import useAppState from 'components/hooks/useAppState'

const {
    REACT_APP_MICROSOFT_OFFICE_REDIRECT_URI: MICROSOFT_OFFICE_REDIRECT_URI,
} = process.env

const AuthorizeMicrosoftPage = ({ accountState }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const { refresh, setModalAddO365AccountState } = useAppState()
    const apolloClient = useApolloClient()
    const { trackError } = useTracking()
    const { search } = location
    const [errorMessage, setErrorMessage] = useState(null)

    useAsyncEffect(async () => {
        try {
            const {
                code,
                error,
                state : o365PermissionType ,
                error_description: errorDescription,
            } = parseQueryString(search)
            if (typeof error !== 'undefined') {
                throw new Error(errorDescription)
            }
            if (accountState === ACCOUNT_STATE_INVITED) {
                const response = await linkO365Account(
                    apolloClient,
                    code,
                    `${MICROSOFT_OFFICE_REDIRECT_URI}/user-onboarding`,
                    `${o365PermissionType === 'drives' ? 'DRIVES' : 'MAILS_AND_CALENDARS'}`,
                )
                if (o365PermissionType !== 'drives' && response === 'success') {
                    navigate(routes.userOnboarding(3), { replace: true, state: { o365LinkStatus: 'MAILS_LINK_SUCESS' } }) 
                } else if (o365PermissionType !== 'drives' && response !== 'success') {
                    navigate(routes.userOnboarding(3), { replace: true, state: { o365LinkStatus: 'MAILS_LINK_FAILED' } }) 
                } else if (o365PermissionType === 'drives' && response === 'success') {
                    navigate(routes.userOnboarding(4), { replace: true, state: { o365LinkStatus: 'DRIVES_LINK_SUCESS' }})
                } else if (o365PermissionType === 'drives' && response !== 'success') {
                    navigate(routes.userOnboarding(4), { replace: true, state: { o365LinkStatus: 'DRIVES_LINK_FAILED' }})
                }   
            } else if (accountState === ACCOUNT_STATE_ONBOARDING) {
                const response = await linkO365Account(
                    apolloClient,
                    code,
                    `${MICROSOFT_OFFICE_REDIRECT_URI}/admin-onboarding`,
                    `${o365PermissionType === 'drives' ? 'DRIVES' : 'MAILS_AND_CALENDARS'}`,
                )
                if (o365PermissionType !== 'drives' && response === 'success') {
                    navigate(routes.adminOnboarding(3), { replace: true, state: { o365LinkStatus: 'MAILS_LINK_SUCESS' } }) 
                } else if (o365PermissionType !== 'drives' && response !== 'success') {
                    navigate(routes.adminOnboarding(3), { replace: true, state: { o365LinkStatus: 'MAILS_LINK_FAILED' } }) 
                } else if (o365PermissionType === 'drives' && response === 'success') {
                    navigate(routes.adminOnboarding(4), { replace: true, state: { o365LinkStatus: 'DRIVES_LINK_SUCESS' }})
                } else if (o365PermissionType === 'drives' && response !== 'success') {
                    navigate(routes.adminOnboarding(4), { replace: true, state: { o365LinkStatus: 'DRIVES_LINK_FAILED' }})
                }   
                
            } else {
                const response = await linkO365Account(
                    apolloClient,
                    code,
                    MICROSOFT_OFFICE_REDIRECT_URI,
                    `${o365PermissionType === 'drives' ? 'DRIVES' : 'MAILS_AND_CALENDARS'}`,
                )
                if (o365PermissionType !== 'drives' && response === 'success') {
                    refresh()
                    navigate(routes.profile(),  { replace: true})
                    setModalAddO365AccountState(true, 'MAILS_LINK_SUCESS', 1)
                } else if (o365PermissionType !== 'drives' && response !== 'success') {
                    setModalAddO365AccountState(true, 'MAILS_LINK_FAILED', 1)
                } else if (o365PermissionType === 'drives' && response === 'success') {
                    refresh()
                    navigate(routes.profile(),  { replace: true })
                    setModalAddO365AccountState(true, 'DRIVES_LINK_SUCESS', 2)
                } else if (o365PermissionType === 'drives' && response !== 'success') {
                    setModalAddO365AccountState(true, 'DRIVES_LINK_FAILED', 2)
                }
            }
        } catch (e) {
            trackError(e)
            navigate(routes.home(), { replace: true })
            setErrorMessage(e.message)
        }
    }, [search])

    if (errorMessage !== null) {
        return <PageError error={errorMessage} />
    }
    return <AppLoading />
}

export default AuthorizeMicrosoftPage
