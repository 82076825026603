import React, { createContext, useContext, useState, useMemo } from 'react'

const ActivitiesUpdaterContext = createContext()

export const ActivitiesUpdaterProvider = ({ children }) => {
    const [activitiesQueryUpdater, setActivitiesQueryUpdater] = useState(null)

    // Memoize the context value
    const contextValue = useMemo(
        () => ({
            activitiesQueryUpdater,
            setActivitiesQueryUpdater,
        }),
        [activitiesQueryUpdater],
    )

    return (
        <ActivitiesUpdaterContext.Provider value={contextValue}>
            {children}
        </ActivitiesUpdaterContext.Provider>
    )
}

export const useActivitiesUpdaterContext = () => {
    const context = useContext(ActivitiesUpdaterContext)
    if (!context) {
        throw new Error(
            'useActivitiesUpdaterContext must be used within an AppProvider',
        )
    }
    return context
}
