import React from 'react'
import styled from 'styled-components'

const Title = ({ children, ...props }) => (
    <StyledTitle {...props}>{children}</StyledTitle>
)

const StyledTitle = styled.h3(
    ({ theme }) => `
    color: ${theme.defaultFontColorDark};
    font-weight: ${theme.fontWeightBold};
    font-size: ${theme.fontSizeLarger}rem;
`,
)

export default Title
