import React from 'react'
import { Trans } from '@lingui/react'
import ModalTopBar from 'components/layout/ModalTopBar'
import Modal, { ModalMainContainer } from 'components/layout/Modal'
import ModalConfirmButtonBar from 'components/layout/ModalConfirmButtonBar'

const ModalExpiredToken = ({ logout, isOpen }) => (
    <Modal
        data-testid="UnauthorizedModal"
        ariaLabel="unauthorized"
        isOpen={isOpen}
    >
        <ModalTopBar title={<Trans id="Login required" />} />
        <ModalMainContainer>
            <Trans id="Please login again to keep working with Lexor" />
        </ModalMainContainer>
        <ModalConfirmButtonBar
            withSecondaryButton={false}
            onSubmit={() => logout()}
            submitText={<Trans id="Login again" />}
        />
    </Modal>
)

export default ModalExpiredToken
