import React, { useState } from 'react'
import gql from 'graphql-tag'
import { Trans, useLingui } from '@lingui/react'
import { useQuery } from '@apollo/client'
import { whereId } from '../../util/index'
import useSearch from '../hooks/useSearch'
import useAppState from '../hooks/useAppState'
import usePermissions from '../hooks/usePermissions'
import usePopoverState from '../hooks/usePopoverState'
import useAccessibleDropdown from '../hooks/useAccessibleDropdown'
import useSearchOptionsPreferences from '../hooks/useSearchOptionsPreferences'
import MultiSelect from './MultiSelect'

export const QUERY = gql`
    query projectsQuery(
        $pageSize: Int!
        $currentPage: Int!
        $search: ProjectSearchInput
        $where: NewProjectWhereInput
        $orderBy: NewProjectOrderInput
    ) {
        projectsPaginated(
            where: $where
            search: $search
            orderBy: $orderBy
            pageSize: $pageSize
            currentPage: $currentPage
        ) {
            nodes {
                id
                number
                title
                externalApplicationIds
                allowedActivityCategoryIds
                allowedCostCategoryIds
                children {
                    id
                    child {
                        id
                        number
                        title
                        externalApplicationIds
                        timesheetEntryDurationInMinutesLast30Days
                        timesheetEntryDurationInMinutesOfLoggedInPartnerLast30Days
                        timesheetDescriptionLengthLimit
                        costDescriptionLengthLimit
                        invoicingLanguage {
                            id
                            slug
                            translation
                        }
                    }
                }
                parents {
                    id
                    parent {
                        id
                        number
                        title
                        externalApplicationIds
                        timesheetEntryDurationInMinutesLast30Days
                        timesheetEntryDurationInMinutesOfLoggedInPartnerLast30Days
                        timesheetDescriptionLengthLimit
                        costDescriptionLengthLimit
                        invoicingLanguage {
                            id
                            slug
                            translation
                        }
                    }
                }
                invoicingLanguage {
                    id
                    slug
                    translation
                }
                timesheetEntryDurationInMinutesLast30Days
                timesheetEntryDurationInMinutesOfLoggedInPartnerLast30Days
                timesheetDescriptionLengthLimit
                costDescriptionLengthLimit
            }
        }
    }
`

const ProjectSearchMultiSelect = ({
    open,
    id = 'projects',
    name = 'projects',
    autoFocus = false,
    value = [],
    onChange,
    defaultOptions = [],
    hasChildren = null,
    includeNoProject = false,
    includeActivityRegistration = null,
}) => {
    const { i18n } = useLingui()
    const { currentUser } = useAppState()
    const [search, setSearch] = useSearch('')
    const [inputValue, setInputValue] = useState('')
    const {
        preferences: { involvedProjectsOnly },
        handleUpdatePreferences,
    } = useSearchOptionsPreferences()
    const { canReadUninvolvedProjects } = usePermissions()
    const { openPopover, closePopover } = usePopoverState(id, open)

    const hasSearch = search !== ''

    const variables = {
        where: {
            statusSlugs: ['active'],
            involvedPartnerIds: involvedProjectsOnly ? [currentUser.id] : [],
            hasChildren: hasChildren === null ? null : hasChildren,
            allowActivityRegistration:
                includeActivityRegistration === null
                    ? null
                    : includeActivityRegistration,
        },
        currentPage: 1,
        pageSize: hasSearch ? 50 : 10,
        orderBy: {
            mostRecentActivityOfLoggedInPartner: 'desc',
        },
        ...(search && {
            search: {
                numberContains: search,
                titleContains: search,
            },
        }),
    }

    const { data = {}, loading } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
    })

    let projects = data.projectsPaginated?.nodes || []
    if (includeNoProject) {
        projects = [
            {
                id: -1,
                title: i18n._(/* i18n */ 'No project'),
                number: i18n._(/* i18n */ 'No project'),
            },
            ...projects,
        ]
    }
    if (defaultOptions.length > 0 && !hasSearch) {
        const ids = defaultOptions.map((p) => p.id)
        projects = projects.filter((p) => !ids.includes(p.id))
    }
    if (value.length > 0) {
        projects = projects.filter((p) => !value.find(whereId(p.id)))
    }

    const options = [...defaultOptions, ...projects].map((option, index) => ({
        ...option,
        index,
    }))

    const handleClear = () => {
        setSearch('')
        setInputValue('')
        onChange([])
    }

    const handleChangeInput = (e) => {
        setSearch(e.target.value)
        setInputValue(e.target.value)
    }

    const handleChange = (project) => {
        const index = value.findIndex(whereId(project.id))
        let updatedValues = value
        if (index === -1) {
            updatedValues = [...value, project]
        } else {
            updatedValues.splice(index, 1)
        }
        setInputValue('')
        onChange(updatedValues)
    }

    const { setIsDropdownOpen, activeIndex, select, setIsFocus, listRef } =
        useAccessibleDropdown({
            options,
            isOpen: open,
            onChange: handleChange,
        })

    return (
        <MultiSelect
            ref={listRef}
            id={id}
            content={
                <>
                    <MultiSelect.CheckboxOption
                        disabled={!canReadUninvolvedProjects.value}
                        checked={!involvedProjectsOnly}
                        onChange={() =>
                            handleUpdatePreferences({
                                involvedProjectsOnly: !involvedProjectsOnly,
                            })
                        }
                        label={<Trans id="Include uninvolved projects" />}
                    />
                    {(() => {
                        if (loading) {
                            return (
                                <MultiSelect.Placeholder>
                                    <Trans id="Loading..." />
                                </MultiSelect.Placeholder>
                            )
                        }
                        if (projects.length === 0) {
                            return (
                                <MultiSelect.Placeholder>
                                    <Trans id="No results" />
                                </MultiSelect.Placeholder>
                            )
                        }
                        return (
                            <>
                                {hasSearch ? (
                                    <>
                                        {defaultOptions.length > 0 ? (
                                            <>
                                                <span className="p-3 w-full uppercase text-xs text-gray-500">
                                                    <Trans id="Suggestions" />
                                                </span>
                                                {defaultOptions.map(
                                                    (project, index) => (
                                                        <MultiSelect.Option
                                                            key={project.id}
                                                            label={`${project.number} ${project.title}`}
                                                            isActive={
                                                                defaultOptions.length +
                                                                    index ===
                                                                activeIndex
                                                            }
                                                            onClick={() =>
                                                                select(project)
                                                            }
                                                        />
                                                    ),
                                                )}
                                            </>
                                        ) : null}
                                        <span className="p-3 w-full uppercase text-xs text-gray-500">
                                            <Trans id="Recently used" />
                                        </span>
                                    </>
                                ) : null}
                                {projects.map((project, index) => {
                                    const label =
                                        project.id === -1 ? (
                                            <Trans id="No project" />
                                        ) : (
                                            `${project.number} ${project.title}`
                                        )
                                    return (
                                        <MultiSelect.Option
                                            key={project.id}
                                            label={label}
                                            isActive={
                                                defaultOptions.length +
                                                    index ===
                                                activeIndex
                                            }
                                            onClick={() => select(project)}
                                        />
                                    )
                                })}
                            </>
                        )
                    })()}
                </>
            }
        >
            <>
                {value.map((project) => {
                    const label =
                        project.id === -1 ? (
                            <Trans id="No project" />
                        ) : (
                            `${project.number} ${project.title}`
                        )
                    return (
                        <MultiSelect.Value
                            key={project.id}
                            label={label}
                            onRemove={() => handleChange(project)}
                        />
                    )
                })}
                <MultiSelect.SearchInput
                    autoComplete="off"
                    id={id}
                    name={name}
                    value={value}
                    inputValue={inputValue}
                    onClear={handleClear}
                    onChange={handleChangeInput}
                    placeholder={i18n._(/* i18n */ 'Search for project')}
                    autoFocus={autoFocus}
                    onFocus={(e) => {
                        e.target.select()
                        setIsFocus(true)
                        setIsDropdownOpen(true)
                        openPopover()
                    }}
                    onBlur={(e) => {
                        setIsFocus(false)
                        setIsDropdownOpen(false)
                        if (e.relatedTarget) {
                            closePopover()
                        }
                    }}
                />
            </>
        </MultiSelect>
    )
}

export default ProjectSearchMultiSelect
