import React, { useEffect, useState, useContext } from 'react'
import { Trans } from '@lingui/react'
import {
    TYPE_ERROR,
    TYPE_SUCCESS,
    TYPE_WARNING,
    TYPE_MESSAGE,
} from 'util/constants'
import { NotificationsContext } from 'components/providers/NotificationsProvider'

const useNotifications = () => {
    const notificationDispatcher = useContext(NotificationsContext)
    const [notifications, setNotifications] = useState([])
    useEffect(
        () =>
            notificationDispatcher.subscribe((newNotifications) => {
                setNotifications(newNotifications)
            }),
        [notificationDispatcher],
    )

    const dispatchSuccess = (data, isPersistent) =>
        notificationDispatcher.dispatch(
            {
                title: <Trans id="Success" />,
                ...data,
                type: TYPE_SUCCESS,
            },
            isPersistent,
        )

    const dispatchError = (data, isPersistent) =>
        notificationDispatcher.dispatch(
            {
                title: <Trans id="Error" />,
                ...data,
                type: TYPE_ERROR,
            },
            isPersistent,
        )

    const dispatchWarning = (data, isPersistent) =>
        notificationDispatcher.dispatch(
            {
                ...data,
                type: TYPE_WARNING,
            },
            isPersistent,
        )

    const dispatchMessage = (data, isPersistent) =>
        notificationDispatcher.dispatch(
            {
                ...data,
                type: TYPE_MESSAGE,
            },
            isPersistent,
        )

    return {
        notifications,
        dispatchError,
        dispatchWarning,
        dispatchSuccess,
        dispatchMessage,
        dispatch: (data) => notificationDispatcher.dispatch(data),
        onCloseNotification: (notification) =>
            notificationDispatcher.removeNotification(notification),
    }
}

export default useNotifications
