import gql from 'graphql-tag'
import dotProp from 'dot-prop-immutable'
import {
    replaceInCollection,
    addToCollection,
    removeFromCollection,
    alterInCollection,
} from 'util/index'

export const connectionToCollection = (connection = { edges: [] }) =>
    connection.edges.map((edge) => edge.node)

export const nestedConnectionToCollection = (key) => (entity) => ({
    ...entity,
    [key]: connectionToCollection(entity[key]),
})

export const mergeQueryResults = (previousResult, fetchMoreResult, path) => {
    const { edges: newEdges, totalCount, pageInfo } = fetchMoreResult[path]

    return newEdges.length
        ? {
              [path]: {
                  // eslint-disable-next-line no-underscore-dangle
                  __typename: previousResult[path].__typename,
                  edges: [...previousResult[path].edges, ...newEdges],
                  pageInfo,
                  totalCount,
              },
          }
        : previousResult
}

export const graphqlErrorsToFormErrors = (errors) =>
    errors.reduce(
        (invalidFields, error) => ({
            ...invalidFields,
            ...error.extensions.exception.invalidFields,
        }),
        {},
    )

export const isUnauthenticatedError = (error) =>
    error.extensions.code === 'UNAUTHENTICATED'

export const PAGE_INFO_FRAGMENT = gql`
    fragment PageInfo on PageInfo {
        hasNextPage
        startCursor
        endCursor
    }
`

export const createQueryUpdater =
    (client, query, variables) => (path, value) => {
        const data = client.readQuery({
            query,
            variables,
        })
        const mutatedData = dotProp.set(data, path, value)
        client.writeQuery({
            query,
            variables,
            data: mutatedData,
        })
    }

export const createReplaceQueryUpdater =
    (client, query, variables) => (path, updatedObject) =>
        createQueryUpdater(
            client,
            query,
            variables,
        )(path, (cachedObjects) =>
            replaceInCollection(updatedObject)(cachedObjects),
        )
export const createAlterQueryUpdater =
    (client, query, variables) => (path, updatedObject) =>
        createQueryUpdater(
            client,
            query,
            variables,
        )(path, (cachedObjects) =>
            alterInCollection(updatedObject)(cachedObjects),
        )
export const createAddQueryUpdater =
    (client, query, variables) => (path, newObject) =>
        createQueryUpdater(
            client,
            query,
            variables,
        )(path, (cachedObjects) => addToCollection(newObject)(cachedObjects))
export const createRemoveQueryUpdater =
    (client, query, variables) => (path, removedObjectId) =>
        createQueryUpdater(
            client,
            query,
            variables,
        )(path, (cachedObjects) =>
            removeFromCollection(removedObjectId)(cachedObjects),
        )
