import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const START_IMPERSONATION_SESSION = gql`
    mutation startImpersonationSession($description: String!, $key: String!) {
        startImpersonationSession(description: $description, key: $key) {
            impersonationSession {
                id
                description
            }
        }
    }
`

export default function useStartImpersonationSessionMutation() {
    return useMutation(START_IMPERSONATION_SESSION)
}
