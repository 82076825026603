import React, { useEffect, useRef } from 'react'
import { Trans } from '@lingui/react'
import styled from 'styled-components'
import PrimaryButton from 'components/util/PrimaryButton'
import SecondaryButton from 'components/util/SecondaryButton'

const ModalConfirmButtonBar = ({
    cancelButtonTestId,
    saveButtonTestId,
    onCancel,
    onSubmit,
    disabled,
    isSubmitting,
    submitIcon = 'check',
    submitText = <Trans id="Save" />,
    cancelText = <Trans id="Cancel" />,
    submittingText = <Trans id="Saving" />,
    withSecondaryButton = true,
}) => {
    const submitButton = useRef(null)

    useEffect(() => {
        submitButton.current.focus()
    }, [])
    return (
        <Container>
            {withSecondaryButton && (
                <SecondaryButton
                    data-testid={cancelButtonTestId}
                    onClick={onCancel}
                    disabled={isSubmitting}
                >
                    {cancelText}
                </SecondaryButton>
            )}
            <SubmitButton
                ref={submitButton}
                data-testid={saveButtonTestId}
                iconName={submitIcon}
                onClick={onSubmit}
                isLoading={isSubmitting}
                disabled={disabled || isSubmitting}
            >
                {isSubmitting ? submittingText : submitText}
            </SubmitButton>
        </Container>
    )
}

export const Container = styled.div(
    ({ theme }) => `
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    padding: ${theme.spacingSmaller}rem ${theme.spacing}rem;
    border-top-width: ${theme.borderWidth}rem;
    border-top-color: ${theme.colorGreyLighter};
`,
)

const SubmitButton = styled(PrimaryButton)(
    ({ theme }) => `
    margin-left: ${theme.spacingSmall}rem;
`,
)

export default ModalConfirmButtonBar
