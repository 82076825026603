import React, { useEffect, useRef, useState } from 'react'
import { Manager, Reference, Popper } from 'react-popper'
import styled, { css } from 'styled-components'
import useOnClickOutside from 'components/hooks/useOnClickOutside'
import { combineRefs } from 'util/index'

const ARROW_SIZE = 1
const POSITION_TOP = 'top'
const POSITION_BOTTOM = 'bottom'
const POSITION_BOTTOM_START = 'bottom-start'
const POSITION_LEFT = 'left'
const POSITION_RIGHT = 'right'

const Popover = ({
    id,
    defaultOpen = false,
    hasArrow = false,
    content,
    position = POSITION_BOTTOM,
    hasDarkBackground,
    children,
    disabled,
}) => {
    const [isOpen, setOpen] = useState(defaultOpen)
    const [isClickedOrHovered, setClickedOrHovered] = useState(false)
    const clickOutsideRef = useRef(null)
    useOnClickOutside(clickOutsideRef, () => setOpen(false))

    const mouseEnterHandler = () => setClickedOrHovered(true)
    const mouseClickHandler = (e) => {
        e.stopPropagation()
        setClickedOrHovered(!isClickedOrHovered)
    }
    const mouseLeaveHandler = () => setClickedOrHovered(false)

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    useEffect(() => {
        if (disabled) {
            handleClose()
        }
    }, [disabled])

    useEffect(() => {
        const timer = isClickedOrHovered
            ? setTimeout(handleOpen, 0)
            : setTimeout(handleClose, 500)
        return () => {
            clearTimeout(timer)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isClickedOrHovered])

    return (
        <Manager tag={false}>
            <Reference>
                {({ ref }) => {
                    const referenceProps = {
                        id,
                        ref,
                        onClick: mouseClickHandler,
                        onMouseLeave: mouseLeaveHandler,
                        'aria-haspopup': true,
                        'aria-expanded': isOpen,
                    }
                    if (typeof children === 'function') {
                        return children({
                            referenceProps,
                            isOpen,
                            handleOpen,
                            handleClose,
                        })
                    }
                    return React.cloneElement(children, referenceProps)
                }}
            </Reference>
            {isOpen && (
                <Popper
                    placement={position}
                    positionFixed
                    modifiers={[
                        {
                            name: 'preventOverflow',
                            options: {
                                rootBoundary: 'viewport',
                            },
                        },
                    ]}
                >
                    {({ ref: popperRef, style, placement, arrowProps }) => (
                        <PopoverContent
                            ref={combineRefs([clickOutsideRef, popperRef])}
                            style={style}
                            data-placement={placement}
                            aria-labelledby={id}
                            hasArrow={hasArrow}
                            hasDarkBackground={hasDarkBackground}
                            onMouseEnter={mouseEnterHandler}
                            onMouseLeave={mouseLeaveHandler}
                        >
                            {hasArrow && (
                                <ArrowContainer {...arrowProps}>
                                    <Arrow
                                        viewBox="0 0 12 6"
                                        hasDarkBackground={hasDarkBackground}
                                    >
                                        <path d="M6 0l6 6H0z" />
                                    </Arrow>
                                </ArrowContainer>
                            )}
                            {typeof content === 'function'
                                ? content({
                                      handleOpen,
                                      handleClose,
                                  })
                                : content}
                        </PopoverContent>
                    )}
                </Popper>
            )}
        </Manager>
    )
}

const getBackground = ({ theme, hasDarkBackground }) =>
    hasDarkBackground ? theme.colorBlack : theme.colorWhite

const PopoverContent = styled.div`
    background-color: ${getBackground};
    position: relative;
    z-index: ${({ theme }) => theme.defaultPopoverZIndex};
    border-radius: ${({ theme }) => theme.defaultBorderRadius}rem;
    box-shadow: ${({ theme }) => theme.boxShadowLarge};
    ${({ hasArrow }) =>
        hasArrow &&
        css`
            &[data-placement^='top'] {
                margin-bottom: ${ARROW_SIZE}rem;
            }

            &[data-placement^='bottom'] {
                margin-top: ${ARROW_SIZE}rem;
            }

            &[data-placement^='left'] {
                margin-right: ${ARROW_SIZE}rem;
            }

            &[data-placement^='right'] {
                margin-left: ${ARROW_SIZE}rem;
            }
        `}
`

const ArrowContainer = styled.div`
    position: absolute;
    display: block;
    width: ${ARROW_SIZE * 2}rem;
    height: ${ARROW_SIZE}rem;
    top: -${ARROW_SIZE}rem;
    ${PopoverContent}[data-placement^="top"] & {
        bottom: 0;
    }

    ${PopoverContent}[data-placement^="left"]  & {
        right: -1.5rem;
    }

    ${PopoverContent}[data-placement^="right"] & {
        left: -1.5rem;
    }
`

const Arrow = styled.svg`
    display: block;
    width: ${ARROW_SIZE * 2}rem;
    height: ${ARROW_SIZE}rem;
    fill: ${getBackground};
    ${PopoverContent}[data-placement^="top"] & {
        bottom: 0;
        transform: rotate(180deg);
    }

    ${PopoverContent}[data-placement^="left"]  & {
        transform: rotate(90deg);
        right: -1.5rem;
    }

    ${PopoverContent}[data-placement^="right"] & {
        left: -1.5rem;
        transform: rotate(-90deg);
    }
`

Popover.POSITION_TOP = POSITION_TOP
Popover.POSITION_BOTTOM = POSITION_BOTTOM
Popover.POSITION_BOTTOM_START = POSITION_BOTTOM_START
Popover.POSITION_LEFT = POSITION_LEFT
Popover.POSITION_RIGHT = POSITION_RIGHT

export default Popover
