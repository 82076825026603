import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const UPDATE_APP_SETTINGS_MUTATION = gql`
    mutation updateAppPreferences($input: String!) {
        updateAppSettings(input: $input)
    }
`

export const useUpdateAppPreferencesMutation = () =>
    useMutation(UPDATE_APP_SETTINGS_MUTATION)

export default async function updateAppPreferences(
    apolloClient,
    input,
    options,
) {
    const mutation = UPDATE_APP_SETTINGS_MUTATION
    const variables = { input }
    const response = await apolloClient.mutate({
        mutation,
        variables,
        ...options,
    })
    return response.data.updateAppSettings
}
