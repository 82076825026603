import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const TERMINATE_IMPERSONATION_RIGHTS_MUTATION = gql`
    mutation terminateImpersonationRights($keys: [String]!) {
        terminateImpersonationRights(keys: $keys)
    }
`

export default function useTerminateImpersonationRightsMutation() {
    return useMutation(TERMINATE_IMPERSONATION_RIGHTS_MUTATION)
}
