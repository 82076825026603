import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const SET_TEAMLEADER_FOCUS_USER_MUTATION = gql`
    mutation setTeamleaderFocusPermissions(
        $code: String!
        $redirectUrl: String!
    ) {
        setTeamleaderFocusPermissions(code: $code, redirectUrl: $redirectUrl)
    }
`

export default function useSetTeamleaderFocusUserPermissions() {
    return useMutation(SET_TEAMLEADER_FOCUS_USER_MUTATION)
}
