import gql from 'graphql-tag'

export const PERMISSION_FRAGMENT = gql`
    fragment Permission on Permission {
        isHidden
        reasonSlug
        value
    }
`

export const APPLICATION_PERMISSIONS_FRAGMENT = gql`
    fragment ApplicationPermissions on ApplicationPermissions {
        canCreateTimer {
            ...Permission
        }
        canCreateActivities {
            ...Permission
        }
        canCreateCosts {
            ...Permission
        }
        canCreateProjects {
            ...Permission
        }
        canCreatePartners {
            ...Permission
        }
        canCreateTimesheetEntryLockedPeriods {
            ...Permission
        }
        canDeleteTimer {
            ...Permission
        }
        canDeleteActivities {
            ...Permission
        }
        canDeleteActivityBlacklist {
            ...Permission
        }
        canDeleteBilling {
            ...Permission
        }
        canDeleteCosts {
            ...Permission
        }
        canDeleteProjects {
            ...Permission
        }
        canDeletePartners {
            ...Permission
        }
        canFilterActivitiesByRelevancy {
            ...Permission
        }
        canReadActivitiesMarkup {
            ...Permission
        }
        canReadTimer {
            ...Permission
        }
        canReadActivities {
            ...Permission
        }
        canReadActivityStatistics {
            ...Permission
        }
        canReadActivityBlacklist {
            ...Permission
        }
        canReadCosts {
            ...Permission
        }
        canReadDailyTimesheetEntryPredictions {
            ...Permission
        }
        canReadProjects {
            ...Permission
        }
        canReadPartners {
            ...Permission
        }
        canReadSettingsTenantSettings {
            ...Permission
        }
        canReadSettingsSubscription {
            ...Permission
        }
        canReadSettingsCoworkers {
            ...Permission
        }
        canReadSettingsTemplates {
            ...Permission
        }
        canReadSettingsProjectFields {
            ...Permission
        }
        canReadSettingsProjectPartnerLinkFields {
            ...Permission
        }
        canReadSettingsProjectNumbering {
            ...Permission
        }
        canReadSettingsProjectTags {
            ...Permission
        }
        canReadSettingsPartnerFields {
            ...Permission
        }
        canReadSettingsPartnerLinkFields {
            ...Permission
        }
        canReadSettingsProfessions {
            ...Permission
        }
        canReadSettingsPartnerLinkRoles {
            ...Permission
        }
        canReadSettingsPartnerInfoTypes {
            ...Permission
        }
        canReadSettingsPartnerTags {
            ...Permission
        }
        canReadSettingsActivityCategories {
            ...Permission
        }
        canReadSettingsCostCategories {
            ...Permission
        }
        canReadSettingsO365 {
            ...Permission
        }
        canReadSettingsExternalApplication {
            ...Permission
        }
        canReadSettingsApiSubscription {
            ...Permission
        }
        canReadTimesheetEntries {
            ...Permission
        }
        canReadTimesheetEntriesDescriptionProposals {
            ...Permission
        }
        canReadTimesheetEntryLockedPeriods {
            ...Permission
        }
        canReadUninvolvedProjects {
            ...Permission
        }
        canUpdateTimer {
            ...Permission
        }
        canUpdateActivityBlacklist {
            ...Permission
        }
        canUpdateSettingsTenantSettings {
            ...Permission
        }
        canUpdateSettingsSubscription {
            ...Permission
        }
        canUpdateSettingsCoworkers {
            ...Permission
        }
        canUpdateSettingsProjectNumbering {
            ...Permission
        }
        canUpdateSettingsProjectTags {
            ...Permission
        }
        canUpdateSettingsProfessions {
            ...Permission
        }
        canUpdateSettingsPartnerLinkRoles {
            ...Permission
        }
        canUpdateSettingsPartnerInfoTypes {
            ...Permission
        }
        canUpdateSettingsProjectPartnerLinkRoles {
            ...Permission
        }
        canUpdateSettingsPartnerTags {
            ...Permission
        }
        canUpdateSettingsActivityCategories {
            ...Permission
        }
        canUpdateSettingsCostCategories {
            ...Permission
        }
        canUpdateSettingsO365 {
            ...Permission
        }
        canExportActivities {
            ...Permission
        }
        canExportCosts {
            ...Permission
        }
        canFilterActivities {
            ...Permission
        }
        canFilterCosts {
            ...Permission
        }
        canFilterProjects {
            ...Permission
        }
        canFilterPartners {
            ...Permission
        }
        canFilterTimesheetEntriesByPartner {
            ...Permission
        }
        canFilterActivitiesByPartner {
            ...Permission
        }
        canFilterCostsByPartner {
            ...Permission
        }
    }
    ${PERMISSION_FRAGMENT}
`

export const INITIAL_DATA_QUERY = gql`
    query initialDataQuery {
        me {
            id
            partner {
                id
                fullName
                initials
                firstName
                name
                hasO365Link
                appSettings
                receiveAnalyticsMail
                emails {
                    email
                }
                preferredCulture {
                    id
                    code
                    translation
                }
                primaryEmail {
                    id
                    email
                }
                permissionRole {
                    id
                    slug
                    translation
                }
                preferences {
                    allowTimesheetDescriptionProposals
                    receiveAnalyticsMail
                    mLCategoryProposalBasedOnProject
                    activitiesMarkupDuration
                    activitiesMarkupIsActive
                    activitiesMarkupType
                }
                addresses {
                    id
                    address
                    addressData
                    country {
                        id
                        code
                        slug
                        translation
                    }
                    type {
                        id
                        slug
                        translation
                    }
                    isPrimary
                }
            }
            loginEmail
            accountState
            accountStateDetails
            accountStateCompletion {
                hasInvitedUsers
                azureAdLoginOk
                hasLinkedO365
                hasUpdatedProfile
                o365LinkExpirationStatus
                o365DrivesLinkExpirationStatus
                o365DrivesPermissionsGranted
                o365MailsAndCalendarsLinkExpirationStatus
                o365MailsAndCalendarsPermissionsGranted
                o365LinkStatuses {
                    o365UserId
                    o365LinkExpirationStatus
                    o365DrivesLinkExpirationStatus
                    o365DrivesPermissionsGranted
                    o365MailsAndCalendarsLinkExpirationStatus
                    o365MailsAndCalendarsPermissionsGranted
                    o365PermissionsGranted
                    o365User {
                        id
                        email
                        isPrimary
                    }
                }
                o365PermissionsGranted
                requiredAddressOk
            }
            applicationPermissions {
                ...ApplicationPermissions
            }
            availableTenants {
                guid
                name
            }
            availableImpersonationRights {
                id
                key
                puppetLoginEmail
                expirationDateTime
                tenant {
                    guid
                    name
                }
            }
        }
        currentTimesheetEntryLockedPeriod {
            id
            endTimestamp
        }
        currentTenant {
            id
            name
        }
        currentTenantGuid
        tenantSettings {
            id
            dateFormat
            timeFormat
            currency {
                id
                code
                slug
                translation
            }
            currentPlan {
                id
                product {
                    id
                    slug
                }
            }
            currentSubscription {
                id
                trialEnd
                quantity
                occupiedUserSeats
            }
            coworkerCount
            timesheetEntriesProjectRequired
        }
        interfaceCultures {
            id
            slug
            translation
        }
        invoicingCultures {
            id
            slug
            translation
        }
        applicationSecrets {
            activeDirectoryClientId
            azureMapsKey
            stripePublishableKey
            o365ClientId
            o365DrivesClientId
            o365MailsAndCalendarsClientId
            o365AdminClientId
            o365AdminCallRecordsClientId
        }
    }
    ${APPLICATION_PERMISSIONS_FRAGMENT}
`

export default async function getInitialData(apolloClient, options) {
    const query = INITIAL_DATA_QUERY
    const response = await apolloClient.query({
        query,
        ...options,
    })
    return response.data
}
