import React from 'react'
import { Trans } from '@lingui/react'
import styled from 'styled-components'
import Icon from 'components/util/Icon'
import Title from 'components/util/Title'
import PageWithState from 'components/layout/PageWithState'
import PrimaryButton from 'components/util/PrimaryButton'
import { useNavigate } from 'react-router-dom'
import usePermissions from 'components/hooks/usePermissions'

const SubscriptionSuspendedPage = ({ ...props }) => {
    const { isAdmin } = usePermissions()
    const navigate = useNavigate()
    return (
        <PageWithState {...props}>
            <ContentContainer>
                <StyledIcon isLarger name="warning" accentColor="primary" />
                <Title>
                    <Trans id="Oops, no access!" />
                </Title>
                <ErrorMessage>
                    <Trans id="Your subscription is suspended." />
                    <br />
                    {isAdmin ? (
                        <Trans id="Please renew your subscription." />
                    ) : (
                        <Trans id="Please contact your office admin to renew the subscription." />
                    )}
                </ErrorMessage>
                {isAdmin && (
                    <PrimaryButton
                        onClick={() => navigate('/settings/subscription')}
                    >
                        <Trans id="Go to the subscription page" />
                    </PrimaryButton>
                )}
            </ContentContainer>
        </PageWithState>
    )
}

const ContentContainer = styled.div(
    ({ theme }) => `
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: auto;
    padding: ${theme.spacingLarger}rem;
    background: ${theme.colorGreyWhite};
`,
)

const StyledIcon = styled(Icon)`
    margin-bottom: ${({ theme }) => theme.spacingSmaller}rem;
`

const ErrorMessage = styled.p`
    text-align: center;
`

export default SubscriptionSuspendedPage
