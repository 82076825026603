import React from 'react'
import styled from 'styled-components'
import usePermissions from 'components/hooks/usePermissions'
import PermissionWrapper from 'components/util/PermissionWrapper'
import TopBarSearchWithState from 'components/layout/TopBarSearchWithState'
import TimerWithState from 'components/layout/TimerWithState'
import Logo from 'components/util/Logo'
import { useNavigate } from 'react-router-dom'
import TopBarUserDropdownWithState from './TopBarUserDropdownWithState'

export const TOPBAR_HEIGHT = 5

const TopBar = ({ currentUser, currentTenant, canReadTimer }) => {
    const navigate = useNavigate()

    return (
        <Container>
            <LogoContainer onClick={() => navigate('/')}>
                <Logo />
            </LogoContainer>
            <SearchContainer>
                <TopBarSearchWithState />
            </SearchContainer>
            <PermissionWrapper position="bottom" permission={canReadTimer}>
                <TimerWithState />
            </PermissionWrapper>
            <NavBar>
                <NavBarList>
                    <NavBarListItem>
                        <User>
                            <Name>{currentUser?.fullName}</Name>
                            {currentTenant?.name}
                        </User>
                        <TopBarUserDropdownWithState />
                    </NavBarListItem>
                </NavBarList>
            </NavBar>
        </Container>
    )
}

const User = styled.div(
    ({ theme }) => `
    color: ${theme.colorGreyDark};
    font-weight: ${theme.fontWeightMedium};
    font-size: ${theme.fontSizeSmall}rem;
    line-height: 1.4rem;
    text-align: right;
    padding-right: ${theme.spacingSmaller}rem;
`,
)

const Name = styled.div(
    ({ theme }) => `
    color: ${theme.colorGreyDarker};
    font-weight: ${theme.fontWeightBold};   
`,
)

const LogoContainer = styled.div(
    ({ theme }) => `
    display: flex;
    align-items: center;
    padding-right: ${theme.spacing}rem;
    cursor: pointer;
`,
)

const Container = styled.header(
    ({ theme }) => `
    position: fixed;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    padding-left: ${theme.spacing}rem;
    padding-right: ${theme.spacingSmall}rem;
    height: ${TOPBAR_HEIGHT}rem;
    z-index: 2;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
    background: ${theme.colorWhite};
`,
)

const SearchContainer = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`

const NavBar = styled.nav`
    flex: auto;
    height: 100%;
    align-items: center;
`

const NavBarList = styled.ul`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

const NavBarListItem = styled.li(
    ({ theme }) => `
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: ${theme.spacingSmallest}rem;
`,
)

export default TopBar
