/* eslint-disable react/no-unused-state */
import React from 'react'
import ReactGA from 'react-ga'
import { hotjar } from 'react-hotjar'
import getInitialData from 'api/getInitialData'
import getPublicApplicationSecrets from 'api/getPublicApplicationSecrets'
import * as storage from 'util/storage'
import loadScript from 'util/loadScript'
import { getNotificationMessage } from 'util/entities'
import { TYPE_WARNING, NOTIFICATION_TYPE_PERSISTENT } from 'util/constants'
import { createApplicationInsights } from 'services/applicationInsights'
import { loadAndValidateTokens } from 'util/auth'
import getNotifications from 'api/getNotifications'
import getInitialAuthenticatedData from 'api/getInitialAuthenticatedData'
import AppStateProvider from './providers/AppStateProvider'
import LanguageProvider from './providers/LanguageProvider'
import App from './App'

const {
    REACT_APP_APPINSIGHTS_KEY,
    REACT_APP_HOTJAR_SITE_ID,
    REACT_APP_HOTJAR_VERSION,
} = process.env

const INITIAL_STATE = {
    isLoading: true,
    isAuthorized: false,
    isAuthenticated: false,
    isImpersonating: false,
    currentUser: null,
    currentTenant: null,
    tenantSettings: null,
    accountState: '',
    accountStateDetails: '',
    accountStateCompletion: {},
    applicationPermissions: {},
    applicationSecrets: {},
    notifications: [],
    availableTenants: [],
    availableImpersonationRights: [],
    preferredCultureCode: LanguageProvider.EN,
    interfaceCultures: null,
    invoicingCultures: null,
    timesheetLockPeriodDate: null,
    modalConfirmExpireToken: { modalState: false, daysLeft: 0 },
    modalAddO365Account: { modalState: false, o365LinkStatusRes: 'initial', step: 1 },
    o365linkStateModal: {modalState: false, o365LinkExpirationStatus : null},
    azureAdLoginModalState: {modalState: false},
    bannerToDisplay: [],
}

export default class AppWithState extends React.Component {
    constructor(props) {
        super(props)

        this.login = this.login.bind(this)
        this.logout = this.logout.bind(this)
        this.setTenant = this.setTenant.bind(this)
        this.setIsBannerDisplyed = this.setIsBannerDisplyed.bind(this)
        this.setImpersonationKey = this.setImpersonationKey.bind(this)
        this.fetchInitialData = this.fetchInitialData.bind(this)
        this.refresh = this.refresh.bind(this)
        this.initializeServices = this.initializeServices.bind(this)
        this.handleWithinOneWeekBeforeExpiration =
            this.handleWithinOneWeekBeforeExpiration.bind(this)
        this.closemodalConfirmExpireToken =
            this.closemodalConfirmExpireToken.bind(this)
        this.setModalAddO365AccountState =
            this.setModalAddO365AccountState.bind(this)
        this.setO365linkModalState =
            this.setO365linkModalState.bind(this)
        this.setAzureAdLoginModalState = 
            this.setAzureAdLoginModalState.bind(this)

        this.state = {
            ...INITIAL_STATE,
            login: this.login,
            logout: this.logout,
            refresh: this.refresh,
            setTenant: this.setTenant,
            setIsBannerDisplyed: this.setIsBannerDisplyed,
            setImpersonationKey: this.setImpersonationKey,
            closemodalConfirmExpireToken: this.closemodalConfirmExpireToken,
            setModalAddO365AccountState:this.setModalAddO365AccountState,
            setO365linkModalState:this.setO365linkModalState,
            setAzureAdLoginModalState:this.setAzureAdLoginModalState,
        }
    }

    async componentDidMount() {
        const { apolloClient } = this.props
        try {
            const { isAuthenticated, isAuthorized } = await this.loadTokens()
            await apolloClient.stop()
            await apolloClient.resetStore()
            await this.fetchInitialData({ isAuthenticated, isAuthorized })
        } catch (e) {
            this.logout()
        }
        this.initializeServices()
    }

    // eslint-disable-next-line class-methods-use-this
    async handleWithinOneWeekBeforeExpiration() {
        const slidinfWindowTokenExp = await storage.getRefreshTokenWindow()
        const userHasIgnoretokenWarning =
            await storage.getUserHasIgnoretokenWarning()
        const expirTokenDate = new Date(slidinfWindowTokenExp)
        const currentDate = new Date() // Get the current date
        const oneWeekFromNow = new Date(
            currentDate.getTime() + 7 * 24 * 60 * 60 * 1000,
        ) // Date one week from now

        if (oneWeekFromNow >= expirTokenDate && !userHasIgnoretokenWarning) {
            const timeDifference =
                expirTokenDate.getTime() - currentDate.getTime()

            // Convert time difference from milliseconds to days
            const daysDifference = Math.ceil(
                timeDifference / (1000 * 3600 * 24),
            )

            this.setState((prevState) => ({
                ...prevState,
                modalConfirmExpireToken: {
                    modalState: true,
                    daysLeft: daysDifference > 0 ? daysDifference : 0,
                },
            }))
        }
    }

    // eslint-disable-next-line class-methods-use-this
    async setTenant(tenantGUID) {
        await Promise.all([
            storage.removeImpersonationKey(),
            storage.setTentantGUID(tenantGUID),
        ])
        await this.setState((prevState) => ({
            ...prevState,
            bannerToDisplay: [],
        }))
        await this.refresh()
    }

    async setImpersonationKey(impersonationKey, tenantGUID) {
        await Promise.all([
            storage.setImpersonationKey(impersonationKey),
            storage.setTentantGUID(tenantGUID),
        ])
        await this.refresh()
    }

    // eslint-disable-next-line class-methods-use-this
    async setIsBannerDisplyed(bannerContent) {
        this.setState((prevState) => ({
            ...prevState,
            bannerToDisplay:bannerContent
        }))
    } 

    async setModalAddO365AccountState(modalState, o365LinkStatusRes, step) {
        this.setState((prevState) => ({
            ...prevState,
            modalAddO365Account: { modalState, o365LinkStatusRes, step },
        }))
    } 

    async setO365linkModalState(modalState, o365LinkExpirationStatus) {
        this.setState((prevState) => ({
            ...prevState,
            o365linkStateModal: { modalState, o365LinkExpirationStatus},
        }))
    } 

    async setAzureAdLoginModalState(modalState) {
        this.setState((prevState) => ({
            ...prevState,
            azureAdLoginModalState: { modalState},
        }))
    }  

    async closemodalConfirmExpireToken() {
        await storage.setUserHasIgnoretokenWarning(true)
        this.setState((prevState) => ({
            ...prevState,
            modalConfirmExpireToken: { modalState: false, daysLeft: 0 },
        }))
    }

    async fetchInitialData({
        isAuthenticated = false,
        isAuthorized = false,
    } = {}) {
        if (isAuthorized) {
            await this.fetchAuthorizedInitialData()
            this.showNotifications()
        } else if (isAuthenticated) {
            await this.fetchAuthenticatedInitialData()
        } else {
            await this.fetchUnauthenticatedInitialData()
        }
    }

    async showNotifications() {
        const { apolloClient, notificationDispatcher } = this.props
        await this.fetchNotifications(apolloClient)
        const { notifications } = this.state
        if (notifications.length > 0) {
            notifications.forEach((notification) => {
                const isPersistent =
                    notification.type === NOTIFICATION_TYPE_PERSISTENT
                notificationDispatcher.dispatch(
                    {
                        title: 'Warning',
                        content: getNotificationMessage(notification.slug),
                        type: TYPE_WARNING,
                    },
                    isPersistent,
                )
            })
        }
    }

    async clearNotifications() {
        const { notificationDispatcher } = this.props
        notificationDispatcher.removeAllNotifications()
    }

    async initFreshChat() {
        await loadScript('https://wchat.freshchat.com/js/widget.js')
        this.freshChat = window.fcWidget
        this.freshChat.init({
            token: '558fa7cc-49a9-483b-b957-bd8c99427a8e',
            host: 'https://wchat.freshchat.com',
            siteId: 'LEXOR_APP',
            config: {
                showFAQOnOpen: false,
                hideFAQ: false,
                headerProperty: {
                    hideChatButton: true,
                },
            },
        })
    }

    async initializeServices() {
        // google analytics
        ReactGA.initialize('UA-154563244-1')
        // history.listen((window) => {
        ReactGA.pageview(window.location.pathname)
        // })

        // freshChat) {
        this.initFreshChat()

        // hotjar
        hotjar.initialize(REACT_APP_HOTJAR_SITE_ID, REACT_APP_HOTJAR_VERSION)

        // applicationInsights
        if (process.env.NODE_ENV !== 'test') {
            createApplicationInsights(REACT_APP_APPINSIGHTS_KEY)
        }
    }

    async loadTokens() {
        const { accessToken } = await loadAndValidateTokens()
        const tenantGUID = await storage.getTenantGUID()
        const impersonationKey = await storage.getImpersonationKey()
        const isAuthenticated = accessToken !== null
        const isAuthorized = accessToken !== null && tenantGUID !== null
        const isImpersonating = impersonationKey !== null
        this.setState((prevState) => ({
            ...prevState,
            isLoading: true,
            isAuthorized,
            isAuthenticated,
            isImpersonating,
        }))
        if (isAuthenticated) await this.handleWithinOneWeekBeforeExpiration()
        return { isAuthenticated, isAuthorized }
    }

    async fetchAuthorizedInitialData() {
        const { apolloClient } = this.props
        const {
            me: {
                partner,
                loginEmail,
                accountState,
                accountStateDetails,
                accountStateCompletion,
                applicationPermissions,
                availableTenants,
                availableImpersonationRights,
            },
            currentTenant,
            currentTenantGuid,
            tenantSettings,
            interfaceCultures,
            invoicingCultures,
            applicationSecrets,
            currentTimesheetEntryLockedPeriod,
        } = await getInitialData(apolloClient)

        let preferredCultureCode = LanguageProvider.EN
        if (partner !== null) {
            preferredCultureCode = partner.preferredCulture.code
        }

        // parse the addressData field as soon as it comes from the database.
        // start of the block
        const { addresses } = partner
        const newAddresses = []
        addresses.map((address) =>
            newAddresses.push({
                ...address,
                addressData: JSON.parse(address.addressData),
            }),
        )
        const newPartner = {
            ...partner,
            addresses: newAddresses,
        }
        // end of the block

        this.setState({
            isAuthenticated: true,
            currentUser: { ...newPartner, loginEmail },
            currentTenant: { ...currentTenant, guid: currentTenantGuid },
            tenantSettings,
            accountState,
            accountStateDetails,
            accountStateCompletion,
            applicationPermissions,
            isLoading: false,
            preferredCultureCode,
            interfaceCultures,
            invoicingCultures,
            applicationSecrets,
            availableTenants,
            availableImpersonationRights,
            timesheetLockPeriodDate: currentTimesheetEntryLockedPeriod
                ? new Date(currentTimesheetEntryLockedPeriod.endTimestamp)
                : null,
        })
    }

    async fetchAuthenticatedInitialData() {
        const { apolloClient } = this.props
        const { me, applicationSecrets } =
            await getInitialAuthenticatedData(apolloClient)
        this.setState((prevState) => ({
            ...prevState,
            accountState: me.accountState,
            applicationSecrets,
            isLoading: false,
        }))
    }

    async fetchUnauthenticatedInitialData() {
        const { apolloClient } = this.props
        const { applicationSecrets } =
            await getPublicApplicationSecrets(apolloClient)
        this.setState((prevState) => ({
            ...prevState,
            applicationSecrets,
            isLoading: false,
        }))
    }

    async fetchNotifications() {
        const { apolloClient } = this.props
        const { notifications } = await getNotifications(apolloClient)
        this.setState((prevState) => ({
            ...prevState,
            notifications,
        }))
    }

    async login(
        accessToken,
        accessTokenExpiration,
        refreshToken,
        refreshTokenExpiration,
        refreshTokenSlidingWindowExpiration,
        me,
    ) {
        await storage.setAccessToken(accessToken, accessTokenExpiration)
        await storage.setRefreshToken(refreshToken, refreshTokenExpiration)
        await storage.setRefreshTokenWindow(refreshTokenSlidingWindowExpiration)
        await storage.setUserHasIgnoretokenWarning(false)
        const { accountState, availableTenants, availableImpersonationRights } =
            me
        if (availableTenants.length === 1) {
            this.setTenant(availableTenants[0].guid)
        }
        this.setState((prevState) => ({
            ...prevState,
            accountState,
            availableTenants,
            availableImpersonationRights,
            isAuthenticated: true,
        }))
    }

    async logout() {
        await Promise.all([
            storage.removeAccessToken(),
            storage.removeRefreshToken(),
            storage.removeTenantGUID(),
            storage.removeImpersonationKey(),
            storage.removeAuthorizationScheme(),
            storage.removeUserHasIgnoretokenWarning(),
            storage.removeMS365openModal(),
        ])
        if (typeof this.freshChat !== 'undefined' && this.freshChat !== null) {
            this.freshChat.destroy()
        }
        this.clearNotifications()
        this.setState(
            (prevState) => ({
                ...prevState,
                currentUser: null,
                currentTenant: null,
                tenantSettings: null,
                isAuthenticated: false,
                isAuthorized: false,
                isImpersonating: false,
                modalConfirmExpireToken: { modalState: false, daysLeft: 0 },
            }),
            this.refresh,
        )
    }

    async refresh() {
        const { apolloClient } = this.props
        this.setState((prevState) => ({
            ...prevState,
            currentUser: null,
            currentTenant: null,
            tenantSettings: null,
            isAuthenticated: false,
            isAuthorized: false,
        }))

        const { isAuthenticated, isAuthorized } = await this.loadTokens()
        await apolloClient.stop()
        await apolloClient.resetStore()
        await this.clearNotifications()
        await this.fetchInitialData({ isAuthenticated, isAuthorized })
    }

    render() {
        const { notificationDispatcher, apolloClient } = this.props
        const { bannerToDisplay } = this.state

        return (
            <AppStateProvider value={this.state}>
                <App
                    apolloClient={apolloClient}
                    notificationDispatcher={notificationDispatcher}
                />
            </AppStateProvider>
        )
    }
}
