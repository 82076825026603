import React, { forwardRef } from 'react'
import styled from 'styled-components'
import {
    TYPE_ERROR,
    TYPE_SUCCESS,
    TYPE_WARNING,
    TYPE_MESSAGE,
} from 'util/constants'
import Icon from 'components/util/Icon'

const getToasterBackgroundColor = (type, theme) => {
    if (type === TYPE_SUCCESS) {
        return theme.colorSuccess
    }
    if (type === TYPE_ERROR) {
        return theme.colorError
    }
    if (type === TYPE_WARNING) {
        return theme.colorWarning
    }
    if (type === TYPE_MESSAGE) {
        return theme.colorGrey
    }
    return theme.colorGrey
}

const ToasterItem = forwardRef(({ data, onCloseNotification }, ref) => {
    const { type, title, content } = data
    const titleIsSet = typeof title !== 'undefined'
    const contentIsSet = typeof content !== 'undefined'
    return (
        <StyledToasterItem ref={ref} type={type}>
            <ToasterContent>
                <CloseIcon
                    isSmaller
                    name="close"
                    onClick={onCloseNotification}
                />
                {titleIsSet && <Title>{title}</Title>}
                {contentIsSet && <Content>{content}</Content>}
            </ToasterContent>
        </StyledToasterItem>
    )
})

const ToasterContent = styled.div``

const Title = styled.h3`
    font-size: ${({ theme }) => theme.fontSizeLarge}rem;
    font-weight: ${({ theme }) => theme.fontWeightMedium};
`
const Content = styled.span``

const CloseIcon = styled(Icon)`
    position: absolute;
    top: ${({ theme }) => theme.spacingSmaller}rem;
    right: ${({ theme }) => theme.spacingSmaller}rem;
    color: ${({ theme }) => theme.colorGreyDark};
`

const StyledToasterItem = styled.div`
    position: relative;
    display: flex;
    width: 30rem;
    color: ${({ theme }) => theme.colorGreyDarkest};
    background-color: ${({ type, theme }) =>
        getToasterBackgroundColor(type, theme)};
    padding: ${({ theme }) => theme.spacingSmall}rem;
    box-shadow: ${({ theme }) => theme.boxShadow};
    margin-bottom: ${({ theme }) => theme.spacingSmall}rem;
    border-width: ${({ theme }) => theme.defaultBorderWidth}rem;
`

export default ToasterItem
