import gql from 'graphql-tag'

export const INITIAL_AUTHENTICATED_DATA_QUERY = gql`
    query initialAuthenticatedDataQuery {
        me {
            id
            accountState
            availableTenants {
                guid
                name
            }
        }
        applicationSecrets {
            activeDirectoryClientId
        }
    }
`

export default async function getInitialAuthenticatedData(
    apolloClient,
    options,
) {
    const query = INITIAL_AUTHENTICATED_DATA_QUERY
    const response = await apolloClient.query({
        query,
        ...options,
    })
    return response.data
}
