import React from 'react'
import styled from 'styled-components'
import useTableState from 'components/hooks/useTableState'
import TableSorting from 'components/util/TableSorting'

const HeaderCell = ({ name, isSortable = false, children, ...props }) => {
    const { onSort, activeSortName } = useTableState()
    const hasActiveSorting = isSortable && name === activeSortName
    return (
        <StyledTableHeaderCell
            isSortable={isSortable}
            hasActiveSorting={hasActiveSorting}
            onClick={() => isSortable && onSort(name)}
            {...props}
        >
            {(() => {
                if (isSortable) {
                    return (
                        <HeaderCellContent>
                            {children}
                            {isSortable && <TableSorting name={name} />}
                        </HeaderCellContent>
                    )
                }
                return children
            })()}
        </StyledTableHeaderCell>
    )
}

const StyledTableHeaderCell = styled.th(
    ({ theme, isSortable, hasActiveSorting, textAlign = 'left' }) => `
    text-align: ${textAlign};
    vertical-align: top;
    color: ${theme.colorGreyDark};
    font-weight: ${theme.fontWeightSemiBold};
    padding-top: ${theme.spacingSmallest}rem;
    padding-bottom: ${theme.spacingSmallest}rem;
    padding-right: ${theme.spacingSmallest}rem;
    padding-left: ${theme.spacingSmallest}rem;
    background-color: ${theme.colorWhite};
    ${
        isSortable &&
        `
        :hover {
            cursor: pointer;
        }
    `
    }
    ${
        hasActiveSorting &&
        `
        color: ${theme.defaultFontColorDark};
        font-weight: ${theme.fontWeightBold};
    `
    }
`,
)

const StyledHeaderCell = styled(HeaderCell)``

const StyledHeaderCellSmall = styled(HeaderCell)``

const CheckboxCell = styled(HeaderCell)``

const CheckboxCellSmall = styled(StyledHeaderCellSmall)``

const HeaderCellContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const Header = styled.thead``

Header.Cell = StyledHeaderCell
Header.CellSmall = StyledHeaderCellSmall
Header.CheckboxCell = CheckboxCell
Header.CheckboxCellSmall = CheckboxCellSmall

export default Header
