import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Trans } from '@lingui/react'
import { useApolloClient } from '@apollo/client'
import cancelTimer from 'api/cancelTimer'
import getActiveTimers from 'api/activeTimersData'
import startActivity from 'api/startActivity'
import pauseTimer from 'api/pauseTimer'
import PrimaryButton from 'components/util/PrimaryButton'
import Tooltip from 'components/util/Tooltip'
import { getPermissionsReasonMessage } from 'util/entities'
import useModalState from 'components/hooks/useModalState'
import useNotifications from 'components/hooks/useNotifications'
import useTracking from 'components/hooks/useTracking'
import { useActivitiesUpdaterContext } from 'components/providers/ActivitiesUpdaterProvider'
import CreateActivityModalWithState from 'components/pages/activities-create/CreateActivityModalWithState'
import StartActivityModalWithState from 'components/pages/activities-create/activity-start/StartActivityModalWithState'
import Timer from 'components/layout/Timer'
import {
    parseTime,
    differenceInMinutes,
    getUtcDateTime,
    formatTimeWithSecond,
    formatTime,
    calculateEndDate,
} from 'util/dates'
import DeleteTimerModalWithState from './DeleteTimerModalWithState'

const date = new Date()

const TimerWithState = ({ disabled }) => {
    const apolloClient = useApolloClient()
    const { trackError } = useTracking()
    const { dispatchError, dispatchSuccess } = useNotifications()
    const startActivityModalState = useModalState()
    const createActivityModalState = useModalState()
    const deleteTimerModalState = useModalState()
    const [timer, setTimer] = useState(null)
    const [isActionLoading, setIsActionLoading] = useState(false)
    const [seconds, setSeconds] = useState(0)
    const [isDayChanged, setIsDayChanged] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const { activitiesQueryUpdater } = useActivitiesUpdaterContext()

    useEffect(() => {
        if (!disabled) handlegetActiveTimer()
    }, [disabled])

    useEffect(() => {
        let interval

        if (isActive) {
            interval = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds + 1)
            }, 1000)
        }

        return () => clearInterval(interval)
    }, [isActive])

    useEffect(() => {
        if (timer && timer.status === 'running' && seconds >= 28800) {
            setIsActive(false)
        }
    }, [seconds])

    const calculeDurationInSecond = (
        status,
        earliestStartDateTime,
        latestStartDateTime,
        elapsedDurationInSeconds,
    ) => {
        const durationInSecond = { isDayChanged: false }
        const now = formatTimeWithSecond(Date.now())
        const earliestTimerStartDate = formatTimeWithSecond(
            new Date(earliestStartDateTime),
        )

        if (status === 'paused' || status === 'expired-duration-exceeded') {
            durationInSecond.timeDurationInSecond = elapsedDurationInSeconds
        }

        if (status === 'running') {
            const latestTimerStartDate = formatTimeWithSecond(
                new Date(latestStartDateTime),
            )
            const difFromStartToNow =
                differenceInMinutes(
                    parseTime(now),
                    parseTime(latestTimerStartDate),
                ) * 60

            if (elapsedDurationInSeconds === 0) {
                durationInSecond.timeDurationInSecond =
                    differenceInMinutes(
                        parseTime(now),
                        parseTime(earliestTimerStartDate),
                    ) * 60
            } else if (difFromStartToNow > 0) {
                durationInSecond.timeDurationInSecond =
                    difFromStartToNow + elapsedDurationInSeconds
                durationInSecond.isDayChanged = false
            } else {
                const earliesttimerStartDate = formatTimeWithSecond(
                    new Date(latestStartDateTime),
                )
                const difFromMidnightToNow =
                    differenceInMinutes(
                        parseTime(now),
                        parseTime(earliesttimerStartDate),
                    ) * 60
                durationInSecond.timeDurationInSecond =
                    difFromMidnightToNow + elapsedDurationInSeconds
                durationInSecond.isDayChanged = true
            }
        }

        return durationInSecond
    }

    const noDataText = () =>
        disabled ? (
            <Tooltip
                position="bottom"
                label={getPermissionsReasonMessage('subscriptionplan')}
            >
                <DisabledTimerBtn isSmall disabled iconName="starTimer">
                    <Trans id="Start timer" />
                </DisabledTimerBtn>
            </Tooltip>
        ) : (
            <SubmitButton
                isSmall
                iconName="starTimer"
                onClick={() => startActivityModalState.open()}
                // isLoading={isSubmitting}
                disabled={startActivityModalState.isOpen}
            >
                <Trans id="Start timer" />
            </SubmitButton>
        )

    const handlegetActiveTimer = async () => {
        const result = await getActiveTimers(apolloClient, {})
        const {
            activeTimersPaginated: { nodes },
        } = result

        if (nodes.length === 0) {
            setTimer(null)
            return
        }

        const timer = nodes[0]
        const {
            earliestStartDateTime,
            latestStartDateTime,
            elapsedDurationInSeconds,
            status,
        } = timer
        const durationInSecond = calculeDurationInSecond(
            status,
            earliestStartDateTime,
            latestStartDateTime,
            elapsedDurationInSeconds,
        )
        const { isDayChanged, timeDurationInSecond } = durationInSecond

        if (timeDurationInSecond >= 28800 && timer.status === 'running') {
            setSeconds(28800)
            setIsActive(false)
        } else {
            setSeconds(timeDurationInSecond)
            setIsActive(timer.status === 'running' && !isDayChanged)
            setIsDayChanged(timer.status === 'paused' ? false : isDayChanged)
        }
        setTimer(timer)
    }

    const pauseActivity = async () => {
        const now = formatTimeWithSecond(Date.now())
        const input = {
            timerId: timer?.id,
            dateTime: getUtcDateTime(date, now),
        }
        setIsActionLoading(true)
        try {
            await pauseTimer(apolloClient, { ...input }).then((data) => {
                const { timer } = data
                setTimer(timer)
                setIsActive(false)
            })
            dispatchSuccess({
                content: <Trans id="You successfully paused the Timer!" />,
            })
        } catch (e) {
            trackError(e)
            dispatchError({
                title: <Trans id="Something went wrong!" />,
                content: e.message,
            })
        }
        setIsActionLoading(false)
    }

    // const activity = {}
    const createActivity = () => {
        const {
            status,
            project,
            description,
            id,
            latestStartDateTime,
            earliestStartDateTime,
            elapsedDurationInSeconds,
        } = timer
        const now = formatTime(Date.now())
        const timerStartDate = formatTime(new Date(timer.earliestStartDateTime))
        let timerEndtDate = null
        if (status === 'paused' || status === 'expired-duration-exceeded') {
            const latestStart = calculateEndDate(
                elapsedDurationInSeconds,
                earliestStartDateTime,
            )
            timerEndtDate = formatTime(new Date(latestStart))
        } else {
            setIsActive(false)
            if (elapsedDurationInSeconds === 0) timerEndtDate = now

            if (elapsedDurationInSeconds !== 0) {
                const latestTimerStartDate = formatTimeWithSecond(
                    new Date(latestStartDateTime),
                )
                const difFromStartToNow =
                    differenceInMinutes(
                        parseTime(now),
                        parseTime(latestTimerStartDate),
                    ) * 60
                const newElapseDuration =
                    difFromStartToNow + elapsedDurationInSeconds
                const latestStart = calculateEndDate(
                    newElapseDuration,
                    earliestStartDateTime,
                )
                timerEndtDate = formatTime(new Date(latestStart))
            }
        }

        createActivityModalState.open({
            start: timerStartDate,
            end: isDayChanged ? '23:59' : timerEndtDate,
            project,
            description,
            type: {
                id: 11,
                slug: 'timer',
                translation: 'timer',
                __typename: 'ActivityType',
            },
            timerId: id,
        })
    }

    const startPausedActivity = async () => {
        const { project } = timer
        const now = formatTimeWithSecond(Date.now())
        const input = {
            startDateTime: getUtcDateTime(date, now),
            projectId: project ? project.id : null,
            description: timer.description,
        }
        setIsActionLoading(true)
        try {
            await startActivity(apolloClient, {
                timerId: timer.id,
                input: { ...input },
            }).then((data) => {
                const { timer } = data
                setTimer(timer)
                setIsActive(true)
            })
            dispatchSuccess({
                content: <Trans id="You successfully started the Timer!" />,
            })
        } catch (e) {
            trackError(e)
            dispatchError({
                title: <Trans id="Something went wrong!" />,
                content: e.message,
            })
        }
        setIsActionLoading(false)
    }

    const handlecancelTimer = () => {
        deleteTimerModalState.open({
            customField: timer,
        })
    }

    const handleCloseCreateActivityModal = () => {
        if (timer.status === 'running' && seconds < 28800 && !isDayChanged)
            setIsActive(true)
        createActivityModalState.close()
    }

    return (
        <>
            {!timer ? (
                noDataText()
            ) : (
                <Timer
                    isDayChanged={isDayChanged}
                    seconds={seconds}
                    timer={timer}
                    isActive={isActive}
                    pauseTimer={pauseActivity}
                    createActivity={createActivity}
                    startActivity={startPausedActivity}
                    cancelTimer={handlecancelTimer}
                    isActionLoading={isActionLoading}
                />
            )}
            {startActivityModalState.isOpen && (
                <StartActivityModalWithState
                    onClose={() => startActivityModalState.close()}
                    setTimer={setTimer}
                    setIsActive={setIsActive}
                    setSeconds={setSeconds}
                    {...startActivityModalState.initialState}
                />
            )}
            {createActivityModalState.isOpen && (
                <CreateActivityModalWithState
                    onClose={() => handleCloseCreateActivityModal()}
                    onComplete={async (path, data) => {
                        try {
                            await activitiesQueryUpdater.add(path, data)
                            await setTimer(null)
                            await setSeconds(0)
                            setIsDayChanged(false)
                        } catch (e) {
                            trackError(e)
                            dispatchError({
                                title: <Trans id="Something went wrong!" />,
                                content: e.message,
                            })
                        }
                    }}
                    {...createActivityModalState.initialState}
                />
            )}
            {deleteTimerModalState.isOpen && (
                <DeleteTimerModalWithState
                    onClose={() => deleteTimerModalState.close()}
                    onSubmit={async ({ id }) => {
                        try {
                            await cancelTimer(apolloClient, id)
                            await handlegetActiveTimer()
                            await setTimer(null)
                            await setSeconds(0)
                            await setIsDayChanged(false)
                        } catch (e) {
                            trackError(e)
                            dispatchError({
                                title: <Trans id="Something went wrong!" />,
                                content: e.message,
                            })
                        }
                    }}
                    {...deleteTimerModalState.initialState}
                />
            )}
        </>
    )
}

const SubmitButton = styled(PrimaryButton)(
    () => `
    border-top-left-radius:24px;
    border-bottom-left-radius:24px;
    border-top-right-radius:24px;
    border-bottom-right-radius:24px;
`,
)

const DisabledTimerBtn = styled(PrimaryButton)(
    () => `
    border-top-left-radius:24px;
    border-bottom-left-radius:24px;
    border-top-right-radius:24px;
    border-bottom-right-radius:24px;
    color:grey;
    border-color:grey;
    background-color:#FFFF;
    :disabled {
        cursor: not-allowed;
        border-color:grey;
        background-color:#FFFF;
    }
    :hover{
        ti
    }
`,
)

export default TimerWithState
