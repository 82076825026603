import {
    min,
    format,
    isDate,
    isValid,
    addDays as dateFnsAddDays,
    subDays as dateFnsSubDays,
    subWeeks,
    endOfDay,
    startOfDay,
    startOfMonth,
    endOfWeek,
    endOfMonth,
    subMonths,
    startOfWeek,
    isPast as dateFnsIsPast,
    isBefore as dateFnsIsBefore,
    isSameDay as dateFnsIsSameDay,
    addMinutes as dateFnsAddMinutes,
    compareAsc as dateFnsCompareAsc,
    differenceInMinutes as dateFnsDifferenceInMinutes,
    parseISO,
    startOfYear,
    subYears,
    endOfYear,
    startOfQuarter,
    subQuarters,
    endOfQuarter,
    isWeekend as dateFnsIsWeekend,
    parse,
    startOfToday,
    addWeeks,
} from 'date-fns'

export const DEFAULT_FORMAT_DATE_STRING = 'dd-MM-yyyy'
export const DEFAULT_FORMAT_TIME_STRING = 'HH:mm'
export const DEFAULT_FORMAT_TIME_STRING_SECONDS = 'HH:mm:ss'

// Date helpers
export const parseTime = (timeString) => {
    const dateTime = new Date()
    const [hours, minutes] = timeString.split(':')
    dateTime.setHours(Number(hours))
    dateTime.setMinutes(Number(minutes))
    dateTime.setSeconds(0)
    dateTime.setMilliseconds(0)
    return dateTime
}

export const timeStringToMinutes = (timeString) => {
    const [hours, minutes] = timeString.split(':')
    const total = parseInt(minutes, 10) + hours * 60
    return total
}

export const differenceInMinutes = (dateLeft, dateRight) =>
    dateFnsDifferenceInMinutes(dateLeft, dateRight)

export const minutesUntilMidnight = (date = new Date()) => {
    const midnight = new Date()
    midnight.setHours(24)
    midnight.setMinutes(0)
    midnight.setSeconds(0)
    midnight.setMilliseconds(0)
    return (midnight.getTime() - date.getTime()) / 1000 / 60
}

export const parseDate = (dateString) => parseISO(dateString)

// eslint-disable-next-line max-len
export const formatDate = (
    date,
    formatString = DEFAULT_FORMAT_DATE_STRING,
    props,
) => format(date, formatString, { ...props })

// eslint-disable-next-line max-len
export const formatTimeWithSecond = (
    date,
    formatString = DEFAULT_FORMAT_TIME_STRING_SECONDS,
    props,
) => format(date, formatString, { ...props })

// eslint-disable-next-line max-len
export const formatTime = (
    date,
    formatString = DEFAULT_FORMAT_TIME_STRING,
    props,
) => format(date, formatString, { ...props })

export const formatDateString = (
    isoString,
    formatString = DEFAULT_FORMAT_DATE_STRING,
) => formatDate(parseDate(isoString), formatString)

export const formatTimeString = (
    isoString,
    formatString = DEFAULT_FORMAT_TIME_STRING,
) => formatTime(parseDate(isoString), formatString)

export const getDayFromWeek = (date, props) =>
    format(date, 'EEEE', { ...props })

export const getUtcDateTime = (date, time = date) => {
    let dateString = date
    let timeString = time
    if (typeof date !== 'string') {
        dateString = formatDate(date, 'yyyy-MM-dd')
    }
    if (typeof time !== 'string') {
        timeString = formatTime(time)
    }
    const parsedDateTime = parseDate(`${dateString}T${timeString}`)
    const utcDateTime = parsedDateTime.toISOString()
    return utcDateTime
}

export const getDateString = (date) => {
    let dateString = date
    if (typeof date !== 'string') {
        dateString = formatDate(date, 'yyyy-MM-dd')
    } else {
        throw new Error('received string instead, expected date object')
    }
    return dateString
}

export const getStartOfDay = (date = new Date()) => startOfDay(date)

export const getStartOfWeek = (date = new Date(), weekStartsOn = 1) =>
    startOfWeek(date, { weekStartsOn })
export const getStartOfPreviousWeek = (date = new Date(), weekStartsOn = 1) =>
    startOfWeek(subWeeks(date, 1), { weekStartsOn })
export const getStartOfNextWeek = (date = new Date(), weekStartsOn = 1) =>
    startOfWeek(addWeeks(date, 1), { weekStartsOn })

export const getStartOfMonth = (date = new Date()) => startOfMonth(date)
export const getStartOfPreviousMonth = (date = new Date()) =>
    startOfMonth(subMonths(date, 1))

export const getStartOfQuarter = (date = new Date()) => startOfQuarter(date)
export const getStartOfPreviousQuarter = (date = new Date()) =>
    startOfQuarter(subQuarters(date, 1))

export const getStartOfYear = (date = new Date()) => startOfYear(date)
export const getStartOfPreviousYear = (date = new Date()) =>
    startOfYear(subYears(date, 1))

export const getEndOfDay = (date = new Date()) => endOfDay(date)

export const getEndOfWeek = (date = new Date(), weekStartsOn = 1) =>
    endOfWeek(date, { weekStartsOn })
export const getEndOfPreviousWeek = (date = new Date(), weekStartsOn = 1) =>
    endOfWeek(subWeeks(date, 1), { weekStartsOn })

export const getEndOfMonth = (date = new Date()) => endOfMonth(date)
export const getEndOfPreviousMonth = (date = new Date()) =>
    endOfMonth(subMonths(date, 1))

export const getEndOfQuarter = (date = new Date()) => endOfQuarter(date)
export const getEndOfPreviousQuarter = (date = new Date()) =>
    endOfQuarter(subQuarters(date, 1))

export const getEndOfYear = (date = new Date()) => endOfYear(date)
export const getEndOfPreviousYear = (date = new Date()) =>
    endOfYear(subYears(date, 1))

export const getMinimumDate = (dates) => min(dates)

export const addMinutes = (date, amount) => dateFnsAddMinutes(date, amount)
export const addDays = (date, amount) => dateFnsAddDays(date, amount)
export const subDays = (date, amount) => dateFnsSubDays(date, amount)
export const isPast = (date) => dateFnsIsPast(date)
export const isSameDay = (dateLeft, dateRight) =>
    dateFnsIsSameDay(dateLeft, dateRight)
export const isBefore = (dateLeft, dateRight) =>
    dateFnsIsBefore(dateLeft, dateRight)

export const compareAsc = (dateStringLeft, dateStringRight) =>
    dateFnsCompareAsc(parseDate(dateStringLeft), parseDate(dateStringRight))

export const isValidDate = (date) => isDate(date) && isValid(date)

export const formattedStringIsValidDate = (
    dateString,
    dateFormat = DEFAULT_FORMAT_DATE_STRING,
) => parse(dateString, dateFormat, new Date())

export const isValidTimeString = (timeString) => {
    const regexp = /^\d{0,2}?:?\d{0,2}$/
    const [hoursStr, minutesStr] = timeString.split(':')
    if (!regexp.test(timeString) || !timeString.length) {
        return false
    }
    const hours = Number(hoursStr)
    const minutes = Number(minutesStr)
    const isValidHour = (hour) =>
        Number.isInteger(hour) && hour >= 0 && hour < 24
    const isValidMinutes = (mins) =>
        (Number.isInteger(mins) && hours >= 0 && hours < 24) ||
        Number.isNaN(mins)
    if (!isValidHour(hours) || !isValidMinutes(minutes)) {
        return false
    }
    if (minutes < 10 && Number(minutesStr[0]) > 5) {
        return false
    }
    const valArr =
        timeString.indexOf(':') !== -1 ? timeString.split(':') : [timeString]
    // check mm and HH
    if (
        valArr[0] &&
        valArr[0].length &&
        (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 23)
    ) {
        return false
    }
    if (
        valArr[1] &&
        valArr[1].length &&
        (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)
    ) {
        return false
    }
    return true
}

export const minutesToTimeFormatString = (value) => {
    const minutes = Math.abs(value)
    if (Number.isNaN(minutes) || minutes === 0) {
        return '00:00'
    }
    if (minutes < 10) {
        return `00:0${minutes}`
    }
    let formattedMinutes = minutes % 60
    if (formattedMinutes < 10) {
        formattedMinutes = `0${formattedMinutes}`
    }
    let formattedHours = Math.floor(minutes / 60)
    if (formattedHours < 10) {
        formattedHours = `0${formattedHours}`
    }
    return `${formattedHours}:${formattedMinutes}`
}

const isValidHourTen = (ten) => Number.isInteger(ten) && ten >= 0 && ten < 3
const isValidHourUnit = (unit) =>
    Number.isInteger(unit) && unit >= 0 && unit < 24
const isValidMinuteTen = (ten) => Number.isInteger(ten) && ten >= 0 && ten < 6
const isValidMinuteUnit = (unit) =>
    Number.isInteger(unit) && unit >= 0 && unit <= 9

export const parseValidTimeString = (timeString) => {
    const [hours, minutes] = timeString.split(':')
    let hourTen = Number(hours[0])
    let hourUnit = Number(hours[1])
    let minuteTen = Number(minutes[0])
    let minuteUnit = Number(minutes[1])

    hourTen = isValidHourTen(hourTen) ? hours[0] : '0'
    hourUnit = isValidHourUnit(hourUnit) ? hours[1] : '0'
    minuteTen = isValidMinuteTen(minuteTen) ? minutes[0] : '0'
    minuteUnit = isValidMinuteUnit(minuteUnit) ? minutes[1] : '0'

    return `${hourTen}${hourUnit}:${minuteTen}${minuteUnit}`
}

export const isWeekend = (date) => dateFnsIsWeekend(date)

export const today = () => startOfToday()

// This function prevenst integers being returned as x.00
// Examples:
// x = 2 -> 2
// x = 2.56 -> 2.6
export const toDecimal = (x, decimals = 1) =>
    x === 0 ? 0 : x.toFixed(decimals).replace(/[.,]00$/, '')

export const calculateEndDate = (
    elapsedDurationInSeconds,
    latestStartDateTime,
) => {
    // Convert latestStartDateTime to milliseconds since epoch
    const latestStartTimestamp = new Date(latestStartDateTime).getTime()

    // Add elapsedDurationInSeconds to latestStartDateTime in milliseconds
    const totalMilliseconds =
        latestStartTimestamp + elapsedDurationInSeconds * 1000

    // Create a new Date object for the end date using the calculated milliseconds
    const endDate = new Date(totalMilliseconds)

    return endDate.toISOString() // Return the end date in ISO format
}
