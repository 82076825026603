import { useContext, useEffect } from 'react'
import {
    OPEN_POPOVER,
    CLOSE_POPOVER,
    PopoverStateContext,
} from '../providers/PopoverStateProvider'

const usePopoverState = (id, defaultOpen = false) => {
    const { state, dispatch } = useContext(PopoverStateContext)
    const { popovers } = state

    const openPopover = () => {
        dispatch({
            type: OPEN_POPOVER,
            id,
        })
    }

    const closePopover = () => {
        dispatch({
            type: CLOSE_POPOVER,
            id,
        })
    }

    useEffect(() => {
        if (defaultOpen) {
            openPopover()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultOpen])

    const isOpen = popovers.some((v) => v === id)

    return {
        isOpen,
        openPopover,
        closePopover,
    }
}

export default usePopoverState
