import React from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import ToasterItem from 'components/util/ToasterItem'
import useNotifications from 'components/hooks/useNotifications'

const Toaster = () => {
    const { notifications, onCloseNotification } = useNotifications()
    return (
        <Container>
            <motion.div animate={{ opacity: 0.7 }} exit={{ opacity: 0 }}>
                {notifications.map((notification) => (
                    <ToasterItem
                        key={notification.createdAt}
                        data={notification.data}
                        onCloseNotification={() =>
                            onCloseNotification(notification)
                        }
                    />
                ))}
            </motion.div>
        </Container>
    )
}

const Container = styled.div`
    position: fixed;
    bottom: ${({ theme }) => theme.spacing}rem;
    right: ${({ theme }) => theme.spacing}rem;
    z-index: ${({ theme }) => theme.toasterZIndex};
`

export default Toaster
