import { parseQueryString } from 'util/index'
import {
    resetActiveDirectoryPassword,
    loginWithActiveDirectory,
} from 'util/activeDirectory'
import {
    AUTHORIZATION_PASSWORD_FORGOTTEN_CODE as PASSWORD_FORGOTTEN,
    AUTHORIZATION_PASSWORD_FORGOTTEN_CANCELED_CODE as PASSWORD_FORGOTTEN_CANCELED,
} from 'util/constants'

const handleAuthorizationUrl = (url, activeDirectoryClientId) => {
    const {
        code,
        error,
        state,
        error_description: errorDescription,
    } = parseQueryString(url)

    // TODO: Remove this check and pass isPasswordReset = true and code to login mutation
    if (state === 'pw_reset') {
        loginWithActiveDirectory(activeDirectoryClientId)
    } else if (typeof error === 'undefined') {
        return { code, state }
    } else if (errorDescription.search(PASSWORD_FORGOTTEN) !== -1) {
        resetActiveDirectoryPassword(activeDirectoryClientId)
    } else if (errorDescription.search(PASSWORD_FORGOTTEN_CANCELED) !== -1) {
        loginWithActiveDirectory(activeDirectoryClientId)
    }
    throw new Error(error)
}

export default handleAuthorizationUrl
