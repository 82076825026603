import gql from 'graphql-tag'

const LoginMutation = gql`
    mutation loginMutation(
        $code: String!
        $callBackUrl: String
        $invitationId: String
    ) {
        login(
            authorizationCode: $code
            callBackUrl: $callBackUrl
            invitationId: $invitationId
        ) {
            accessToken
            accessTokenExpiration
            refreshToken
            refreshTokenExpiration
            refreshTokenSlidingWindowExpiration
            me {
                accountState
                availableTenants {
                    guid
                    name
                }
                availableImpersonationRights {
                    id
                    key
                    puppetLoginEmail
                    expirationDateTime
                    tenant {
                        guid
                        name
                    }
                }
            }
        }
    }
`

export default async function login(apolloClient, variables) {
    const mutation = LoginMutation
    const response = await apolloClient.mutate({ mutation, variables })
    return response.data.login
}
