
import React from 'react'
import Box from 'components/util/Box'
import MainSideBar from 'components/layout/MainSideBar'

const SkeletonPage = ({withSkeletonSideBar}) => <Box className='flex h-screen max-w-full mt-14' >
    <Box className="w-40 h-full" >
        {withSkeletonSideBar ?
            Array.from({ length: 8 }).map((_,index)=><Box className={`w-full h-40 p-2 bg-gray-400 animate-pulse ${
                index % 2 === 0 ? 'opacity-70' : 'opacity-100'
            }`} />)  : <MainSideBar />
        }
    </Box>
    <div className='w-5' />
    <Box className='ml-50 p-4 w-full'>
        <div className='w-40 h-1 p-2 bg-gray-600 opacity-30 animate-pulse rounded-lg'/>
        <Box className='w-full h-1/2 p-2 bg-gray-600 opacity-30 animate-pulse mt-5 '/>
        <Box className='w-full h-1/2 p-2 bg-gray-600 opacity-40 animate-pulse mt-5 '/>
    </Box>
</Box>

export default SkeletonPage