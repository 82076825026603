import useAppState from 'components/hooks/useAppState'
import useAppInsights from 'components/hooks/useAppInsights'

const useTracking = () => {
    const appInsights = useAppInsights()
    const { currentUser, currentTenant } = useAppState()
    const environment = process.env.NODE_ENV
    const shouldLog = environment !== 'local' && environment !== 'test'
    const trackError = (error) => {
        if (shouldLog) {
            appInsights.trackException({
                exception: error,
                properties: {
                    currentUserId: currentUser && currentUser.id,
                    currentTenantGuid: currentTenant && currentTenant.guid,
                },
            })
        } else {
            // eslint-disable-next-line no-console
            console.log(error)
        }
    }
    return {
        trackError,
    }
}
export default useTracking
