import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'

let reactPlugin = null
let appInsights = null

export function getReactPlugin() {
    if (reactPlugin === null) {
        reactPlugin = new ReactPlugin()
    }
    return reactPlugin
}

export function createApplicationInsights(instrumentationKey) {
    if (!instrumentationKey) {
        throw new Error('Instrumentation key not provided')
    }

    reactPlugin = getReactPlugin()

    appInsights = new ApplicationInsights({
        config: {
            instrumentationKey,
            maxBatchInterval: 0,
            disableFetchTracking: false,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: {},
            },
        },
    })
    appInsights.loadAppInsights()
}

export const getAppInsights = (instrumentationKey) => {
    if (appInsights === null) {
        createApplicationInsights(instrumentationKey)
    }
    return appInsights
}
