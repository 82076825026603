import React from 'react'
import clsx from 'clsx'
import gql from 'graphql-tag'
import { Trans } from '@lingui/react'
import { useQuery } from '@apollo/client'
import { minutesToTimeFormatString } from '../../util/dates'
import Popover from './Popover2'

export const QUERY = gql`
    query project($projectId: Int!) {
        project(id: $projectId) {
            id
            title
            number
            timesheetEntryDurationInMinutesLast30Days
            timesheetEntryDurationInMinutesOfLoggedInPartnerLast30Days
            timesheetDescriptionLengthLimit
            costDescriptionLengthLimit
        }
    }
`

const ProjectTimesheetDurationCardPopover = ({ project, className }) => {
    const variables = { projectId: project?.id }
    const { data = {}, loading } = useQuery(QUERY, {
        skip: !project,
        variables,
    })

    if (loading || !data.project) {
        return null
    }
    const {
        project: {
            timesheetEntryDurationInMinutesLast30Days,
            timesheetEntryDurationInMinutesOfLoggedInPartnerLast30Days,
        },
    } = data
    if (!project || timesheetEntryDurationInMinutesLast30Days <= 0) {
        return null
    }
    return (
        <Popover
            id="ProjectTimesheetDurationCardPopover"
            event="hover"
            content={
                <div className="p-3 min-w-[100px] bg-white shadow-lg">
                    <div className="flex justify-between">
                        <span className="whitespace-nowrap font-bold">
                            <Trans id="Total: " />
                        </span>
                        <span>
                            {minutesToTimeFormatString(
                                timesheetEntryDurationInMinutesLast30Days,
                            )}
                        </span>
                    </div>
                    <div className="flex justify-between">
                        <span className="whitespace-nowrap font-bold">
                            <Trans id="Me: " />
                        </span>
                        <span>
                            {minutesToTimeFormatString(
                                timesheetEntryDurationInMinutesOfLoggedInPartnerLast30Days,
                            )}
                        </span>
                    </div>
                </div>
            }
        >
            <div
                className={clsx(
                    'flex items-center flex-col rounded-md bg-blue-dark px-4 text-white',
                    className,
                )}
            >
                <span className="text-sm">
                    <Trans id="Last 30d" />
                </span>
                <span className="font-bold">
                    {minutesToTimeFormatString(
                        timesheetEntryDurationInMinutesLast30Days,
                    )}
                </span>
            </div>
        </Popover>
    )
}

export default ProjectTimesheetDurationCardPopover
