import React from 'react'
import { Trans } from '@lingui/macro'
import ModalTopBar from 'components/layout/ModalTopBar'
import Modal, { ModalMainContainer } from 'components/layout/Modal'
import ModalConfirmButtonBar from 'components/layout/ModalConfirmButtonBar'

const DeleteTimerModal = ({ isSubmitting, onClose, onSubmit }) => (
    <Modal ariaLabel="Cancel timer">
        <ModalTopBar title={<Trans>Cancel timer</Trans>} onClose={onClose} />
        <ModalMainContainer>
            <p>
                <Trans>Are you sure you want to cancel this Timer?</Trans>
                <br />
                <Trans>
                    All data related to this timer will be permanently removed!
                </Trans>
            </p>
        </ModalMainContainer>
        <ModalConfirmButtonBar
            isSubmitting={isSubmitting}
            onCancel={onClose}
            onSubmit={onSubmit}
            submitText={<Trans>Delete</Trans>}
            submittingText={<Trans>Deleting</Trans>}
        />
    </Modal>
)

export default DeleteTimerModal
