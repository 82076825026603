import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

export const SET_TEAMLEADER_FOCUS_ADMIN_MUTATION = gql`
    mutation setTeamleaderFocusAdminPermissions(
        $code: String!
        $redirectUrl: String!
    ) {
        setTeamleaderFocusAdminPermissions(
            code: $code
            redirectUrl: $redirectUrl
        )
    }
`

export default function useSetTeamleaderFocusAdminPermissions() {
    return useMutation(SET_TEAMLEADER_FOCUS_ADMIN_MUTATION)
}
