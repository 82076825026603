import React from 'react'
import { Trans } from '@lingui/react'
import styled from 'styled-components'
import Icon from 'components/util/Icon'
import Title from 'components/util/Title'
import PageWithState from 'components/layout/PageWithState'

const AccountDeactivitedPage = ({ ...props }) => (
    <PageWithState {...props}>
        <ContentContainer>
            <StyledIcon isLarger name="warning" accentColor="primary" />
            <Title>
                <Trans id="Oops, no access!" />
            </Title>
            <ErrorMessage>
                <Trans id="Your account is not active." />
                <br />
                <Trans id="Please contact an office admin to (re)activate your account." />
            </ErrorMessage>
        </ContentContainer>
    </PageWithState>
)

const ContentContainer = styled.div(
    ({ theme }) => `
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: auto;
    padding: ${theme.spacingLarger}rem;
    background: ${theme.colorGreyWhite};
`,
)

const StyledIcon = styled(Icon)`
    margin-bottom: ${({ theme }) => theme.spacingSmaller}rem;
`

const ErrorMessage = styled.p`
    text-align: center;
`

export default AccountDeactivitedPage
