import React, { forwardRef } from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'

const Checkbox = forwardRef(
    (
        {
            error,
            name,
            children,
            className,
            disabled = false,
            onChange,
            ...props
        },
        ref,
    ) => (
        <Container disabled={disabled}>
            <Container
                disabled={disabled}
                className={className}
                htmlFor={name}
                whileHover={{ scale: 1.15 }}
            >
                <StyledCheckbox
                    ref={ref}
                    error={error}
                    id={name}
                    name={name}
                    onChange={(e) => {
                        e.stopPropagation()
                        if (disabled) {
                            e.preventDefault()
                        } else {
                            onChange(e)
                        }
                    }}
                    {...props}
                />
                <StyledCheckMarkUnchecked disabled={disabled} />
                <StyledCheckMarkChecked />
            </Container>
            {typeof children !== 'undefined' && <Label>{children}</Label>}
        </Container>
    ),
)

const CheckContainer = ({ children, ...props }) => (
    <svg height="16" width="16" viewBox="0 0 16 16" {...props}>
        {children}
    </svg>
)

const CheckMarkUnchecked = (props) => (
    <CheckContainer {...props}>
        <rect x="1" y="1" width="14" height="14" rx="1" ry="1" />
    </CheckContainer>
)

const CheckMarkChecked = (props) => (
    <CheckContainer {...props}>
        <path d="M14,0H2C0.9,0,0,0.9,0,2v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V2C16,0.9,15.1,0,14,0z M7,11.4L3.6,8L5,6.6 l2,2l4-4L12.4,6L7,11.4z" />
    </CheckContainer>
)

const StyledCheckMarkUnchecked = styled(CheckMarkUnchecked)`
    display: block;
    fill: none;
    stroke: ${({ theme, disabled }) =>
        disabled ? theme.colorGreyLighter : theme.colorGrey};
`

const StyledCheckMarkChecked = styled(CheckMarkChecked)`
    display: none;
    fill: ${({ theme }) => theme.colorPrimary};
`

const StyledCheckbox = styled.input.attrs({
    type: 'checkbox',
})`
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
    :checked ~ ${StyledCheckMarkUnchecked} {
        display: none;
    }
    :checked ~ ${StyledCheckMarkChecked} {
        display: block;
    }
`

const Container = styled(motion.label)(
    ({ disabled }) => `
    display: flex;
    align-items: center;
    position: relative;
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
`,
)

const Label = styled.span`
    margin-left: ${({ theme }) => theme.spacingSmallest}rem;
`

export default Checkbox
