import React, { forwardRef } from 'react'
import clsx from 'clsx'
import { withTheme } from 'styled-components'

const COLOR_WHITE = 'white'
const COLOR_GREEN = 'green'
const COLOR_RED = 'red'
const COLOR_YELLOW = 'yellow'
const COLOR_SUCCESS = 'success'
const COLOR_ERROR = 'error'
const COLOR_WARNING = 'warning'
const COLOR_GREY_DARK = 'greyDark'
const COLOR_PRIMARY = 'primary'
const COLOR_DEFAULT_FONT = 'defaultFont'
const COLOR_DEFAULT_FONT_LIGHT = 'defaultFontLight'

export const getAccentColor = (color, theme) => {
    if (color === COLOR_PRIMARY) {
        return theme.colorPrimary
    }
    if (color === COLOR_DEFAULT_FONT) {
        return theme.defaultFontColor
    }
    if (color === COLOR_DEFAULT_FONT_LIGHT) {
        return theme.defaultFontColorLight
    }
    if (color === COLOR_WHITE) {
        return theme.colorWhite
    }
    if (color === COLOR_GREEN || color === COLOR_SUCCESS) {
        return theme.colorGreen
    }
    if (color === COLOR_RED || color === COLOR_ERROR) {
        return theme.colorRed
    }
    if (color === COLOR_YELLOW || color === COLOR_WARNING) {
        return theme.colorYellow
    }
    if (color === COLOR_GREY_DARK) {
        return theme.colorGreyDark
    }
    return color
}

const IconContainer = forwardRef(
    (
        {
            size,
            viewBox,
            accentColor = 'currentColor',
            theme,
            children,
            strokeWidth = 2,
            disabled,
            onClick,
            className,
            ...props
        },
        ref,
    ) => {
        const finalAccentColor = getAccentColor(accentColor, theme)
        return (
            <svg
                x="0px"
                y="0px"
                width={size}
                height={size}
                viewBox={viewBox}
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                ref={ref}
                className={clsx(
                    'fill-none stroke-current overflow-visible',
                    disabled ? 'cursor-not-allowed' : '',
                    typeof onClick === 'function' ? 'cursor-pointer' : '',
                    className,
                )}
                onClick={onClick}
                {...props}
            >
                {typeof children === 'function'
                    ? children(finalAccentColor)
                    : children}
            </svg>
        )
    },
)

export default withTheme(IconContainer)
