import React from 'react'
import clsx from 'clsx'
import Icon from './Icon'

const DropdownMenuItem = ({
    iconName,
    iconClassName,
    iconSize = 'sm',
    children,
    className,
    disabled = false,
    ...props
}) => (
    <button
        disabled={disabled}
        type="button"
        role="menuitem"
        className={clsx(
            'group flex items-center text-left w-full px-4 py-2 text-sm leading-5 ',
            !disabled && 'text-gray-700 hover:bg-gray-100 hover:text-gray-900 ',
            disabled &&
                'cursor-not-allowed text-gray-400 hover:bg-transparent hover:text-gray-400',
            className,
        )}
        {...props}
    >
        {iconName && (
            <Icon
                name={iconName}
                size={iconSize}
                color="none"
                className={clsx(
                    'mr-3 ',
                    iconClassName,
                    !disabled && 'text-gray-400 group-hover:text-gray-500',
                    disabled && 'text-gray-300 group-hover:text-gray-300',
                )}
            />
        )}
        {children}
    </button>
)

export default DropdownMenuItem
