import React, { cloneElement } from 'react'
import Portal from '@reach/portal'
import { TooltipPopup, useTooltip } from '@reach/tooltip'
import styled, { withTheme } from 'styled-components'
import { toolTipZIndex } from 'styles/theme'

const POSITION_TOP = 'top'
const POSITION_BOTTOM = 'bottom'

const bottom = (triggerRect, tooltipRect) => {
    const triggerCenter = triggerRect.left + triggerRect.width / 2
    const left = triggerCenter - tooltipRect.width / 2
    const maxLeft = window.innerWidth - tooltipRect.width - 2
    return {
        left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
        top: triggerRect.bottom + 8 + window.scrollY,
    }
}

const top = (triggerRect, tooltipRect) => {
    const triggerCenter = triggerRect.left + triggerRect.width / 2
    const left = triggerCenter - tooltipRect.width / 2
    const maxLeft = window.innerWidth - tooltipRect.width - 2
    return {
        left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
        top: triggerRect.top - tooltipRect.height - 8 + window.scrollY,
    }
}

const Tooltip = ({
    show = true,
    isLight = false,
    theme,
    label,
    ariaLabel,
    children,
    position = POSITION_TOP,
}) => {
    const [trigger, tooltip] = useTooltip()
    // get the props from useTooltip
    // destructure off what we need to position the triangle
    const { isVisible, triggerRect } = tooltip
    const showTooltip = show && isVisible
    let positionStyle
    let triangleStyle
    switch (position) {
        case POSITION_BOTTOM:
            positionStyle = bottom
            triangleStyle = {
                top: triggerRect && triggerRect.bottom + window.scrollY,
                borderLeft: '1rem solid transparent',
                borderRight: '1rem solid transparent',
                borderBottom: `1rem solid ${
                    isLight ? theme.colorWhite : theme.colorGreyBlack
                }`,
            }
            break
        case POSITION_TOP:
        default:
            positionStyle = top
            triangleStyle = {
                top: triggerRect && triggerRect.top + window.scrollY - 10,
                borderLeft: '1rem solid transparent',
                borderRight: '1rem solid transparent',
                borderTop: `1rem solid ${
                    isLight ? theme.colorWhite : theme.colorGreyBlack
                }`,
            }
            break
    }
    if (typeof label === 'undefined' || label === null || label.length === 0) {
        return children
    }
    return (
        <>
            {cloneElement(children, trigger)}
            {showTooltip && (
                // The Triangle. We position it relative to the trigger, not the popup
                // so that collisions don't have a triangle pointing off to nowhere.
                // Using a Portal may seem a little extreme, but we can keep the
                // positioning logic simpler here instead of needing to consider
                // the popup's position relative to the trigger and collisions
                <>
                    <Portal>
                        <div
                            style={{
                                position: 'absolute',
                                left:
                                    triggerRect &&
                                    triggerRect.left -
                                        10 +
                                        triggerRect.width / 2,
                                width: 0,
                                height: 0,
                                zIndex: toolTipZIndex,
                                ...triangleStyle,
                            }}
                        />
                    </Portal>
                    <TooltipPopup
                        {...tooltip}
                        label={
                            <span style={{ whiteSpace: 'pre-line' }}>
                                {label}
                            </span>
                        }
                        ariaLabel={ariaLabel}
                        style={{
                            backgroundColor: isLight
                                ? theme.colorWhite
                                : theme.colorGreyBlack,
                            color: isLight
                                ? theme.colorGreyBlak
                                : theme.colorWhite,
                            border: 'none',
                            borderRadius: `${theme.borderRadius}rem`,
                            padding: `${theme.spacingSmall}rem`,
                            boxShadow:
                                '0 4px 8px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.08)', // Same as Popover
                            maxWidth: '40rem',
                            whiteSpace: 'normal',
                            overflowWrap: 'break-word',
                            zIndex: toolTipZIndex,
                        }}
                        position={positionStyle}
                    />
                </>
            )}
        </>
    )
}

const StyledTitle = styled.h3(
    ({ theme }) => `
    text-align: left;
    color: ${theme.colorWhite};
    font-weight: ${theme.fontWeightBold};
    font-size: ${theme.defaultFontSize}rem;
    margin-bottom: ${theme.spacingSmaller}rem;
`,
)

const StyledText = styled.p(
    ({ theme }) => `
    margin: 0;
    color: ${theme.colorGreyWhite};
    font-weight: ${theme.fontWeightRegular};
    font-size: ${theme.fontSizeSmall}rem;
`,
)

const StyledList = styled.ul`
    list-style-type: disc;
`

const StyledListItem = styled.li(
    ({ theme }) => `
    text-align: left;
    color: ${theme.colorWhite};
    font-size: ${theme.fontSizeSmaller}rem;
    margin-left: ${theme.spacingSmall}rem
`,
)

Tooltip.Title = StyledTitle
Tooltip.Text = StyledText
Tooltip.List = StyledList
Tooltip.ListItem = StyledListItem

export default withTheme(Tooltip)
