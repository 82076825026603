import React, { forwardRef } from 'react'
import classNames from 'classnames'

const Box = forwardRef(({ children, className, id,onKeyDown, ...rest }, ref) => {
    // Construire une liste de classes en fonction des props reçues
    const {
        grid,
        gap,
        gridCols,
        colSpan,
        rowSpan,
        gridRows,
        bgColor,
        color,
        padding,
        margin,
        rounded,
        width,
        height,
        border,
        borderSize,
        borderColor,
        shadow,
        display,
        flex,
        flexDirection,
        justifyContent,
        alignItems,
        overflow,
        cursor,
    } = rest

    const classes =
        rest &&
        classNames(
            className,
            bgColor && `bg-[${bgColor}]`,
            color && `text-${color}`,
            padding && `p-${padding}`,
            margin && `m-${margin}`,
            width === 'full' ? 'w-full' : width && `w-${width}`,
            height === 'full' ? 'h-full' : height && `h-${height}`,
            border && `border-${border}`,
            borderSize && `border-${borderSize}`,
            borderColor && `border-[${borderColor}]`,
            rounded && `rounded-${rounded}`,
            shadow && `shadow-${shadow}`,
            display && 'block',
            flex && 'flex',
            flexDirection && `flex-${flexDirection}`,
            justifyContent && `justify-${justifyContent}`,
            alignItems && `items-${alignItems}`,
            overflow && `overflow-${overflow}`,
            grid && `grid grid-${grid}`,
            gridCols && `grid-cols-${gridCols}`,
            gridRows && `grid-rows-${gridRows}`,
            colSpan && `col-span-${colSpan}`,
            rowSpan && `row-span-${rowSpan}`,
            gap && `gap-${gap}`,
            cursor && `cursor-${cursor}`
        )

    return (
        <div id={id} ref={ref} className={classes}>
            {children}
        </div>
    )
})

export default Box
