import { ApolloClient, InMemoryCache } from '@apollo/client'
import { createHttpLink } from '@apollo/client/link/http'
import { setContext } from 'apollo-link-context'
import * as storage from 'util/storage'

const { REACT_APP_GRAPHQL_URI } = process.env

const setHeaders = async (request, { headers }) => {
    const currentHeaders = { ...headers, ClientInfo: 'WebApp' }
    const authorizationScheme = await storage.getAuthorizationScheme()
    if (authorizationScheme) {
        currentHeaders['Authorization-Scheme'] = authorizationScheme
    }
    return {
        headers: {
            ...currentHeaders,
            ClientInfo: 'WebApp',
        },
    }
}

const httpLink = createHttpLink({
    uri: REACT_APP_GRAPHQL_URI,
    credentials: 'include',
})

export default function createSimpleApolloClient() {
    return new ApolloClient({
        link: setContext(setHeaders).concat(httpLink),
        cache: new InMemoryCache(),
    })
}
