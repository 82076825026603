import gql from 'graphql-tag'

const pauseTimerMutation = gql`
    mutation pauseTimer($timerId: Int!, $dateTime: DateTime!) {
        pauseTimer(timerId: $timerId, dateTime: $dateTime) {
            timer {
                id
                description
                project {
                    id
                    title
                    number
                }
                status
                elapsedDurationInSeconds
                earliestStartDateTime
                latestStartDateTime
            }
        }
    }
`
export default async function pauseTimer(apolloClient, variables, options) {
    const mutation = pauseTimerMutation
    const response = await apolloClient.mutate({
        mutation,
        variables,
        ...options,
    })
    return response.data.pauseTimer
}
