import React from 'react'
import { Trans } from '@lingui/react'
import { useApolloClient } from '@apollo/client'
import search from 'api/search'
import useNotifications from 'components/hooks/useNotifications'
import TopBarSearch from 'components/layout/TopBarSearch'
import useTracking from 'components/hooks/useTracking'

const TopBarSearchWithState = () => {
    const apolloClient = useApolloClient()
    const { dispatchError } = useNotifications()
    const { trackError } = useTracking()
    return (
        <TopBarSearch
            onSearch={async (searchValue) => {
                try {
                    const response = await search(apolloClient, searchValue)
                    return response
                } catch (e) {
                    trackError(e)
                    dispatchError({
                        title: <Trans id="Something went wrong!" />,
                        content: e.message,
                    })
                    return []
                }
            }}
        />
    )
}

export default TopBarSearchWithState
