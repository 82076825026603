import styled from 'styled-components'
import withTestId from 'components/hoc/withTestId'

const AppContainer = styled.div(
    ({ theme }) => `
    min-height: 100vh;
    width: 100%;
    min-width: ${theme.minWidth}rem;
    display: flex;
`,
)

export default withTestId(AppContainer, 'AppContainer')
