import { oneLineTrim } from 'common-tags'

const {
    REACT_APP_ACTIVE_DIRECTORY_B2C_AUTHORIZE_URL: B2C_AUTHORIZE_URL,
    REACT_APP_ACTIVE_DIRECTORY_B2C_PASSWORD_RESET_URL: B2C_PASSWORD_RESET_URL,
    REACT_APP_ACTIVE_DIRECTORY_REDIRECT_URI: DIRECTORY_REDIRECT_URI,
} = process.env

export const composeActiveDirectoryUrl = (
    activeDirectoryClientId,
) => oneLineTrim`${B2C_AUTHORIZE_URL}
        &client_id=${activeDirectoryClientId}
        &scope=${encodeURIComponent(
            `${activeDirectoryClientId} offline_access`,
        )}
        &redirect_uri=${encodeURIComponent(DIRECTORY_REDIRECT_URI)}
        &response_type=code
        &response_mode=query
        &prompt=login
        &nonce=defaultNonce
    `

export const composeResetPasswordUrl = (
    activeDirectoryClientId,
) => oneLineTrim`${B2C_PASSWORD_RESET_URL}
        &client_id=${activeDirectoryClientId}
        &scope=${encodeURIComponent(
            `${activeDirectoryClientId} offline_access`,
        )}
        &redirect_uri=${encodeURIComponent(DIRECTORY_REDIRECT_URI)}
        &response_type=code
        &state=pw_reset
    `

export const loginWithActiveDirectory = (activeDirectoryClientId) => {
    const href = composeActiveDirectoryUrl(activeDirectoryClientId)
    window.location.href = href
}

export const resetActiveDirectoryPassword = (activeDirectoryClientId) => {
    const href = composeResetPasswordUrl(activeDirectoryClientId)
    window.location.href = href
}
