import gql from 'graphql-tag'

const IS_TENANT_AVAILABLE = gql`
    query isTenantAvailable($tenantGuid: String!) {
        isTenantAvailable(tenantGuid: $tenantGuid)
    }
`

export default async function isTenantAvailable(apolloClient, variables) {
    const query = IS_TENANT_AVAILABLE
    const response = await apolloClient.query({
        query,
        variables,
        fetchPolicy: 'network-only',
    })
    return response.data.isTenantAvailable
}
