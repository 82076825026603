/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef, forwardRef } from 'react'
import { usePopper } from 'react-popper'
import { motion } from 'framer-motion'
import clsx from 'clsx'
import { useOnClickOutside } from '@wappla/react-hooks'
import { combineRefs } from '../../util/index'
import Portal from './Portal'
import usePopoverState from '../hooks/usePopoverState'

const EVENT_CLICK = 'click'
const EVENT_HOVER = 'hover'
const POSITION_AUTO = 'auto'
const POSITION_AUTO_START = 'auto-start'
const POSITION_AUTO_END = 'auto-end'
const POSITION_TOP = 'top'
const POSITION_TOP_START = 'top-start'
const POSITION_TOP_END = 'top-end'
const POSITION_BOTTOM = 'bottom'
const POSITION_BOTTOM_START = 'bottom-start'
const POSITION_BOTTOM_END = 'bottom-end'
const POSITION_RIGHT = 'right'
const POSITION_RIGHT_START = 'right-start'
const POSITION_RIGHT_END = 'right-end'
const POSITION_LEFT = 'left'
const POSITION_LEFT_START = 'left-start'
const POSITION_LEFT_END = 'left-end'

const PopoverContainer = forwardRef(({ onOutsideClick, ...props }, ref) => {
    const containerRef = useRef()
    useOnClickOutside(containerRef, () => {
        if (typeof onOutsideClick === 'function') {
            onOutsideClick()
        }
    })
    const refs = ref !== null ? [ref, containerRef] : [containerRef]
    return <div ref={combineRefs(refs)} {...props} />
})

const AnimatedPopoverContainer = motion(PopoverContainer)

const Popover = ({
    id,
    as = 'span',
    disabled = false,
    position = POSITION_BOTTOM_START,
    event = 'click',
    content,
    hasArrow,
    arrowClassName,
    className,
    querySelector = '#popovers',
    children,
}) => {
    const Component = as
    const [arrowElement, setArrowElement] = useState(null)
    const [popperElement, setPopperElement] = useState(null)
    const [referenceElement, setReferenceElement] = useState(null)
    const { isOpen, openPopover, closePopover } = usePopoverState(id)
    let arrowModifiers = []
    if (hasArrow) {
        arrowModifiers = [
            {
                name: 'arrow',
                options: {
                    element: arrowElement,
                },
            },
            {
                name: 'offset',
                enabled: true,
                options: {
                    offset: [0, 8],
                },
            },
        ]
    }
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        strategy: 'fixed',
        placement: position,
        modifiers: [...arrowModifiers],
    })
    return (
        <>
            <Component
                className={clsx(
                    disabled ? 'cursor-not-allowed' : 'cursor-default',
                    className,
                )}
                ref={setReferenceElement}
                onClick={(e) => {
                    if (!disabled && event === EVENT_CLICK) {
                        openPopover()
                        e.stopPropagation()
                    }
                }}
                onMouseEnter={() =>
                    !disabled && event === EVENT_HOVER && openPopover()
                }
                onMouseLeave={() =>
                    !disabled && event === EVENT_HOVER && closePopover()
                }
            >
                {children}
            </Component>
            {isOpen && (
                <Portal querySelector={querySelector}>
                    <AnimatedPopoverContainer
                        ref={setPopperElement}
                        style={styles.popper}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        onOutsideClick={() => {
                            closePopover()
                        }}
                        onMouseEnter={() =>
                            !disabled && event === EVENT_HOVER && openPopover()
                        }
                        onMouseLeave={() =>
                            !disabled && event === EVENT_HOVER && closePopover()
                        }
                        className="z-popovers"
                        {...attributes.popper}
                    >
                        {hasArrow && (
                            <div
                                ref={setArrowElement}
                                style={styles.arrow}
                                className={arrowClassName}
                            />
                        )}
                        {(() => {
                            if (typeof content === 'function') {
                                return content({
                                    open: () => openPopover(),
                                    close: () => closePopover(),
                                })
                            }
                            return content
                        })()}
                    </AnimatedPopoverContainer>
                </Portal>
            )}
        </>
    )
}

Popover.POSITION_TOP = POSITION_TOP
Popover.POSITION_AUTO = POSITION_AUTO
Popover.POSITION_AUTO_START = POSITION_AUTO_START
Popover.POSITION_AUTO_END = POSITION_AUTO_END
Popover.POSITION_TOP = POSITION_TOP
Popover.POSITION_TOP_START = POSITION_TOP_START
Popover.POSITION_TOP_END = POSITION_TOP_END
Popover.POSITION_BOTTOM = POSITION_BOTTOM
Popover.POSITION_BOTTOM_START = POSITION_BOTTOM_START
Popover.POSITION_BOTTOM_END = POSITION_BOTTOM_END
Popover.POSITION_RIGHT = POSITION_RIGHT
Popover.POSITION_RIGHT_START = POSITION_RIGHT_START
Popover.POSITION_RIGHT_END = POSITION_RIGHT_END
Popover.POSITION_LEFT = POSITION_LEFT
Popover.POSITION_LEFT_START = POSITION_LEFT_START
Popover.POSITION_LEFT_END = POSITION_LEFT_END

export default Popover
