import React from 'react'
import { Trans } from '@lingui/react'
import Dropdown from 'components/layout/Dropdown'
import TopBarUserButton from './TopBarUserButton'

const TopBarUserDropdown = ({
    onClickProfile,
    onSwitchTenant,
    onSupport,
    onLogout,
}) => (
    <Dropdown
        listItems={
            <Dropdown.List>
                <Dropdown.ListItem iconName="person" onClick={onClickProfile}>
                    <Trans id="Profile" />
                </Dropdown.ListItem>
                <Dropdown.ListItem iconName="company" onClick={onSwitchTenant}>
                    <Trans id="Switch tenant" />
                </Dropdown.ListItem>
                <Dropdown.ListItem
                    iconName="filledQuestionMark"
                    onClick={onSupport}
                >
                    <Trans id="Support" />
                </Dropdown.ListItem>
                <Dropdown.ListItem iconName="logout" onClick={onLogout}>
                    <Trans id="Logout" />
                </Dropdown.ListItem>
            </Dropdown.List>
        }
    >
        <TopBarUserButton />
    </Dropdown>
)
export default TopBarUserDropdown
