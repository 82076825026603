import React, { useState } from 'react'
import { Trans } from '@lingui/react'
import { useApolloClient } from '@apollo/client'
import styled from 'styled-components'
import { linkOffice365Account } from 'util/office365'
import updateAccountStateCompletion from 'api/updateAccountStateCompletion'
import OnboardingModal from 'components/pages/onboarding/OnboardingModal'
import useAppSecrets from 'components/hooks/useAppSecrets'
import PrimaryButton from 'components/util/PrimaryButton'
import Icon from 'components/util/Icon'
import PageNotFound from 'components/layout/PageNotFound'
import useTracking from 'components/hooks/useTracking'
import useNotifications from 'components/hooks/useNotifications'
import { APP_NAME } from '../../../util/constants'



const TOTAL_STEPS = 2

const AddO365AccountModalWithState = ({onClose, o365LinkStatusRes, step }) => {
    const { o365DrivesClientId, o365MailsAndCalendarsClientId } = useAppSecrets()
    const { trackError } = useTracking()
    const apolloClient = useApolloClient()
    const { dispatchError } = useNotifications()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [currentStep, setcurrentStep] = useState(step || 1)
    const [o365LinkStatus, setO365LinkStatus] = useState(o365LinkStatusRes)

    const onLinkOffice365Account = async (fileType) => {
                setIsSubmitting(true)
                try {
                await linkOffice365Account(
                    fileType === 'drives'? o365DrivesClientId : o365MailsAndCalendarsClientId ,
                    '',
                    '',
                    fileType,
                )
                    await updateAccountStateCompletion(
                        apolloClient,
                        {  hasLinkedO365: true
                         },
                    )
                } catch (e) {
                    trackError(e)
                    dispatchError({
                        title: <Trans id="Something went wrong!" />,
                        content: e.message,
                    })
                } finally {
                    setIsSubmitting(false)
                }
            }
            
        const moveToNextStep = () => {
                setcurrentStep(currentStep+1)
                setO365LinkStatus('initial')
        }    

        const closemodal = () => {
            setcurrentStep(1)
            setO365LinkStatus('initial')
            onClose()
        }
    const renderSteps = () => {
        switch (currentStep) {
            case 1:
                return <Container>{o365LinkStatus === 'initial' && <PrimaryButton
                            iconName="microsoft"
                            onClick={()=>onLinkOffice365Account('mails_calendars')}
                        >
                            <Trans id='Track your meetings and mails' />
                        </PrimaryButton>} 
                        { o365LinkStatus === 'MAILS_LINK_SUCESS'  && <Success>
                                <SuccesIcon name="check" isLarge />
                                <Trans id="Succesfully linked to Office 365!" />
                            </Success>} 
                        { o365LinkStatus === 'MAILS_LINK_FAILED' && <Success>
                                <FailedIcon  name="warning" accentColor="red" isLarge />
                                <Trans id="Failed linked to Office 365!" />
                        </Success>} 
                        </Container>
            case 2:
                return <Container>{o365LinkStatus === 'initial' && <PrimaryButton
                     iconName="microsoft"
                     onClick={()=>onLinkOffice365Account('drives')}
                 >
                     <Trans id="Track your Word, Excel and PowerPoint" />
                 </PrimaryButton>} 
             { o365LinkStatus === 'DRIVES_LINK_SUCESS'  && <Success>
                     <SuccesIcon name="check" isLarge />
                     <Trans id="Succesfully linked to Office 365!" />
                 </Success>} 
             { o365LinkStatus === 'DRIVES_LINK_FAILED' && <Success>
                     <FailedIcon  name="warning" accentColor="red" isLarge />
                     <Trans id="Failed linked to Office 365!" />
             </Success>} 
             </Container>
                                default:
                return <PageNotFound />
    }
}


     return <OnboardingModal
     title={
        <Trans id="Welcome to the {APP_NAME} App" values={{ APP_NAME }} />
    }
    subTitle={
        <Trans
            id="To use {APP_NAME} at full strength, please connect your Office 365 account"
            values={{ APP_NAME }}
        />
    }
     showNextButton={currentStep < TOTAL_STEPS}
     onNext={()=>moveToNextStep()}
     showSkipButton
     totalSteps={3}
     onSkip={()=>closemodal()}
    >
      {renderSteps()}
      <Disclaimer>
            { currentStep === 2 ? <Trans
                id="To automatically track the time you spend on editing Word, Excel or PowerPoint files that are stored in <0> OneDrive </0>or <0> SharePoint </0>, click the button below."
                components={[<strong />]}
                values={{ APP_NAME }}
            /> : <Trans
            id="<0>Give {APP_NAME}</0> permission to automatically track your time spent on sending mails and attending meetings by clicking the button below."
            components={[<strong />]}
            values={{ APP_NAME }}
        />  
            }
        </Disclaimer>
    </OnboardingModal>
} 

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const Disclaimer = styled.div(
    ({ theme }) => `
    padding-top: ${theme.spacingLarger}rem;
    padding-bottom: ${theme.spacingSmall}rem;
`,
)

const Success = styled.div(
    ({ theme }) => `
    padding: ${theme.spacingSmall}rem;
    font-size: ${theme.fontSizeLarge}rem;
`,
)

const SuccesIcon = styled(Icon)(
    ({ theme }) => `
    stroke: ${theme.colorWhite};
    fill: ${theme.colorGreen};
    margin: ${theme.spacingSmall}rem;
`,
)

const FailedIcon = styled(Icon)(
    ({ theme }) => `
    stroke: ${theme.colorWhite};
    fill: ${theme.colorError};
    margin: ${theme.spacingSmall}rem;
`,
)


export default AddO365AccountModalWithState
