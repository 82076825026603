import React from 'react'
import styled from 'styled-components'
import Modal from 'components/layout/Modal'
import Spinner from 'components/util/Spinner'

const ModalLoading = () => (
    <Modal ariaLabel="loading" issmall="true">
        <Container>
            <Spinner />
        </Container>
    </Modal>
)

const Container = styled.div(
    ({ theme }) => `
    padding: ${theme.spacingLargest}rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`,
)

export default ModalLoading
