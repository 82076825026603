import React, { useState } from 'react'
import { useSearchParams, useLocation } from 'react-router-dom'
import useAppSecrets from 'components/hooks/useAppSecrets'
import * as storage from 'util/storage'
import { composeAzureADUrl, composeAzureADB2CUrl } from 'util/auth'
import LoginPage from './LoginPage'

const LoginPageWithState = () => {
    const [params] = useSearchParams()
    const { aADActiveDirectoryClientId, activeDirectoryClientId } =
        useAppSecrets()
    const invitationId = params.get('invitationId')
    const location = useLocation()
    const [displayError, setDisplayError] = useState(
        location.state?.error ? location.state : false,
    )

    const onCloseError = () => {
        setDisplayError(false)
    }
    const handleLogin = async (provider) => {
        switch (provider) {
            case 'AD':
                await storage.setAuthorizationScheme('AzureAd')
                window.location.href = composeAzureADUrl(
                    aADActiveDirectoryClientId,
                    invitationId,
                )
                break
            case 'B2C':
                await storage.setAuthorizationScheme('AzureAdB2C')
                window.location.href = composeAzureADB2CUrl(
                    activeDirectoryClientId,
                )
                break
            default:
                break
        }
    }
    const isLoading = !activeDirectoryClientId
    return (
        <LoginPage
            isLoading={isLoading}
            onLogin={handleLogin}
            onCloseError={onCloseError}
            errorOnLogin={displayError}
        />
    )
}

export default LoginPageWithState
