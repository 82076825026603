import gql from 'graphql-tag'
import { useCacheAndNetworkQuery } from 'components/hooks/useApolloQueries'
import {
    DEFAULT_FORMAT_DATE_STRING,
    DEFAULT_FORMAT_TIME_STRING,
} from 'util/dates'

export const TENANT_SETTINGS_QUERY = gql`
    query tenantSettings {
        tenantSettings {
            id
            dateFormat
            timeFormat
            currency {
                id
                code
                slug
                translation
            }
            currentPlan {
                id
                product {
                    id
                    slug
                }
            }
            currentSubscription {
                id
                trialEnd
                quantity
                occupiedUserSeats
            }
            coworkerCount
            tenantType {
                id
                slug
                translation
            }
            tenantTypeId
        }
    }
`

const useTenantSettings = () => {
    const { data, loading } = useCacheAndNetworkQuery(TENANT_SETTINGS_QUERY)

    if (loading) {
        return {
            dateFormat: DEFAULT_FORMAT_DATE_STRING,
            timeFormat: DEFAULT_FORMAT_TIME_STRING,
            currency: {},
            currentPlan: { product: {} },
            currentSubscription: {},
            coworkerCount: 0,
            tenantType:{},
        }
    }

    return {
        ...data.tenantSettings,
    }
}

export default useTenantSettings
