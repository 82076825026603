import React, { forwardRef } from 'react'
import styled from 'styled-components'
import IconButton from 'components/util/IconButton'

const TopBarUserButton = forwardRef(
    ({ onPressButton, onPressChevron, ...props }, ref) => (
        <ButtonContainer>
            <UserButton
                isSmaller
                circled
                iconName="person"
                data-testid="TopBarUserButton"
                ref={ref}
                {...props}
            />
        </ButtonContainer>
    ),
)

const ButtonContainer = styled.div`
    display: flex;
`

const UserButton = styled(IconButton)(
    ({ theme }) => `
    color: ${theme.colorPrimary};
`,
)

export default TopBarUserButton
