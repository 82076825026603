import React, { forwardRef } from 'react'
import { Trans } from '@lingui/macro'
import { useTheme, withTheme } from 'styled-components'
import AsyncCreatable from 'react-select/async-creatable'
import {
    getSelectStyles,
    getSelectTheme,
    SelectDropdownIndicator,
} from 'components/util/Select'

const SelectCreatable = forwardRef(
    (
        {
            hasError,
            isFixed,
            isAddonBefore = false,
            isAddonAfter = false,
            hasAddonAfter = false,
            hasAddonBefore = false,
            disabled,
            noResultsText = <Trans>No results</Trans>,
            loadingText = <Trans>Loading</Trans>,
            ...props
        },
        ref,
    ) => {
        let selectProps = props
        const theme = useTheme()

        if (isFixed) {
            selectProps = {
                ...selectProps,
                menuPosition: 'fixed',
                menuPlacement: 'bottom',
                menuPortalTarget: document.body,
            }
        }
        return (
            <AsyncCreatable
                ref={ref}
                theme={(selectTheme) => getSelectTheme(selectTheme, theme)}
                styles={getSelectStyles(theme, {
                    isDisabled: disabled,
                    hasError,
                    isAddonBefore,
                    isAddonAfter,
                    hasAddonAfter,
                    hasAddonBefore,
                })}
                components={{
                    DropdownIndicator: SelectDropdownIndicator,
                    IndicatorSeparator: () => null,
                }}
                noOptionsMessage={() => noResultsText}
                loadingMessage={() => loadingText}
                {...selectProps}
            />
        )
    },
)

export default withTheme(SelectCreatable)
