import React from 'react'
import clsx from 'clsx'
import gql from 'graphql-tag'
import { Trans } from '@lingui/react'
import { useQuery } from '@apollo/client'
import useNotifications from '../hooks/useNotifications'
import ProjectLinksTable from '../pages/project-details-project-links/ProjectLinksTable'
import Popover from './Popover2'

export const QUERY = gql`
    query projectLinks($projectId: Int!) {
        project(id: $projectId) {
            id
            title
            number
            children {
                id
                child {
                    id
                    title
                    number
                }
            }
            parents {
                id
                parent {
                    id
                    title
                    number
                }
            }
        }
    }
`

const ProjectLinksCardPopover = ({ value, className, onChange }) => {
    const { dispatchError } = useNotifications()

    const variables = { projectId: value?.id }

    const {
        data = {},
        refetch,
        loading,
    } = useQuery(QUERY, {
        skip: !value,
        variables,
    })

    if (!value) {
        return null
    }

    if (loading) {
        return null
    }

    const { project: projectLinks } = data
    let links = []
    let level = 0
    if (projectLinks?.parents && projectLinks?.children) {
        links = links.concat(
            projectLinks.parents.map(({ parent }) => ({
                level,
                project: parent,
            })),
        )
        level = links.length > 0 ? 1 : 0
        links = links.concat({
            level,
            project: projectLinks,
            isCurrentProject: true,
        })
        links = links.concat(
            projectLinks.children.map(({ child }) => ({
                level: level + 1,
                project: child,
            })),
        )
    }

    const handleChange = async (updatedProject) => {
        try {
            onChange(updatedProject)
            await refetch({ projectId: updatedProject.id })
        } catch (e) {
            dispatchError({
                title: <Trans id="Something went wrong!" />,
                content: e.message,
            })
        }
    }

    if (links.length <= 1) {
        return null
    }

    return (
        <Popover
            id="ProjectLinksCardPopover"
            event="hover"
            content={({ close }) => (
                <div className="p-3 min-w-[45rem] bg-white shadow-lg">
                    <ProjectLinksTable
                        projectLinks={links}
                        removeLinkEnabled={false}
                        onClickProject={(p) => {
                            handleChange(p)
                            close()
                        }}
                        onClickProjectNewTab={({ id }) =>
                            window.open(`/projects/${id}`, '_blank')
                        }
                    />
                </div>
            )}
        >
            <div
                className={clsx(
                    'flex items-center flex-col rounded-md bg-blue-dark px-4 text-white',
                    className,
                )}
            >
                <span className="text-sm">
                    <Trans id="Linked" />
                </span>
                <span className="font-bold">{links.length - 1}</span>
            </div>
        </Popover>
    )
}

export default ProjectLinksCardPopover
