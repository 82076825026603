import React, { Suspense, lazy } from 'react'
import { Routes, Route } from 'react-router-dom'
import {
    ACCOUNT_STATE_DETAILS_DEACTIVATED,
    ACCOUNT_STATE_DETAILS_UNPAID,
} from 'util/constants'

import PageNotFound from 'components/layout/PageNotFound'
import SkeletonPage from 'components/layout/SkeletonPage'
import LoginPageWithState from 'components/pages/login/LoginPageWithState'
import AuthenticatingPageWithState from 'components/pages/authenticating/AuthenticatingPageWithState'
import RegisterModalLoading from 'components/pages/register/RegisterModalLoading'
import usePermissions from 'components/hooks/usePermissions'
import AuthorizeMicrosoftPage from 'components/pages/authorize-microsoft/AuthorizeMicrosoftPage'
import AdminOnboardingModalWithState from 'components/pages/onboarding/admin-onboarding/AdminOnboardingModalWithState'
import UserOnboardingModalWithState from 'components/pages/onboarding/user-onboarding/UserOnboardingModalWithState'
import SubscriptionSuspendedPage from 'components/pages/subscription-suspended/SubscriptionSuspendedPage'
import AccountDeactivitedPage from 'components/pages/account-deactivated/AccountDeactivitedPage'
import TenantSelectionModalWithState from './pages/tenant-selection/TenantSelectionModalWithState'
import TeamleaderAdminRedirectPage from './pages/settings-external-applications/TeamleaderAdminRedirectPage'
import TeamleaderUserRedirectPage from './pages/settings-external-applications/TeamleaderUserRedirectPage'


const CostsPageWithState = lazy(() =>
    import('components/pages/costs/CostsPageWithState'),
)
const ProfilePageWithState = lazy(() =>
    import('components/pages/profile/ProfilePageWithState'),
)
const ProjectsPageWithState = lazy(() =>
    import('components/pages/projects/ProjectsPageWithState'),
)
const SettingsPageWithState = lazy(() =>
    import('components/pages/settings/SettingsPageWithState'),
)
const ContactsPageWithState = lazy(() =>
    import('components/pages/contacts/ContactsPageWithState'),
)
const ActivitiesPageWithState = lazy(() =>
    import('components/pages/activities/ActivitiesPageWithState'),
)
const ProjectDetailsPageWithState = lazy(() =>
    import('components/pages/project-details/ProjectDetailsPageWithState'),
)
const SettingsThemePageWithState = lazy(() =>
    import('components/pages/settings-theme/SettingsThemePageWithState'),
)
const ContactDetailsPageWithState = lazy(() =>
    import('components/pages/contact-details/ContactDetailsPageWithState'),
)
const SettingsGeneralPageWithState = lazy(() =>
    import('components/pages/settings-general/SettingsGeneralPageWithState'),
)
const SettingsCoworkersPageWithState = lazy(() =>
    import(
        'components/pages/settings-coworkers/SettingsCoworkersPageWithState'
    ),
)
const SettingsProjectTagsPageWithState = lazy(() =>
    import(
        'components/pages/settings-project-tags/SettingsProjectTagsPageWithState'
    ),
)
const SettingsPartnerTagsPageWithState = lazy(() =>
    import(
        'components/pages/settings-partner-tags/SettingsPartnerTagsPageWithState'
    ),
)
const SettingsProjectNumberingPageWithState = lazy(() =>
    import(
        'components/pages/settings-project-numbering/SettingsProjectNumberingPageWithState'
    ),
)
const SettingsProjectPartnerLinkRolesPageWithState = lazy(() =>
    import(
        'components/pages/settings-project-partner-link-roles/SettingsProjectPartnerLinkRolesPageWithState'
    ),
)
const SettingsPartnerLinkRolesPageWithState = lazy(() =>
    import(
        'components/pages/settings-partner-link-roles/SettingsPartnerLinkRolesPageWithState'
    ),
)
const SettingsPartnerInfoTypesPagewithState = lazy(() =>
    import(
        'components/pages/settings-partner-info-types/SettingsPartnerInfoTypesPagewithState'
    ),
)
const SettingsPartnerProfessionsPageWithState = lazy(() =>
    import(
        'components/pages/settings-partner-professions/SettingsPartnerProfessionsPageWithState'
    ),
)
const SettingsActivityCategoriesPageWithState = lazy(() =>
    import(
        'components/pages/settings-activity-categories/SettingsActivityCategoriesPageWithState'
    ),
)

const SettingsO365PageWithState = lazy(() =>
    import('components/pages/settingsO365/SettingsO365PageWithState'),
)
const SettingsCostCategoriesPageWithState = lazy(() =>
    import(
        'components/pages/settings-cost-categories/SettingsCostCategoriesPageWithState'
    ),
)
const SettingsSubscriptionPageWithState = lazy(() =>
    import(
        'components/pages/settings-subscription/SettingsSubscriptionPageWithState'
    ),
)


const DashboardPageWithState = lazy(() =>
    import('components/pages/dashboard/DashboardPageWithState'),
)
const SupportPageWithState = lazy(() =>
    import('./pages/support/SupportPageWithState'),
)
const SettingsProjectTemplatesPageWithState = lazy(() =>
    import(
        './pages/settings-project-templates/SettingsProjectTemplatesPageWithState'
    ),
)
const SettingsProjectFieldsPageWithState = lazy(() =>
    import(
        './pages/settings-project-fields/SettingsProjectFieldsPageWithState'
    ),
)
const SettingsProjectContactFieldsPageWithState = lazy(() =>
    import(
        './pages/settings-project-contact-fields/SettingsProjectContactFieldsPageWithState'
    ),
)
const SettingsContactFieldsPageWithState = lazy(() =>
    import(
        './pages/settings-contact-fields/SettingsContactFieldsPageWithState'
    ),
)
const SettingsExternalApplicationsPageWithState = lazy(() =>
    import(
        './pages/settings-external-applications/SettingsExternalApplicationsPageWithState'
    ),
)


const TimesheetEntriesPageWithState = lazy(() =>
    import('./pages/timesheet-entries/TimesheetEntriesPageWithState'),
)
const TimesheetEntriesListPageWithState = lazy(() =>
    import('./pages/timesheet-entries-list/TimesheetEntriesListPageWithState'),
)
const SettingsTimesheetEntriesLockPeriodsPageWithState = lazy(() =>
    import(
        './pages/settings-timesheet-entries-lock/SettingsTimesheetEntriesLockPeriodsPageWithState'
    ),
)


const AppRouter = ({
    isAuthenticated,
    isAuthorized,
    isNew,
    isLoading,
    isOnboarding,
    isInvited,
    isSuspended,
    accountState,
    accountStateDetails,
}) => {
    const { isAdmin } = usePermissions()

    if (!isAuthenticated) {
        return (
            <Routes>
                <Route path="*" element={<LoginPageWithState />} />
                <Route path="/login" element={<LoginPageWithState />} />
                <Route
                    path="/authenticating"
                    element={<AuthenticatingPageWithState />}
                />
            </Routes>
        )
    }

    if (isNew || isLoading) {
        return (
            <Routes>
                <Route
                    path="/register/loading"
                    element={<RegisterModalLoading />}
                />
                <Route path="*" element={<RegisterModalLoading />} />
            </Routes>
        )
    }

    if (!isAuthorized) {
        return (
            <Routes>
                <Route
                    path="*"
                    element={<TenantSelectionModalWithState skipDataFetch />}
                />
            </Routes>
        )
    }

    if (isOnboarding) {
        return (
            <Routes>
                <Route
                    path="/authorize-microsoft/admin-onboarding"
                    element={
                        <AuthorizeMicrosoftPage accountState={accountState} />
                    }
                />
                <Route
                    path="/admin-onboarding/:step"
                    element={
                        <AdminOnboardingModalWithState />
                    }
                />
            </Routes>
        )
    }

    if (isInvited) {
        return (
            <Routes>
                <Route
                    path="/authorize-microsoft/user-onboarding"
                    element={
                        <AuthorizeMicrosoftPage accountState={accountState} />
                    }
                />
                <Route
                    path="/user-onboarding/:step"
                    element={<UserOnboardingModalWithState />}
                />
            </Routes>
        )
    }

    if (isSuspended) {
        if (accountStateDetails === ACCOUNT_STATE_DETAILS_DEACTIVATED) {
            return (
                <Routes>
                    <Route
                        path="/account-deactivated"
                        element={<AccountDeactivitedPage />}
                    />
                    <Route path="*" element={<AccountDeactivitedPage />} />
                </Routes>
            )
        }

        if (accountStateDetails === ACCOUNT_STATE_DETAILS_UNPAID) {
            if (isAdmin) {
                return (
                    <Routes>
                        <Route
                            path="*"
                            element={<SubscriptionSuspendedPage />}
                        />
                        <Route
                            path="/subscription-suspended"
                            element={<SubscriptionSuspendedPage />}
                        />
                        <Route
                            path="/settings"
                        element={
                            <Suspense fallback={<SkeletonPage />}>
                                <SettingsPageWithState />
                            </Suspense>
                        }
                        />
                        <Route
                            path="/settings/general"
                            element={
                                <Suspense fallback={<SkeletonPage />}>
                                    <SettingsGeneralPageWithState />
                                </Suspense>
                            }
                        />
                        <Route
                            path="/settings/subscription"
                            element={
                                <Suspense fallback={<SkeletonPage />}>
                                    <SettingsSubscriptionPageWithState />
                                </Suspense>
                            }
                        />
                    </Routes>
                )
            }
            return (
                <Routes>
                    <Route path="*" element={<SubscriptionSuspendedPage />} />
                    <Route
                        path="/subscription-suspended"
                        element={<SubscriptionSuspendedPage />}
                    />
                </Routes>
            )
        }
    }

    return (
        <Routes>
            <Route path="*" element={<PageNotFound />} />
            <Route
                path="/"
                element={
                    <Suspense fallback={<SkeletonPage />}>
                        <TimesheetEntriesPageWithState />
                    </Suspense>
                }
            />
            <Route
                path="/activities"
                element={
                    <Suspense fallback={<SkeletonPage />}>
                        <ActivitiesPageWithState />
                    </Suspense>
                }
            />
            <Route
                path="/authorize-microsoft"
                element={<AuthorizeMicrosoftPage accountState={accountState} />}
            />
            <Route
                path="/contacts"
                element={
                    <Suspense fallback={<SkeletonPage />}>
                        <ContactsPageWithState />
                    </Suspense>
                }
            />
            <Route
                path="/contacts/:partnerId"
                element={
                    <Suspense fallback={<SkeletonPage />}>
                        <ContactDetailsPageWithState />
                    </Suspense>
                }
            />
            <Route
                path="/costs"
                element={
                    <Suspense fallback={<SkeletonPage />}>
                        <CostsPageWithState />
                    </Suspense>
                }
            />
            <Route
                path="/dashboard"
                element={
                    <Suspense fallback={<SkeletonPage />}>
                        <DashboardPageWithState />
                    </Suspense>
                }
            />
            <Route
                path="/profile"
                element={
                    <Suspense fallback={<SkeletonPage withSideBar/>}>
                        <ProfilePageWithState />
                    </Suspense>
                }
            />
            <Route
                path="/projects"
                element={
                    <Suspense fallback={<SkeletonPage />}>
                        <ProjectsPageWithState />
                    </Suspense>
                }
            />
            <Route
                path="/projects/:projectId/*"
                element={
                    <Suspense fallback={<SkeletonPage />}>
                        <ProjectDetailsPageWithState />
                    </Suspense>
                }
            />
            <Route
                path="/settings/external-applications"
                element={
                    <Suspense fallback={<SkeletonPage />}>
                        <SettingsExternalApplicationsPageWithState />
                    </Suspense>
                }
            />
            <Route
                path="/settings/general"
                element={
                    <Suspense fallback={<SkeletonPage />}>
                        <SettingsGeneralPageWithState />
                    </Suspense>
                }
            />
            <Route
                path="/settings"
                element={
                    <Suspense fallback={<SkeletonPage />}>
                        <SettingsPageWithState />
                    </Suspense>
                }
            />
            <Route
                path="/settings/activity-categories"
                element={
                    <Suspense fallback={<SkeletonPage />}>
                        <SettingsActivityCategoriesPageWithState />
                    </Suspense>
                }
            />
            <Route
                path="/settings/contact-address-types"
                element={
                    <Suspense fallback={<SkeletonPage />}>
                        <SettingsPartnerInfoTypesPagewithState />
                    </Suspense>
                }
            />
            <Route
                path="/settings/contact-fields"
                element={
                    <Suspense fallback={<SkeletonPage />}>
                        <SettingsContactFieldsPageWithState />
                    </Suspense>
                }
            />
            <Route
                path="/settings/contact-professions"
                element={
                    <Suspense fallback={<SkeletonPage />}>
                        <SettingsPartnerProfessionsPageWithState />
                    </Suspense>
                }
            />
            <Route
                path="/settings/contact-relationships"
                element={
                    <Suspense fallback={<SkeletonPage />}>
                        <SettingsPartnerLinkRolesPageWithState />
                    </Suspense>
                }
            />
            <Route
                path="/settings/contact-roles"
                element={
                    <Suspense fallback={<SkeletonPage />}>
                        <SettingsProjectPartnerLinkRolesPageWithState />
                    </Suspense>
                }
            />
            <Route
                path="/settings/contact-tags"
                element={
                    <Suspense fallback={<SkeletonPage />}>
                        <SettingsPartnerTagsPageWithState />
                    </Suspense>
                }
            />
            <Route
                path="/settings/cost-categories"
                element={
                    <Suspense fallback={<SkeletonPage />}>
                        <SettingsCostCategoriesPageWithState />
                    </Suspense>
                }
            />
            <Route
                path="/settings/coworkers"
                element={
                    <Suspense fallback={<SkeletonPage />}>
                        <SettingsCoworkersPageWithState />
                    </Suspense>
                }
            />
            <Route
                path="/settings/external-applications/teamleader-admin-redirect"
                element={<TeamleaderAdminRedirectPage />}/>
            <Route
                path="/settings/external-applications/teamleader-user-redirect"
                element={<TeamleaderUserRedirectPage />}
            />
            <Route
                path="/settings/O365"
                element={
                    <Suspense fallback={<SkeletonPage />}>
                        <SettingsO365PageWithState />
                    </Suspense>
                }
            />
            <Route
                path="/settings/project-contact-fields"
                element={
                    <Suspense fallback={<SkeletonPage />}>
                        <SettingsProjectContactFieldsPageWithState />
                    </Suspense>
                }
            />
            <Route
                path="/settings/project-fields"
                element={
                    <Suspense fallback={<SkeletonPage />}>
                        <SettingsProjectFieldsPageWithState />
                    </Suspense>
                }
            />
            <Route
                path="/settings/project-numbering"
                element={
                    <Suspense fallback={<SkeletonPage />}>
                        <SettingsProjectNumberingPageWithState />
                    </Suspense>
                }
            />
            <Route
                path="/settings/project-tags"
                element={
                    <Suspense fallback={<SkeletonPage />}>
                        <SettingsProjectTagsPageWithState />
                    </Suspense>
                }
            />
            <Route
                path="/settings/project-templates"
                element={
                    <Suspense fallback={<SkeletonPage />}>
                        <SettingsProjectTemplatesPageWithState />
                    </Suspense>
                }
            />
            <Route
                path="/settings/subscription"
                element={
                    <Suspense fallback={<SkeletonPage />}>
                        <SettingsSubscriptionPageWithState />
                    </Suspense>
                }
            />
            <Route
                path="/settings/theme"
                element={
                    <Suspense fallback={<SkeletonPage />}>
                        <SettingsThemePageWithState />
                    </Suspense>
                }
            />
            <Route
                path="/settings/timesheet-entries-lock"
                element={
                    <Suspense fallback={<SkeletonPage />}>
                        <SettingsTimesheetEntriesLockPeriodsPageWithState />
                    </Suspense>
                }
            />
            <Route
                path="/support"
                element={
                    <Suspense fallback={<SkeletonPage />}>
                        <SupportPageWithState />
                    </Suspense>
                }
            />
            <Route
                path="/timesheet-entries-list"
                element={
                    <Suspense fallback={<SkeletonPage />}>
                        <TimesheetEntriesListPageWithState />
                    </Suspense>
                }
            />
            <Route
                path="/timesheet-entries"
                element={
                    <Suspense fallback={<SkeletonPage />}>
                        <TimesheetEntriesPageWithState />
                    </Suspense>
                }
            />
            <Route
                path="/timesheet-entries/:date"
                element={
                    <Suspense fallback={<SkeletonPage />}>
                        <TimesheetEntriesPageWithState />
                    </Suspense>
                }
            />
        </Routes>
    )
}

export default AppRouter
