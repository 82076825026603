/* eslint-disable react/no-unstable-nested-components */
import React, {
    forwardRef,
    useEffect,
    useState,
    useRef,
    useLayoutEffect,
} from 'react'
import clsx from 'clsx'
import Icon from './Icon'
import DropdownMenu from './DropdownMenu'
import Checkbox from './Checkbox'

const Select = forwardRef(
    (
        {
            id,
            disabled,
            hasError,
            className,
            dropdownClassName,
            content,
            children,
            ...props
        },
        ref,
    ) => {
        const [width, setWidth] = useState(null)
        const selectRef = useRef(null)
        useLayoutEffect(() => {
            if (selectRef.current) {
                setWidth(selectRef.current.offsetWidth)
            }
        }, [])
        return (
            <DropdownMenu
                as="div"
                id={id}
                ref={ref}
                disabled={disabled}
                className={dropdownClassName}
                style={{ minWidth: `${width}px` }}
                content={content}
                tabIndex={-1}
                {...props}
            >
                <div
                    ref={selectRef}
                    className={clsx(
                        'relative mt-1 shadow-sm flex flex-wrap items-center gap-y-1 w-full rounded-lg border pl-2.5 pr-12 py-2 text-base min-h-[38px] placeholder-gray-400 hover:border-gray-400',
                        hasError
                            ? 'border-red-300 text-red-600'
                            : 'border-gray-300',
                        className,
                    )}
                >
                    {children}
                </div>
            </DropdownMenu>
        )
    },
)

Select.SearchInput = ({
    value,
    onClear,
    disabled,
    isClearable = true,
    iconName = 'search',
    className,
    ...props
}) => (
    <>
        <input
            type="text"
            className={clsx(
                'border-none text-base flex-auto w-full p-0 bg-transparent placeholder-gray-400 placeholder:italic',
                disabled ? 'cursor-not-allowed' : '',
                className,
            )}
            value={value}
            disabled={disabled}
            {...props}
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-4">
            {value && isClearable ? (
                <Icon
                    name="cross"
                    onClick={onClear}
                    className="w-5 h-5 text-gray-400 cursor-pointer"
                    aria-hidden="true"
                />
            ) : (
                <Icon
                    name={iconName}
                    className="w-5 h-5 text-gray-400"
                    aria-hidden="true"
                />
            )}
        </div>
    </>
)

Select.Input = ({ value, onClear, disabled, className, ...props }) => (
    <>
        <input
            type="text"
            className={clsx(
                'flex items-center border-none text-base flex-auto w-full p-0 bg-transparent placeholder-gray-400 placeholder:italic caret-transparent',
                disabled ? 'cursor-not-allowed' : 'cursor-default',
                className,
            )}
            value={value}
            disabled={disabled}
            onChange={() => {}}
            {...props}
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-4">
            <Icon
                name="chevronDown"
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
            />
        </div>
    </>
)

const Option = ({ label, onClick, isActive = false, className, ...props }) => {
    const ref = useRef(null)
    useEffect(() => {
        if (ref.current && isActive) {
            ref.current.scrollIntoView(false)
        }
    }, [isActive])
    return (
        <li
            ref={ref}
            role="option"
            aria-hidden="true"
            aria-selected={isActive}
            className={clsx(
                'p-3 bg-white text-black hover:bg-primary-200 aria-selected:bg-primary-200 list-none',
                className,
            )}
            onMouseDown={(e) => {
                e.preventDefault()
                e.stopPropagation()
                onClick()
            }}
            onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                onClick()
            }}
            {...props}
        >
            {label}
        </li>
    )
}

Select.Option = Option

Select.CheckboxOption = ({ label, ...props }) => (
    <li
        role="option"
        aria-hidden="true"
        className="p-3 bg-white text-gray-600 italic list-none"
        aria-selected={false}
    >
        <Checkbox {...props}>{label}</Checkbox>
    </li>
)

Select.Placeholder = ({ className, children }) => (
    <div className={clsx('p-3 w-full text-center', className)}>{children}</div>
)

export default Select
