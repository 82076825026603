import React from 'react'
import styled from 'styled-components'
import { useMatch, useNavigate } from 'react-router-dom'
import Icon from 'components/util/Icon'

const MenuItem = ({
    iconName,
    path,
    addWildcardToPath = true,
    children,
    isSubMenu,
}) => {
    const navigate = useNavigate()
    const match = useMatch(addWildcardToPath ? `${path}/*` : path)
    return (
        <MenuItemContainer
            isActive={match}
            isSubMenu={isSubMenu}
            onClick={() => navigate(path)}
        >
            {iconName && <StyledIcon name={iconName} size="18" />}
            {children}
        </MenuItemContainer>
    )
}

const MenuItemContainer = styled.li(
    ({ theme, isActive, isSubMenu, disabled }) => `
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    border-color: transparent;
    border-left-width: .3rem; 
    font-size: ${theme.fontSizeSmall}rem;
    line-height: ${theme.spacingSmall}rem;
    color: ${theme.colorGreyDarker};
    padding: ${theme.spacingSmall}rem ${theme.spacingSmall}rem;
    &:hover {
        background-color: ${
            isSubMenu ? theme.colorGreyLighter : theme.colorPrimaryLightest
        };
    }
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    ${
        isActive &&
        `
        border-color: ${isSubMenu ? theme.colorBlueDark : theme.colorPrimary};
        background: ${
            isSubMenu ? theme.colorGreyLightest : theme.colorPrimaryLightest
        };`
    }
        font-weight: ${theme.fontWeightSemiBold};
`,
)

const StyledIcon = styled(Icon)(
    ({ theme }) => `
    color: ${theme.colorPrimary};
    margin-bottom: ${theme.spacingSmaller}rem;
`,
)

export default MenuItem
