import React from 'react'
import { Trans } from '@lingui/macro'
import OnboardingModal from 'components/pages/onboarding/OnboardingModal'

const AdminOnboardingModalWelcome = ({ ...props }) => (
    <OnboardingModal
        title={<Trans>Welcome</Trans>}
        subTitle={
            <>
                <Trans>We have succesfully setup a new company for you</Trans>
                <br />
                <Trans>
                    Before we get started, we need to set a few general settings
                </Trans>
            </>
        }
        {...props}
    />
)

export default AdminOnboardingModalWelcome
