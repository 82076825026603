import React, { forwardRef } from 'react'
import Input from 'components/util/Input'
import { parseValidTimeString } from 'util/dates'

const TimeInput = forwardRef(({ onChange, ...props }, ref) => (
    <Input
        ref={ref}
        onChange={(e) => {
            e.target.value = parseValidTimeString(e.target.value)
            onChange(e)
        }}
        maskChar="0"
        mask="99:99"
        placeholder="00:00"
        disableUnderline
        {...props}
    />
))

export default TimeInput
