export const home = () => '/timesheet-entries'

export const login = () => '/login'

export const authenticating = () => '/authenticating'

export const dashboard = () => '/dashboard'

export const projects = () => '/projects'

export const projectDetails = (id = ':projectId', detail = ':detail') =>
    `/projects/${id}/${detail}`

export const projectDetailsBilling = (id = ':projectId', detail = ':detail') =>
    `${projectDetails(id, 'billing')}/${detail}`

export const billing = () => '/billing'

export const contacts = () => '/contacts'

export const costs = () => '/costs'

export const overview = () => '/overview'

export const projectLinks = () => '/projectLinks'

export const contactDetails = (id = ':partnerId') => `${contacts()}/${id}`

export const projectInvolvedContactDetails = (projectId, partnerId) =>
    `${projectDetails(projectId, 'contacts')}/${partnerId}`

export const documents = () => '/documents'

export const agenda = () => '/agenda'

export const billingUnbilled = () => `${billing()}/unbilled`

export const billingConfirmed = () => `${billing()}/confirmed`

export const billingBilled = () => `${billing()}/billed`

export const invoices = () => '/invoices'

export const profile = () => '/profile'

export const addO365Account = (step = ':step') => `/profile/add-account/${step}`

export const activities = () => '/activities'

export const settings = () => '/settings'

export const settingsCoworkers = () => `${settings()}/coworkers`

export const settingsTheme = () => `${settings()}/theme`

export const settingsGeneral = () => `${settings()}/general`

export const settingsO365 = () => `${settings()}/O365`

export const settingsProjectNumbering = () => `${settings()}/project-numbering`

export const settingsProjectTags = () => `${settings()}/project-tags`

export const settingsPartnerTags = () => `${settings()}/partner-tags`

export const settingsPartnerProfessions = () =>
    `${settings()}/partner-professions`

export const settingsTypesDefaults = () => `${settings()}/types-defaults`

export const settingsProjectPartnerLinkRoles = () =>
    `${settingsTypesDefaults()}/project-partner-link-roles`

export const settingsProcedures = () => `${settingsTypesDefaults()}/procedures`

export const settingsPartnerLinkRoles = () =>
    `${settingsTypesDefaults()}/partner-link-roles`

export const settingsActivityCategories = () =>
    `${settingsTypesDefaults()}/activity-categories`

export const settingsCostCategories = () =>
    `${settingsTypesDefaults()}/cost-categories`

export const settingsCustomFields = () => `${settings()}/custom-fields`

export const settingsSubscription = () => `${settings()}/subscription`

export const settingsProjectTupes = () => `${settings()}/project-types`

export const register = () => '/register'

export const registerLoading = () => '/register/loading'

export const authorizeMicrosoft = () => 'authorize-microsoft'

export const adminOnboarding = (step = ':step') => `/admin-onboarding/${step}`

export const userOnboarding = (step = ':step') => `/user-onboarding/${step}`

