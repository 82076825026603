import React, { forwardRef } from 'react'
import styled, { useTheme } from 'styled-components'
import Icon, { getIconSize } from './Icon'

const CircledIcon = forwardRef(({ ...props }, ref) => {
    const theme = useTheme()
    const size = getIconSize({ ...props, theme })
    return (
        <CircleContainer size={size} {...props} ref={ref}>
            <Icon {...props} />
        </CircleContainer>
    )
})

const CircleContainer = styled.div(
    ({ theme, size, backgroundColor }) => `
    border-radius: 50%;
    width: ${size * 2.5}px;
    height: ${size * 2.5}px;
    background-color: ${backgroundColor || theme.colorPrimary};
    color: ${theme.colorWhite};
    display: flex;
    align-items: center;
    justify-content: center;
`,
)

export default CircledIcon
