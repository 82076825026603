import gql from 'graphql-tag'

const cancelTimerMutation = gql`
    mutation cancelTimer($timerId: Int!) {
        cancelTimer(timerId: $timerId)
    }
`
export default async function cancelTimer(apolloClient, id, options) {
    const mutation = cancelTimerMutation
    const variables = { timerId: id }
    const response = await apolloClient.mutate({
        mutation,
        variables,
        ...options,
    })
    return response.data.cancelTimer
}
