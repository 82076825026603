import dotProp from 'dot-prop-immutable'
import mime from 'mime'
import { roundTo } from 'round-to'
import { parse } from 'query-string'
import { differenceInMinutes, parseDate } from './dates'

export const delay = (time, value) =>
    new Promise((resolve) => {
        setTimeout(resolve, time, value)
    })

export const parseQueryString = (search) => parse(search)

export const arrayToObject = (array, keyField) =>
    array.reduce((obj, item) => {
        const newObj = obj
        newObj[item[keyField]] = item
        return obj
    }, {})

export const capitalizeFirstLetter = (input) =>
    typeof input === 'string'
        ? input[0].toUpperCase() + input.substring(1)
        : input

export const clientFilterOptions = (input, array, key = 'translation') =>
    array.filter((i) => i[key].toLowerCase().includes(input.toLowerCase()))

export const removeEmptyValues = (field) =>
    field
        .map((value) => {
            if (Object.keys(value).some((key) => value[key] === null)) {
                return null
            }
            return value
        })
        .filter((value) => value !== null)

export const sum = (current, next) => current + next

export const countWhere = (key) => (current, obj) => current + obj[key]

export const flatten = (a, b) => [...a, ...b]

export const unique = (array) => [...new Set(array)]

export const hasIntersectionValues = (array1, array2) =>
    array1.some((value) => array2.indexOf(value) !== -1)

export const where =
    (...keys) =>
    (...values) =>
    (obj) =>
        keys.every((key) =>
            key.includes('!')
                ? !values.includes(obj[key.replace('!', '')])
                : values.includes(obj[key]),
        )

export const whereIsNot = (key) => (value) => (obj) => obj[key] !== value

export const whereId = where('id')

export const whereNotId = whereIsNot('id')

export const whereValue = where('value')

export const whereName = where('name')

export const whereSlug = where('slug')

export const whereUnique = (key) => (currentObject, currentIndex, array) => {
    const value = dotProp.get(currentObject, key)
    if (typeof value === 'undefined') {
        return true
    }
    const foundIndex = array.findIndex(
        (object) => dotProp.get(object, key) === value,
    )
    return foundIndex === currentIndex
}

export const removeFromCollection = (id) => (collection) =>
    collection.filter(whereNotId(id))

export const addToCollection = (obj) => (collection) => [...collection, obj]

export const replaceInCollection = (obj) => (collection) =>
    collection.map((i) => (i.id === obj.id ? obj : i))

export const alterInCollection = (obj) => (collection) =>
    collection.map((i) => (i.id === obj.id ? { ...i, ...obj } : i))

export const sortBy = (key) => (a, b) => {
    if (a === null || b === null) {
        return -1
    }
    const aComp = a[key]
    const bComp = b[key]
    if (aComp < bComp) {
        return -1
    }
    if (aComp > bComp) {
        return 1
    }
    return 0
}

export const download = async (url, name) => {
    const a = document.createElement('a')
    a.download = name
    a.href = url
    a.style.display = 'none'
    document.body.append(a)
    a.click()
    // Chrome requires the timeout
    await delay(100)
    a.remove()
}

export const downloadFromResponse = async (response) => {
    if (response.ok) {
        const blob = await response.blob()
        const url = window.URL.createObjectURL(blob)
        const fileExtension = mime.getExtension(blob.type)
        let filename = `download-${new Date().toJSON()}.${fileExtension}`
        const headers = response.headers.get('content-disposition')
        if (headers !== null) {
            const regEx = 'filename\\*?=(([\'\\"])[\\s\\S]*?\\2|[^;\\n]*)'
            // eslint-disable-next-line prefer-destructuring
            filename = headers.match(regEx)[1]
        }
        const a = document.createElement('a')
        a.href = url
        a.download = filename
        document.body.appendChild(a)
        a.click()
        a.remove()
    }
}

export const downloadMultipleFiles = async (files) => {
    const downloadPromises = files.map(async ({ url, name }, index) => {
        await delay(index * 1000)
        download(url, name)
    })
    await Promise.all(downloadPromises)
}

export const validateEmail = (email) => {
    const regEx =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regEx.test(email)
}

export const toBoolean = (input) =>
    typeof input === 'boolean' ? input : input.toLowerCase() === 'true'

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
export const range = (from, to, step = 1) => {
    let i = from
    const rangeArray = []

    while (i <= to) {
        rangeArray.push(i)
        i += step
    }

    return rangeArray
}

export const arrayMove = (array, from, to) => {
    const newArray = [...array]
    const startIndex = to < 0 ? newArray.length + to : to

    if (startIndex >= 0 && startIndex < newArray.length) {
        const item = newArray.splice(from, 1)[0]
        newArray.splice(startIndex, 0, item)
    }

    return newArray
}

export const arraysAreIdentical = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false
    for (let i = 0, len = arr1.length; i < len; i += 1) {
        if (arr1[i] !== arr2[i]) {
            return false
        }
    }
    return true
}

export const arraysContainSameValues = (arr1, arr2) => {
    if (
        arr1.length === arr2.length &&
        arr1.every((val) => arr2.includes(val))
    ) {
        return true
    }
    return false
}

export const roundNumber = (value, digits = 0) => roundTo(value, digits)

export const truncateString = (value, length) => {
    if (value === null || value.length <= length) {
        return value
    }
    return `${value.substring(0, length)}`
}

export const asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index += 1) {
        // eslint-disable-next-line no-await-in-loop
        await callback(array[index], index, array)
    }
}

export const groupBy = (items, key) =>
    items.reduce(
        (result, item) => ({
            ...result,
            [item[key]]: [...(result[item[key]] || []), item],
        }),
        {},
    )

export const getTotalDurationWithoutOverlap = (activitiesArr) => {
    if (activitiesArr.length === 0) return 0
    const sortedActivities = [...activitiesArr].sort(sortBy('start'))
    const mergedActivities = sortedActivities.reduce((acc, event) => {
        if (!acc.length) {
            acc.push({ ...event })
        } else if (
            event.start <= acc[acc.length - 1].end &&
            event.end <= acc[acc.length - 1].end
        ) {
            return acc
        } else if (
            event.start < acc[acc.length - 1].end &&
            event.end > acc[acc.length - 1].end
        ) {
            acc[acc.length - 1] = {
                ...acc[acc.length - 1],
                end: event.end,
                durationInMinutes: differenceInMinutes(
                    parseDate(event.end),
                    parseDate(acc[acc.length - 1].start),
                ),
            }
        } else {
            acc.push({ ...event })
        }
        return acc
    }, [])
    return mergedActivities.reduce(countWhere('durationInMinutes'), 0)
}

export const combineRefs =
    (refs = []) =>
    (el) => {
        refs.forEach((ref) => {
            if (typeof ref === 'function') {
                ref(el)
            } else if (ref != null) {
                // eslint-disable-next-line no-param-reassign
                ref.current = el
            }
        })
    }

export const invertColor = (hex, bw) => {
    const padZero = (str, len) => {
        const result = len || 2
        const zeros = new Array(result).join('0')
        return (zeros + str).slice(-result)
    }
    let hexResult = hex
    if (hexResult.indexOf('#') === 0) {
        hexResult = hex.slice(1)
    }
    // convert 3-digit hex to 6-digits.
    if (hexResult.length === 3) {
        hexResult = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
    }
    if (hexResult.length !== 6) {
        throw new Error('Invalid HEX color.')
    }
    let r = parseInt(hexResult.slice(0, 2), 16)
    let g = parseInt(hexResult.slice(2, 4), 16)
    let b = parseInt(hexResult.slice(4, 6), 16)
    if (bw) {
        // https://stackoverflow.com/a/3943023/112731
        return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF'
    }
    // invert color components
    r = (255 - r).toString(16)
    g = (255 - g).toString(16)
    b = (255 - b).toString(16)
    // pad each with zeros and return
    return `#${padZero(r)}${padZero(g)}${padZero(b)}`
}

export const isSafari = () => {
    const chromeInAgent = navigator.userAgent.indexOf('Chrome') > -1
    const safariInAgent = navigator.userAgent.indexOf('Safari') > -1
    return safariInAgent && !chromeInAgent
}

export const variant = (prop, variants) => variants[prop]

// Debounce function implementation
export const debounce = (func, delay) => {
    let timer
    return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => func.apply(this, args), delay)
    }
}

// Calcule round duration in Munute

export function roundDuration(duration, rounded) {
    // Ensure both parameters are positive numbers
    if (
        typeof duration !== 'number' ||
        typeof rounded !== 'number' ||
        duration < 0 ||
        rounded <= 0
    ) {
        return 'Invalid input. Please provide positive numbers.'
    }

    // Calculate the closest multiple greater than or equal to duration
    const closestMultipleValue = Math.ceil(duration / rounded) * rounded

    // Calculate the minutes added to duration
    const minutesAdded = closestMultipleValue - duration

    // Return an object with both values
    return {
        closestMultipleValue,
        minutesAdded,
    }
}
