import React, { useReducer, useMemo } from 'react'

export const PopoverStateContext = React.createContext()

export const OPEN_POPOVER = 'OPEN_POPOVER'
export const CLOSE_POPOVER = 'CLOSE_POPOVER'

const reducer = (state, action) => {
    const { type } = action
    switch (type) {
        case OPEN_POPOVER: {
            const { id } = action
            const popovers = [...state.popovers]
            const index = popovers.findIndex((v) => v === id)
            if (index === -1) {
                popovers.push(id)
            }
            return { popovers }
        }
        case CLOSE_POPOVER: {
            const { id } = action
            const popovers = [...state.popovers]
            const index = popovers.findIndex((v) => v === id)
            popovers.splice(index, 1)
            return { popovers }
        }
        default: {
            throw new Error(`Unsupported action type: ${action.type}`)
        }
    }
}

const PopoverStateProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, {
        popovers: [],
    })
    const value = useMemo(() => ({ state, dispatch }), [state])
    return <PopoverStateContext.Provider value={value} {...props} />
}

export default PopoverStateProvider
