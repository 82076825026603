import { useReducer, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import gql from 'graphql-tag'
import dotProp from 'dot-prop-immutable'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { useCacheAndNetworkQuery } from 'components/hooks/useApolloQueries'
import { useUpdateAppPreferencesMutation } from 'api/updateAppPreferences'
import { createQueryUpdater } from 'util/graphql'
import useTracking from './useTracking'

const PARTNER_APP_SETTINGS_QUERY = gql`
    query partnerAppSettings {
        me {
            id
            partner {
                id
                appSettings
            }
        }
    }
`

// JSON as stored in database
// const data = {
//     calendarPreferences: {},
//     tabelPrefences: {
//         activities: [{
//             name: 'date',
//             sequence: 3,
//         }, {
//             name: 'duration',
//             sequence: 2,
//         }, {
//             name: 'type',
//             sequence: 4,
//         }, {
//             name: 'project',
//             sequence: 1,
//         }],
//         projects: [{
//             name: 'number',
//             sequence: 6,
//         }, {
//             name: 'title',
//             sequence: 2,
//         }, {
//             name: 'dominusLitis',
//             sequence: 3,
//         }, {
//             name: 'mostRecentActivity',
//             sequence: 4,
//         }, {
//             name: 'customField9',
//             sequence: 5,
//         }, {
//             name: 'customField10',
//             sequence: 1,
//         }],
//     },
// }

const UPDATE_VALUES = 'UPDATE_VALUES'

const reducer = (state, action) => {
    const { type } = action
    switch (type) {
        case UPDATE_VALUES: {
            const { values, path } = action
            return dotProp.set(state, path, values)
        }
        default:
            throw new Error(`Unknown action '${action.type}'.`)
    }
}

const usePartnerAppPreferences = () => {
    const { trackError } = useTracking()
    const apolloClient = useApolloClient()
    const [isFirstRender, setIsFirstRender] = useState(true)
    const [updateAppPreferences] = useUpdateAppPreferencesMutation()

    const { data } = useCacheAndNetworkQuery(PARTNER_APP_SETTINGS_QUERY)

    const partnerAppSettingsQueryUpdater = {
        replace: createQueryUpdater(apolloClient, PARTNER_APP_SETTINGS_QUERY),
    }

    let appSettings
    try {
        appSettings = JSON.parse(data.me.partner.appSettings)
    } catch {
        appSettings = null
    }

    if (appSettings === null) {
        appSettings = {}
    }

    const [state, dispatch] = useReducer(reducer, appSettings)

    const handleUpdateAppPreferences = async (body) => {
        try {
            const input = JSON.stringify(body)
            const variables = { input }
            await updateAppPreferences({
                variables,
                update: (proxy, { data: result }) => {
                    const { updateAppSettings } = result
                    const path = 'me.partner.appSettings'
                    partnerAppSettingsQueryUpdater.replace(
                        path,
                        updateAppSettings,
                    )
                },
            })
        } catch (error) {
            // Silently fail
            trackError(error)
        }
    }

    useDeepCompareEffect(() => {
        if (isFirstRender) {
            setIsFirstRender(false)
        } else {
            handleUpdateAppPreferences(state)
        }
    }, [state])

    const handleUpdateAppPreferencesByPath = (values, path) => {
        dispatch({
            type: UPDATE_VALUES,
            path,
            values,
        })
    }

    return {
        ...state,
        handleUpdateAppPreferencesByPath,
    }
}

export default usePartnerAppPreferences
