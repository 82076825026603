import React, { Component } from 'react'
import { getAppInsights } from 'services/applicationInsights'
import ErrorPage from 'components/pages/error/ErrorPage'

class AppErrorBoundary extends Component {
    constructor(props) {
        super(props)
        const environment = process.env.NODE_ENV
        this.isLocal = environment === 'local'
        this.isTest = environment === 'test'
        this.isDev = environment === 'development'
        this.state = {}
    }

    static getDerivedStateFromError() {
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        if (this.isLocal || this.isTest) {
            // eslint-disable-next-line no-console
            console.log(error, errorInfo)
        } else {
            const appInsights = getAppInsights()
            if (this.isDev) {
                // eslint-disable-next-line no-console
                console.log(error, errorInfo)
            }
            appInsights.trackException({
                exception: error,
                properties: {
                    ...errorInfo,
                },
            })
        }
    }

    render() {
        const { hasError } = this.state
        if (hasError) {
            return <ErrorPage />
        }
        const { children } = this.props
        return children
    }
}

export default AppErrorBoundary
