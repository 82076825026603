import React, { useMemo, useReducer } from 'react'

export const TableStateContext = React.createContext()

export const UPDATE_SORTING = 'UPDATE_SORTING'

const INITIAL_STATE = {
    activeSortName: null,
    activeSortDirection: null,
}

const getInitialState = (sorting) => {
    if (sorting) {
        const [activeSortName] = Object.keys(sorting)
        const activeSortDirection = sorting[activeSortName].direction
        return {
            activeSortName,
            activeSortDirection,
        }
    }
    return INITIAL_STATE
}

const reducer = (state, action) => {
    const { type, values } = action
    switch (type) {
        case UPDATE_SORTING: {
            const { activeSortName, activeSortDirection } = values
            return {
                ...state,
                activeSortName,
                activeSortDirection,
            }
        }
        default: {
            throw new Error(`Unsupported action type: ${action.type}`)
        }
    }
}

const TableStateProvider = (props) => {
    const { onSort, initialSorting } = props
    const initialState = getInitialState(initialSorting)
    const [state, dispatch] = useReducer(reducer, initialState)
    const value = useMemo(() => ({ state, dispatch, onSort }), [state, onSort])
    return <TableStateContext.Provider value={value} {...props} />
}

export default TableStateProvider
