import useAppState from 'components/hooks/useAppState'
import { nl, fr, enGB, es } from 'date-fns/locale'

export default function useDateFnsLocale() {
    const { preferredCultureCode } = useAppState()
    switch (preferredCultureCode) {
        case 'nl':
            return nl
        case 'fr':
            return fr
        case 'es':
            return es
        case 'en':
        default:
            return enGB
    }
}
