
import { i18n } from '../../providers/LanguageProvider'

export const  findFirstExpiredUser = (statuses) => {
  let userNeedsAttention = null
    statuses.forEach((item) => {
      if (
        item.o365MailsAndCalendarsLinkExpirationStatus === 'MISSING' || item.o365DrivesLinkExpirationStatus === 'MISSING'
      ) {
          
        userNeedsAttention = {item, isMissing : true,  isExpired: false, isExpiring : false}
      }
        if (
          item.o365MailsAndCalendarsLinkExpirationStatus === 'EXPIRED' ||
          item.o365DrivesLinkExpirationStatus === 'EXPIRED'
        ) {
          if(userNeedsAttention) {
            userNeedsAttention = {...userNeedsAttention, isExpired : true}
          } else {
            userNeedsAttention = {item, isMissing : false,  isExpired: true, isExpiring : false}
          }
        }
        if (
          item.o365MailsAndCalendarsLinkExpirationStatus === 'EXPIRING' ||
          item.o365DrivesLinkExpirationStatus === 'EXPIRING'
        ) {
          if(userNeedsAttention) {
            userNeedsAttention = {...userNeedsAttention, isExpiring : true}
          } else {
            userNeedsAttention = {item, isMissing : false,  isExpired: false, isExpiring : true}
          }
        }
      })
    return userNeedsAttention
  }

export const getExpirationStatus = (o365User) => {
    const { drivesExpirationStatus, mailsAndCalendarsExpirationStatus } = o365User

    const statusesToCheck = ['EXPIRED', 'EXPIRING', 'MISSING']

    return statusesToCheck.includes(drivesExpirationStatus) || statusesToCheck.includes(mailsAndCalendarsExpirationStatus)
}

export const accountToLinks = [
    {
        slug: 'drives',
        translation: i18n._(/* i18n */ 'Drives'),
    },
    {
        slug: 'mailCalender',
        translation: i18n._(/* i18n */ 'Mails and Calendars'),
    },
]



