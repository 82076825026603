import gql from 'graphql-tag'

const ACTIVE_TIMER_QUERY = gql`
    query activeTimersPaginated {
        activeTimersPaginated(pageSize: 50, currentPage: 1) {
            meta {
                currentPage
                totalCount
                pageSize
                lastPage
            }
            nodes {
                id
                description
                project {
                    id
                    title
                    number
                }
                status
                elapsedDurationInSeconds
                earliestStartDateTime
                latestStartDateTime
            }
        }
    }
`

export default async function getActiveTimers(apolloClient, options) {
    const query = ACTIVE_TIMER_QUERY
    const response = await apolloClient.query({
        query,
        ...options,
    })
    return response.data
}
