import React from 'react'
import { Trans } from '@lingui/react'
import styled from 'styled-components'
import OnboardingModal from 'components/pages/onboarding/OnboardingModal'
import PrimaryButton from 'components/util/PrimaryButton'
import Icon from 'components/util/Icon'
import { APP_NAME } from '../../../../util/constants'

const AdminOnboardingModalLinkO365 = ({
    linkTo,
    authorizReponse,
    onLinkOffice365Account,
    ...props
}) => 
    <OnboardingModal
        title={
            <Trans id="Welcome to the {APP_NAME} App" values={{ APP_NAME }} />
        }
        subTitle={
            <Trans
                id="To use {APP_NAME} at full strength, please connect your Office 365 account"
                values={{ APP_NAME }}
            />
        }
        {...props}
    >
        <Container>
            {!authorizReponse && linkTo ==="mails_calendars"  && <PrimaryButton
                    iconName="microsoft"
                    onClick={()=>onLinkOffice365Account(linkTo)}
                >
                    <Trans id='Track your meetings and mails' />
                </PrimaryButton>} 
            {authorizReponse && authorizReponse.o365LinkStatus === 'MAILS_LINK_SUCESS' && linkTo ==="mails_calendars"  && <Success>
                    <SuccesIcon name="check" isLarge />
                    <Trans id="Succesfully linked to Office 365!" />
                </Success>} 
            {authorizReponse && authorizReponse.o365LinkStatus === 'MAILS_LINK_FAILED' && linkTo ==="mails_calendars"  && <Success>
                    <FailedIcon  name="warning" accentColor="red" isLarge />
                    <Trans id="Failed linked to Office 365!" />
            </Success>} 
            {!authorizReponse && linkTo ==="drives"  && <PrimaryButton
                    iconName="microsoft"
                    onClick={()=>onLinkOffice365Account(linkTo)}
                >
                    <Trans id="Track your Word, Excel and PowerPoint" />
                </PrimaryButton>} 
            {authorizReponse && authorizReponse.o365LinkStatus === 'DRIVES_LINK_SUCESS' && linkTo ==="drives"  && <Success>
                    <SuccesIcon name="check" isLarge />
                    <Trans id="Succesfully linked to Office 365!" />
                </Success>} 
            {authorizReponse && authorizReponse.o365LinkStatus === 'DRIVES_LINK_FAILED' && linkTo ==="drives"  && <Success>
                    <FailedIcon  name="warning" accentColor="red" isLarge />
                    <Trans id="Failed linked to Office 365!" />
            </Success>} 
        </Container>
        <Disclaimer>
            { linkTo === 'drives' ? <Trans
                id="To automatically track the time you spend on editing Word, Excel or PowerPoint files that are stored in <0> OneDrive </0> or <0>SharePoint </0>, click the button below."
                components={[<strong />]}
                values={{ APP_NAME }}
            /> : <Trans
            id="<0>Give {APP_NAME}</0> permission to automatically track your time spent on sending mails and attending meetings by clicking the button below."
            components={[<strong />]}
            values={{ APP_NAME }}
        />  
            }
        </Disclaimer>
    </OnboardingModal>

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const Disclaimer = styled.div(
    ({ theme }) => `
    padding-top: ${theme.spacingLarger}rem;
    padding-bottom: ${theme.spacingSmall}rem;
`,
)

const Success = styled.div(
    ({ theme }) => `
    padding: ${theme.spacingSmall}rem;
    font-size: ${theme.fontSizeLarge}rem;
`,
)

const SuccesIcon = styled(Icon)(
    ({ theme }) => `
    stroke: ${theme.colorWhite};
    fill: ${theme.colorGreen};
    margin: ${theme.spacingSmall}rem;
`,
)

const FailedIcon = styled(Icon)(
    ({ theme }) => `
    stroke: ${theme.colorWhite};
    fill: ${theme.colorError};
    margin: ${theme.spacingSmall}rem;
`,
)

export default AdminOnboardingModalLinkO365
