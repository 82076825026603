import gql from 'graphql-tag'

const updateSubscriptionMutation = gql`
    mutation updateSubscription($input: UpdateSubscriptionInput!) {
        updateSubscription(input: $input) {
            status
        }
    }
`

export default async function updateSubscription(apolloClient, input, options) {
    const mutation = updateSubscriptionMutation
    const variables = { input }
    const response = await apolloClient.mutate({
        mutation,
        variables,
        ...options,
    })
    return response.data.updateSubscription
}
