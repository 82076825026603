import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import { useApolloClient } from '@apollo/client'
import createActivity from 'api/createActivity'
import useNotifications from 'components/hooks/useNotifications'
import CreateActivityModal from 'components/pages/activities-create/CreateActivityModal'
import useCreateActivityForm from 'components/pages/activities-create/useCreateActivityForm'
import usePermissions from 'components/hooks/usePermissions'
import ModalUnauthorized from 'components/layout/ModalUnauthorized'
import useTracking from 'components/hooks/useTracking'
import { formatTime, today } from 'util/dates'

const NOW = formatTime(Date.now())
const TODAY = today()

const CreateActivityModalWithState = ({
    end = NOW,
    start = NOW,
    date = TODAY,
    project = null,
    type = null,
    description = '',
    timerId = null,
    onClose,
    onComplete,
    ...props
}) => {
    const { canCreateActivities } = usePermissions()
    const { trackError } = useTracking()
    const { dispatchError, dispatchSuccess } = useNotifications()
    const apolloClient = useApolloClient()
    const formState = useCreateActivityForm({
        project,
        date,
        start,
        end,
        description,
        type,
        timerId,
    })

    const [isSubmitting, setIsSubmitting] = useState(false)
    if (canCreateActivities.value !== true) {
        return <ModalUnauthorized />
    }
    return (
        <CreateActivityModal
            formState={formState}
            onSubmit={async () => {
                const isValid = formState.validate()
                if (isValid) {
                    setIsSubmitting(true)
                    try {
                        const input = formState.valuesToInput()
                        await createActivity(apolloClient, input, {
                            update: (proxy, { data }) => {
                                const { activity } = data.createActivity
                                const newActivity = {
                                    ...activity,
                                    relatedActivityIds: [],
                                }
                                const path = 'activitiesPaginated.nodes'
                                onComplete(path, newActivity)
                            },
                        })
                        dispatchSuccess({
                            content: (
                                <Trans>
                                    You successfully created this activity!
                                </Trans>
                            ),
                        })
                        onClose()
                    } catch (e) {
                        trackError(e)
                        dispatchError({
                            title: <Trans>Something went wrong!</Trans>,
                            content: e.message,
                        })
                        setIsSubmitting(false)
                    }
                }
            }}
            onClose={onClose}
            isSubmitting={isSubmitting}
            {...props}
        />
    )
}

export default CreateActivityModalWithState
