import gql from 'graphql-tag'
import createSimpleApolloClient from 'apollo/createSimpleApolloClient'
import { getAppInsights } from 'services/applicationInsights'

const { REACT_APP_APPINSIGHTS_KEY } = process.env

const refreshAccessTokenMutation = gql`
    mutation refreshAccessTokenMutation($refreshToken: String!) {
        refreshAccessToken(refreshToken: $refreshToken) {
            accessToken
            accessTokenExpiration
            refreshToken
            refreshTokenExpiration
        }
    }
`

export default async function refreshAccessToken(refreshToken) {
    const apolloClient = createSimpleApolloClient()
    const appInsights = getAppInsights(REACT_APP_APPINSIGHTS_KEY)
    try {
        const mutation = refreshAccessTokenMutation
        const variables = { refreshToken }
        const response = await apolloClient.mutate({
            mutation,
            variables,
            errorPolicy: 'ignore',
        })
        return response.data.refreshAccessToken
    } catch (error) {
        // if auth error
        // logout
        appInsights.trackException({
            exception: error,
            properties: {
                refreshToken,
            },
        })
        return null
    }
}
