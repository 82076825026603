import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState from 'components/hooks/useFormState'
import createFormValidation from 'util/createFormValidation'
import { validateEmail } from 'util/index'

const INITIAL_VALUES = {
    emailInput: '',
    preferredCultureInput: null,
    emailAddresses: [],
    paymentMethod: null,
}

const toOnboardingInput = ({ emailAddresses, paymentMethod }) => ({
    emailAddresses,
    paymentMethod,
})

const validation = createFormValidation([
    {
        path: 'emailInput',
        validate: (emailInput) => validateEmail(emailInput),
        message: <Trans>Please enter a valid email</Trans>,
    },
])

const useAdminOnboardingForm = (initialValues) => {
    const formState = useFormState(
        {
            ...INITIAL_VALUES,
            ...initialValues,
        },
        {
            validation,
            valuesToInput: toOnboardingInput,
        },
    )

    const handleAddEmailAddress = (newEmail) => {
        const emailAddresses = [
            ...formState.values.emailAddresses,
            { email: newEmail },
        ]
        formState.setValues({ emailAddresses, emailInput: '' })
    }

    const handleRemoveEmailAddress = (index) => {
        formState.handleChange('emailAddresses', (emailAddresses) =>
            emailAddresses.filter((v, i) => i !== index),
        )
    }

    return {
        ...formState,
        handleAddEmailAddress,
        handleRemoveEmailAddress,
    }
}

export default useAdminOnboardingForm
