import gql from 'graphql-tag'

export const PUBLIC_APP_SECRETS_QUERY = gql`
    query publicApplicationSecrets {
        applicationSecrets {
            activeDirectoryClientId
            aADActiveDirectoryClientId
        }
    }
`

export default async function getPublicApplicationSecrets(
    apolloClient,
    options,
) {
    const query = PUBLIC_APP_SECRETS_QUERY
    const response = await apolloClient.query({
        query,
        ...options,
    })
    return response.data
}
