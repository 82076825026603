import { createGlobalStyle } from 'styled-components'

const AppGlobalFontStyles = createGlobalStyle`
    @font-face {
        font-family: 'Public Sans';
        font-style: normal;
        font-weight: ${({ theme }) => theme.fontWeightRegular};
        src: local('Public Sans Regular'),
            url('/fonts/PublicSans-Regular.woff2') format('woff2'),
            url('/fonts/PublicSans-Regular.woff') format('woff');
    }
    @font-face {
        font-family: 'Public Sans';
        font-style: italic;
        font-weight: ${({ theme }) => theme.fontWeightRegular};
        src: local('Public Sans Italic'),
            url('/fonts/PublicSans-Italic.woff2') format('woff2'),
            url('/fonts/PublicSans-Italic.woff') format('woff');
    }
    @font-face {
        font-family: 'Public Sans';
        font-style: normal;
        font-weight: ${({ theme }) => theme.fontWeightThin};
        src: local('Public Sans Thin'),
            url('/fonts/PublicSans-Thin.woff2') format('woff2'),
            url('/fonts/PublicSans-Thin.woff') format('woff');
    }
    @font-face {
        font-family: 'Public Sans';
        font-style: italic;
        font-weight: ${({ theme }) => theme.fontWeightThin};
        src: local('Public Sans Thin Italic'),
            url('/fonts/PublicSans-ThinItalic.woff2') format('woff2'),
            url('/fonts/PublicSans-ThinItalic.woff') format('woff');
    }
    @font-face {
        font-family: 'Public Sans';
        font-style: normal;
        font-weight: ${({ theme }) => theme.fontWeightExtraLight};
        src: local('Public Sans ExtraLight'),
            url('/fonts/PublicSans-ExtraLight.woff2') format('woff2'),
            url('/fonts/PublicSans-ExtraLight.woff') format('woff');
    }
    @font-face {
        font-family: 'Public Sans';
        font-style: italic;
        font-weight: ${({ theme }) => theme.fontWeightExtraLight};
        src: local('Public Sans ExtraLight Italic'),
            url('/fonts/PublicSans-ExtraLightItalic.woff2') format('woff2'),
            url('/fonts/PublicSans-ExtraLightItalic.woff') format('woff');
    }
    @font-face {
        font-family: 'Public Sans';
        font-style: normal;
        font-weight: ${({ theme }) => theme.fontWeightLight};
        src: local('Public Sans Light'),
            url('/fonts/PublicSans-Light.woff2') format('woff2'),
            url('/fonts/PublicSans-Light.woff') format('woff');
    }
    @font-face {
        font-family: 'Public Sans';
        font-style: italic;
        font-weight: ${({ theme }) => theme.fontWeightLight};
        src: local('Public Sans Light Italic'),
            url('/fonts/PublicSans-LightItalic.woff2') format('woff2'),
            url('/fonts/PublicSans-LightItalic.woff') format('woff');
    }
    @font-face {
        font-family: 'Public Sans';
        font-style: normal;
        font-weight: ${({ theme }) => theme.fontWeightMedium};
        src: local('Public Sans Medium'),
            url('/fonts/PublicSans-Medium.woff2') format('woff2'),
            url('/fonts/PublicSans-Medium.woff') format('woff');
    }
    @font-face {
        font-family: 'Public Sans';
        font-style: italic;
        font-weight: ${({ theme }) => theme.fontWeightMedium};
        src: local('Public Sans Medium Italic'),
            url('/fonts/PublicSans-MediumItalic.woff2') format('woff2'),
            url('/fonts/PublicSans-MediumItalic.woff') format('woff');
    }
    @font-face {
        font-family: 'Public Sans';
        font-style: normal;
        font-weight: ${({ theme }) => theme.fontWeightSemiBold};
        src: local('Public Sans SemiBold'),
            url('/fonts/PublicSans-SemiBold.woff2') format('woff2'),
            url('/fonts/PublicSans-SemiBold.woff') format('woff');
    }
    @font-face {
        font-family: 'Public Sans';
        font-style: italic;
        font-weight: ${({ theme }) => theme.fontWeightSemiBold};
        src: local('Public Sans SemiBold Italic'),
            url('/fonts/PublicSans-SemiBoldItalic.woff2') format('woff2'),
            url('/fonts/PublicSans-SemiBoldItalic.woff') format('woff');
    }
    @font-face {
        font-family: 'Public Sans';
        font-style: normal;
        font-weight: ${({ theme }) => theme.fontWeightBold};
        src: local('Public Sans Bold'),
            url('/fonts/PublicSans-Bold.woff2') format('woff2'),
            url('/fonts/PublicSans-Bold.woff') format('woff');
    }
    @font-face {
        font-family: 'Public Sans';
        font-style: italic;
        font-weight: ${({ theme }) => theme.fontWeightBold};
        src: local('Public Sans Bold Italic'),
            url('/fonts/PublicSans-BoldItalic.woff2') format('woff2'),
            url('/fonts/PublicSans-BoldItalic.woff') format('woff');
    }
    @font-face {
        font-family: 'Public Sans';
        font-style: normal;
        font-weight: ${({ theme }) => theme.fontWeightExtraBold};
        src: local('Public Sans ExtraBold'),
            url('/fonts/PublicSans-ExtraBold.woff2') format('woff2'),
            url('/fonts/PublicSans-ExtraBold.woff') format('woff');
    }
    @font-face {
        font-family: 'Public Sans';
        font-style: italic;
        font-weight: ${({ theme }) => theme.fontWeightExtraBold};
        src: local('Public Sans ExtraBold Italic'),
            url('/fonts/PublicSans-ExtraBoldItalic.woff2') format('woff2'),
            url('/fonts/PublicSans-ExtraBoldItalic.woff') format('woff');
    }
    @font-face {
        font-family: 'Public Sans';
        font-style: normal;
        font-weight: ${({ theme }) => theme.fontWeightBlack};
        src: local('Public Sans Black'),
            url('/fonts/PublicSans-Black.woff2') format('woff2'),
            url('/fonts/PublicSans-Black.woff') format('woff');
    }
    @font-face {
        font-family: 'Public Sans';
        font-style: italic;
        font-weight: ${({ theme }) => theme.fontWeightBlack};
        src: local('Public Sans Black Italic'),
            url('/fonts/PublicSans-BlackItalic.woff2') format('woff2'),
            url('/fonts/PublicSans-BlackItalic.woff') format('woff');
    }
`

export default AppGlobalFontStyles
