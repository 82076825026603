import React, { forwardRef } from 'react'
import styled from 'styled-components'
import Spinner from 'components/util/Spinner'
import TableStateProvider from 'components/providers/TableStateProvider'
import { Trans } from '@lingui/macro'
import TableHeader from 'components/util/TableHeader'
import TableColumnWidths from 'components/util/TableColumnWidths'

const Table = forwardRef(
    (
        {
            data,
            header,
            footer,
            renderRow,
            isLoading,
            initialSorting,
            onSort,
            columnWidths,
            noDataText,
            children,
            ...props
        },
        ref,
    ) => {
        let body = children
        const hasRenderProp = typeof renderRow === 'function'
        if (hasRenderProp) {
            body = data.map(renderRow)
        }
        const hasHeader = body.length > 0 && typeof header !== 'undefined'
        const hasFooter = body.length > 0 && typeof footer !== 'undefined'
        return (
            <TableStateProvider onSort={onSort} initialSorting={initialSorting}>
                <StyledTable ref={ref} {...props}>
                    <TableColumnWidths widths={columnWidths} />
                    {hasHeader && <TableHeader>{header}</TableHeader>}
                    <Body>
                        {(() => {
                            if (isLoading) {
                                return <TableLoading />
                            }
                            if (hasRenderProp && !body.length) {
                                return <TableEmpty>{noDataText}</TableEmpty>
                            }
                            return body
                        })()}
                    </Body>
                    {hasFooter && <TableFooter>{footer}</TableFooter>}
                </StyledTable>
            </TableStateProvider>
        )
    },
)

const TableLoading = () => (
    <BigRow>
        <CellContainer colSpan="9999">
            <Spinner />
        </CellContainer>
    </BigRow>
)

const TableEmpty = ({ children = <Trans>No data</Trans> }) => (
    <BigRow>
        <CellContainer colSpan="9999">{children}</CellContainer>
    </BigRow>
)

const StyledTable = styled.table(
    ({ theme }) => `
    position: relative;
    width: 100%;
    border: none;
    border-collapse: collapse;
    font-size: ${theme.defaultFontSize}rem;
`,
)

const Body = styled.tbody``

const Row = styled.tr(
    ({ theme }) => `
    :nth-child(odd) {
        background-color: ${theme.colorGreyWhite};
    }
`,
)

const BigRow = styled.tr(
    ({ theme }) => `
    :nth-child(odd) {
        background-color: ${theme.colorGreyWhite};
    }
`,
)

const Cell = styled.td(
    ({ theme, textAlign = 'left', wrapText = 'true' }) => `
    text-align: ${textAlign};
    padding: ${theme.spacingSmallest}rem;
    white-space: ${wrapText === 'true' ? 'wrap' : 'nowrap'};
    max-width: 1px; // important to have fix width for overlow props
    overflow: hidden;
    text-overflow: ellipsis;
    ${Row}:hover & {
        background: ${theme.colorGreyLightest};
    }

`,
)

const CellSmall = styled(Cell)(
    ({ theme }) => `
    padding-top: ${theme.spacingSmallest}rem;
    padding-bottom: ${theme.spacingSmallest}rem;
    padding-right: ${theme.spacingSmaller}rem;
`,
)

const CheckboxCell = styled(Cell)(
    ({ theme }) => `
    padding-left: ${theme.spacingSmallest}rem;
    padding-right: ${theme.spacingSmallest}rem;
`,
)

const CheckboxCellSmall = styled(CellSmall)(
    ({ theme }) => `
    padding-left: ${theme.spacingSmallest}rem;
    padding-right: ${theme.spacingSmallest}rem;
`,
)

const CellContainer = styled.td(
    ({ theme }) => `
    text-align: center;
    padding: ${theme.spacingLargest}rem 0;
    background-color: ${theme.colorWhite};
`,
)

const TableFooter = styled.tfoot(
    ({ theme }) => `
    font-weight: ${theme.fontWeightSemiBold};
    background-color: ${theme.colorWhite} !important;
    ${Row}:hover & {
        background-color: ${theme.colorWhite} !important;
    }
`,
)

const FooterRow = styled(Row)(
    ({ theme }) => `
    background-color: ${theme.colorWhite} !important;
    :hover {
        background-color: ${theme.colorWhite} !important;
    }
`,
)

Table.Header = TableHeader
Table.HeaderCell = TableHeader.Cell
Table.HeaderCheckboxCell = TableHeader.CheckboxCell
Table.HeaderCheckboxCellSmall = TableHeader.CheckboxCellSmall
Table.HeaderCellSmall = TableHeader.CellSmall
Table.Body = Body
Table.Cell = Cell
Table.CheckboxCell = CheckboxCell
Table.CheckboxCellSmall = CheckboxCellSmall
Table.CellSmall = CellSmall
Table.Row = Row
Table.FooterRow = FooterRow

export default Table
