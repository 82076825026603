import React, { forwardRef } from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import Icon from 'components/util/Icon'
import Spinner from 'components/util/Spinner'
import { colorPrimary } from 'styles/theme'

const InversedButton = forwardRef(
    (
        {
            iconName,
            iconPosition = 'left',
            children,
            isSmall,
            isLoading,
            isAnimated = true,
            isAddonBefore,
            isAddonAfter,
            hasAddonBefore,
            hasAddonAfter,
            ...props
        },
        ref,
    ) => {
        const iconIsSet = typeof iconName !== 'undefined'
        const isIconButton = typeof children === 'undefined' && iconIsSet
        return (
            <Button
                ref={ref}
                whileTap={{ scale: isAnimated ? 1 : 0.95 }}
                $isAddonBefore={isAddonBefore}
                $isAddonAfter={isAddonAfter}
                $hasAddonBefore={hasAddonBefore}
                $hasAddonAfter={hasAddonAfter}
                $isSmall={isSmall}
                {...props}
            >
                {iconIsSet && !isLoading && iconPosition === 'left' && (
                    <StyledIconLeft
                        isSmaller
                        name={iconName}
                        $isIconButton={isIconButton}
                    />
                )}
                {isLoading && (
                    <SpinnerContainer>
                        <Spinner size={12} color={colorPrimary} />
                    </SpinnerContainer>
                )}
                <div>{children}</div>
                {iconIsSet && iconPosition === 'right' && (
                    <StyledIconRight
                        isSmaller
                        name={iconName}
                        $isIconButton={isIconButton}
                    />
                )}
            </Button>
        )
    },
)

const Button = styled(motion.button)(
    ({
        theme,
        $isAddonBefore,
        $isAddonAfter,
        $hasAddonBefore,
        $hasAddonAfter,
        disabled,
        $isSmall,
    }) => `
    display: flex;
    background-color: ${theme.colorWhite};
    border-color: ${theme.colorPrimary};
    border-top-width : ${theme.defaultBorderWidth}rem;
    border-bottom-width : ${theme.defaultBorderWidth}rem;
    border-left-width : ${$isAddonAfter ? 0 : theme.defaultBorderWidth}rem;
    border-right-width : ${$isAddonBefore ? 0 : theme.defaultBorderWidth}rem;
    border-top-left-radius: ${
        $hasAddonBefore || $isAddonAfter ? 0 : theme.defaultButtonBorderRadius
    }rem;
    border-bottom-left-radius: ${
        $hasAddonBefore || $isAddonAfter ? 0 : theme.defaultButtonBorderRadius
    }rem;
    border-top-right-radius: ${
        $hasAddonAfter || $isAddonBefore ? 0 : theme.defaultButtonBorderRadius
    }rem;
    border-bottom-right-radius: ${
        $hasAddonAfter || $isAddonBefore ? 0 : theme.defaultButtonBorderRadius
    }rem;
    color: ${theme.colorPrimary};
    padding: ${
        $isSmall
            ? `${theme.spacingSmallest}rem ${theme.spacingSmaller}rem`
            : `${theme.spacingSmaller}rem ${theme.spacingSmall}rem`
    };
    font-size: ${theme.fontSize}rem;
    font-weight: ${$isSmall ? theme.fontWeightMedium : theme.fontWeightBold};
    justify-content: center;
    align-items: center;
    :hover {
        background-color: ${!disabled && theme.colorPrimaryLightest};
    }
    :disabled {
        cursor: not-allowed;
        color: ${theme.colorGreyLight};
        border-color: ${theme.colorGreyLight};
    }
`,
)

const StyledIconLeft = styled(Icon)(
    ({ theme, $isIconButton }) => `
    margin-right: ${!$isIconButton && theme.spacingSmaller}rem;
`,
)

const StyledIconRight = styled(Icon)(
    ({ theme, $isIconButton }) => `
    margin-left: ${!$isIconButton && theme.spacingSmall}rem;
`,
)

const SpinnerContainer = styled.span(
    ({ theme }) => `
    font-size: ${theme.fontSizeSmall}rem;
    height: 12px;
    margin-right: ${theme.spacingSmaller}rem;
`,
)

export default InversedButton
