import { oneLineTrim } from 'common-tags'
import refreshAccessToken from 'api/refreshAccessToken'
import { getAppInsights } from 'services/applicationInsights'
import * as storage from './storage'

const {
    REACT_APP_APPINSIGHTS_KEY,
    REACT_APP_ACTIVE_DIRECTORY_API_ID: AZURE_AD_API_ID,
    REACT_APP_ACTIVE_DIRECTORY_AUTHORIZE_URL: AZURE_AD_AUTHORIZE_URL,
    REACT_APP_ACTIVE_DIRECTORY_REDIRECT_URI: AZURE_AD_REDIRECT_URI,
    REACT_APP_ACTIVE_DIRECTORY_B2C_AUTHORIZE_URL: AZURE_AD_B2C_AUTHORIZE_URL,
} = process.env

export const isTokenExpired = async () => {
    const accessTokenExp = await storage.getAccessTokenExpiration()
    if (
        typeof accessTokenExp === 'undefined' ||
        accessTokenExp === null ||
        new Date(accessTokenExp) < new Date()
    ) {
        return true
    }
    return false
}

export const isRefreshTokenExpired = async () => {
    const refreshTokenExp = await storage.getRefreshTokenExpiration()
    if (
        typeof refreshTokenExp === 'undefined' ||
        refreshTokenExp === null ||
        new Date(refreshTokenExp) < new Date()
    ) {
        return true
    }
    return false
}

export const isRefreshTokenWindowExpired = async () => {
    const refreshTokenWindow = await storage.getRefreshTokenWindow()
    if (
        typeof refreshTokenWindow === 'undefined' ||
        refreshTokenWindow === null ||
        new Date(refreshTokenWindow) < new Date()
    ) {
        return true
    }
    return false
}

export const loadAndValidateTokens = async () => {
    const appInsights = getAppInsights(REACT_APP_APPINSIGHTS_KEY)
    let [accessToken, refreshToken] = await Promise.all([
        storage.getAccessToken(),
        storage.getRefreshToken(),
    ])
    let isUserLogged = false
    const [
        accessTokenIsExpired,
        refreshTokenIsExpired,
        refreshTokenWindowIsExpired,
    ] = await Promise.all([
        isTokenExpired(),
        isRefreshTokenExpired(),
        isRefreshTokenWindowExpired(),
    ])

    try {
        if (accessToken && refreshToken) {
            isUserLogged = true
            if (
                refreshTokenWindowIsExpired ||
                (accessTokenIsExpired && refreshTokenIsExpired)
            ) {
                accessToken = null
                refreshToken = null
                await Promise.all([
                    storage.removeAccessToken(),
                    storage.removeRefreshToken(),
                    storage.removeRefreshTokenWindow(),
                ])
            } else if (accessTokenIsExpired) {
                const response = await refreshAccessToken(refreshToken)
                if (response === null) {
                    accessToken = null
                    refreshToken = null
                } else {
                    ;({ accessToken, refreshToken } = response)
                    await Promise.all([
                        storage.setAccessToken(
                            response.accessToken,
                            response.accessTokenExpiration,
                        ),
                        storage.setRefreshToken(
                            response.refreshToken,
                            response.refreshTokenExpiration,
                        ),
                    ])
                }
            }
        }
    } catch (error) {
        appInsights.trackError(error)
    }
    return {
        refreshToken,
        accessToken,
        isUserLogged,
    }
}

export const composeAzureADUrl = (clientId, state) => {
    const url = oneLineTrim`${AZURE_AD_AUTHORIZE_URL}
        ?client_id=${clientId}
        &redirect_uri=${encodeURIComponent(AZURE_AD_REDIRECT_URI)}
        &response_type=code
        &prompt=consent
        &scope=${encodeURIComponent(
            `api://${AZURE_AD_API_ID}/Lexor.SignIn offline_access`,
        )}
        &response_mode=query
    `
    if (state) {
        return `${url}&state=${state}`
    }
    return url
}

export const composeAzureADB2CUrl = (
    clientId,
) => oneLineTrim`${AZURE_AD_B2C_AUTHORIZE_URL}
    &client_id=${clientId}
    &scope=${encodeURIComponent(`${clientId} offline_access`)}
    &redirect_uri=${encodeURIComponent(AZURE_AD_REDIRECT_URI)}
    &response_type=code
    &response_mode=query
    &prompt=login
    &nonce=defaultNonce
`
