import React from 'react'
import { Trans } from '@lingui/macro'
import styled from 'styled-components'
import Spinner from 'components/util/Spinner'
import Popover from 'components/util/Popover'

const ConfirmPopover = ({
    onConfirm,
    confirmText,
    confirmButtonText,
    disabled,
    isLoading,
    children,
    ...props
}) => (
    <Popover
        content={
            <ConfirmContainer>
                <ConfirmText>{confirmText}</ConfirmText>
                <ConfirmButton
                    disabled={disabled || isLoading}
                    onClick={onConfirm}
                >
                    {isLoading && (
                        <SpinnerContainer>
                            <Spinner size={12} color="white" />
                        </SpinnerContainer>
                    )}
                    {typeof confirmButtonText !== 'undefined' ? (
                        confirmButtonText
                    ) : (
                        <Trans>Yes</Trans>
                    )}
                </ConfirmButton>
            </ConfirmContainer>
        }
        hasArrow
        hasDarkBackground
        {...props}
    >
        <Container>{children}</Container>
    </Popover>
)

const Container = styled.span`
    cursor: pointer;
`

const ConfirmContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 20rem;
    color: ${({ theme }) => theme.colorWhite};
    border-radius: ${({ theme }) => theme.borderRadius}rem;
`

const ConfirmText = styled.span`
    padding: ${({ theme }) => theme.spacingSmaller}rem;
    text-align: center;
`

const ConfirmButton = styled.button.attrs({ type: 'button' })`
    display: flex;
    justify-content: center;
    padding: ${({ theme }) => theme.spacingSmaller}rem;
    color: ${({ theme }) => theme.colorRed};
    border-top-width: ${({ theme }) => theme.borderWidth}rem;
    border-top-color: ${({ theme }) => theme.colorGreyDarker};
    width: 100%;
    :disabled {
        cursor: not-allowed;
    }
`

const SpinnerContainer = styled.span`
    font-size: ${({ theme }) => theme.fontSizeSmall}rem;
    height: 12px;
    margin-right: ${({ theme }) => theme.spacingSmaller}rem;
`

export default ConfirmPopover
