import React, { useEffect, useState } from 'react'
import styled, { createGlobalStyle, css, withTheme } from 'styled-components'
import { Trans } from '@lingui/macro'
import { DayPicker } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import { Manager, Reference, Popper } from 'react-popper'
import Portal from '@reach/portal'
import {
    isValidDate,
    formatDate,
    formattedStringIsValidDate,
    formatDateString,
    parseDate,
} from 'util/dates'
import useOnClickOutside from 'components/hooks/useOnClickOutside'
import Input from 'components/util/Input'
import useTenantSettings from 'components/hooks/useTenantSettings'
import { combineRefs, where } from 'util/index'
import { dateFormatOptions } from 'util/entities'
import LinkButton from './LinkButton'
import SecondaryButton from './SecondaryButton'
import useDateFnsLocale from '../hooks/useDateFnsLocale'

const whereValue = where('value')

const DatePicker = ({
    placement = 'bottom',
    type = 'input',
    onChange,
    theme,
    value,
    disabledDays,
    ...props
}) => {
    const locale = useDateFnsLocale()
    const [isOpen, setOpen] = useState(false)
    const [month, setMonth] = useState(new Date())
    const [year, setYear] = useState(new Date())
    const [theInput, setTheInput] = useState(value)

    useEffect(() => {
        if (isValidDate(value)) {
            setMonth(value)
            setYear(value)
        }
    }, [value])

    const clickOutsideRef = React.useRef(null)
    useOnClickOutside(clickOutsideRef, () => setOpen(false))

    const handleOpen = () => setOpen(true)

    const { dateFormat } = useTenantSettings()
    const { mask } = dateFormatOptions.find(whereValue(dateFormat))

    const getValue = (input) => {
        if (isValidDate(input)) {
            return formatDate(input, dateFormat)
        }
        if (isValidDate(parseDate(input))) {
            return formatDateString(input, dateFormat)
        }
        return input
    }

    const handleChange = (e) => {
        const input = e.target.value
        const date = formattedStringIsValidDate(input, dateFormat)
        setTheInput(e.target.value)
        if (e.target.value.length === 10 && isValidDate(date)) {
            onChange(date)
            setMonth(date)
            setYear(date)
        }
    }

    return (
        <Manager>
            <Reference>
                {({ ref }) =>
                    type === 'input' ? (
                        <StyledInput
                            ref={ref}
                            value={getValue(theInput)}
                            onChange={handleChange}
                            onFocus={handleOpen}
                            onClick={handleOpen}
                            mask={mask}
                            {...props}
                        />
                    ) : (
                        <SecondaryButton
                            ref={ref}
                            iconName="calendar"
                            onClick={handleOpen}
                            onChange={handleChange}
                        />
                    )
                }
            </Reference>
            {isOpen && (
                <Popper
                    placement={placement}
                    positionFixed
                    modifiers={[
                        {
                            name: 'preventOverflow',
                            options: {
                                rootBoundary: 'viewport',
                            },
                        },
                    ]}
                >
                    {({ ref: popperRef, style }) => (
                        <Portal>
                            <DayPickerOverlay
                                ref={combineRefs([clickOutsideRef, popperRef])}
                                style={style}
                                data-placement={placement}
                            >
                                <DayPicker
                                    onDayClick={(selectedDay, { disabled }) => {
                                        if (
                                            !disabled &&
                                            typeof selectedDay !== 'undefined'
                                        ) {
                                            onChange(selectedDay)
                                            setTheInput(selectedDay)
                                            setOpen(false)
                                        }
                                    }}
                                    selected={[value]}
                                    month={month}
                                    year={year}
                                    showWeekNumber
                                    onMonthChange={(e) => setMonth(e)}
                                    weekStartsOn={1}
                                    footer={
                                        <StyledLink
                                            onClick={() => onChange(new Date())}
                                        >
                                            <Trans>Today</Trans>
                                        </StyledLink>
                                    }
                                    disabled={disabledDays}
                                    required
                                    locale={locale}
                                />
                            </DayPickerOverlay>
                        </Portal>
                    )}
                </Popper>
            )}
        </Manager>
    )
}

const StyledInput = styled(Input)(
    ({ theme }) => `
    width: 100%;
    font-size: ${theme.defaultFontSize}rem;
    :hover {
        border-Color: ${theme.colorGrey};
    }
    ::placeholder {
        color: ${theme.colorGreyLight};
        font-style: italic;
    }
`,
)

const StyledLink = styled(LinkButton)`
    text-align: center;
`

const DayPickerOverlay = styled.div(
    ({ theme }) => `
    box-shadow: ${theme.boxShadow};
    border-radius: ${theme.defaultBorderRadius}rem;
    background: ${theme.colorWhite};
    z-index: 100;
`,
)

export const DayPickerGlobalStyles = createGlobalStyle`
    ${({ theme }) => css`
        :root {
            --rdp-cell-size: 4rem;
            --rdp-accent-color: ${theme.colorPrimary};
            --rdp-background-color: ${theme.colorWhite};
            --rdp-outline: 0;
        }
        .rdp-weeknumber {
            color: ${theme.colorGreyLight};
            font-size: ${theme.fontSizeSmallest};
        }
        .rdp-day {
            border-radius: ${theme.defaultBorderRadius}rem;
            padding: 0.6em;
            cursor: pointer;
        }
        .rdp-day_selected:not([disabled]),
        .rdp-day_selected:focus:not([disabled]),
        .rdp-day_selected:active:not([disabled]),
        .rdp-day_selected:hover:not([disabled]) {
            background-color: ${theme.colorPrimary};
            color: ${theme.colorWhite};
        }
        .rdp-day_today:not(.rdp-day_outside, .rdp-day_selected) {
            color: ${theme.colorPrimary};
            font-weight: ${theme.fontWeightBold};
        }
        .rdp-tfoot {
            text-align: center;
        }
    `}  
`

export default withTheme(DatePicker)
