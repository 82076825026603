import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Trans } from '@lingui/react'
import OnboardingModal from 'components/pages/onboarding/OnboardingModal'
import SecondaryButton from 'components/util/SecondaryButton'
import PrimaryButton from 'components/util/PrimaryButton'
import * as routes from 'routes/index'

const O365Modal = ({
    onClose,
    onLinkOffice365Account,
    o365LinkExpirationStatus,
}) => {
    const navigate = useNavigate()
    const renewTokenButton = useRef()
    const title = <Trans id="Link with Microsoft 365 needs your attention." />

    const subTitle = (
        <>
            <br />
            <Trans
                id={`If you want Lexor to automatically register activities based on the time you spend using Microsoft 365, please ${
                    o365LinkExpirationStatus ? 'renew' : 'review'
                } the access on your profile page.`}
            />
        </>
    )

    useEffect(
        () => renewTokenButton.current && renewTokenButton.current.focus(),
        [renewTokenButton],
    )

    const handleNextButton = () => {
        navigate(routes.profile(),  { replace: true })
        onClose(o365LinkExpirationStatus)
    }

    return (
        <OnboardingModal title={title} subTitle={subTitle}>
            <div className="flex justify-end mt-4 gap-x-4">
                <SecondaryButton
                    onClick={() => onClose(o365LinkExpirationStatus)}
                >
                    <Trans id="Skip" />
                </SecondaryButton>
                {o365LinkExpirationStatus ?  (
                    <PrimaryButton
                        onClick={handleNextButton}
                    >
                        <Trans id="Go to profile" />
                    </PrimaryButton>
                ) : (
                    <PrimaryButton
                        onClick={onLinkOffice365Account}
                        ref={renewTokenButton}
                    >
                        <Trans id="Add account" />
                    </PrimaryButton>
                )}
            </div>
        </OnboardingModal>
    )
}

export default O365Modal
