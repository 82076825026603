import { oneLineTrim } from 'common-tags'

const {
    REACT_APP_MICROSOFT_OFFICE_REDIRECT_URI: MICROSOFT_OFFICE_REDIRECT_URI,
    REACT_APP_MICROSOFT_OFFICE_AUTHORIZE_URL: MICROSOFT_OFFICE_AUTHORIZE_URL,
} = process.env

export const composeOffice365Url = (
    o365ClientId,
    redirectRoute,
    o365UserEmail,
    state
) => oneLineTrim`${MICROSOFT_OFFICE_AUTHORIZE_URL}
        ?client_id=${o365ClientId}
        &response_type=code
        &prompt=consent
        &redirect_uri=${encodeURIComponent(
            MICROSOFT_OFFICE_REDIRECT_URI + redirectRoute,
        )}
        &msafed=0
        &resource=${encodeURIComponent('https://graph.microsoft.com')}
        &scope=${encodeURIComponent('openid profile User.ReadWrite')}
        &login_hint=${o365UserEmail}
        &state=${state}
    `

export const linkOffice365Account = (
    o365ClientId,
    o365UserEmail = '',
    redirectRoute = '',
    state
) => {
    const href = composeOffice365Url(o365ClientId, redirectRoute, o365UserEmail, state)
    window.location.href = href
}