import gql from 'graphql-tag'
import { PERMISSION_FRAGMENT } from './getInitialData'

export const ACTIVITY_CATEGORY_FRAGMENT = gql`
    fragment ActivityCategory on ActivityCategory {
        id
        slug
        translation
        isActive
        externalApplicationIds
        isBillableByDefault
        translation
        translations {
            id
            value
            targetCultureId
            targetCulture {
                id
                code
            }
        }
        permissions {
            canUpdate {
                ...Permission
            }
            canDelete {
                ...Permission
            }
        }
    }
    ${PERMISSION_FRAGMENT}
`

export const COST_CATEGORY_FRAGMENT = gql`
    fragment CostCategory on CostCategory {
        id
        slug
        isActive
        externalApplicationIds
        translation
        translations {
            id
            value
            targetCultureId
            targetCulture {
                id
                code
            }
        }
        permissions {
            canUpdate {
                ...Permission
            }
            canDelete {
                ...Permission
            }
        }
        isFixedPrice
        pricePerUnit
        unit
        fixedPrice
    }
    ${PERMISSION_FRAGMENT}
`
