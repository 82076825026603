import React from 'react'
import { Trans } from '@lingui/macro'
import createFormValidation from 'util/createFormValidation'
import useFormState from 'components/hooks/useFormState'

const INITIAL_VALUES = {
    description: '',
    key: '',
}

const validation = createFormValidation([
    {
        path: 'description',
        validate: (description) => description.length > 10,
        message: <Trans>Minimum 10 characters</Trans>,
    },
])

const valuesToInput = (values) => values

const useTenantSelectionImpersonationForm = (initialValues, options) =>
    useFormState(
        {
            ...INITIAL_VALUES,
            ...initialValues,
        },
        {
            validation,
            valuesToInput,
            ...options,
        },
    )

export default useTenantSelectionImpersonationForm
