import gql from 'graphql-tag'
import { COWORKER_FRAGMENT } from 'components/pages/settings-coworkers/useFetchSettingsCoworkersPageData'

const ACTIVATE_PARTNER_MUTATION = gql`
    mutation activatePartner($id: Int!) {
        activatePartner(id: $id) {
            partner {
                ...Coworker
            }
        }
    }
    ${COWORKER_FRAGMENT}
`

export default async function activatePartner(apolloClient, id, options) {
    const mutation = ACTIVATE_PARTNER_MUTATION
    const variables = { id }
    const response = await apolloClient.mutate({
        mutation,
        variables,
        ...options,
    })
    return response.data.activatePartner
}
