import React from 'react'
import { Trans } from '@lingui/react'
import styled from 'styled-components'
import ModalTopBar from 'components/layout/ModalTopBar'
import Modal, { ModalMainContainer } from 'components/layout/Modal'
import ModalConfirmButtonBar from 'components/layout/ModalConfirmButtonBar'
import StartActivityForm from 'components/pages/activities-create/activity-start/StartActivityForm'

const StartActivityModal = ({ formState, onClose, onSubmit, isSubmitting }) => (
    <Modal
        data-testid="CreateActivityModal"
        ariaLabel="Create activity"
        islarge="true"
    >
        <ModalTopBar title={<Trans id="Start activity" />} onClose={onClose} />
        <StyledModalMainContainer>
            <StartActivityForm formState={formState} />
        </StyledModalMainContainer>
        <ModalConfirmButtonBar
            isSubmitting={isSubmitting}
            onCancel={onClose}
            onSubmit={onSubmit}
        />
    </Modal>
)

const StyledModalMainContainer = styled(ModalMainContainer)`
    min-height: 25rem;
`

export default StartActivityModal
