import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import Icon from 'components/util/Icon'
import CircledIcon from './CircledIcon'

const IconButton = forwardRef(({ iconName, circled, ...props }, ref) => {
    const {
        isSmallest,
        isSmaller,
        isSmall,
        isLarge,
        isLarger,
        isLargest,
        ...buttonProps
    } = props
    const iconProps = {
        isSmallest,
        isSmaller,
        isSmall,
        isLarge,
        isLarger,
        isLargest,
    }
    return (
        <Button ref={ref} {...buttonProps}>
            {circled ? (
                <CircledIcon name={iconName} {...iconProps} />
            ) : (
                <StyledIcon name={iconName} {...iconProps} />
            )}
        </Button>
    )
})

const Button = styled.button`
    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.2;
            cursor: not-allowed;
        `}
    border-color: ${({ theme }) => theme.defaultBorderColor};
    border-radius: ${({ theme }) => theme.defaultBorderRadius}rem;
    ${({ isAddonAfter }) =>
        isAddonAfter &&
        css`
            border-left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        `}
    ${({ hasError, theme }) =>
        hasError &&
        css`
            border-color: ${theme.colorRed};
        `}
`

const StyledIcon = styled(Icon)``

export default IconButton
