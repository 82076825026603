import React, { forwardRef, useState } from 'react'
import { Trans } from '@lingui/macro'
import styled from 'styled-components'
import useDebouncedCallback from 'components/hooks/useDebouncedCallback'
import SelectCreatable from 'components/util/SelectCreatable'
import Select from 'components/util/Select'

const SearchSelect = forwardRef(
    (
        {
            fetchOptions,
            allowCreate = false,
            getOptionValue = ({ id }) => id,
            getOptionLabel = ({ translation }) => translation,
            getNewOptionData = (value, label) => ({
                id: value,
                translation: label,
                isNewOption: true,
            }),
            formatCreateLabel = (label) => (
                <CreateLabel>
                    <Trans>Create &quot;{label}&quot;</Trans>
                </CreateLabel>
            ),
            ...props
        },
        ref,
    ) => {
        const [isSearching, setIsSearching] = useState(false)

        const { defaultOptions } = props
        const searchDefaultOptions = async (searchInput) =>
            defaultOptions.filter(
                (option) =>
                    typeof getOptionLabel(option) === 'string' &&
                    getOptionLabel(option)
                        .toLowerCase()
                        .includes(searchInput.toLowerCase()),
            )

        const handleSearch = (searchInput, callback) => {
            setIsSearching(true)
            if (typeof fetchOptions === 'undefined') {
                searchDefaultOptions(searchInput).then((options) => {
                    callback(options)
                })
            } else {
                fetchOptions(searchInput).then((options) => {
                    callback(options)
                })
            }
            setIsSearching(false)
        }

        const debouncedSearch = useDebouncedCallback(handleSearch, 500)

        if (allowCreate) {
            return (
                <SelectCreatable
                    ref={ref}
                    loadOptions={debouncedSearch}
                    isLoading={isSearching}
                    getOptionValue={getOptionValue}
                    getOptionLabel={getOptionLabel}
                    getNewOptionData={getNewOptionData}
                    formatCreateLabel={formatCreateLabel}
                    {...props}
                />
            )
        }

        return (
            <Select
                ref={ref}
                loadOptions={debouncedSearch}
                isLoading={isSearching}
                getOptionValue={getOptionValue}
                getOptionLabel={getOptionLabel}
                {...props}
            />
        )
    },
)

const CreateLabel = styled.span(
    ({ theme }) => `
    font-weight: ${theme.fontWeightBold};
`,
)

export default SearchSelect
