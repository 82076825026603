import gql from 'graphql-tag'
import { useQuery } from 'components/hooks/useApolloQueries'
import { flatten } from 'util/index'

export const CUSTOM_FIELD_FRAGMENT = gql`
    fragment CustomField on CustomField {
        id
        isRequired
        labelTranslation
        labelTranslations {
            id
            targetCultureId
            value
        }
        orderValue
        placeholderTranslation
        placeholderTranslations {
            id
            targetCultureId
            value
        }
        typeId
        type {
            id
            slug
            translation
        }
        datasetId
        dataset {
            id
            items {
                id
                translation
                translations {
                    id
                    targetCultureId
                    value
                }
            }
        }
    }
`

export const PROJECT_TEMPLATES_QUERY = gql`
    query projectTemplatesPaginated($currentPage: Int!, $pageSize: Int!) {
        projectTemplatesPaginated(
            currentPage: $currentPage
            pageSize: $pageSize
        ) {
            nodes {
                id
                label
                colorCode
                slug
                translation
                externalApplication {
                    id
                    name
                }
                customFields {
                    ...CustomField
                }
            }
            meta {
                currentPage
                pageSize
                lastPage
                totalCount
                exportKey
            }
        }
    }
    ${CUSTOM_FIELD_FRAGMENT}
`

const useFetchSettingsProjectFieldsPageData = (options) => {
    const variables = {
        currentPage: 1,
        pageSize: 100,
        ...options,
    }
    const {
        data = {},
        error,
        loading,
    } = useQuery(PROJECT_TEMPLATES_QUERY, { variables })

    const settingProjectFieldsPageRefetchQueries = [
        {
            query: PROJECT_TEMPLATES_QUERY,
            variables,
        },
    ]

    const { projectTemplatesPaginated = {} } = data

    if (loading) {
        return {
            error,
            projectTemplates: [],
            projectFields: [],
            isFetching: loading,
        }
    }

    const projectFields = projectTemplatesPaginated.nodes
        .map((template) =>
            template.customFields.map((customField) => ({
                ...customField,
                externalApplication: template.externalApplication,
                template,
            })),
        )
        .reduce(flatten, [])

    return {
        error,
        projectTemplates: projectTemplatesPaginated.nodes,
        projectFields,
        pageMeta: projectTemplatesPaginated.meta,
        isFetching: loading,
        refetchQueries: settingProjectFieldsPageRefetchQueries,
    }
}

export default useFetchSettingsProjectFieldsPageData
