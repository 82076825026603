import React, { forwardRef } from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import Icon from 'components/util/Icon'
import Spinner from './Spinner'

const SecondaryButton = forwardRef(
    (
        {
            children,
            isLoading,
            isSmall,
            isAnimated = true,
            iconName,
            isAddonBefore,
            isAddonAfter,
            ...props
        },
        ref,
    ) => {
        const { iconPosition = 'left' } = props
        const iconIsSet = typeof iconName !== 'undefined'
        const isIconButton = typeof children === 'undefined' && iconIsSet
        return (
            <Button
                ref={ref}
                whileTap={{ scale: isAnimated ? 1 : 0.95 }}
                $isAddonBefore={isAddonBefore}
                $isAddonAfter={isAddonAfter}
                $isSmall={isSmall}
                {...props}
            >
                {iconIsSet && iconPosition === 'left' && !isLoading && (
                    <StyledIconLeft
                        isSmaller
                        name={iconName}
                        $isIconButton={isIconButton}
                    />
                )}
                {isLoading && (
                    <SpinnerContainer>
                        <Spinner size={12} />
                    </SpinnerContainer>
                )}
                <div>{children}</div>
                {iconIsSet && iconPosition === 'right' && !isLoading && (
                    <StyledIconRight
                        isSmaller
                        name={iconName}
                        $isIconButton={isIconButton}
                    />
                )}
            </Button>
        )
    },
)

const Button = styled(motion.button)(
    ({ theme, $isAddonBefore, $isAddonAfter, $isSmall }) => `
    display: flex;
    background-color: inherit;
    color: ${theme.colorGrey};
    border-radius: ${theme.defaultButtonBorderRadius}rem;
    border-width: ${theme.defaultBorderWidth}rem;
    border-color: transparent;
    padding: ${
        $isSmall
            ? `${theme.spacingSmallest}rem ${theme.spacingSmaller}rem`
            : `${theme.spacingSmaller}rem ${theme.spacingSmall}rem`
    };
    font-size: ${theme.fontSize}rem;
    font-weight: ${$isSmall ? theme.fontWeightMedium : theme.fontWeightBold};
    justify-content: center;
    align-items: center;
    &:hover:not(:disabled) {
        border-color: ${theme.defaultBorderColor};
    }
    :disabled {
        cursor: not-allowed;
    }
    ${
        $isAddonBefore &&
        `
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    `
    }
    ${
        $isAddonAfter &&
        `
        border-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    `
    }
`,
)

const StyledIconLeft = styled(Icon)`
    color: ${({ theme }) => theme.colorGrey};
    margin-right: ${({ theme, $isIconButton }) =>
        !$isIconButton && theme.spacingSmaller}rem;
    ${Button}:hover & {
        color: ${({ theme }) => theme.colorGreyDark};
    }
`
const StyledIconRight = styled(Icon)`
    color: ${({ theme }) => theme.colorGrey};
    margin-left: ${({ theme, $isIconButton }) =>
        !$isIconButton && theme.spacingSmall}rem;
    ${Button}:hover & {
        color: ${({ theme }) => theme.colorGreyDark};
    }
`

const SpinnerContainer = styled.span(
    ({ theme }) => `
    font-size: ${theme.fontSizeSmall}rem;
    height: 12px;
    margin-right: ${theme.spacingSmaller}rem;
`,
)

export default SecondaryButton
