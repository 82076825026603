import React from 'react'
import styled from 'styled-components'

const WIDTH = 100
const HEIGHT = 22
const RATIO = WIDTH / HEIGHT

const Logo = ({ size = HEIGHT, ...props }) => {
    const height = size
    const width = height * RATIO
    return (
        <Svg
            width={`${width}px`}
            height={`${height}px`}
            viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
            {...props}
        >
            <path
                d="M18.048 1.8407C17.6369 1.42033 17.1224 1.36793 16.6085 1.57812C13.8834 2.84042 11.1066 4.10213 8.38147 5.36384C6.63306 6.20635 1.64563 8.57257 0 9.36151L8.27869 11.8325L10.6955 20.2983C11.4149 18.6156 13.7289 13.4098 14.5517 11.6218L18.2535 3.26138C18.4597 2.78743 18.4086 2.20927 18.048 1.84129"
                fill="#E56A59"
            />
            <path
                d="M35.1463 20.2983H30.7328V0.738281H35.1463V20.2983Z"
                fill="#E56A59"
            />
            <path
                d="M52.8738 14.0758H42.7718C42.8372 15.182 43.2459 16.0285 43.9978 16.6151C44.7579 17.2017 45.8735 17.495 47.3447 17.495C48.2764 17.495 49.1795 17.3232 50.0541 16.9796C50.9286 16.636 51.6192 16.2673 52.126 15.8734H52.6163V19.5064C51.6192 19.917 50.6793 20.2145 49.7966 20.3989C48.9139 20.5832 47.9372 20.6754 46.8666 20.6754C44.104 20.6754 41.9872 20.0385 40.516 18.7647C39.0449 17.4908 38.3093 15.6765 38.3093 13.3216C38.3093 10.9918 39.004 9.14809 40.3934 7.79045C41.791 6.42443 43.7036 5.74142 46.131 5.74142C48.3704 5.74142 50.0541 6.32387 51.182 7.48875C52.3098 8.64526 52.8738 10.313 52.8738 12.4919V14.0758ZM48.4848 11.4234C48.4603 10.4764 48.2315 9.76405 47.7983 9.28636C47.3651 8.80868 46.6908 8.56983 45.7754 8.56983C44.9254 8.56983 44.2266 8.79611 43.679 9.24865C43.1314 9.7012 42.8249 10.4261 42.7596 11.4234H48.4848Z"
                fill="#E56A59"
            />
            <path
                d="M70.3806 20.2983H65.2315L62.2892 16.0745L59.2733 20.2983H54.2224L59.6534 13.2461L54.3082 6.1814H59.4572L62.3505 10.3297L65.2561 6.1814H70.3193L64.9618 13.133L70.3806 20.2983Z"
                fill="#E56A59"
            />
            <path
                d="M86.8576 13.2461C86.8576 15.5675 86.1956 17.3987 84.8716 18.7395C83.5557 20.072 81.7045 20.7383 79.3179 20.7383C76.9314 20.7383 75.0761 20.072 73.752 18.7395C72.4362 17.3987 71.7782 15.5675 71.7782 13.2461C71.7782 10.908 72.4402 9.07266 73.7643 7.74017C75.0965 6.40767 76.9477 5.74142 79.3179 5.74142C81.7208 5.74142 83.5761 6.41186 84.8838 7.75274C86.1997 9.09361 86.8576 10.9247 86.8576 13.2461ZM81.4266 16.6779C81.7126 16.3176 81.9251 15.886 82.0641 15.3832C82.2112 14.872 82.2848 14.168 82.2848 13.2713C82.2848 12.4416 82.2112 11.746 82.0641 11.1845C81.917 10.623 81.7126 10.1747 81.4511 9.83948C81.1896 9.49588 80.8749 9.25284 80.5071 9.11037C80.1393 8.96791 79.7429 8.89667 79.3179 8.89667C78.8929 8.89667 78.5129 8.95534 78.1778 9.07266C77.8508 9.18999 77.5362 9.42464 77.2338 9.77662C76.9641 10.1035 76.7475 10.5518 76.584 11.1217C76.4287 11.6916 76.3511 12.4081 76.3511 13.2713C76.3511 14.0423 76.4205 14.7127 76.5595 15.2826C76.6984 15.8441 76.9028 16.2966 77.1725 16.6402C77.434 16.9671 77.7446 17.2059 78.1042 17.3568C78.472 17.5076 78.8888 17.583 79.3547 17.583C79.7552 17.583 80.1352 17.516 80.4948 17.3819C80.8626 17.2394 81.1732 17.0048 81.4266 16.6779Z"
                fill="#E56A59"
            />
            <path
                d="M100 10.4932H99.6077C99.4197 10.4261 99.1173 10.3758 98.7005 10.3423C98.2836 10.3088 97.9363 10.292 97.6584 10.292C97.0291 10.292 96.4733 10.3339 95.9911 10.4177C95.5089 10.5015 94.9899 10.644 94.4341 10.8451V20.2983H90.0206V6.1814H94.4341V8.25557C95.4067 7.40076 96.2526 6.83508 96.9719 6.55852C97.6911 6.27358 98.3531 6.13112 98.9579 6.13112C99.1132 6.13112 99.2889 6.13531 99.4851 6.14369C99.6812 6.15207 99.8529 6.16464 100 6.1814V10.4932Z"
                fill="#E56A59"
            />
        </Svg>
    )
}

const Svg = styled.svg`
    fill: ${({ theme }) => theme.colorPrimary};
`

export default Logo
