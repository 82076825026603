import React, { useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import { Trans } from '@lingui/macro'
import * as routes from 'routes/index'
import { linkOffice365Account } from 'util/office365'
import useAppState from 'components/hooks/useAppState'
import completeOnboarding from 'api/completeOnboarding'
import createCoworkers from 'api/createCoworkers'
import updateAccountStateCompletion from 'api/updateAccountStateCompletion'
import PageNotFound from 'components/layout/PageNotFound'
import PageWithState from 'components/layout/PageWithState'
import useNotifications from 'components/hooks/useNotifications'
import useOnboardingForm from 'components/pages/onboarding/admin-onboarding/useAdminOnboardingForm'
import ModalInviteUsers from 'components/pages/onboarding/admin-onboarding/AdminOnboardingModalInviteUsers'
import ModalLinkO365 from 'components/pages/onboarding/admin-onboarding/AdminOnboardingModalLinkO365'
import ModalWelcome from 'components/pages/onboarding/admin-onboarding/AdminOnboardingModalWelcome'
import ModalGetStarted from 'components/pages/onboarding/admin-onboarding/AdminOnboardingModalGetStarted'
import useAppSecrets from 'components/hooks/useAppSecrets'
import useTracking from 'components/hooks/useTracking'
import updateSubscription from 'api/updateSubscription'
import { PRODUCT_SLUG_PLUGIN } from 'util/constants'
import useTenantSettings from 'components/hooks/useTenantSettings'

const TOTAL_STEPS = 5

const AdminOnboardingModalWithState = ({ ...props }) => {
    const navigate = useNavigate()
    const { step } = useParams()
    const location = useLocation()
    const { state : authorizReponse } = location
    const { refresh } = useAppState()
    const { currentPlan } = useTenantSettings()
    const { trackError } = useTracking()
    const { o365DrivesClientId, o365MailsAndCalendarsClientId } = useAppSecrets()
    const apolloClient = useApolloClient()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { dispatchError } = useNotifications()
    const formState = useOnboardingForm()
    const { values } = formState
    const currentStep = Number(step)
    const nextStep = (stepSize = 1) =>
        navigate(routes.adminOnboarding(currentStep + stepSize))
    const [disclaimerChecked, checkDisclaimer] = useState(false)
    switch (currentStep) {
        case 1:
            return (
                <PageWithState {...props}>
                    <ModalWelcome
                        showNextButton
                        totalSteps={TOTAL_STEPS}
                        isSubmitting={isSubmitting}
                        onNext={() => {
                            if (
                                currentPlan.product.slug === PRODUCT_SLUG_PLUGIN
                            ) {
                                nextStep(2)
                            } else {
                                nextStep()
                            }
                        }}
                        {...props}
                    />
                </PageWithState>
            )
        case 2:
            return (
                <PageWithState {...props}>
                    <ModalInviteUsers
                        showNextButton={values.emailAddresses.length > 0}
                        showSkipButton={values.emailAddresses.length === 0}
                        totalSteps={TOTAL_STEPS}
                        isSubmitting={isSubmitting}
                        formState={formState}
                        onNext={async () => {
                            setIsSubmitting(true)
                            const coworkersInput = formState.valuesToInput()
                            const quantity =
                                coworkersInput.emailAddresses.length + 1
                            const subscriptionInput = {
                                planId: currentPlan.id,
                                quantity,
                            }
                            try {
                                await updateSubscription(
                                    apolloClient,
                                    subscriptionInput,
                                )
                                await Promise.all([
                                    createCoworkers(
                                        apolloClient,
                                        coworkersInput.emailAddresses,
                                    ),
                                    updateAccountStateCompletion(apolloClient, {
                                        hasInvitedUsers: true,
                                    }),
                                ])
                                nextStep()
                            } catch (e) {
                                trackError(e)
                                dispatchError({
                                    title: <Trans>Something went wrong!</Trans>,
                                    content: e.message,
                                })
                            } finally {
                                setIsSubmitting(false)
                            }
                        }}
                        onSkip={async () => {
                            setIsSubmitting(true)
                            try {
                                await updateAccountStateCompletion(
                                    apolloClient,
                                    { hasInvitedUsers: true },
                                )
                                nextStep()
                            } catch (e) {
                                trackError(e)
                                dispatchError({
                                    title: <Trans>Something went wrong!</Trans>,
                                    content: e.message,
                                })
                            } finally {
                                setIsSubmitting(false)
                            }
                        }}
                        {...props}
                    />
                </PageWithState>
            )
        case 3: // link to Mail&Calendar
            return (
                <PageWithState {...props}>
                    <ModalLinkO365
                        authorizReponse={authorizReponse}
                        linkTo="mails_calendars"
                        showNextButton
                        totalSteps={TOTAL_STEPS}
                        isSubmitting={isSubmitting}
                        onNext={() => nextStep()}
                        onLinkOffice365Account={async () => {
                            setIsSubmitting(true)
                            try {
				            await linkOffice365Account(
                                o365MailsAndCalendarsClientId,
                                '',
                                '/admin-onboarding',
                                'mails_calendars',
                            )
                                await updateAccountStateCompletion(
                                    apolloClient,
                                    {  hasLinkedO365: true
                                     },
                                )
                            } catch (e) {
                                trackError(e)
                                dispatchError({
                                    title: <Trans>Something went wrong!</Trans>,
                                    content: e.message,
                                })
                            } finally {
                                setIsSubmitting(false)
                            }
                        }}
                        {...props}
                    />
                </PageWithState>
            )
            case 4: // link to Drives
                return (
                    <PageWithState {...props}>
                        <ModalLinkO365
                            authorizReponse={authorizReponse}
                            linkTo="drives"
                            showSkipButton
                            totalSteps={TOTAL_STEPS}
                            isSubmitting={isSubmitting}
                            onSkip={() => nextStep()}
                            onLinkOffice365Account={async () => {
                                setIsSubmitting(true)
                                try {
				                    await linkOffice365Account(
                                    o365DrivesClientId, 
                                    '', 
                                    '/admin-onboarding',
                                    'drives',
                                    )
                                    await updateAccountStateCompletion(
                                        apolloClient,
                                        { hasLinkedO365: true },
                                    )
                                } catch (e) {
                                    trackError(e)
                                    dispatchError({
                                        title: <Trans>Something went wrong!</Trans>,
                                        content: e.message,
                                    })
                                } finally {
                                    setIsSubmitting(false)
                                }
                            }}
                            {...props}
                        />
                    </PageWithState>
                )    
        case 5:
            return (
                <PageWithState {...props}>
                    <ModalGetStarted
                        disclaimerChecked={disclaimerChecked}
                        handleCheckDisclaimer={checkDisclaimer}
                        isSubmitting={isSubmitting}
                        onGetStarted={async () => {
                            setIsSubmitting(true)
                            try {
                                await completeOnboarding(apolloClient)
                                navigate(routes.home())
                                refresh()
                            } catch (e) {
                                trackError(e)
                                dispatchError({
                                    title: <Trans>Something went wrong!</Trans>,
                                    content: e.message,
                                })
                            } finally {
                                setIsSubmitting(false)
                            }
                        }}
                        {...props}
                    />
                </PageWithState>
            )
        default:
            return <PageNotFound />
    }
}

export default AdminOnboardingModalWithState
