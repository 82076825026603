export default function loadScript(url) {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = url
        script.async = true
        script.addEventListener('load', () => resolve(script), false)
        script.addEventListener('error', () => reject(script), false)
        document.body.appendChild(script)
    })
}
