import React from 'react'
import { Trans } from '@lingui/macro'
import Dropdown from 'components/layout/Dropdown'
import useAppState from 'components/hooks/useAppState'
import { gql } from 'graphql-tag'
import PermissionWrapper from '../../util/PermissionWrapper'

const FRAGMENT = gql`
    fragment SettingsCoworkersActionsPopover on UnifiedPermissions {
        canDelete {
            isHidden
            reasonSlug
            value
        }
    }
`

const SettingsCoworkersActionsPopover = ({
    coworker,
    onUpdateCoworker,
    onRemoveCoworker,
    onResendCoworkerCrendentials,
    children,
}) => {
    const { currentUser } = useAppState()
    const currentUserIsCoworker = currentUser.id === coworker.id
    return (
        <Dropdown
            listItems={
                <Dropdown.List>
                    <Dropdown.HeaderListItem>
                        <Trans>Actions</Trans>
                    </Dropdown.HeaderListItem>
                    <Dropdown.ListItem
                        isSmaller
                        iconName="edit"
                        onClick={onUpdateCoworker}
                    >
                        <Trans>Update coworker</Trans>
                    </Dropdown.ListItem>
                    <PermissionWrapper
                        permission={coworker.permissions.canDelete}
                    >
                        <Dropdown.ListItem
                            isSmaller
                            iconName="delete"
                            onClick={onRemoveCoworker}
                            disabled={currentUserIsCoworker}
                        >
                            <Trans>Delete coworker</Trans>
                        </Dropdown.ListItem>
                    </PermissionWrapper>
                    <Dropdown.ListItem
                        isSmaller
                        iconName="outlook"
                        onClick={onResendCoworkerCrendentials}
                    >
                        <Trans>Send credential info</Trans>
                    </Dropdown.ListItem>
                </Dropdown.List>
            }
        >
            {children}
        </Dropdown>
    )
}

SettingsCoworkersActionsPopover.FRAGMENT = FRAGMENT

export default SettingsCoworkersActionsPopover
