import React from 'react'
import { Trans, useLingui } from '@lingui/react'
import OnboardingModal from 'components/pages/onboarding/OnboardingModal'
import Input from 'components/util/Input'
import FormItem from 'components/util/FormItem'
import { clientFilterOptions } from 'util/index'
import SearchSelect from 'components/util/SearchSelect'

const UserOnboardingUpdateProfile = ({
    isLoading,
    genders,
    cultures,
    formState,
    ...props
}) => {
    const { getNativeInputProps, getInputProps, getErrorMessages } = formState
    const { i18n } = useLingui()
    return (
        <OnboardingModal
            title={<Trans id="Your profile" />}
            subTitle={<Trans id="Please complete your profile" />}
            {...props}
        >
            <FormItem
                label={<Trans id="First name" />}
                error={getErrorMessages('firstName')}
                required
            >
                <Input
                    {...getNativeInputProps('firstName')}
                    placeholder={i18n._('First name')}
                />
            </FormItem>
            <FormItem
                label={<Trans id="Name" />}
                error={getErrorMessages('name')}
                required
            >
                <Input
                    {...getNativeInputProps('name')}
                    placeholder={i18n._('Name')}
                />
            </FormItem>
            <FormItem
                label={<Trans id="Language" />}
                error={getErrorMessages('preferredCulture')}
                required
            >
                <SearchSelect
                    {...getInputProps('preferredCulture')}
                    isFixed
                    isDisabled={isLoading}
                    defaultOptions={cultures}
                    fetchOptions={async (input) =>
                        clientFilterOptions(input, cultures)
                    }
                    placeholder={i18n._('Select language')}
                />
            </FormItem>
        </OnboardingModal>
    )
}

export default UserOnboardingUpdateProfile
