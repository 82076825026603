import React from 'react'
import styled, { css } from 'styled-components'
import Icon from 'components/util/Icon'
import Table from 'components/util/Table'

const NewTabLinkButton = ({ onClickNewTab, children, ...props }) => {
    const { disabled } = props
    const canOpenInNewTab = typeof onClickNewTab === 'function'
    return (
        <Link {...props}>
            {children}
            {canOpenInNewTab && (
                <IconNewTab
                    isSmallest
                    name="openInTab"
                    disabled={disabled}
                    onClick={(e) => {
                        onClickNewTab()
                        e.stopPropagation()
                    }}
                />
            )}
        </Link>
    )
}

const IconNewTab = styled(Icon)`
    color: ${({ theme }) => theme.colorPrimary};
    margin-left: ${({ theme }) => theme.spacingSmallest}rem;
    visibility: hidden;
`

const Link = styled.a`
    :hover {
        color: ${({ theme }) => theme.colorPrimary};
        border-bottom-width: ${({ theme }) => theme.defaultBorderWidth}rem;
        border-color: ${({ theme }) => theme.colorPrimary};
        ${IconNewTab} {
            visibility: visible;
        }
    }
    ${({ disabled }) =>
        disabled &&
        css`
            cursor: not-allowed;
        `}
    ${Table.Row}:hover & {
        color: ${({ theme }) => theme.colorPrimary};
        border-bottom-width: ${({ theme }) => theme.defaultBorderWidth}rem;
        border-color: ${({ theme }) => theme.colorPrimary};
        ${IconNewTab} {
            visibility: visible;
        }
    }
`

export default NewTabLinkButton
