import React from 'react'
import styled from 'styled-components'
import Spinner from 'components/util/Spinner'

const AppLoading = () => (
    <Container>
        <Spinner />
    </Container>
)

const Container = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export default AppLoading
