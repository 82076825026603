import { useQuery as useApolloQuery } from '@apollo/client'

export const useCacheAndNetworkQuery = (query, options) => {
    const result = useApolloQuery(query, {
        ...options,
        fetchPolicy: 'cache-and-network',
    })

    const inCache = result.data !== undefined
    if (inCache) {
        return {
            ...result,
            loading: false,
            refetching: result.loading,
        }
    }

    return {
        ...result,
        refetching: result.loading,
    }
}

export const useNetworkOnlyQuery = (query, options) => {
    const result = useApolloQuery(query, {
        ...options,
        fetchPolicy: 'network-only',
    })
    return result
}

export const useQuery = useApolloQuery
