import useAppState from 'components/hooks/useAppState'
import { whereId } from 'util/index'

export default function useAppCultures() {
    const { interfaceCultures, invoicingCultures } = useAppState()

    const cultures = [
        ...interfaceCultures,
        ...invoicingCultures.filter(
            ({ id }) => !interfaceCultures.find(whereId(id)),
        ),
    ]

    return {
        interfaceCultures,
        invoicingCultures,
        cultures,
    }
}
