import React from 'react'
import { Trans } from '@lingui/macro'
import ModalTopBar from 'components/layout/ModalTopBar'
import Modal, { ModalMainContainer } from 'components/layout/Modal'
import ModalConfirmButtonBar from 'components/layout/ModalConfirmButtonBar'
import TenantSelectionImpersonationForm from './TenantSelectionImpersonationForm'

const TenantSelectionImpersonationModal = ({
    formState,
    onClose,
    onSubmit,
    isSubmitting,
}) => (
    <Modal ariaLabel="Remote support reason">
        <ModalTopBar
            title={<Trans>Enter the reason for remote support</Trans>}
            onClose={onClose}
        />
        <ModalMainContainer>
            <TenantSelectionImpersonationForm formState={formState} />
        </ModalMainContainer>
        <ModalConfirmButtonBar
            isSubmitting={isSubmitting}
            onCancel={onClose}
            onSubmit={onSubmit}
        />
    </Modal>
)

export default TenantSelectionImpersonationModal
