/* eslint-disable no-param-reassign */
import React from 'react'
import { where } from 'util/index'
import { Trans } from '@lingui/macro'
import {
    CUSTOM_FIELD_TYPE_SLUG_DATE,
    CUSTOM_FIELD_TYPE_SLUG_DATETIME,
} from './constants'
import { parseDate, isValidDate, getDateString } from './dates'

export const containsErrors = (errors) => errors.length > 0

export const containsNoErrors = (errors) => errors.length === 0

export const getErrorsPerStep = (keysPerStep, errors) =>
    keysPerStep.map((stepKeys) =>
        stepKeys.reduce((stepErrors, key) => {
            const error = errors.find(({ path }) => path.includes(key))
            if (typeof error !== 'undefined') {
                return [...stepErrors, error]
            }
            return stepErrors
        }, []),
    )

export const getNextStep = (steps, currentStep) => {
    const currentStepIndex = steps.indexOf(currentStep)
    const nextStep = steps[currentStepIndex + 1]
    if (typeof nextStep !== 'undefined') {
        return nextStep
    }
    return null
}

export const getPreviousStep = (steps, currentStep) => {
    const currentStepIndex = steps.indexOf(currentStep)
    const previousStep = steps[currentStepIndex - 1]
    if (typeof previousStep !== 'undefined') {
        return previousStep
    }
    return null
}

export const mapInitialFormValues = (initialValues) =>
    Object.keys(initialValues).reduce((acc, curr) => {
        if (initialValues[curr] === null) {
            acc[curr] = ''
        } else {
            acc[curr] = initialValues[curr]
        }
        return acc
    }, {})

export const getValuesToValidate = (formValues, validationPaths) => {
    if (!validationPaths.length) {
        return formValues
    }
    return validationPaths.reduce((obj, field) => {
        const newObj = obj
        if (typeof formValues[field] !== 'undefined') {
            newObj[field] = formValues[field]
        }
        return newObj
    }, {})
}

const whereCustomFieldId = where('customFieldId')

export const getCustomFieldsValidation = (customFields = []) => {
    const validationRules = customFields.reduce((acc, customField) => {
        if (
            customField.type.slug === CUSTOM_FIELD_TYPE_SLUG_DATETIME ||
            customField.type.slug === CUSTOM_FIELD_TYPE_SLUG_DATE
        ) {
            const rule = {
                path: `customField${customField.id}.value`,
                validate: (date) =>
                    !customField.isRequired || isValidDate(date),
                message: <Trans>Invalid date</Trans>,
            }
            acc.push(rule)
        } else if (customField.isRequired) {
            const rule = {
                path: `customField${customField.id}.value`,
                validate: (field) => field !== '',
                message: <Trans>This field is required</Trans>,
            }
            acc.push(rule)
        }
        return acc
    }, [])
    return validationRules
}

export const getCustomFieldsValues = (
    customFields = [],
    customFieldValues = [],
) =>
    customFields.reduce((values, customField) => {
        let value = ''
        const path = `customField${customField.id}`
        const customFieldValue = customFieldValues.find(
            whereCustomFieldId(customField.id),
        )
        if (
            typeof customFieldValue !== 'undefined' &&
            customFieldValue.value !== null
        ) {
            const { type } = customField
            if (
                type.slug === CUSTOM_FIELD_TYPE_SLUG_DATE ||
                type.slug === CUSTOM_FIELD_TYPE_SLUG_DATETIME
            ) {
                const dateValue = parseDate(customFieldValue.value)
                value = isValidDate(dateValue) ? dateValue : null
            } else {
                ;({ value } = customFieldValue)
            }
        }
        if (
            typeof customFieldValue !== 'undefined' &&
            customFieldValue.selectedDatasetItem !== null
        ) {
            value = customFieldValue.selectedDatasetItem
        }
        const field = {
            customFieldId: customField.id,
            typeSlug: customField.type.slug,
            value,
        }
        values[path] = field
        return values
    }, {})

export const getCustomFieldApiValues = (formValues = {}) =>
    Object.values(formValues).reduce((apiValues, formValue) => {
        if (
            typeof formValue !== 'undefined' &&
            typeof formValue.customFieldId !== 'undefined' &&
            typeof formValue.value !== 'undefined' &&
            formValue.value !== null &&
            formValue.value !== ''
        ) {
            const { __typename } = formValue.value
            if (__typename === 'DatasetItem') {
                apiValues.push({
                    customFieldId: formValue.customFieldId,
                    selectedDataSetItemId: formValue.value.id,
                })
            } else if (formValue.typeSlug === CUSTOM_FIELD_TYPE_SLUG_DATE) {
                apiValues.push({
                    customFieldId: formValue.customFieldId,
                    value: getDateString(formValue.value),
                })
            } else {
                apiValues.push({
                    customFieldId: formValue.customFieldId,
                    value: formValue.value,
                })
            }
        }
        return apiValues
    }, [])
