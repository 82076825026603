import React from 'react'
import { Trans } from '@lingui/macro'
import styled from 'styled-components'
import TextArea from 'components/util/TextArea'
import FormItem from 'components/util/FormItem'
import TimeInput from 'components/util/TimeInput'
import DatePicker from 'components/util/DatePicker'
import Checkbox from 'components/util/Checkbox'
import ActivityTypeInputSearch from 'components/util/ActivityTypeInputSearch'
import ProjectSearchSelect from 'components/util/ProjectSearchSelect'
import ProjectLinksCardPopover from 'components/util/ProjectLinksCardPopover'
import ProjectTimesheetDurationCardPopover from 'components/util/ProjectTimesheetDurationCardPopover'
import ActivityCategorySearchSelect from 'components/util/ActivityCategorySearchSelect'

const ActivityForm = ({ formState, projectGuesses, isFetching }) => {
    const {
        values,
        getValue,
        getErrorMessages,
        getInputProps,
        getNativeInputProps,
        handleChangeProject,
        handleChangeCategory,
        handleChangeStartTime,
        handleChangeEndTime,
        handleChangeDuration,
        handleChangeDescription,
    } = formState
    let invoicingLanguage = ''
    if (
        values.project !== null &&
        typeof values.project.invoicingLanguage !== 'undefined'
    ) {
        invoicingLanguage = `(${formState.values.project.invoicingLanguage.translation})`
    }
    return (
        <>
            <FormItem
                label={<Trans>Project</Trans>}
                error={getErrorMessages('project')}
            >
                <div className="flex flex-row items-center justify-between gap-x-4">
                    <ProjectSearchSelect
                        id="activityFormProjectSearchSelect"
                        {...getInputProps('project')}
                        defaultOptions={projectGuesses}
                        onChange={handleChangeProject}
                        disabled={isFetching}
                        className="flex-1"
                    />
                    <ProjectLinksCardPopover {...getInputProps('project')} />
                    <ProjectTimesheetDurationCardPopover
                        project={getValue('project')}
                    />
                </div>
            </FormItem>
            <FormItem
                label={<Trans>Category and type</Trans>}
                error={getErrorMessages('category')}
                required
            >
                <div className="flex justify-between items-end gap-x-4">
                    <ActivityCategorySearchSelect
                        id="activityFormActivityCategorySelect"
                        {...getInputProps('category')}
                        onChange={handleChangeCategory}
                        key={values?.project?.id}
                        projectId={values?.project?.id}
                        className="flex-1"
                    />
                    <ActivityTypeInputSearch
                        {...getInputProps('type')}
                        defaultValue
                        className="flex-1"
                    />
                </div>
            </FormItem>
            <FormItem
                label={<Trans>Description {invoicingLanguage}</Trans>}
                error={getErrorMessages('description')}
                onChange={handleChangeDescription}
                required
            >
                <TextArea minRows="5" {...getNativeInputProps('description')} />
            </FormItem>
            <FlexContainer>
                <FormItem
                    label={<Trans>Date</Trans>}
                    error={getErrorMessages('date')}
                    style={{ marginRight: '1rem' }}
                    required
                >
                    <DatePicker {...getInputProps('date')} />
                </FormItem>
                <FormItem
                    label={<Trans>Start</Trans>}
                    error={getErrorMessages('start')}
                    required
                >
                    <TimeInput
                        {...getNativeInputProps('start')}
                        onChange={handleChangeStartTime}
                        hasAddonAfter
                    />
                </FormItem>
                <FormItem
                    label={<Trans>End</Trans>}
                    error={getErrorMessages('end')}
                    required
                >
                    <TimeInput
                        {...getNativeInputProps('end')}
                        onChange={handleChangeEndTime}
                        hasAddonBefore
                    />
                </FormItem>
                <FormItem
                    label={<Trans>Duration</Trans>}
                    error={getErrorMessages('duration')}
                    style={{ marginLeft: '1rem' }}
                    required
                >
                    <TimeInput
                        {...getNativeInputProps('duration')}
                        onChange={handleChangeDuration}
                    />
                </FormItem>
            </FlexContainer>
            <BottomContainer>
                <Checkbox
                    checked={values.isBillable}
                    {...getNativeInputProps('isBillable')}
                >
                    <Trans>Billable</Trans>
                </Checkbox>
            </BottomContainer>
        </>
    )
}

const FlexContainer = styled.div`
    display: flex;
    align-items: top;
    justify-content: space-between;
`

const BottomContainer = styled.div(
    ({ theme }) => `
    display: flex;
    margin-top: ${theme.spacingSmall}rem;
`,
)

export default ActivityForm
