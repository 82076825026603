import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { MockedProvider } from '@apollo/client/testing'

const ThemeProvider = ({ client = null, children }) => {
    if (client === null) {
        return <MockedProvider>{children}</MockedProvider>
    }
    return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default ThemeProvider
