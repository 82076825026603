import React from 'react'
import { Trans } from '@lingui/react'
import ModalTopBar from 'components/layout/ModalTopBar'
import Modal, { ModalMainContainer } from 'components/layout/Modal'
import ModalConfirmButtonBar from 'components/layout/ModalConfirmButtonBar'
import * as storage from 'util/storage'

const ModalInformExpirToken = ({ logout, isOpen, onCancel, daysLeft }) => (
    <Modal
        data-testid="Session almost expired"
        ariaLabel="Session almost expired"
        isOpen={isOpen}
    >
        <ModalTopBar title={<Trans id="Session almost expired" />} />
        <ModalMainContainer>
            <Trans
                id="You're Lexor session is about to expire. Please login again within {daysLeft} days to keep using Lexor"
                values={{
                    daysLeft,
                }}
            />
        </ModalMainContainer>
        <ModalConfirmButtonBar
            onSubmit={() => logout()}
            onCancel={() => onCancel()}
            submitText={<Trans id="Login again" />}
            cancelText={<Trans id="Cancel" />}
        />
    </Modal>
)

export default ModalInformExpirToken
