import React, { forwardRef, useState } from 'react'
import styled, { css, withTheme } from 'styled-components'

const MIN_ROWS = 1
const MAX_ROWS = 10

const getTextAreaRows = (e, minRows, maxRows, theme) => {
    const textAreaPadding = theme.spacingSmaller * 10
    const textAreaLineHeight = theme.defaultLineHeight * 10
    const previousRows = e.target.rows
    e.target.rows = minRows
    const currentRows = Math.floor(
        (e.target.scrollHeight - textAreaPadding) / textAreaLineHeight,
    )
    if (currentRows === previousRows) {
        e.target.rows = currentRows
    }
    if (currentRows >= maxRows) {
        e.target.rows = maxRows
        e.target.scrollTop = e.target.scrollHeight
    }
    return currentRows < maxRows ? currentRows : maxRows
}

const TextArea = forwardRef(
    (
        {
            onChange,
            theme,
            minRows = MIN_ROWS,
            label,
            maxRows = MAX_ROWS,
            ...props
        },
        ref,
    ) => {
        const [rows, setRows] = useState(minRows)
        return (
            <div className="relative">
                {label && (
                    <label
                        className="absolute top-[-10px] left-5 flex items-center pointer-events-none text-xs bg-white px-1"
                        htmlFor="fname"
                        id="label-fname"
                    >
                        <div className="text-[12px]">{label}</div>
                    </label>
                )}
                <StyledTextArea
                    {...props}
                    ref={ref}
                    rows={rows}
                    onChange={(e) => {
                        setRows(getTextAreaRows(e, minRows, maxRows, theme))
                        onChange(e)
                    }}
                />
            </div>
        )
    },
)

const StyledTextArea = styled.textarea`
    resize: none;
    height: auto;
    overflow: auto;
    width: ${({ width }) => (width ? `${width}rem` : '100%')};
    font-size: ${({ theme }) => theme.defaultFontSize}rem;
    line-height: ${({ theme }) => theme.defaultLineHeight}rem;
    border-width: ${({ theme }) => theme.borderWidth}rem;
    border-color: ${({ theme, hasError }) =>
        `${hasError ? theme.colorRed : theme.defaultBorderColor}`};
    border-radius: ${({ theme }) => theme.defaultBorderRadius}rem;
    ${({ hasAddonBefore }) =>
        hasAddonBefore &&
        css`
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        `}
    ${({ hasAddonAfter }) =>
        hasAddonAfter &&
        css`
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        `}
    padding: ${({ theme }) => theme.spacingSmaller}rem;
    color: ${({ theme, hasError }) =>
        hasError ? theme.colorRed : theme.colorBlack};
    opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
    background-color: ${({ theme }) => theme.colorWhite};
    :hover {
        border-color: ${({ theme }) => theme.colorGrey};
    }
    ::placeholder {
        color: ${({ theme, hasError }) =>
            hasError ? theme.colorRed : theme.colorGreyLight};
        font-style: italic;
    }
    :disabled {
        background-color: ${({ theme }) => theme.colorGreyWhite};
    }
`

export default withTheme(TextArea)
