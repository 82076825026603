import React from 'react'
import styled from 'styled-components'

const SubTitle = ({ children, actions, ...props }) => (
    <StyledSubTitle {...props}>
        {children}
        {typeof actions !== 'undefined' && (
            <ActionContainer>{actions}</ActionContainer>
        )}
    </StyledSubTitle>
)

const StyledSubTitle = styled.h4(
    ({ theme }) => `
    display: flex;
    align-items: center;
    font-size: ${theme.fontSizeLarge}rem;
    font-weight: ${theme.fontWeightMedium};
    color: ${theme.defaultFontColorDark};
`,
)

const ActionContainer = styled.span`
    margin-left: ${({ theme }) => theme.spacingLarge}rem;
`

export default SubTitle
