import React from 'react'
import styled from 'styled-components'
import { capitalizeFirstLetter } from 'util/index'

export const DEFAULT_LABEL_WITH = 12

const FormDetails = ({
    label,
    labelWidth = DEFAULT_LABEL_WITH,
    isHorizontal = true,
    value,
    children,
}) => (
    <Container isHorizontal={isHorizontal}>
        <Label width={labelWidth} isHorizontal={isHorizontal}>
            {label}
            {label && ':'}
        </Label>
        <ChildContainer isHorizontal={isHorizontal}>
            {(() => {
                if (value === null || value === false) {
                    return '-'
                }
                if (typeof value === 'string') {
                    return capitalizeFirstLetter(value)
                }
                if (typeof value !== 'undefined') {
                    return value
                }
                return children
            })()}
        </ChildContainer>
    </Container>
)

const Container = styled.div(
    ({ theme, isHorizontal }) => `
    display: flex;
    flex-direction: ${isHorizontal ? 'row' : 'column'};
    margin-bottom: ${theme.spacingSmallest}rem;
`,
)

const Label = styled.label(
    ({ theme, width, isHorizontal }) => `
    display: flex;
    justify-content: ${isHorizontal ? 'flex-end' : 'flex-start'};
    color: ${theme.colorGrey};
    min-width:  ${width}rem;
    ${
        !isHorizontal &&
        `
        margin-bottom: ${theme.spacingSmaller}rem;
    `
    }
`,
)

const ChildContainer = styled.div(
    ({ theme, isHorizontal }) => `
    flex: 1;
    margin-left: ${isHorizontal ? theme.spacing : 0}rem;
`,
)

export default FormDetails
