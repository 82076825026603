/* eslint-disable react/no-unstable-nested-components */
import React, {
    forwardRef,
    useEffect,
    useState,
    useRef,
    useLayoutEffect,
} from 'react'
import clsx from 'clsx'
import Icon from './Icon'
import DropdownMenu from './DropdownMenu'
import Checkbox from './Checkbox'

const MultiSelect = forwardRef(
    (
        {
            id,
            hasError,
            disabled,
            className,
            dropdownClassName,
            content,
            children,
            ...props
        },
        ref,
    ) => {
        const [width, setWidth] = useState(null)
        const selectRef = useRef(null)
        useLayoutEffect(() => {
            if (selectRef.current) {
                setWidth(selectRef.current.offsetWidth)
            }
        }, [])
        return (
            <DropdownMenu
                as="div"
                id={id}
                ref={ref}
                disabled={disabled}
                className={dropdownClassName}
                style={{ minWidth: `${width}px` }}
                content={content}
                tabIndex={-1}
                {...props}
            >
                <div
                    ref={selectRef}
                    className={clsx(
                        'relative mt-1 shadow-sm flex flex-wrap items-center gap-y-1 w-full rounded-lg border pl-2.5 pr-12 py-2 text-base min-h-[38px] overflow-hidden hover:border-gray-400',
                        hasError
                            ? 'border-red-300 text-red-600'
                            : 'border-gray-300',
                        className,
                    )}
                >
                    {children}
                </div>
            </DropdownMenu>
        )
    },
)

MultiSelect.SearchInput = ({
    value,
    inputValue,
    disabled,
    onClear,
    className,
    ...props
}) => (
    <>
        <input
            type="text"
            className={clsx(
                'border-none text-base p-0 bg-transparent',
                value.length > 0 || inputValue
                    ? 'placeholder-transparent'
                    : 'placeholder-gray-400 placeholder:italic',
                disabled ? 'cursor-not-allowed' : '',
                className,
            )}
            value={inputValue}
            disabled={disabled}
            {...props}
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-4">
            {value.length > 0 || inputValue ? (
                <Icon
                    name="cross"
                    onClick={onClear}
                    className="w-5 h-5 text-gray-400 cursor-pointer"
                    aria-hidden="true"
                />
            ) : (
                <Icon
                    name="search"
                    className="w-5 h-5 text-gray-400"
                    aria-hidden="true"
                />
            )}
        </div>
    </>
)

MultiSelect.Input = ({ value, onClear, disabled, className, ...props }) => (
    <>
        <input
            type="text"
            className={clsx(
                'border-none text-base p-0 bg-transparent caret-transparent',
                disabled ? 'cursor-not-allowed' : 'cursor-default',
                value.length > 0
                    ? 'placeholder-transparent w-[1px]'
                    : 'placeholder-gray-400 placeholder:italic w-full',
                className,
            )}
            disabled={disabled}
            onChange={() => {}}
            {...props}
        />
        {value.length > 0 ? (
            <div className="absolute inset-y-0 right-6 flex items-center pr-4">
                <Icon
                    name="cross"
                    onClick={onClear}
                    className="w-5 h-5 text-gray-400 cursor-pointer"
                    aria-hidden="true"
                />
            </div>
        ) : null}
        <div className="absolute inset-y-0 right-0 flex items-center pr-4">
            <Icon
                name="chevronDown"
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
            />
        </div>
    </>
)

MultiSelect.Value = ({ label, onRemove = () => {} }) => (
    <div className="flex bg-primary text-white rounded-md mr-1 max-w-xs">
        <div className="rounded-md overflow-hidden text-ellipsis whitespace-nowrap py-[2px] pl-2.5 pr-2">
            {label}
        </div>
        <div
            tabIndex={0}
            role="button"
            onClick={onRemove}
            className="flex items-center text-white rounded-r-sm px-2 cursor-pointer hover:bg-primary-300 hover:text-primary"
            aria-hidden="true"
        >
            <Icon name="close" isSmallest className="w-2.5 h-2.5" />
        </div>
    </div>
)

const Option = ({ label, onClick, isActive = false, ...props }) => {
    const ref = useRef(null)
    useEffect(() => {
        if (ref.current && isActive) {
            ref.current.scrollIntoView(false)
        }
    }, [isActive])
    return (
        <li
            ref={ref}
            role="option"
            aria-hidden="true"
            aria-selected={isActive}
            className="p-3 bg-white text-black hover:bg-primary-200 aria-selected:bg-primary-200 first-letter:list-none"
            onMouseDown={(e) => {
                e.preventDefault()
                e.stopPropagation()
                onClick()
            }}
            onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                onClick()
            }}
            {...props}
        >
            {label}
        </li>
    )
}

MultiSelect.Option = Option

MultiSelect.CheckboxOption = ({ label, ...props }) => (
    <div className="p-3 bg-white text-gray-600 italic">
        <Checkbox {...props}>{label}</Checkbox>
    </div>
)

MultiSelect.Placeholder = ({ className, children }) => (
    <div className={clsx('p-3 w-full text-center', className)}>{children}</div>
)

export default MultiSelect
