import 'cross-fetch/polyfill'
import './styles/index.css'
import React from 'react'
import ReactDOM from 'react-dom/client'
import AppWithState from 'components/AppWithState'
import { getNotificationDispatcher } from 'util/notifications'
import createApolloClient from './apollo/createApolloClient'

const apolloClient = createApolloClient()
const notificationDispatcher = getNotificationDispatcher()

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <AppWithState
        apolloClient={apolloClient}
        notificationDispatcher={notificationDispatcher}
    />,
)
