import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { tokenEventTarget } from 'apollo/createApolloClient'
import { Trans } from '@lingui/react'
import useModalState from 'components/hooks/useModalState'
import ModalExpiredToken from 'components/layout/ModalExpiredToken'
import ModalInformExpirToken from 'components/layout/ModalInformExpirToken'
import useAppState from 'components/hooks/useAppState'
import useTracking from 'components/hooks/useTracking'
import useNotifications from 'components/hooks/useNotifications'
import useUseO365UserToLogInMutation from 'components/pages/profile/useUseO365UserToLogInMutation'
import AddO365AccountModalWithState from 'components/pages/profile/AddO365AccountModalWithState' 
import AzureAdLoginModal from 'components/pages/profile/AzureAdLoginModal'
import { findFirstExpiredUser } from 'components/pages/profile/utils'
import O365Modal from 'components/pages/profile/O365Modal' 
import * as routes from 'routes/index'
import {
    ACCOUNT_STATE_NEW,
    ACCOUNT_STATE_INVITED,
    ACCOUNT_STATE_ONBOARDING,
    ACCOUNT_STATE_LOADING,
    ACCOUNT_STATE_SUSPENDED,
} from 'util/constants'
import AppRouter from './AppRouter'



const AppRouterWithState = ({
    isAuthenticated,
    isAuthorized,
    accountState,
    accountStateDetails,
    accountStateCompletion,
    ...props
}) => {
    const { logout, 
            refresh,
            closemodalConfirmExpireToken, 
            modalConfirmExpireToken, 
            modalAddO365Account, 
            azureAdLoginModalState,
            bannerToDisplay,
            o365linkStateModal,
            setIsBannerDisplyed,
            setModalAddO365AccountState, 
            setO365linkModalState,
            setAzureAdLoginModalState,
        } = useAppState()
    const o365ModalState = useModalState()  
    const [mutateUseO365UserToLogin, { loading: isUsingO365UserToLogin }] =
    useUseO365UserToLogInMutation()
    
    const { trackError } = useTracking()
    const { dispatchError } = useNotifications()
    const navigate = useNavigate()
    const location = useLocation()
    const { search } = location
    const [isTokenExpired, setIsTokenExpired] = useState(false)
    const isNew = accountState === ACCOUNT_STATE_NEW
    const isLoading = accountState === ACCOUNT_STATE_LOADING
    const isOnboarding = accountState === ACCOUNT_STATE_ONBOARDING
    const isInvited = accountState === ACCOUNT_STATE_INVITED
    const isSuspended = accountState === ACCOUNT_STATE_SUSPENDED

    const isRedirectedOnboarding =
        window.location.href.indexOf('onboarding?code=') > -1
    const isRedirectedAuthenticating =
        window.location.href.indexOf('authenticating?code=') > -1

    useEffect(() => {
        tokenEventTarget.addEventListener('tokenExpired', () => {
            setIsTokenExpired(true)
        })

        return () => {
            tokenEventTarget.removeEventListener('tokenExpired')
        }
    }, [])

    useEffect(() => {
        const initialRouting = () => {
            if (isNew || isLoading) {
                return navigate(routes.registerLoading())
            }
            if (isOnboarding && !isRedirectedOnboarding) {
                if (!accountStateCompletion.hasInvitedUsers) {
                    return navigate(routes.adminOnboarding(1))
                }
                return navigate(routes.adminOnboarding(3))
            }
            if (isInvited && !isRedirectedOnboarding) {
                if (!accountStateCompletion.hasUpdatedProfile) {
                    return navigate(routes.userOnboarding(1))
                }
                return navigate(routes.userOnboarding(3))
            }

            if (isRedirectedAuthenticating) {
                if (!accountStateCompletion.requiredAddressOk) {
                    return navigate(routes.profile(), {
                        state: { source: 'isRedirectedAuthenticating' },
                    })
                }
                return navigate(routes.home())
            }

            if (isSuspended) {
                return navigate('/subscription-suspended')
            }
            if (
                accountStateCompletion.o365LinkStatuses.length === 0 && !bannerToDisplay.find(({type})=>type ==='review_access')
            ) {
                if(search ==='') setO365linkModalState(true, null)
            }

            if (accountStateCompletion.o365LinkStatuses.length) {
                if(!accountStateCompletion.azureAdLoginOk) {
                    setAzureAdLoginModalState(true)
                }
            
                const firstExpiredUser = findFirstExpiredUser(accountStateCompletion.o365LinkStatuses)       
                if (!firstExpiredUser) return null
                if ((firstExpiredUser.isExpired || firstExpiredUser.isExpiring) && !bannerToDisplay.find(({type})=>type ==='renew_access')) {
                    setO365linkModalState(true, firstExpiredUser.item.o365UserId)
                  } 
                else if (firstExpiredUser.isMissing && !bannerToDisplay.find(({type})=>type ==='missing_link') ) {
                // show banner with missing link if sync active
                            setIsBannerDisplyed([...bannerToDisplay, {
                                status: 'warning',
                                content: 'Link with Microsoft 365 needs your attention. ',
                                type: 'missing_link',
                                actionContent: {
                                message: 'Please renew the access on your profile page.',
                                },
                            }])
                }   
                
            }
           
            return null
        }
        // setAzureAdLoginModalState(true)
        if (isAuthenticated && accountStateCompletion.o365LinkStatuses ) {
            initialRouting()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNew])

    const handleLogout = async () => {
        setIsTokenExpired(false)
        await logout()
    }

    const handleClose = async () => {
        await closemodalConfirmExpireToken()
    }
    
    const handlOpenAddO365AccountModal = () => {
        o365ModalState.close()
        setModalAddO365AccountState(true, 'initial', 1)
    }

    const onSkipAddAccount = () => {
        setModalAddO365AccountState(false, 'initial', 1)
    } 
   
    const handleCloseAzureAdLoginModalState = async () => {
        await setIsBannerDisplyed([...bannerToDisplay,{
            status: 'warning',
            content: 'Login with Microsoft will become mandatory soon, ',
            type: 'azureAdLogin',
            actionContent: {
                message: 'go to the profile page to activate this now!',
            },
        }])
        setAzureAdLoginModalState(false)
    }

    const handleCloseO365ModalState = async (o365LinkExpirationStatus) => {
        await setIsBannerDisplyed([...bannerToDisplay,{
            status: 'warning',
            content: 'Link with Microsoft 365 needs your attention. ',
            type: o365LinkExpirationStatus
            ? 'renew_access'
            : 'review_access',
            actionContent: {
                message: ` Please ${
                    o365LinkExpirationStatus ? 'renew' : 'review'
                } the access on your profile page.`,
            },
        }])
        setO365linkModalState(false, null)
    }

     const handleUse365AccountToLogin = async () => {
        if(accountStateCompletion.o365LinkStatuses.length === 1) {
            const { o365UserId } = accountStateCompletion.o365LinkStatuses[0]
            try {
                await mutateUseO365UserToLogin({
                    variables: { o365UserId },
                    update: () => { 
                        setAzureAdLoginModalState(false)
                        refresh()
                    }
                })
                
            } catch (e) {
                trackError(e)
                dispatchError({
                    title: <Trans id="Something went wrong!" />,
                    content: e.message,
                })
            }
        } else if(accountStateCompletion.o365LinkStatuses.length > 1){
            navigate(routes.profile(),  { replace: true })
            handleCloseAzureAdLoginModalState()
        }
        
    }


    return (
        <>
            <AppRouter
                isAuthenticated={isAuthenticated}
                isAuthorized={isAuthorized}
                isNew={isNew}
                isLoading={isLoading}
                isOnboarding={isOnboarding}
                isInvited={isInvited}
                isSuspended={isSuspended}
                accountState={accountState}
                accountStateDetails={accountStateDetails}
                {...props}
            />
            {isTokenExpired && (
                <ModalExpiredToken
                    isOpen={isTokenExpired}
                    logout={handleLogout}
                />
            )}
            {azureAdLoginModalState && azureAdLoginModalState?.modalState && (
                <AzureAdLoginModal
                    onClose={handleCloseAzureAdLoginModalState}
                    activateMsLogin={handleUse365AccountToLogin}
                    o365AccountNumber={accountStateCompletion.o365LinkStatuses.length}
                />
            )}
            {modalConfirmExpireToken && modalConfirmExpireToken?.modalState && (
                <ModalInformExpirToken
                    onCancel={handleClose}
                    logout={handleLogout}
                    daysLeft={modalConfirmExpireToken?.daysLeft || 0}
                />
            )}
            {o365linkStateModal && o365linkStateModal?.modalState&& (
                <O365Modal
                    onClose={handleCloseO365ModalState}
                    onLinkOffice365Account={handlOpenAddO365AccountModal}
                    o365LinkExpirationStatus={o365linkStateModal.o365LinkExpirationStatus}
                />  
            )}

            {modalAddO365Account && modalAddO365Account?.modalState &&
                 <AddO365AccountModalWithState onClose={onSkipAddAccount}  o365LinkStatusRes={modalAddO365Account.o365LinkStatusRes}  step={modalAddO365Account.step} />
            }
        </>
    )
}

export default AppRouterWithState
