import gql from 'graphql-tag'

export const NOTIFICATIONS_QUERY = gql`
    query notifications {
        notifications {
            type
            slug
        }
    }
`

export default async function getNotifications(apolloClient, options) {
    const query = NOTIFICATIONS_QUERY
    const response = await apolloClient.query({
        query,
        ...options,
    })
    return response.data
}
