import { useContext } from 'react'
import { ASC, DESC } from 'components/util/TableSorting'
import {
    TableStateContext,
    UPDATE_SORTING,
} from 'components/providers/TableStateProvider'

const getUpdatedSorting = (previousDirection, updatedDirection) => {
    if (typeof updatedDirection === 'undefined') {
        switch (previousDirection) {
            case null: {
                return ASC
            }
            case ASC: {
                return DESC
            }
            case DESC:
                return null
            default: {
                return updatedDirection
            }
        }
    }
    return updatedDirection
}

const useTableState = () => {
    const context = useContext(TableStateContext)
    const { state, dispatch, onSort } = context

    const dispatchSort = (name, direction) => {
        const newDirection = state.activeSortName !== name ? ASC : direction
        const activeSortDirection = getUpdatedSorting(
            state.activeSortDirection,
            newDirection,
        )
        const activeSortName = activeSortDirection !== null ? name : null
        onSort(activeSortName, activeSortDirection)
        dispatch({
            type: UPDATE_SORTING,
            values: {
                activeSortName,
                activeSortDirection,
            },
        })
    }

    return {
        ...state,
        onSort: dispatchSort,
    }
}

export default useTableState
