import styled from 'styled-components'

const LinkButton = styled.a`
    color: ${({ theme }) => theme.colorPrimary};
    border-color: transparent;
    border-bottom-width: ${({ theme }) => theme.defaultBorderWidth}rem;
    :hover {
        text-decoration: underline;
    }
    :disabled {
        cursor: not-allowed;
    }
`

export default LinkButton
