import gql from 'graphql-tag'

const LINK_O365_ACCOUNT = gql`
    mutation linkO365Account($code: String!, $callBackUrl: String!, $o365PermissionType: O365PermissionTypes!) {
        linkO365Account(o365PermissionType: $o365PermissionType, authorizationCode: $code, callBackUrl: $callBackUrl)
    }
`

export default async function linkO365Account(
    apolloClient,
    code,
    callBackUrl,
    o365PermissionType,
    options,
) {
    const mutation = LINK_O365_ACCOUNT
    const variables = {
        code,
        callBackUrl,
        o365PermissionType,
    }
    try {
        const response = await apolloClient.mutate({
            mutation,
            variables,
            ...options,
            })
        return response.data.linkO365Account
    } catch (error) {
        throw new Error(error.message || 'Failed to link O365 account')
    }
}
