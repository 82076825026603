import React from 'react'
import { Trans } from '@lingui/react'
import Dropdown from './Dropdown'
import Icon from '../util/Icon'

const TimerStopDropDownButton = ({ onCreateActivity, onCancelTimer }) => (
    <Dropdown
        position="bottom-end"
        listItems={
            <Dropdown.List>
                <Dropdown.ListItem iconName="plus" onClick={onCreateActivity}>
                    <Trans id="Activity" />
                </Dropdown.ListItem>
                <Dropdown.ListItem iconName="remove" onClick={onCancelTimer}>
                    <Trans id="Cancel timer" />
                </Dropdown.ListItem>
            </Dropdown.List>
        }
    >
        <div className="flex">
            <Icon
                name="stopTimer"
                accentColor="primary"
                className="cursor-pointer text-primary-400"
            />
        </div>
    </Dropdown>
)

export default TimerStopDropDownButton
