import React from 'react'
import clsx from 'clsx'
import Icon from './Icon'
import Spinner from './Spinner'

const TertiaryButton = ({
    isLoading,
    iconLeft = null,
    iconRight = null,
    className,
    children,
    ...props
}) => (
    <button
        type="button"
        className={clsx(
            'inline-flex items-center justify-center rounded border border-primary bg-transparent px-4 py-2 text-sm font-medium text-primary shadow-sm hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2',
            className,
        )}
        {...props}
    >
        {!isLoading && iconLeft ? (
            <Icon
                name={iconLeft}
                className="-ml-1 mr-2 h-5 w-5"
                aria-hidden="true"
            />
        ) : null}
        {isLoading ? (
            <div className="-ml-1 mr-2">
                <Spinner size={12} />
            </div>
        ) : null}
        {children}
        {iconRight ? (
            <Icon
                name={iconRight}
                className="ml-2 -mr-1 h-5 w-5"
                aria-hidden="true"
            />
        ) : null}
    </button>
)

export default TertiaryButton
